//svg images

import { ReactComponent as MMMLoginScreen } from 'assets/svg/MMMLoginScreen.svg';
import { ReactComponent as RuralCoveragenPotentialPlatFormScreen } from 'assets/svg/RuralCoverageAndPotentialPlatform.svg';

import DataCrwalers from '../../assets/png/dataCrawler.png';
import RuralPotentialPlatform from '../../assets/png/newRuralPotentialPlatform.png';
import MarketingMediaMix from '../../assets/png/newMarketingMediaMix.png';
import RuralPotentialPlatformPDF from '../../assets/files/ruralMarketingCoverageAndPotentialMapping.pdf';
import MarketingMediaMixPDF from '../../assets/files/marketingMediaMix.pdf';
import CommercialChannelSalesMappingPDF from '../../assets/files/commercialChannelSalesMapping.pdf';


const marketingTiles = [
  {
    name: 'Rural Coverage And Potential Platform',
    title: 'Rural Potential Platform',
    description: 'Mapping of rural market coverage & assessment of sales potential',
    tooltip: 'Comprehensively map rural market coverage, evaluating sales potential. Consider demographic and economic factors, along with infrastructure, to strategically assess opportunities. The project aims to optimize market penetration in rural areas for sustainable growth and increased market share.',
    tileLink: 'https://dev.msilruralmaps.com/',
    vertical: 'MNS',
    // btnText: 'Explore',
    icon: RuralCoveragenPotentialPlatFormScreen,
    img:RuralPotentialPlatform,
    pdfFile:RuralPotentialPlatformPDF,
  },
  {
    name: 'Marketing Media Mix',
    title: 'Marketing Media Mix',
    description: 'Optimization of marketing media expenses for vehicle models',
    tooltip: 'Enhance marketing efficiency by optimizing media expenses across digital, print, TV, etc. channels for vehicle models. Strategically allocate resources at national and regional levels to maximize returns, ensuring cost-effectiveness and impactful market presence.',
    tileLink: 'https://www.marutisuzukimmmodel.com/',
    vertical: 'MNS',
    // btnText: 'Explore',
    icon: MMMLoginScreen,
    img:MarketingMediaMix,
    pdfFile:MarketingMediaMixPDF,
  },
  {
    name: 'Commercial Channel Mapping ',
    title: 'Commercial Channel Mapping ',
    description: 'Assess tapped and untapped markets for the commercial channel ',
    tooltip: 'Utilize GIS-based digital dashboards to map customers, evaluating tapped and untapped markets for the commercial channel, specifically Super Carry. This project aims to optimize market targeting, enhance distribution, and capitalize on growth opportunities for increased commercial success.',
    tileLink: 'https://msilcommercialsalesmaps.com/',
    vertical: 'MNS',
    // btnText: 'Explore',
    icon: MMMLoginScreen,
    img:MarketingMediaMix,
    pdfFile:CommercialChannelSalesMappingPDF,
  },
];

export default marketingTiles;
