import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getUser =
  ({ email }) =>
  (dispatch, getState) => {
    const {
      config: {
        vars: { endpoint, loginRedirectUrl },
      },
    } = getState();

    dispatch(getUserRequest());
    dispatch(addLoader('getUser'));

    return api
      .get({
        endpoint: endpoint.userManagementService,
        path: `v1/um/${email}`,
      })
      .then((res) => {
        dispatch(getUserSuccess(res?.data?.data || []));
        return res;
      })
      .catch((error) => {
        dispatch(getUserError(error));
        dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
        api.handleUnauthorized(error, dispatch, loginRedirectUrl);

        return error;
      })
      .finally(() => {
        dispatch(removeLoader('getUser'));
      });
  };

export const getUserRequest = () => {
  return {
    type: 'GET_USER_REQUEST',
  };
};

export const getUserSuccess = (data) => {
  return {
    type: 'GET_USER_SUCCESS',
    payload: data,
  };
};

export const getUserError = (error) => {
  return {
    type: 'GET_USER_FAILURE',
    payload: error,
  };
};
