import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';

// app
import { config, consts } from 'globalsData';
import { Categoriesview } from './Categories.view';
import { showModal, getCategoryList, updateCategory, searchCategories, clearSearchCategories } from 'stores';
import DCMPMainLogo from 'assets/svg/DCMPMainLogo.svg';

//mui
import { Link, Typography } from '@mui/material';

export default function Categories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => get(state, 'user.userInfo.isAdmin', false));
  const catList = useSelector((state) => get(state, 'categories.categoryList', []));
  const [searchText, setSearchText] = useState('');
  const params = useParams();

  const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
  ];

  useEffect(() => {
    dispatch(getCategoryList());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddCat = () => {
    dispatch(
      showModal({
        component: 'ADD_EDIT_CATEGORY',
        props: {
          title: 'Create New Category',
          maxWidth: 'xs',
        },
      })
    );
  };

  const handleUpdateCat = (cat) => {
    dispatch(
      showModal({
        component: 'ADD_EDIT_CATEGORY',
        props: {
          title: 'Update Category',
          maxWidth: 'xs',
          componentProps: {
            cat,
          },
        },
      })
    );
  };

  const handleCatEnable = (item) => {
    dispatch(
      updateCategory({
        categoryName: item?.categoryName,
        powerBiUrl: item?.powerBIURL,
        status: item?.status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
        categoryId: item?.categoryId,
      })
    );
  };

  const handleCatDetails = (cat) => {
    navigate(`${config.routes.dcmp.websiteManagment}/${cat.categoryName.split(' ').join('-')}/${cat.categoryId}`);
  };

  const onCatSearch = (e) => {
    setSearchText(e.target.value);
    dispatch(searchCategories(e.target.value));
  };

  const onSearchClear = () => {
    setSearchText('');
    dispatch(clearSearchCategories());
  };
  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };
  const breadcrumbs = [
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.home.root)}>
      {consts.APP_TITLE}
    </Link>,
    <Typography key="3" color="text.primary" sx={{fontSize:'14px'}}>
      {consts.FEATURE_LIST_OBJ.dcmp}
    </Typography>,
  ];

  // const bannerProps = {
  //   title: consts.FEATURE_LIST_OBJ.dcmp,
  //   paragraph1:
  //     'Platform to access and download external data from websites and their corresponding crawler scripts. This information can also be utilized as input for analytics models.',
  //   icon: DCMPMainLogo,
  // };

  return (
    <Categoriesview
      isAdmin={isAdmin}
      searchText={searchText}
      categoryList={catList}
      handlers={{ handleAddCat, handleUpdateCat, handleCatDetails, handleCatEnable, onCatSearch, onSearchClear }}
      breadcrumbs={breadcrumbs}
      // bannerProps={bannerProps}
      options={options}
    />
  );
}
