import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const downloadIncrementalData = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(downloadIncrementalDataRequest());
  dispatch(addLoader('downloadIncrementalData'));

  return api
    .get({
      endpoint: endpoint.crawlerScriptService,
      path: `v1/scraping/downloadIncremenatlData/${params?.websiteId}`,
    })
    .then((res) => {
      const url = res?.data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${'Incremental Data'}.${'csv'}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => {
      dispatch(downloadIncrementalDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('downloadIncrementalData'));
    });
};

export const downloadIncrementalDataRequest = () => {
  return {
    type: 'DOWNLOAD_INCREMENTAL_DATA_REQUEST',
  };
};

export const downloadIncrementalDataSuccess = (data) => {
  return {
    type: 'DOWNLOAD_INCREMENTAL_DATA_SUCCESS',
    payload: data,
  };
};

export const downloadIncrementalDataError = (error) => {
  return {
    type: 'DOWNLOAD_INCREMENTAL_DATA_FAILURE',
    payload: error,
  };
};
