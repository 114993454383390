const { get } = require('lodash');

const initialState = {
  modal: [],
  loader: {
    queue: [],
  },
  sidebar: {
    expanded: false,
  },
  notification: {
    queue: [],
  },
};

const uiReducers = (state = initialState, action = {}) => {
  const loaderQueue = 'loader.queue';
  const notificationQueue = 'notification.queue';

  switch (action.type) {
    case 'MODAL_SHOW':
      return {
        ...state,
        modal: [
          ...state.modal,
          ...[
            {
              visible: true,
              type: action.payload.component,
              props: action.payload.props,
              actions: action.payload.actions,
            },
          ],
        ],
      };

    case 'MODAL_HIDE':
      return {
        ...state,
        modal: action.payload ? get(state, 'modal', []).filter((m) => m.type !== action.payload) : [],
      };

    case 'LOADER_ADD':
      const obj =
        typeof action.payload === 'string' || typeof action.payload === 'number'
          ? { key: action.payload, message: 'Loading...' }
          : action.payload;
      return {
        ...state,
        loader: {
          queue: [...get(state, loaderQueue, []), obj],
        },
      };
    case 'LOADER_REMOVE':
      const indexToRemove = get(state, loaderQueue, []).findIndex((item) => {
        return item.key === action.payload;
      });

      const loaderQueueRemove = get(state, loaderQueue, []).filter((_, index) => {
        return index !== indexToRemove;
      });

      return {
        ...state,
        loader: {
          queue: loaderQueueRemove,
        },
      };

    case 'SIDEBAR_EXPAND':
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          expanded: action.payload,
        },
      };
    case 'SIDEBAR_COLLAPSE':
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          expanded: false,
        },
      };

    case 'NOTIFICATION_ADD':
      return {
        ...state,
        notification: {
          queue: [...get(state, notificationQueue, []), action.payload],
        },
      };
    case 'NOTIFICATION_HIDE':
      const queueNotificationHide = get(state, notificationQueue, []).map((item) => {
        if (item.key === action.payload) {
          item.visible = false;
        }

        return item;
      });

      return {
        ...state,
        notification: {
          queue: queueNotificationHide,
        },
      };

    case 'NOTIFICATION_REMOVE':
      const queueNotificationRemove = get(state, notificationQueue, []).filter((item) => {
        return item.key !== action.payload;
      });

      return {
        ...state,
        notification: {
          queue: queueNotificationRemove,
        },
      };

    default:
      return state;
  }
};

export default uiReducers;
