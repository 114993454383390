import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import ModelCarrierView from './ModelCarrier.view'
import { useDispatch,useSelector } from 'react-redux';

// app
import { getModelCarrierTableData, getCarModelList, getCarrierList} from 'stores';
const ModelCarrier = () => {
  const dispatch = useDispatch();
  const [isActiveTab, setIsActiveTab] = useState({ dashboard: 'active', carModels: 'inactive' , carrier: 'inactive'});
  const [value, setValue] = React.useState('1');

  useEffect(()=>{
    dispatch(getModelCarrierTableData({
      carrier_list: ["TR"],
      model_list: ["alto"]
      }));
      dispatch(getCarModelList());
      dispatch(getCarrierList());
  },[])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    return(
        <ModelCarrierView
            isActiveTab = {isActiveTab}
            setIsActiveTab = {setIsActiveTab}
            handleChange = {handleChange}
            value = {value}
        />
    )
}

export default ModelCarrier;