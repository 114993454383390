import React from 'react';
import BVITVehicleSpecificationsView from './BVITVehicleSpecifications.view';

const BVITVehicleSpecifications = ({ filterDetails, handleFiltersChange, vehicleSpecifications,
     setVehicleSpecifications }) => {

    return(
        <BVITVehicleSpecificationsView filterDetails={filterDetails} handleFiltersChange={handleFiltersChange}
         vehicleSpecifications={vehicleSpecifications} setVehicleSpecifications={setVehicleSpecifications} />
    )
}

export default BVITVehicleSpecifications;