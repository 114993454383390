import { addLoader, removeLoader, snackbarNotification } from 'stores';
import { api } from 'utils';

export const getCategoryList = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getCategoryListRequest());
  dispatch(addLoader('getCategoryList'));

  return api
    .get({
      endpoint: endpoint.categoryService,
      path: 'v1/category',
    })
    .then((res) => {
      dispatch(getCategoryListSuccess(res?.data || []));
      return res;
    })
    .catch((error) => {
      dispatch(getCategoryListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getCategoryList'));
    });
};

export const getCategoryListRequest = () => {
  return {
    type: 'GET_CATEGORY_LIST_REQUEST',
  };
};

export const getCategoryListSuccess = (data) => {
  return {
    type: 'GET_CATEGORY_LIST_SUCCESS',
    payload: data,
  };
};

export const getCategoryListError = (error) => {
  return {
    type: 'GET_CATEGORY_LIST_FAILURE',
    payload: error,
  };
};
