import React from 'react';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';
import { Banner, BootstrapTooltip, Layout } from 'components';
import { useMedia } from 'globalsData';
import AcceleratorsImg from '../../assets/svg/Accelerators.svg';
import EastIcon from '@mui/icons-material/East';
import './Home.css';

//mui
import { Box, Card, CardContent, Typography, CardActions, Button, Grid, useTheme } from '@mui/material';

HomeView.propTypes = {
  tiles: PropTypes.array.isRequired,
  coursesTiles: PropTypes.array.isRequired,
  bannerProps: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default function HomeView({ tiles, bannerProps, handleClick, coursesTiles }) {
  const theme = useTheme();
  const { mobile, wideUp } = useMedia();

  const getWidth = () => {
    if (mobile) return '100%';
    if (wideUp) return '84%';
    return '94%';
  };

  return (
    <Layout>
      {/* <Banner {...bannerProps} /> */}
      <Box>
        <Typography sx={{ fontSize: '22px', fontWeight: '600', pb: 2, color: (theme) => theme.palette.otherColor.solutionTextColor }}>
          Enterprise Analytics & AI Platform
        </Typography>
      </Box>

      <Box pb={2}>
        <Grid
          container
          spacing={2}
          className="homePageCardsStyle"
          sx={{ width: 'calc(99% - 0px)', overflowX: 'auto', whiteSpace: 'nowrap', height: '320px', display: 'block', margin: '0px' }}
        >
          {tiles?.map((tile) => (
            <Grid item xs={12} sm={4} md={3} lg={3} key={tile.id} sx={{ display: 'inline-block' }}>
              <Card sx={{ borderRadius: '16px' }}>
                <Box sx={{ padding: '12px 10px 0px 10px', borderBottom: '1px solid #edf2f6' }}>
                  <Box>
                    <img src={tile?.image} style={{ width: '100%' }} alt="cardImage" />
                  </Box>
                  <Box sx={{ py: 2, px: 1, borderBottom: '1px solid #EDF2F6', whiteSpace: 'wrap' }}>
                    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '700', fontSize: '1rem', color: 'black' }}>
                      {utils.app.getEllipsisString(tile?.title, 32)}
                    </Typography>
                    <BootstrapTooltip  title={<Typography variant="body2" sx={{ fontSize: '10px' }}>{tile?.description}</Typography>} arrow>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        sx={{ fontWeight: '400', fontSize: '0.75rem', color: theme.palette.primary.textColor }}
                      >
                        {utils.app.getEllipsisString(tile?.description, 55)}
                      </Typography>
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <Box sx={{ p: 1 }}>
                  <Button
                    // variant="contained"
                    size="medium"
                    px={5}
                    sx={{
                      textTransform: 'none',
                      color: theme.palette.otherColor.solutionTextColor,
                      fontWeight: '600',
                      justifyContent: 'flex-start',
                      width: '100%',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    endIcon={<EastIcon />}
                    onClick={() => {
                      handleClick(tile);
                    }}
                  >
                    {tile?.btnText}
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ py: 2 }}>
        <Box sx={{ pb: 2 }}>
          <Typography sx={{ fontSize: '22px', fontWeight: '600', color: (theme) => theme.palette.otherColor.solutionTextColor }}>
            Top Rated Analytics Courses
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          className="homePageCardsStyle"
          sx={{ width: 'calc(99% - 0px)', overflowX: 'auto', whiteSpace: 'nowrap', height: '220px', display: 'block', margin: '0px' }}
        >
          {coursesTiles?.map((tile) => (
            <Grid item xs={12} sm={4} md={3} lg={3} key={tile.id} sx={{ display: 'inline-block', paddingLeft: '8px !important', maxWidth:'160px !important'}}>
              <Card sx={{ boxShadow: 'unset', border: 'none', backgroundColor: 'transparent' }}>
                <Box sx={{ padding: '12px 10px 0px 10px', borderBottom: '1px solid #edf2f6', textAlign: 'center' }}>
                  <a href={tile.link} target="_blank" rel="noopener noreferrer">
                    <Box sx={{ cursor: 'pointer', height: '100px', width: '130px' }}>
                      <img src={tile?.image} style={{ width: '100%', height: '100%' }} alt="cardImage" />
                    </Box>
                  </a>
                  <Box sx={{ pt: 2, borderBottom: '1px solid #edf2f6', textAlign: 'left' }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: '700', fontSize: '12px', color: 'black', cursor: 'pointer',whiteSpace:'wrap' }}
                    >
                      {utils.app.getEllipsisString(tile?.title, 40)}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      sx={{ fontWeight: '400', fontSize: '0.75rem', color: theme.palette.primary.textColor }}
                    >
                      {utils.app.getEllipsisString(tile?.description, 40)}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
}
