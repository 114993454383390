import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { get } from 'lodash';

// app
import { CategoryManagmentView } from './CategoryManagment.view';
import {
  showModal,
  getCategoryManagementList,
  hideModal,
  deleteWebsite,
  getCategoryList,
  runCrawlerScript,
  downloadIncrementalData,
  downloadAllData,
  downloadScript,
  getViewRecordsList,
  snackbarNotification,
} from 'stores';
import { DataGridCellExpand, BootstrapTooltip } from 'components';
import * as utils from 'utils';
import { config, consts } from 'globalsData';

// mui
import { Box, IconButton, Link, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function CategoryManagment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name: catName, id: catId } = useParams();

  const rusScript = 'Run Script';

  const categoryName = catName?.split('-')?.filter(Boolean)?.join(' ');

  const rows = useSelector((state) => get(state, `categoryManagement.categories[${catId}]`, []));
  const isAdmin = useSelector((state) => get(state, 'user.userInfo.isAdmin', false));

  const websiteId = 'websiteId'; // unique Id in rows list

  const [selectedItems, setSelectedItems] = useState([]);

  const getWebSiteList = () => {
    dispatch(getCategoryManagementList({ catId: catId }));
  };

  useEffect(() => {
    getWebSiteList();
  }, [catId]); // eslint-disable-line react-hooks/exhaustive-deps

  const breadcrumbs = [
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.home.root)}>
      {consts.APP_TITLE}
    </Link>,
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.dcmp.root)}>
      {consts.FEATURE_LIST_OBJ.dcmp}
    </Link>,
    <Typography key="3" color="text.primary" sx={{fontSize:'14px'}}>
      {categoryName}
    </Typography>,
  ];
  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  const columns = [
    {
      field: 'websiteName',
      headerName: 'Website Name',
      minWidth: 120,
      flex: 0.1,
      renderCell: (params) => <DataGridCellExpand value={params.value || ''} />,
    },
    {
      field: 'websiteUrl',
      headerName: 'URL',
      minWidth: 140,
      flex: 0.16,
      editable: false,
      renderCell: (params) => <DataGridCellExpand value={params.value || ''} />,
    },
    {
      field: 'secondLastRefreshedDate',
      headerName: 'Second Last Refresh',
      minWidth: 100,
      flex: 0.15,
      type: 'string',
      editable: false,
      renderCell: (params) => <DataGridCellExpand value={utils.generic.getLocalTimeFromTimeStamp(params?.row?.secondLastRefreshedDate)} />,
    },
    {
      field: 'lastRefreshedDate',
      headerName: 'Last Refresh',
      type: 'string',
      minWidth: 100,
      flex: 0.15,
      editable: false,
      renderCell: (params) => <DataGridCellExpand value={utils.generic.getLocalTimeFromTimeStamp(params?.row?.lastRefreshedDate)} />,
    },
    {
      field: 'totalRecordCount',
      headerName: 'Total Record Count',
      minWidth: 80,
      flex: 0.1,
      type: 'string',
      editable: false,
    },
    {
      field: 'incrementalRecordCount',
      headerName: 'Incremental Record Count',
      type: 'string',
      minWidth: 80,
      flex: 0.1,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 50,
      flex: 0.13,
      type: 'string',
      editable: false,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.status || ''} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      minWidth: isAdmin ? 320 : 200,
      flex: isAdmin ? 0.2 : 0.15,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ marginRight: '20px' }}>
            <BootstrapTooltip
              title={`Download Incremental Data from ${
                utils.generic.getMMDDFromTimeStamp(params?.row?.secondLastRefreshedDate) || '-'
              } to ${utils.generic.getMMDDFromTimeStamp(params?.row?.lastRefreshedDate) || '-'}`}
              placement={'bottom'}
            >
              <IconButton
                sx={{ cursor: 'pointer' }}
                aria-label="Incremental Data"
                onClick={(e) => onDownloadIncrementalDataHandler(e, params?.row)}
              >
                <DownloadForOfflineIcon />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title={'Download All Data'} placement={'bottom'}>
              <IconButton
                sx={{ cursor: 'pointer' }}
                aria-label="Download all data"
                onClick={(e) => onDownloadAllDataHandler(e, params?.row)}
              >
                <DownloadIcon />
              </IconButton>
            </BootstrapTooltip>
            {isAdmin && (
              <>
                <BootstrapTooltip title={'Edit'} placement={'bottom'}>
                  <IconButton sx={{ cursor: 'pointer' }} aria-label="Edit" onClick={(e) => onDataEditHandler(e, params?.row)}>
                    <EditIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={'Delete'} placement={'bottom'}>
                  <IconButton sx={{ cursor: 'pointer' }} aria-label="Delete" onClick={(e) => onDataDeleteHandler(e, params?.row)}>
                    <DeleteIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip
                  title={!consts.WEBSITE_STATUS_LIST_TO_DISABLE_RUN_SCRIPT?.includes(params?.row?.status?.toLowerCase()) ? rusScript : ''}
                  placement={'bottom'}
                >
                  <IconButton
                    sx={{ cursor: 'pointer' }}
                    aria-label={rusScript}
                    disabled={consts.WEBSITE_STATUS_LIST_TO_DISABLE_RUN_SCRIPT?.includes(params?.row?.status?.toLowerCase())}
                    onClick={(e) => handleRunCrawler(e, params?.row)}
                  >
                    <RefreshIcon />
                  </IconButton>
                </BootstrapTooltip>
              </>
            )}
            <BootstrapTooltip title={'View Top 20 Records'} placement={'bottom'}>
              <IconButton sx={{ cursor: 'pointer' }} aria-label="info" onClick={(e) => handleViewRecords(e, params?.row)}>
                <VisibilityIcon />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title={'Download Script'} placement={'bottom'}>
              <IconButton sx={{ cursor: 'pointer' }} aria-label="Download script" onClick={(e) => handleScriptDownload(e, params?.row)}>
                <CloudDownloadIcon />
              </IconButton>
            </BootstrapTooltip>
          </div>
        );
      },
    },
  ];

  const handleAddWebsite = () => {
    dispatch(getCategoryList())?.then((res) => {
      if (res?.status === 200) {
        dispatch(
          showModal({
            component: 'ADD_EDIT_WEBSITE',
            props: {
              title: 'Create New Website',
              maxWidth: 'md',
              componentProps: {
                submitHandler: () => {
                  getWebSiteList();
                },
              },
            },
          })
        );
      }
    });
  };

  const onDownloadIncrementalDataHandler = (e, row) => {
    e.stopPropagation();
    dispatch(downloadIncrementalData(row));
  };

  const onDownloadAllDataHandler = (e, row) => {
    e.stopPropagation();
    dispatch(downloadAllData(row));
  };

  const onDataEditHandler = (e, data) => {
    e.stopPropagation();

    dispatch(getCategoryList())?.then((res) => {
      if (res?.status === 200) {
        dispatch(
          showModal({
            component: 'ADD_EDIT_WEBSITE',
            props: {
              title: 'Update Website',
              maxWidth: 'md',
              componentProps: {
                data,
                catId,
              },
            },
          })
        );
      }
    });
  };

  const onDataDeleteHandler = (e, row) => {
    e.stopPropagation();

    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: 'Delete Website',
          maxWidth: 'xs',
          componentProps: {
            confirmText: `Are you sure you want to delete ${row?.websiteName}?`,
            confirmButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('CONFIRM'));
            },
            submitHandler: () => {
              dispatch(
                deleteWebsite({
                  websiteId: row?.websiteId,
                  catId,
                })
              ).then((res) => {
                if (res.status === 200) {
                  dispatch(hideModal('CONFIRM'));
                }
              });
            },
          },
        },
      })
    );
  };

  const hideModalAndCallWebsiteList = (res) => {
    if (res?.status === 200) {
      dispatch(hideModal('CONFIRM'));
      getWebSiteList();
    }
  };

  const handleRunCrawler = (e, row) => {
    e.stopPropagation();

    const { websiteName, websiteId: webId = '' } = row;
    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: rusScript,
          maxWidth: 'xs',
          componentProps: {
            confirmText: (
              <Box>
                <Typography>
                  Are you sure you want to run Crawler Script for <strong>{websiteName}</strong> website?
                </Typography>
              </Box>
            ),
            confirmButtonLabel: 'Run',
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('CONFIRM'));
            },
            submitHandler: () => {
              dispatch(runCrawlerScript({ websiteList: [webId] })).then((res) => {
                hideModalAndCallWebsiteList(res);
              });
            },
          },
        },
      })
    );
  };

  const selectedRowIds = (ids) => {
    setSelectedItems(ids);
  };

  const handleViewRecords = (e, row) => {
    e.stopPropagation();

    dispatch(getViewRecordsList(row))?.then((res) => {
      if (res?.status === 200) {
        if (utils.generic.isValidArray(res?.data, true)) {
          dispatch(
            showModal({
              component: 'VIEW_CRAWLER_RECORDS',
              props: {
                title: `${row?.websiteName} - Top 20 Records`,
                maxWidth: 'lg',
                fullScreen: true,
              },
            })
          );
        } else {
          dispatch(snackbarNotification('No Records Found', 'warning'));
        }
      }
    });
  };

  const handleScriptDownload = (e, row) => {
    e.stopPropagation();

    dispatch(downloadScript(row));
  };

  const runMultiScripts = () => {
    const selectedWebsiteIdsAfterFilter = rows
      ?.filter(
        (website) =>
          selectedItems?.includes(website?.websiteId) &&
          !consts.WEBSITE_STATUS_LIST_TO_DISABLE_RUN_SCRIPT?.includes(website?.status?.toLowerCase())
      )
      .map((web) => web.websiteId);

    const removedWebsiteIdsAfterFilter = rows
      ?.filter((website) => selectedItems?.includes(website?.websiteId))
      .filter((w) => consts.WEBSITE_STATUS_LIST_TO_DISABLE_RUN_SCRIPT?.includes(w?.status?.toLowerCase()))
      .map((web) => web.websiteName);

    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: rusScript,
          componentProps: {
            confirmText: (
              <>
                {removedWebsiteIdsAfterFilter?.length > 0 ? (
                  <Box px={2}>
                    <Box pb={2}>
                      <Typography variant="subtitle1">Below Website(s) are already in Triggered/In Progress status:</Typography>
                      <ul>
                        {removedWebsiteIdsAfterFilter?.map((ws, i) => (
                          <li key={`${ws}-${i}`}>
                            <Typography variant="body1">
                              <strong>{ws}</strong>
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>

                    {selectedWebsiteIdsAfterFilter?.length > 0 && (
                      <Typography variant="subtitle1">
                        Are you sure you want to run Crawler Script for remaining selected Website(s)?
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Box px={2}>
                    <Typography variant="subtitle1">Are you sure you want to run Crawler Script for selected Website(s)?</Typography>
                  </Box>
                )}
              </>
            ),
            confirmButtonLabel: 'Run',
            cancelButtonLabel: 'Cancel',
            disableConfirmButton: selectedWebsiteIdsAfterFilter?.length === 0,
            cancelHandler: () => {
              dispatch(hideModal('CONFIRM'));
            },
            submitHandler: () => {
              dispatch(runCrawlerScript({ websiteList: [...selectedWebsiteIdsAfterFilter] })).then((res) => {
                hideModalAndCallWebsiteList(res);
              });
            },
          },
        },
      })
    );
  };

  const handleRefreshWebsiteList = () => {
    getWebSiteList();
  };

  const handleRowSelectable = (params) => {
    return !consts.WEBSITE_STATUS_LIST_TO_DISABLE_RUN_SCRIPT?.includes(params?.row?.status?.toLowerCase());
  };

  return (
    <CategoryManagmentView
      categoryName={categoryName}
      isAdmin={isAdmin}
      columns={columns}
      rows={rows}
      websiteId={websiteId}
      selectedItems={selectedItems}
      breadcrumbs={breadcrumbs}
      handlers={{ handleAddWebsite, selectedRowIds, runMultiScripts, handleRefreshWebsiteList, handleRowSelectable }}
    />
  );
}
