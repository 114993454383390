const initialState = {
  sobTableData: [],
  transporters:[],
  zones:{},
  downloadFileData:{},
  isShareOfBusinessLoading: false,
};

const acceleratorsReducers = (state = initialState, action = []) => {
  switch (action.type) {
    case 'GET_SHARE_OF_BUSINESS_TABLE_REQUEST':
      return {
        ...state,
        isShareOfBusinessLoading: true,
      };

    case 'GET_SHARE_OF_BUSINESS_TABLE_SUCCESS':
      return {
        ...state,
        sobTableData: action.payload || [],
        isShareOfBusinessLoading: false,
      };
    case 'GET_TRANSPORTERS_LIST_SUCCESS':
      return {
        ...state,
        transporters: action.payload || []
      };
    case 'GET_ZONES_LIST_SUCCESS':
      return {
        ...state,
        zones: action.payload || {}
      };
      case 'GET_SHARE_OF_BUSINESS_FILE_DOWNLOAD_SUCCESS':
        return {
          ...state,
          downloadFileData: action.payload || {}
        };
    default:
      return state;
  }
};

export default acceleratorsReducers;
