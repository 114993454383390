import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const uploadFileToS3 = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { loginRedirectUrl },
    },
  } = getState();

  const { preSignedUrl, file, isThisForAudio,isThisForCsvOrXl } = reqParams;

  if (!preSignedUrl) {
    return Promise.reject({ message: 'Pre Signed Url not found' });
  }

  dispatch(uploadFileToS3Request(reqParams));
  dispatch(addLoader({ key: 'uploadFileToS3', message: 'Uploading File to S3...' }));
  const getTheApiFunction=()=>{
   if(isThisForAudio){
    return api.multipartPutForAudioFiles 
   }
   if(isThisForCsvOrXl){
    return api.multipartPutForCsvAndXlFiles 
   }
    return api.multipartPut
  }
  const apiFunction = getTheApiFunction();
  return apiFunction({
    endpoint: preSignedUrl,
    data: { file },
    isTokenRequired: false,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(uploadFileToS3Error(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('uploadFileToS3'));
    });
};

export const uploadFileToS3Request = (params) => {
  return {
    type: 'UPLOAD_FILE_TO_S3_REQUEST',
    payload: params,
  };
};

export const uploadFileToS3Success = (data) => {
  return {
    type: 'UPLOAD_FILE_TO_S3_SUCCESS',
    payload: data,
  };
};

export const uploadFileToS3Error = (error) => {
  return {
    type: 'UPLOAD_FILE_TO_S3_FAILURE',
    payload: error,
  };
};
