import getAlgorithmHyperParameters from 'globalsData/getAlgorithmHyperParameters/getAlgorithmHyperParameters';
import { consts } from 'globalsData';

const getClassificationPayload = (formData) => {
  if (!formData) return;
  const { targetVariable, testSizeRatio, algorithm,justificationRemarks } = formData;
  const hyperParameters = getAlgorithmHyperParameters(consts.CLASSIFICATION, formData);

  if (algorithm === 'logisticRegression') {
    const { regulationParameter, lrsolver, penalty, tolerance, modelMetrics } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        C: regulationParameter,
        solver: lrsolver,
        penalty,
        tol: tolerance,
      },
    };
  } else if (algorithm === 'linearDiscriminantAnalysis') {
    const { ldaSolver, tolerance, modelMetrics } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        solver: ldaSolver,
        tol: tolerance,
      },
    };
  } else if (algorithm === 'supportVectorMachine') {
    const { regulationParameter, gamma, kernel, tolerance, modelMetrics } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        C: regulationParameter,
        gamma,
        kernel,
        tol: tolerance,
      },
    };
  } else if (algorithm === 'randomForest') {
    const { numberOfEstimators, rfCriterion, rfMaxFeatures, modelMetrics } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        n_estimators: numberOfEstimators,
        criterion: rfCriterion,
        max_features: rfMaxFeatures,
      },
    };
  } else if (algorithm === 'kNearestNeighborsClassifier') {
    const { numberOfNearestNeighbors, p, kNNalgorithm, modelMetrics } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        n_neighbors: numberOfNearestNeighbors,
        p: Number(p),
        algorithm: kNNalgorithm,
      },
    };
  } else if (algorithm === 'baggingClassifier') {
    const { bcNumberOfEstimators, bcMaxFeatures, modelMetrics } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        n_estimators: bcNumberOfEstimators,
        max_features: bcMaxFeatures,
      },
    };
  } else if (algorithm === 'adaBoostClassifier') {
    const { adcAlgorithm, adcNumberOfEstimators, learningRate, modelMetrics } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        algorithm: adcAlgorithm,
        n_estimators: adcNumberOfEstimators,
        learning_rate: learningRate,
      },
    };
  } else if (algorithm === 'gradientBoostingClassifier') {
    const { gbcCriterion, loss, gbcMaxFeatures, numberOfEstimators, tolerance, modelMetrics, learningRateGbc } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        criterion: gbcCriterion,
        loss,
        learning_rate: learningRateGbc,
        max_features: gbcMaxFeatures,
        n_estimators: numberOfEstimators,
        tol: tolerance,
      },
    };
  } else if (algorithm === 'XGBClassifier') {
    const { booster, importanceType, numberOfEstimators, modelMetrics, learningRateXgb } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      modelMetrics,
      justificationRemarks,
      modelHyperParameters: {
        booster,
        importance_type: importanceType,
        learning_rate: learningRateXgb,
        n_estimators: numberOfEstimators,
      },
    };
  } else {
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
    };
  }
};

export default getClassificationPayload;
