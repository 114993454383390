import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';
import { addCategory, getCategoryList, hideModal, updateCategory } from 'stores';
import { AddEditCategoryView } from './AddEditCategory.view';
import { useMedia } from 'globalsData';

AddEditCategory.propTypes = {
  cat: PropTypes.object,
};

export default function AddEditCategory({ cat }) {
  const dispatch = useDispatch();
  const { mobile } = useMedia();

  const isUpdateCat = Boolean(cat?.categoryName);

  const fields = [
    {
      name: 'categoryName',
      label: 'Category Name *',
      defaultValue: cat?.categoryName || '',
      size: 'small',
      type: 'textfield',
      validation: Yup.string().trim().max(50, 'Category Name must be at most 50 characters').required('Category Name is required'),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'status',
      label: 'Status *',
      defaultValue: cat?.status || '',
      size: 'small',
      type: 'select',
      validation: Yup.string().required('Please select learning type'),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const formValues = watch();
  const isPageEdited = !isEqual(defaultValues, formValues);

  const onSubmit = (data) => {
    if (!isUpdateCat) {
      dispatch(addCategory({ categoryName: data?.categoryName, powerBiUrl: data?.powerBiUrl })).then((res) => {
        if (res?.status === 200) {
          dispatch(hideModal('ADD_EDIT_CATEGORY'));
          dispatch(getCategoryList());
        }
      });
    } else {
      dispatch(
        updateCategory({
          categoryName: data?.categoryName,
          powerBiUrl: data?.powerBiUrl,
          // status: cat?.status,
          status: data?.status,
          categoryId: cat?.categoryId,
        })
      ).then((res) => {
        if (res?.status === 200) {
          dispatch(hideModal('ADD_EDIT_CATEGORY'));
        }
      });
    }
  };

  return (
    <AddEditCategoryView
      isPageEdited={isPageEdited}
      fields={fields}
      errors={errors}
      isUpdateCat={isUpdateCat}
      formProps={{ register, handleSubmit }}
      handlers={{ onSubmit }}
    />
  );
}
