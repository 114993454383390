import { get } from 'lodash';

const initialState = {
  categories: {},
  viewRecordsList: [],
};

const categoryManagmentReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_CATEGORY_MANAGEMENT_LIST_SUCCESS':
      return {
        ...state,
        categories: { ...state.categories, ...{ [action.payload.id]: action.payload.data } },
      };

    case 'CLEAR_CATEGORY_MANAGEMENT_LIST':
      return {
        ...state,
        categories: {},
      };

    case 'UPDATE_WEBSITE_SUCCESS':
      const updatedWebsiteList = get(state, `categories[${action.payload?.id}]`, [])?.map((website) => {
        if (website?.websiteId?.toString()?.toLowerCase() === action.payload?.data?.websiteId?.toString()?.toLowerCase()) {
          return { ...website, ...action?.payload?.data };
        }
        return website;
      });
      return {
        ...state,
        categories: { ...state.categories, ...{ [action.payload.id]: updatedWebsiteList } },
      };

    case 'DELETE_WEBSITE_SUCCESS':
      const websiteListAfterDelete = get(state, `categories[${action.payload?.id}]`, [])?.filter(
        (website) => website?.websiteId?.toString()?.toLowerCase() !== action?.payload?.data?.websiteId?.toString()?.toLowerCase()
      );
      return {
        ...state,
        categories: { ...state.categories, ...{ [action.payload.id]: websiteListAfterDelete } },
      };

    case 'GET_VIEW_RECORDS_LIST_SUCCESS':
      return {
        ...state,
        viewRecordsList: action.payload,
      };

    case 'CLEAR_VIEW_RECORDS_LIST':
      return {
        ...state,
        viewRecordsList: [],
      };

    default:
      return state;
  }
};

export default categoryManagmentReducers;
