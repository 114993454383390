import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';
import thunk from 'redux-thunk';

// app
import reducer from './stores';
import './index.css';
import { App, ErrorBoundary, Auth } from 'components';
import { theme } from './theme';

// mui
import { ThemeProvider } from '@mui/material';
import { ConfigLoader } from 'components/ConfigLoader/ConfigLoader';

// middleware
const middleware = [thunk];

const composeEnhancers = window._REACT_DEVTOOLS_EXTENSION_COMPOSE_ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ConfigLoader>
          <ErrorBoundary>
            <Auth>
              <App />
            </Auth>
          </ErrorBoundary>
        </ConfigLoader>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
