import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

//app
import { Skeleton } from 'components';
import { consts, useMedia } from 'globalsData';
import EDASummary from './EDASummary';
import summaryIcon from 'assets/png/summary.png';
import ClassificationSummary from './ClassificationSummary';
import RegressionSummary from './RegressionSummary';
import TimeseriesSummary from './TimeseriesSummary';
import SpeechAndTextSummary from './SpeechAndTextSummary';
import ClusteringSummary from './ClusteringSummary';
//mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export default function Summary({
  acceleratorType,
  summaryDeteails,
  status,
  onEdit,
  onDownloadCsv,
  onDownloadHtml,
  onLearningTypeClick,
  audio,
  textDetails,
  handleDownloadAudioFiles,
}) {
  const theme = useTheme();
  const { mobile, tablet, desktopUp } = useMedia();
  const isSummaryDetailsLoading = useSelector((state) => get(state, 'accelerators.isSummaryDetailsLoading', false));
  const skeletonWaveWidth = () => {
    if (mobile) return '100%';
    if (tablet) return 180;
    if (desktopUp) return 280;
    return 250;
  };

  const DisaplySkeleton = ({ displayNumber }) => (
    <Grid item xs={12} sm={4}>
      <Skeleton
        height={15}
        animation="pulse"
        variant="rectangular"
        width={skeletonWaveWidth()}
        displayNumber={displayNumber}
        skeletonMarginBottom={2}
      />
    </Grid>
  );

  const AccSummary = () => {
    switch (acceleratorType?.toLowerCase()) {
      case consts.ACCELERATORS.EXPLORATORY_DATA_ANALYSIS?.toLowerCase():
        return (
          <EDASummary
            status={status}
            summaryDeteails={summaryDeteails}
            onDownloadCsv={onDownloadCsv}
            onDownloadHtml={onDownloadHtml}
            onLearningTypeClick={onLearningTypeClick}
          />
        );
      case consts.ACCELERATORS.EXPLORATORY_DATA_ANALYSIS_UNSUPERVISED?.toLowerCase():
        return (
          <EDASummary
            status={status}
            summaryDeteails={summaryDeteails}
            onDownloadCsv={onDownloadCsv}
            onDownloadHtml={onDownloadHtml}
            onLearningTypeClick={onLearningTypeClick}
          />
        );
      case consts.ACCELERATORS.CLASSIFICATION?.toLowerCase():
        return (
          <ClassificationSummary
            status={status}
            summaryDeteails={summaryDeteails}
            onDownloadCsv={onDownloadCsv}
            onDownloadHtml={onDownloadHtml}
            onLearningTypeClick={onLearningTypeClick}
          />
        );
      case consts.ACCELERATORS.REGRESSION?.toLowerCase():
        return (
          <RegressionSummary
            status={status}
            summaryDeteails={summaryDeteails}
            onDownloadCsv={onDownloadCsv}
            onDownloadHtml={onDownloadHtml}
            onLearningTypeClick={onLearningTypeClick}
          />
        );
      case consts.ACCELERATORS.TIME_SERIES?.toLowerCase():
        return (
          <TimeseriesSummary
            status={status}
            summaryDeteails={summaryDeteails}
            onDownloadCsv={onDownloadCsv}
            onDownloadHtml={onDownloadHtml}
            onLearningTypeClick={onLearningTypeClick}
          />
        );
      case consts.ACCELERATORS.CLUSTERING?.toLowerCase():
        return (
          <ClusteringSummary
            status={status}
            summaryDeteails={summaryDeteails}
            onDownloadCsv={onDownloadCsv}
            onDownloadHtml={onDownloadHtml}
            onLearningTypeClick={onLearningTypeClick}
          />
        );
      case consts.TEXT_TO_SPEECH?.toLowerCase():
      case consts.ACCELERATORS.SPEECH_TO_TEXT:
        return (
          <SpeechAndTextSummary
            status={status}
            summaryDeteails={summaryDeteails}
            audio={audio}
            textDetails={textDetails}
            handleDownloadAudioFiles={handleDownloadAudioFiles}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        background: `${theme.palette.primary.white} url(${summaryIcon}) 98% 80% no-repeat`,
        boxShadow: '0px 2px 16px 2px rgb(133 133 133 / 25%)',
        borderRadius: '8px',
      }}
      p={2}
    >
      <Grid container spacing={1} justifyContent="space-between" pb={2}>
        <Grid item>
          <Typography fontSize={20} fontWeight={700} color={theme.palette.primary.main}>
            Input Summary:
          </Typography>
        </Grid>
        {acceleratorType.toLowerCase() !== consts.TEXT_TO_SPEECH &&
          acceleratorType?.toLowerCase() !== consts.ACCELERATORS.SPEECH_TO_TEXT && (
            <Grid item>
              <Button
                disabled={
                  !(
                    !isSummaryDetailsLoading &&
                    (status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() ||
                      status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus))
                  )
                }
                variant="text"
                size="small"
                color="primary"
                startIcon={
                  <EditOutlinedIcon
                    sx={{
                      color:
                        status?.toLowerCase() !== consts.acceleratorStatus.complete?.toLowerCase() || isSummaryDetailsLoading
                          ? theme.palette.otherColor.textGrey3
                          : theme.palette.primary.main,
                    }}
                  />
                }
                onClick={() => onEdit(summaryDeteails)}
              >
                <Typography
                  fontSize={16}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Edit
                </Typography>
              </Button>
            </Grid>
          )}
      </Grid>
      {!isSummaryDetailsLoading && <AccSummary />}
      {isSummaryDetailsLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={11}>
            <Grid container>
              <DisaplySkeleton displayNumber={3} />
              <DisaplySkeleton displayNumber={3} />
              <DisaplySkeleton displayNumber={2} />
            </Grid>
          </Grid>
          <Grid item xs={0} sm={1} />
        </Grid>
      )}
    </Box>
  );
}
