import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const addUser = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(addUserRequest(reqParams));
  dispatch(addLoader('addUser'));

  const { email, roleName, categoryList, featureList, otherSolutionsList, verticalId, departmentId } = reqParams;

  const data = {
    email,
    roleName,
    categoryList,
    featureList,
    otherSolutionsList,
    verticalId,
    departmentId,
  };

  return api
    .post({
      endpoint: endpoint.userManagementService,
      path: 'v1/um',
      data,
    })
    .then((res) => {
      dispatch(addUserSuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message || 'User Created Successfully', 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(addUserError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('addUser'));
    });
};

export const addUserRequest = (params) => {
  return {
    type: 'ADD_USER_REQUEST',
    payload: params,
  };
};

export const addUserSuccess = (data) => {
  return {
    type: 'ADD_USER_SUCCESS',
    payload: data,
  };
};

export const addUserError = (error) => {
  return {
    type: 'ADD_USER_FAILURE',
    payload: error,
  };
};
