import React from 'react';
import PropTypes from 'prop-types';

//app
import { Breadcrumbs, VideoModal, Card, Layout } from 'components';
import Banner from 'components/Banner/Banner';

// mui
import { Box, Grid, Typography } from '@mui/material';

AcceleratorsView.propTypes = {
  config: PropTypes.object.isRequired,
  accelerators: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  bannerProps: PropTypes.object.isRequired,
  currentTileTooltip: PropTypes.string.isRequired,
  tileMenuInfo: PropTypes.object.isRequired,
  modalActions: PropTypes.object.isRequired,
  videoInfo: PropTypes.object.isRequired,
  tileMenu: PropTypes.object,
  menuList: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    videoHandler: PropTypes.func.isRequired,
    handleAcceleratorClick: PropTypes.func.isRequired,
    openToolTip: PropTypes.func.isRequired,
    closeToolTip: PropTypes.func.isRequired,
    openTileMenu: PropTypes.func.isRequired,
    closeTileMenu: PropTypes.func.isRequired,
  }).isRequired,
};

export function AcceleratorsView({
  config,
  accelerators,
  breadcrumbs,
  bannerProps,
  currentTileTooltip,
  handlers,
  tileMenu,
  tileMenuInfo,
  modalActions,
  menuList,
  videoInfo,
}) {
  const { modalOpen, closeModal } = modalActions;

  return (
    <Layout>
      {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
      <Box py={2} mt={1}>
        <Banner {...bannerProps} />
        <Box>
          <Grid container spacing={2}>
            {accelerators?.length ? (
              accelerators?.map((accelerator) => {
                return (
                  <Grid item key={accelerator.name} xs={12} md={3} position="relative">
                    <Card
                      cardInfo={accelerator}
                      cardMenuAnchor={tileMenu}
                      cardMenuInfo={tileMenuInfo}
                      currentTileTooltip={currentTileTooltip}
                      isCardEnabled={accelerator.enabled}
                      menuList={menuList}
                      callbacks={{
                        // openCardMenu: handlers.openTileMenu,
                        // closeCardMenu: handlers.closeTileMenu,
                        // videoHandler: handlers.videoHandler,
                        handleCardClick: handlers.handleAcceleratorClick,
                        // closeToolTip: handlers.closeToolTip,
                        // openToolTip: handlers.openToolTip,
                      }}
                    />
                  </Grid>
                );
              })
            ) : (
              <Box px={2}>
                <Typography variant="body1">No accelerator(s) found</Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
      {modalOpen && <VideoModal modalOpen={modalOpen} onCloseModal={closeModal} videoInfo={videoInfo} />}
    </Layout>
  );
}
