import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete,
  Chip,
  Tooltip,
  FormHelperText,
  Button,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import './ShareOfBusiness.css';
import * as utils from 'utils';
import ShareOfBusinessTableView from '../../modules/ShareOfBusinessTableView/shareOfBusinessTableView';
import { Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SampleUploadFile from '../../assets/files/sampleUploadFile.csv';

export default function ShareOfBusinessView({
  tableData,
  handleChange,
  fields,
  formProps,
  handlers,
  zones,
  fileDownload,
  tabValue,
  handleChangeFileUpload,
  handleFileUploadClick,
  hiddenFileInput,
}) {
  const { control, register, handleSubmit, errors, setValue } = formProps;
  const { onSubmit } = handlers;
  const manageAreaOperationField = utils.form.getFieldProp(fields, 'areaOfOperation');
  const manageTransporterField = utils.form.getFieldProp(fields, 'transporter');
  return (
    <Box p={2}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={12} pr={3} pt={2}>
            <Box>
              <Typography variant="p" fontSize="18px" fontWeight="700" color={(theme) => theme.palette.primary.main}>
                Share of Business Optimization System
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} py={3} >
            <Paper elevation={3} sx={{ padding: '10px', marginTop: '10px' }}>
              <Box id="sob-report-form" component="form" p={1}>
                <Grid container py={1}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'block' }}>
                      <FormControl sx={{ width: '100%' }} error={!!errors?.mode?.message}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: '14px' }}>
                          Mode
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="air"
                          name="radio-buttons-group"
                          sx={{ display: 'inline-block' }}
                        >
                          <FormControlLabel
                            className="customRadioLblStyle"
                            sx={{ display: 'inline-block', fontSize: '14px' }}
                            value="air"
                            control={<Radio size="small" />}
                            label="Air"
                            {...register('mode')}
                            {...utils.form.getFieldProp(fields, 'mode')}
                          />
                          <FormControlLabel
                            className="customRadioLblStyle"
                            sx={{ display: 'inline-block', fontSize: '14px' }}
                            value="road"
                            control={<Radio size="small" />}
                            label="Road"
                            {...register('mode')}
                            {...utils.form.getFieldProp(fields, 'mode')}
                          />
                          <FormControlLabel
                            className="customRadioLblStyle"
                            sx={{ display: 'inline-block', fontSize: '14px' }}
                            value="surface"
                            control={<Radio size="small" />}
                            label="Surface"
                            {...register('mode')}
                            {...utils.form.getFieldProp(fields, 'mode')}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* multiselect zone dropdown */}
                  <Grid item xs={12} p={1}>
                    <Controller
                      name={manageAreaOperationField?.name}
                      control={control}
                      defaultValue={[]}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => {
                        return (
                          <Autocomplete
                            {...field}
                            value={field.value}
                            disablePortal={false}
                            filterSelectedOptions
                            multiple
                            size="small"
                            getOptionDisabled={(option) => option.disabled}
                            getOptionLabel={manageAreaOperationField?.getOptionLabel}
                            isOptionEqualToValue={(option, value) => option.areaOfOperation === value.areaOfOperation}
                            onChange={(event, values) => {
                              field.onChange(values);
                              setValue('areaOfOperation', values);
                            }}
                            options={zones}
                            renderTags={(value, getTagProps) => {
                              return value?.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  variant="outlined"
                                  size="small"
                                  label={option[manageAreaOperationField?.optionLabel]}
                                />
                              ));
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                error={!!error}
                                helperText={error?.message}
                                id={manageAreaOperationField?.name}
                                label={manageAreaOperationField?.label}
                                name={manageAreaOperationField?.name}
                                type="search"
                                inputRef={ref}
                                {...params}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <Controller
                      name={manageTransporterField?.name}
                      control={control}
                      defaultValue={[]}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => {
                        return (
                          <Autocomplete
                            {...field}
                            value={field.value}
                            disablePortal={false}
                            filterSelectedOptions
                            multiple
                            size="small"
                            getOptionDisabled={(option) => option.disabled}
                            getOptionLabel={manageTransporterField?.getOptionLabel}
                            isOptionEqualToValue={(option, value) => option.transporter === value.transporter}
                            onChange={(event, values) => {
                              field.onChange(values);
                              setValue('transporter', values);
                            }}
                            options={manageTransporterField?.options}
                            renderTags={(value, getTagProps) => {
                              return value?.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  variant="outlined"
                                  size="small"
                                  label={option[manageTransporterField?.optionLabel]}
                                />
                              ));
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                error={!!error}
                                helperText={error?.message}
                                id={manageTransporterField?.name}
                                label={manageTransporterField?.label}
                                name={manageTransporterField?.name}
                                type="search"
                                inputRef={ref}
                                {...params}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} p={1}>
                    <Box sx={{ display: 'inline-block' }}>
                      <InputLabel sx={{ fontSize: '14px' }}>From Date</InputLabel>
                      <Controller
                        control={control}
                        name="fromDate"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <ReactDatePicker
                            dateFormat="dd-MM-yyyy"
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            defaultValue={new Date()}
                            selected={value}
                            className="dateFieldStyle"
                          />
                        )}
                      />
                      {!!errors?.fromDate?.message && (
                        <FormHelperText sx={{ fontSize: '12px', marginLeft: '0', color: '#d32f2f' }}>
                          {errors.fromDate.message}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box id="toDate" className="toDateHolder" sx={{ display: 'inline-block', padding: '0px 16px' }}>
                      <InputLabel sx={{ fontSize: '14px' }}>To Date</InputLabel>
                      <Controller
                        control={control}
                        name="toDate"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <ReactDatePicker
                            dateFormat="dd-MM-yyyy"
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            selected={value}
                            defaultValue={new Date()}
                            className="dateFieldStyle"
                          />
                        )}
                      />
                      {!!errors?.toDate?.message && (
                        <FormHelperText sx={{ fontSize: '12px', marginLeft: '0', color: '#d32f2f' }}>
                          {errors.toDate.message}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box sx={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer', zIndex: '9' }} px={1}>
                      <Tooltip title="Execute to Fetch Data">
                        <Button
                          variant="contained"
                          endIcon={<MultipleStopIcon />}
                          onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
                          sx={{ textTransform: 'capitalize' ,fontSize:'13px'}}
                        >
                          Execute
                        </Button>
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer', zIndex: '9' }} px={1}>
                      <Button
                        variant="contained"
                        sx={{ textTransform: 'capitalize',fontSize:'13px' }}
                        endIcon={<UploadFileIcon />}
                        onClick={handleFileUploadClick}
                      >
                        <input type="file" onChange={handleChangeFileUpload} ref={hiddenFileInput} style={{ display: 'none' }} />
                        Upload Approved SOBs
                      </Button>
                    </Box>
                    <Box sx={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer', zIndex: '9' }} px={1}>
                      <a href={SampleUploadFile} download>
                        <Tooltip title="Download Sample SOB File">
                          <FileDownloadIcon sx={{ fontSize: '24px' }} color="action" />
                        </Tooltip>
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          {/* upload and download buttons starts */}
          <Grid item xs={12} pt={3} sx={{ textAlign: 'right' }}>
            <Box sx={{ display: 'inline-block', padding: '10px' }}>
            <Tooltip title="Download SOB File">
              <Button variant="contained" sx={{ textTransform: 'capitalize' ,fontSize:'13px'}} endIcon={<FileDownloadIcon />} onClick={fileDownload}>
                Download
              </Button>
              </Tooltip>
            </Box>
          </Grid>
          {/* upload and download buttons ends */}
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <ShareOfBusinessTableView tableData={tableData} />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}
