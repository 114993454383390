import React from 'react';

// app
import { useMedia } from 'globalsData';
import eapWelcomeScreen from 'assets/png/eap-welcome-screen.png';
import maruthiSuzukiWhiteLogo from 'assets/png/maruthi-suzuki-white-logo.png';

// import EAP_logo from 'assets/png/EAP Logo.png';
import EAP_WelcomeScreenLogo from 'assets/png/EAP_WelcomePage Logo.png';
// import { ReactComponent as WelcomeScreenEAPLogo } from 'assets/svg/WelcomeScreenEAPLogo.svg';
import { ReactComponent as WelcomeScreenHexaLogo } from 'assets/svg/WelcomeScreenHexaLogo.svg';

// mui
import { Box, Button, Typography, useTheme } from '@mui/material';

export default function WelcomeScreenView({ loginHandler }) {
  const theme = useTheme();
  const { mobile, tablet, desktop, wide, extraWide } = useMedia();

  const eapWelcomeScreenBg = `url(${eapWelcomeScreen}) center top no-repeat`;

  const getWelcomeFontSize = () => {
    if (mobile || tablet) return '1.5rem';
    if (desktop) return '2rem';
    if (wide) return '2.3rem';
    if (extraWide) return '3rem';
    return '2.5rem';
  };

  const getDescFontSize = () => {
    if (mobile || tablet) return '1rem';
    if (extraWide) return '1.6rem';
    return '1.2rem';
  };

  const getDiveInFontSize = () => {
    if (mobile || tablet) return '0.8rem';
    if (extraWide) return '1.2rem';
    return '1rem';
  };

  return (
    <Box
      sx={{
        background: mobile ? theme.palette.primary.main : eapWelcomeScreenBg,
        backgroundSize: 'cover',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box>
        <img
          src={maruthiSuzukiWhiteLogo}
          alt="Maruthi Suzuki Logo"
          width={200}
          style={{ padding: '1.5rem 0 0 1.5rem', maxWidth: '100%' }}
        />
      </Box>
      <Box textAlign="right" pr={4}>
        <img src={EAP_WelcomeScreenLogo} alt="Maruthi Suzuki Logo" width={150} />
        {/* <WelcomeScreenEAPLogo width={130} height={130} /> */}
      </Box>
      <Box color={theme.palette.primary.white} width={mobile ? 'auto' : '50%'} m="auto 0 auto auto">
        <Box pl={3} pr={4}>
          <Typography fontWeight={300} fontSize={getWelcomeFontSize()}>
            Welcome to the
          </Typography>
          <Typography fontWeight={700} fontSize={getWelcomeFontSize()}>
            Enterprise Analytics & AI Platform
          </Typography>
          <Typography variant="body1" py={2} fontSize={getDescFontSize()}>
            Single platform for everything related to Data Analytics & AI/ML
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: theme.palette.primary.white,
              color: theme.palette.primary.main,
              borderRadius: '99px',
              '&:hover': {
                color: theme.palette.primary.white,
              },
              fontSize: getDiveInFontSize(),
              fontWeight: '700',
              textTransform: 'none',
            }}
            onClick={loginHandler}
          >
            Explore
            {/* Let&apos;s Dive In */}
          </Button>
        </Box>
      </Box>
      <Box textAlign="right" pr={2} position="absolute" right="0" bottom="0">
        <WelcomeScreenHexaLogo width={170} height={170} />
      </Box>
    </Box>
  );
}
