import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getLastProcessedData = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!params?.type) return Promise.reject({ message: 'Accelerator type param is missing' });

  dispatch(getLastProcessedDataRequest());
  dispatch(addLoader({ key: 'getLastProcessedData', message: 'Fetching last processed data...' }));

  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'isLastProDataAvail',
      params: { type: params.type },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(getLastProcessedDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getLastProcessedData'));
    });
};

export const getLastProcessedDataRequest = () => {
  return {
    type: 'GET_LAST_PROCESSED_DATA_REQUEST',
  };
};

export const getLastProcessedDataSuccess = (data) => {
  return {
    type: 'GET_LAST_PROCESSED_DATA_SUCCESS',
    payload: data,
  };
};

export const getLastProcessedDataError = (error) => {
  return {
    type: 'GET_LAST_PROCESSED_DATA_FAILURE',
    payload: error,
  };
};
