import * as XLSX from 'xlsx';
import { orderBy } from 'lodash';

//app
import { api } from 'utils';
import { consts } from 'globalsData';


const app = {
  regExps: {
    url: /((http[s]?|ftp):\/\/)?\/?([^/.]+\.)*?([^/.]+\.[^:/\s.]{2,3}(\.[^:/\s.]{2,3})?)(:\d+)?($|\/)([^#?\s]+)?(.*?)?(#[\w-]+)?/,
  },
  escapeRegExp: (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  },
  getConfig: () => {
    return api.get('/config/config.json');
  },
  getEllipsisString: (fullString, ellipsisLength) => {
    if (!fullString || !ellipsisLength || isNaN(ellipsisLength) || typeof fullString !== 'string') return;
    if (fullString.length > ellipsisLength) return `${fullString.slice(0, ellipsisLength).trim()}...`;
    return fullString;
  },
  hasFeatureAccess: (userInfo, featureName) => {
    if (!userInfo) return false;

    const isActive = userInfo?.status?.toLowerCase() !== 'deleted';
    const isAdmin = userInfo?.roleName?.toLowerCase() === 'admin';
    const featureList = userInfo?.featureList;

    if (!isActive) return false;
    if (isAdmin) return true;
    if (!isAdmin && featureList?.includes(featureName)) return true;
    return false;
  },
  hasBusinessSolutionAccess: (userInfo, solutionName) => {
    if (!userInfo) return false;

    const isActive = userInfo?.status?.toLowerCase() !== 'deleted';
    const isAdmin = userInfo?.roleName?.toLowerCase() === 'admin';
    const otherSolutionsList = userInfo?.otherSolutionsList;

    if (!isActive) return false;
    if (isAdmin) return true;
    if (!isAdmin && otherSolutionsList?.includes(solutionName)) return true;
    return false;
  },
  getBusinessSolutionAccessList: (userInfo, solutionList) => {
    if (!userInfo) return [];
    const isActive = userInfo?.status?.toLowerCase() !== 'deleted';
    const isAdmin = userInfo?.roleName?.toLowerCase() === 'admin';
    const otherSolutionsList = userInfo?.otherSolutionsList;
    if (!isActive) return solutionList;
    return solutionList?.map((solution) => {
      if ((!isAdmin && otherSolutionsList.includes(solution.name)) || isAdmin) {
        return { ...solution, hasAccess: true };
      } else {
        return { ...solution };
      }
    });
  },
  getFileReader: (onProcessData, file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      onProcessData(data);
    };
    reader.readAsBinaryString(file);
  },
  getFileColumnReader: (fileData, accelerator) => {
    const dataStringLines = fileData.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    const firstRow = dataStringLines[1].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    // prepare columns list from headers
    const fileColumns = headers?.map((c, i) => {
      const isNum = !isNaN(firstRow[i]);
      return {
        label: `${isNum ? 'Num' : 'Cat'}_${c}`,
        name: `${c}`,
        key: `${isNum ? 'Num' : 'Cat'}_${c}`?.toLowerCase(),
      };
    });

    if (accelerator === consts.EDA) {
      return fileColumns;
    }

    const numCols = fileColumns?.filter((col) => col?.label?.includes('Num'));
    const catCols = fileColumns?.filter((col) => col?.label?.includes('Cat'));
    return [...orderBy(catCols, ['key'], ['asc']), ...orderBy(numCols, ['key'], ['asc'])];
  },
  getFileDateOrTimeColumnReader: (fileData, accelerator) => {
    const dataStringLines = fileData.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    const firstRow = dataStringLines[1].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const isDateString = (str) => {
      const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{2}$/;
      return dateRegex.test(str);
    };
    const isTimeString = (str) => {
      const timeRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d)(?::(?:[0-5]\d))?$/;
      return timeRegex.test(str);
    };
    const isDateOrTime = (value) => {
      return isDateString(value) || isTimeString(value);
    };

    const dateOrTimeColumns = headers
      .map((c, i) => {
        const isDateOrTimeCol = isDateOrTime(firstRow[i]);

        return isDateOrTimeCol
          ? {
              label: `DateOrTime_${c}`,
              name: c,
              key: `DateOrTime_${c}`.toLowerCase(),
            }
          : null;
      })
      .filter((column) => column !== null);

    return dateOrTimeColumns;
  },
};

export default app;
