import React from 'react';
import PropTypes from 'prop-types';

// mui
import { CircularProgress, Typography, Box, Grid } from '@mui/material';

LoaderView.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
};

export function LoaderView({ message, visible }) {
  // abort
  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1500,
      }}
    >
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <Grid item>
          <CircularProgress size={32} />
        </Grid>
        <Grid item>
          {message && (
            <Typography
              variant="body2"
              sx={{ display: 'block', maxWidth: 200, marginTop: '20px', color: (theme) => theme.palette.primary.main }}
            >
              {message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
