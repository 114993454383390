import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getBvitGraphData = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getBvitGraphDataRequest(reqParams));
  dispatch(addLoader({ key: 'getBvitGraphData', message: 'Fetching graphs data...' }));

  return api
    .post({
      endpoint: endpoint.bvitService,
      path: 'getGraphsData',
      data: reqParams,
    })
    .then((res) => {
      dispatch(getBvitGraphDataSuccess(res?.data));
      return res;
    })
    .catch((error) => {
      dispatch(getBvitGraphDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getBvitGraphData'));
    });
};

export const getBvitGraphDataRequest = (params) => {
  return {
    type: 'GET_BVIT_GRAPH_DATA_REQUEST',
    payload: params,
  };
};

export const getBvitGraphDataSuccess = (data) => {
  return {
    type: 'GET_BVIT_GRAPH_DATA_SUCCESS',
    payload: data,
  };
};

export const getBvitGraphDataError = (error) => {
  return {
    type: 'GET_BVIT_GRAPH_DATA_FAILURE',
    payload: error,
  };
};
