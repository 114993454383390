import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const editAccSummary = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(editAccSummaryRequest(reqParams));
  dispatch(addLoader('editAccSummary'));

  return api
    .post({
      endpoint: endpoint.acceleratorService,
      path: 'edit-acc-summary',
      data: reqParams,
    })
    .then((res) => {
      dispatch(editAccSummarySuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message, 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(editAccSummaryError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('editAccSummary'));
    });
};

export const editAccSummaryRequest = (params) => {
  return {
    type: 'EDIT_EDA_SUMMARY_REQUEST',
    payload: params,
  };
};

export const editAccSummarySuccess = (data) => {
  return {
    type: 'EDIT_EDA_SUMMARY_SUCCESS',
    payload: data,
  };
};

export const editAccSummaryError = (error) => {
  return {
    type: 'EDIT_EDA_SUMMARY_FAILURE',
    payload: error,
  };
};
