import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getPresignedUrl = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!reqParams?.fileName) {
    return Promise.reject({ message: 'file name not found' });
  }

  dispatch(getPresignedUrlRequest(reqParams));
  dispatch(addLoader('getPresignedUrl'));

  return api
    .post({
      endpoint: endpoint.acceleratorService,
      path: 'getPreSignedUrl',
      data: reqParams,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(getPresignedUrlError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getPresignedUrl'));
    });
};

export const getPresignedUrlRequest = (params) => {
  return {
    type: 'GET_PRE_SIGNED_URL_REQUEST',
    payload: params,
  };
};

export const getPresignedUrlSuccess = (data) => {
  return {
    type: 'GET_PRE_SIGNED_URL_SUCCESS',
    payload: data,
  };
};

export const getPresignedUrlError = (error) => {
  return {
    type: 'GET_PRE_SIGNED_URL_FAILURE',
    payload: error,
  };
};
