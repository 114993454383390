export const clearCategoryList = () => {
  return {
    type: 'CLEAR_CATEGORY_LIST',
  };
};

export const searchCategories = (searchValue) => {
  return {
    type: 'SEARCH_CATEGORIES',
    payload: searchValue,
  };
};

export const clearSearchCategories = () => {
  return {
    type: 'CLEAR_SEARCH_CATEGORIES',
  };
};
