import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const updateCarModel = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
  dispatch(updateCarModelRequest(reqParams));
  dispatch(addLoader('updateCarModel'));

  const { Model, length, width, height, bonnet_height, bonnet_length, middle_height,middle_length, back_height,back_length,load_factor,category,carModelId } = reqParams;
  const data = {
    Model,
    length,
    width,
    height,
    bonnet_height,
    bonnet_length,
    middle_height,
    middle_length,
    back_height,
    back_length,
    load_factor,
    category,
  };

  return api
    .post({
      endpoint: endpoint.modelCarrier,
      path: `editCarModel/${carModelId}`,
      data,
    })
    .then((data) => {
      dispatch(updateCarModelSuccess(data?.data));
      dispatch(snackbarNotification(data?.data?.message || 'User Updated Successfully', 'success'));
      return data;
    })
    .catch((error) => {
      dispatch(updateCarModelError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('updateCarModel'));
    });
};

export const updateCarModelRequest = (params) => {
  return {
    type: 'UPDATE_CAR_MODEL_REQUEST',
    payload: params,
  };
};

export const updateCarModelSuccess = (data) => {
  return {
    type: 'UPDATE_CAR_MODEL_SUCCESS',
    payload: data,
  };
};

export const updateCarModelError = (error) => {
  return {
    type: 'UPDATE_CAR_MODEL_FAILURE',
    payload: error,
  };
};
