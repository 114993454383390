import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// mui

import { Typography, Box} from '@mui/material';
import { ReactComponent as UploadCloud } from 'assets/svg/uploadCloud.svg';
import { consts } from 'globalsData';



FileDropZone.propTypes = {
  field: PropTypes.string,
  handleFileUpload: PropTypes.func,
  isItForMp3Files:PropTypes.bool,
  isItForTextFile: PropTypes.bool
};

FileDropZone.defaultProps = {
  field: 'Input Data',
  isItForMp3Files:false,
  isItForTextFile: false,
  handleFileUpload: () => {},
};

export default function FileDropZone({ field, handleFileUpload, isItForMp3Files, isItForTextFile}) {
  
  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload(acceptedFiles, field);
  }, []);

  let { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({ onDrop });

  return (
    <div style={{ padding: '16px' }} {...getRootProps({ className: 'dropzone' })}>
    <Box>
      <Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <UploadCloud width={45} height={45} />
      </Box>
      <Box sx={{ display: 'inline-block', width: 'calc(100% - 20%)', verticalAlign: 'middle', pl: 2 }}>
        <Box>
          <input {...getInputProps()} />
          <Box sx={{ textAlign: 'left', fontSize: '12px' }}>Select a file or drag and drop here</Box>
        </Box>
        <Box>
          <Typography variant="body1" textAlign="left" color="#a1a1a1" fontSize={10} mt={0.5}>
            Files Supported: {isItForMp3Files? 'Mp3, Wav,Ogg,Flac,Opus:' : isItForTextFile? 'Txt, Maximum Size:': 'CSV, XLSX, Maximum Size:'} {isItForTextFile ? `${consts.ACCELERATORS_MAX_TXT_FILE_SIZE / 1000}KB` : `${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
          </Typography>
        </Box>
      </Box>
    </Box>
  </div>
  );
}
