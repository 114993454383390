const regressionStaticData = {
  algorithms: [
    { value: 'linearRegression', label: 'Linear Regression' },
    { value: 'ridgeRegression', label: 'Ridge Regression' },
    { value: 'lassoRegression', label: 'Lasso Regression' },
    { value: 'randomForestRegressor', label: 'Random Forest Regressor' },
    { value: 'kNNRegressor', label: 'KNearest Neighbors Regressor' },
    { value: 'baggingRegressor', label: 'Bagging Regressor' },
    { value: 'adaBoostRegressor', label: 'AdaBoost Regressor' },
    { value: 'gradientBoostingRegressor', label: 'Gradient Boosting Regressor' },
    { value: 'XGBRegressor', label: 'XGB Regressor' },
    { value: 'all', label: 'All' },
  ],

  targetSizeRatioMarks: [
    {
      value: 10,
      label: '10',
    },
    {
      value: 40,
      label: '40',
    },
  ],

  nEstimatorsMarks: [
    {
      value: 10,
      label: '10',
    },
    {
      value: 1000,
      label: '1000',
    },
  ],

  nNeighborsMarks: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 50,
      label: '50',
    },
  ],

  rrSolverList: [
    { value: 'auto', label: 'auto' },
    { value: 'svd', label: 'svd' },
    { value: 'cholesky', label: 'cholesky' },
    { value: 'lsqr', label: 'lsqr' },
    { value: 'sparse_cg', label: 'sparse_cg' },
    { value: 'sag', label: 'sag' },
    { value: 'saga', label: 'saga' },
    { value: 'lbfgs', label: 'lbfgs' },
  ],

  selectionList: [
    { value: 'cyclic', label: 'cyclic' },
    { value: 'random', label: 'random' },
  ],

  kNNalgorithmList: [
    { value: 'auto', label: 'auto' },
    { value: 'ball_tree', label: 'ball_tree' },
    { value: 'kd_tree', label: 'kd_tree' },
    { value: 'brute', label: 'brute' },
  ],

  pList: [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
  ],

  weightsList: [
    { value: 'uniform', label: 'uniform' },
    { value: 'distance', label: 'distance' },
  ],

  rfMaxFeaturesList: [
    { value: 'auto', label: 'auto' },
    { value: 'sqrt', label: 'sqrt' },
    { value: 'log2', label: 'log2' },
  ],

  rfCriterionList: [
    { value: 'squared_error', label: 'squared_error' },
    { value: 'absolute_error', label: 'absolute_error' },
    { value: 'poisson', label: 'poisson' },
  ],

  brMaxFeaturesMarks: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 10,
      label: '10',
    },
  ],

  adaLossList: [
    { value: 'linear', label: 'linear' },
    { value: 'square', label: 'square' },
    { value: 'exponential', label: 'exponential' },
  ],
  gbrCriterionList: [
    {
      value: 'friedman_mse',
      label: 'friedman_mse',
    },
    {
      value: 'squared_error',
      label: 'squared_error',
    },
    {
      value: 'mse',
      label: 'mse',
    },
    {
      value: 'mae',
      label: 'mae',
    },
  ],
  gbrLossList: [
    {
      value: 'squared_error',
      label: 'squared_error',
    },
    {
      value: 'absolute_error',
      label: 'absolute_error',
    },
    {
      value: 'huber',
      label: 'huber',
    },
    {
      value: 'quantile',
      label: 'quantile',
    },
  ],
  gbrMaxFeaturesList: [
    {
      value: 'none',
      label: 'none',
    },
    {
      value: 'auto',
      label: 'auto',
    },
    {
      value: 'sqrt',
      label: 'sqrt',
    },
    {
      value: 'log2',
      label: 'log2',
    },
  ],
  boosterList: [
    {
      value: 'gbtree',
      label: 'gbtree',
    },
    {
      value: 'gblinear',
      label: 'gblinear',
    },
    {
      value: 'dart',
      label: 'dart',
    },
    {
      value: 'none',
      label: 'none',
    },
  ],
  importanceTypeList: [
    {
      value: 'gain',
      label: 'gain',
    },
    {
      value: 'weight',
      label: 'weight',
    },
    {
      value: 'cover',
      label: 'cover',
    },
    {
      value: 'total_gain',
      label: 'total_gain',
    },
    {
      value: 'total_cover',
      label: 'total_cover',
    },
  ],
};

export default regressionStaticData;
