import React from 'react';

const BVITTorqueCalculationView = () => {
  return (
    <div>
      Test Torque
    </div>
  );
};

export default BVITTorqueCalculationView;