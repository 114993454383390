import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { consts } from 'globalsData';

// app
import * as utils from 'utils';
import { addCarModel, getCarModel, hideModal, snackbarNotification, updateCarModel, uploadCarModel } from 'stores';
import { AddEditCarModelsFormView } from './AddEditCarModelsForm.view';
import { useMedia } from 'globalsData';

AddEditCarModelsForm.propTypes = {
  carModel: PropTypes.object,
};

export default function AddEditCarModelsForm({ carModel }) {
  const dispatch = useDispatch();
  const { mobile } = useMedia();
  const [expanded, setExpanded] = useState(false);
  const hiddenFileInput = useRef(null);

  const isUpdateCarModel = Boolean(carModel?._id);
  const fields = [
    {
      name: 'category',
      label: 'Category Name *',
      defaultValue: carModel?.category || '',
      size: 'small',
      type: 'textfield',
      validation: Yup.string()
        .trim()
        .required(consts.modelCarrierValidationMessages.CATEGORY_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'Model',
      label: 'Model Name *',
      defaultValue: carModel?.Model || '',
      size: 'small',
      type: 'textfield',
      validation: Yup.string()
        .trim()
        .max(50, consts.modelCarrierValidationMessages.MODEL_NAME_MAX_FIFTY_CHARACTER)
        .required(consts.modelCarrierValidationMessages.MODEL_NAME_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'load_factor',
      label: 'Load Factor *',
      defaultValue: carModel?.load_factor || '',
      size: 'small',
      type: 'textfield',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.LOAD_FACTOR_MAX_TWO_DIGITS)
        .required(consts.modelCarrierValidationMessages.LOAD_FACTOR_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'length',
      label: 'Length *',
      defaultValue: carModel?.length || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.LENGTH_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.LENGTH_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'width',
      label: 'Width *',
      defaultValue: carModel?.width || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.WIDTH_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.WIDTH_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'height',
      label: 'Height *',
      defaultValue: carModel?.height || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.HEIGHT_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.HEIGHT_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'bonnet_height',
      label: 'Bonnet Height *',
      defaultValue: carModel?.bonnet_height || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.HEIGHT_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.HEIGHT_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'bonnet_length',
      label: 'Bonnet Length *',
      defaultValue: carModel?.bonnet_length || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.HEIGHT_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.HEIGHT_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'middle_height',
      label: 'Middle Height *',
      defaultValue: carModel?.middle_height || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.HEIGHT_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.HEIGHT_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'middle_length',
      label: 'Middle Length *',
      defaultValue: carModel?.middle_length || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.HEIGHT_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.HEIGHT_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'back_height',
      label: 'Back Height *',
      defaultValue: carModel?.back_height || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.HEIGHT_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.HEIGHT_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
    {
      name: 'back_length',
      label: 'Back Length *',
      defaultValue: carModel?.back_length || '',
      size: 'small',
      type: 'number',
      validation: Yup.number()
        .max(10000, consts.modelCarrierValidationMessages.HEIGHT_MAX_FOUR_DIGITS)
        .required(consts.modelCarrierValidationMessages.HEIGHT_REQUIRED),
      inputProps: {
        style: { width: mobile ? '250px' : '368px' },
      },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const formValues = watch();
  const isPageEdited = !isEqual(defaultValues, formValues);

  const onSubmit = (data) => {
    if (!isUpdateCarModel) {
      dispatch(addCarModel(data)).then((res) => {
        if (res?.status === 200) {
          dispatch(getCarModel());
          dispatch(hideModal('ADD_EDIT_CAR_MODELS'));
        }
      });
    } else {
      dispatch(updateCarModel({...data,...{carModelId:carModel?._id}})).then((res) => {
        if (res?.status === 200) {
          dispatch(getCarModel());
          dispatch(hideModal('ADD_EDIT_CAR_MODELS'));
        }
      });
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFileUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChangeFileUpload = (event) => {
    let fileUploaded = event.target.files[0];
    var blob = fileUploaded.slice(0, fileUploaded.size, 'text/xlsx');
    let file = new File([blob], 'carModel.xlsx', { type: 'text/xlsx' });

    const validFileFormat = consts.formAllowedFileFormats?.includes(file?.name?.split('.')[1]);
    if (validFileFormat) {
      dispatch(uploadCarModel({ file })).then((res) => {
        if (res?.status === 200) {
          dispatch(hideModal('ADD_EDIT_CAR_MODELS'));
          dispatch(snackbarNotification('Submitted successully', 'success'));
        }
      });
    } else {
      dispatch(snackbarNotification('This file type is not allowed, upload only csv and xlsx format', 'warning'));
    }
  };
  return (
    <AddEditCarModelsFormView
      hiddenFileInput={hiddenFileInput}
      handleFileUploadClick={handleFileUploadClick}
      handleChangeFileUpload={handleChangeFileUpload}
      isPageEdited={isPageEdited}
      fields={fields}
      errors={errors}
      isUpdateCarModel={isUpdateCarModel}
      formProps={{ register, handleSubmit }}
      handlers={{ onSubmit }}
      expanded={expanded}
      handleChange={handleChange}
    />
  );
}
