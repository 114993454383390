import React, { useCallback } from 'react';
import PropTypes from 'prop-types';


//mui
import { Box, Button, Grid, TextField, Typography, IconButton, MenuItem, FormControl, Select, FormHelperText, TextareaAutosize, Slider } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//app
import * as utils from 'utils';
import { consts } from 'globalsData';
import { BootstrapTooltip, FileDropZone } from 'components';
import "../../Pages/Home/Home.css";

AddEditTimeSeriesFormView.propTypes = {
  inputDataFileNameFromApi: PropTypes.string,
  predictionFileNameFromApi: PropTypes.string,
  watchAlgorithm: PropTypes.string.isRequired,
  isRedirectFromEdaCharts: PropTypes.bool.isRequired,
  summaryDeteails: PropTypes.object.isRequired,
  seasonalPeriodsVal: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  dayFirst: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    viewLastProcessData: PropTypes.func.isRequired,
  }),
  algorithms: PropTypes.array.isRequired,
  specificValue: PropTypes.string.isRequired,
  arimaSeasonal: PropTypes.array.isRequired,
  seasonality: PropTypes.array.isRequired,
  prophetDailySeasonality: PropTypes.array.isRequired,
  seasonalityMode: PropTypes.array.isRequired,
  seasonalPeriod: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  dampedTrend: PropTypes.array.isRequired,
  exponentialSeasonal: PropTypes.array.isRequired,
  freq: PropTypes.array.isRequired,
  trend: PropTypes.array.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  getFileFieldTitle: PropTypes.func.isRequired,
  isNoFilesUploaded: PropTypes.object.isRequired,
  isValidFileFormats: PropTypes.object.isRequired,
  isValidFileSizes: PropTypes.object.isRequired,
  fileUploadFieldList: PropTypes.array.isRequired,
  renderHyperParameters: PropTypes.func.isRequired,
  differencingValueList: PropTypes.array.isRequired,
  targetSizeRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  columnnError: PropTypes.bool.isRequired,
};

export function AddEditTimeSeriesFormView({
  inputDataFileNameFromApi,
  predictionFileNameFromApi,
  watchAlgorithm,
  isRedirectFromEdaCharts,
  summaryDeteails,
  columns,
  dayFirst,
  fileUploadFieldList,
  handleFileUpload,
  formProps,
  fields,
  algorithms,
  arimaSeasonal,
  handlers,
  renderHyperParameters,
  isValidFileFormats,
  isValidFileSizes,
  trend,
  dampedTrend,
  exponentialSeasonal,
  freq,
  specificValue,
  seasonality,
  prophetDailySeasonality,
  seasonalityMode,
  seasonalPeriod,
  isNoFilesUploaded,
  getFileFieldTitle,
  seasonalPeriodsVal,
  differencingValueList,
  targetSizeRatio,
  columnnError,
  timeStep
}) {
  const { register, handleSubmit, errors, control } = formProps;
  const editFormOverFlow = inputDataFileNameFromApi ? { overflowX: 'hidden', overflowY: 'auto' } : {};
  const HelperText = ({ helperTextProp }) => {
    return (
      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
        {helperTextProp}
      </FormHelperText>
    );
  };
  return (
    <>
    {!inputDataFileNameFromApi &&
      <Box sx={{ backgroundColor: '#D6E7FB', borderRadius: '18px', marginBottom: '10px', padding: '15px', textAlign: 'left', fontSize: '14px' }}>
        <Typography fontSize={14} textAlign="left" pb={0.5} >
          Data Guidelines
        </Typography>
        <ul style={{ margin: '0', paddingLeft: '30px' }}>
          <li style={{ fontSize: '14px' }}>The time step should be convertible into a datetime format (e.g., DD/MM/YYYY, 12-jan-2019, 12/01/2019, 12-01-2019).</li>
        </ul>
      </Box>}
    <Box sx={{ borderRadius: '18px', backgroundColor: '#fff', ...editFormOverFlow }}>
      <Box px={4} py={2}>
        <Box
          id="timeseries-form"
          mt={2}
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(handlers.onSubmit)}
          sx={
            !inputDataFileNameFromApi
              ? { overflowY: 'auto', height: `${watchAlgorithm?.toLowerCase() !== 'all' ? '100%' : '100%'}`, overflow: 'auto' }
              : {}
          }
        >
          <Box>
            {inputDataFileNameFromApi && watchAlgorithm?.toLowerCase() === 'all' && <HelperText helperTextProp="You are not allowed to edit the model hyperparameters as you have selected 'All' for algorithm." />}
          </Box>
          <Grid container spacing={2}>
                {inputDataFileNameFromApi && (
                  <Grid item xs={12}>
                    <Typography textAlign="left" pb={0.5} fontSize={14}>
                      Input Data
                    </Typography>
                    <Box border="2px dashed #767676" px={1} py={0.5} borderRadius="6px">
                      {inputDataFileNameFromApi}
                    </Box>
                  </Grid>
                )}
            {!(inputDataFileNameFromApi || predictionFileNameFromApi) && (
              <Grid item xs={12}>
                {fileUploadFieldList.map((item, i) => (
                  <Grid item xs={12} key={item.field}>
                    <Box id="uploadInputFile">
                      <Typography textAlign="left" pb={0.5} fontSize={14}>
                        {isRedirectFromEdaCharts && i === 0 ? 'Input Data' : getFileFieldTitle(item)}
                      </Typography>
                      <Box border="1px dashed #cfcaca" px={1} py={0.5} borderRadius="6px">
                        {isRedirectFromEdaCharts && i === 0 && (
                          <>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                              <Grid item>
                                <Typography fontSize={14}>{summaryDeteails?.input_data?.fileName}</Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {!(isRedirectFromEdaCharts && i === 0) && (
                          <>
                            {!item?.file?.name ? (
                              <>
                                <FileDropZone field={item.field} handleFileUpload={handleFileUpload} />
                              </>
                            ) : (
                              <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                <Grid item>
                                  <Typography fontSize={14}>{item?.file?.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    color="inherit"
                                    aria-label="delete file"
                                    onClick={() => handlers.onDelete(item.field)}
                                    edge="start"
                                    sx={{
                                      marginLeft: 1,
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </Box>
                      <Box className="classificationErrMsgHolder">
                        {isValidFileFormats.inputDataFile &&
                          isValidFileSizes.inputDataFile &&
                          item.field === fileUploadFieldList[0].field &&
                          isNoFilesUploaded.inputDataFile &&
                          !item.file && <HelperText helperTextProp="Please Attach (.csv or .xlsx) Input Data File" />}
                        {!isValidFileFormats.inputDataFile && item.field === fileUploadFieldList[0].field && (
                          <HelperText helperTextProp="Input data file format should be (.csv or .xlsx) and should not contains multiple (.) dots in file name" />
                        )}
                        {!isValidFileSizes.inputDataFile && item.field === fileUploadFieldList[0].field && (
                          <HelperText
                            helperTextProp={`Input data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid item xs={6}>
              <Box id="algorithm">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Algorithm
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_ALGORITHM} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.algorithm?.message}>
                  <Select
                    {...register('algorithm')}
                    {...utils.form.getFieldProp(fields, 'algorithm')}
                    sx={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {algorithms.map((method) => (
                      <MenuItem value={method.value} key={method.value} sx={{ fontSize: '14px' }}>
                        {method.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.algorithm?.message && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.algorithm?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="forecastCol">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Forecast Variable
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_FORECAST_VARIABLE} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                {inputDataFileNameFromApi ? (
                  <TextField {...register('forecastCol')} {...utils.form.getFieldProp(fields, 'forecastCol')} />
                ) : (
                  <>
                    {!isRedirectFromEdaCharts ? (
                      <FormControl sx={{ width: '100%' }} error={!!errors?.targetVarialble?.message}>
                        <Select
                          {...register('forecastCol')}
                          {...utils.form.getFieldProp(fields, 'forecastCol')}
                          sx={{ fontSize: '14px', textAlign: 'left' }}
                        >
                          {columns?.map((header) => (
                            <MenuItem value={header.name} key={header.key} sx={{ fontSize: '14px' }}>
                              {header.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors?.forecastCol?.message && (
                          <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                            {errors.forecastCol.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <TextField {...register('forecastCol')} {...utils.form.getFieldProp(fields, 'forecastCol')} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            {/* timeStepCol grid starts */}
            <Grid item xs={6}>
              <Box id="timeStepCol">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Time Step
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_TIME_STEP} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                {inputDataFileNameFromApi ? (
                  <TextField {...register('timeStepCol')} {...utils.form.getFieldProp(fields, 'timeStepCol')} />
                ) : (
                  <>
                    {!isRedirectFromEdaCharts ? (
                      <FormControl sx={{ width: '100%' }} error={!!errors?.targetVarialble?.message}>
                        <Select
                          {...register('timeStepCol')}
                          {...utils.form.getFieldProp(fields, 'timeStepCol')}
                          sx={{ fontSize: '14px', textAlign: 'left' }}
                        >
                          {timeStep?.map((header) => (
                            <MenuItem value={header.name} key={header.key} sx={{ fontSize: '14px' }}>
                              {header.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors?.timeStepCol?.message && (
                          <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                            {errors.timeStepCol.message}
                          </FormHelperText>
                        )}
                         {columnnError && (
                        <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                          Timestep value should not be same as Forecast variable
                        </FormHelperText>
                      )}
                      </FormControl>
                    ) : (
                      <TextField {...register('timeStepCol')} {...utils.form.getFieldProp(fields, 'timeStepCol')} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="dayFirst">
              <Box sx={{ textAlign: 'left' }}>
                <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  Day First
                </Typography>
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                  <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_DAY_FIRST} placement={'bottom'}>
                    <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                  </BootstrapTooltip>
                </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.dayFirst?.message}>
                  <Select {...register('dayFirst')} {...utils.form.getFieldProp(fields, 'dayFirst')}>
                    {dayFirst.map((dayFirst) => (
                      <MenuItem value={dayFirst.value} key={dayFirst.value}>
                        {dayFirst.label}
                      </MenuItem>
                    ))}
                  </Select>
                    {!!errors?.dayFirst?.message && (
                      <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors?.dayFirst?.message}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="forecastLength">
              <Box sx={{ textAlign: 'left' }}>
                <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  Forecast Length
                </Typography>
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                  <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_FORECAST_LENGTH} placement={'bottom'}>
                    <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                  </BootstrapTooltip>
                </Box>
                </Box>
                <TextField
                  {...register('forecastLength')}
                  {...utils.form.getFieldProp(fields, 'forecastLength')}
                  error={!!errors?.forecastLength?.message}
                  inputProps={{ min: '0.001', max: '10', step: '0.01' }}
                />
                {!!errors?.forecastLength?.message && (
                  <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
                    {errors?.forecastLength?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="testSizeRatio">
              <Box sx={{ textAlign: 'left' }}>
                <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  Test Size Ratio
                </Typography>
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                  <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_TEST_SIZE_RATIO} placement={'bottom'}>
                     <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                  </BootstrapTooltip>
                </Box>
                </Box>
                <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                  <Grid item xs={11}>
                    <Slider {...register('testSizeRatio')} {...utils.form.getFieldProp(fields, 'testSizeRatio')} />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle2" pt={0.5}>
                      {targetSizeRatio}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {watchAlgorithm === 'exponentialSmoothing' ? (
              <Grid item xs={6}>
              <Box id="differencingValue">
              <Box sx={{ textAlign: 'left' }}>
                <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Differencing Value
                </Typography>
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                  <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_DIFFERENCING_VALUE} placement={'bottom'}>
                     <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                  </BootstrapTooltip>
                </Box>
                </Box>
                <TextField
                  {...register('differencingValue')}
                  {...utils.form.getFieldProp(fields, 'differencingValue')}
                  error={!!errors?.differencingValue?.message}
                  inputProps={{ min: '0', max: '12', step: '1' }}
                />
                {!!errors?.differencingValue?.message && (
                  <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
                    {errors?.differencingValue?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            ) : (
              <></>
            )}

            {/* timeStepCol grid ends */}
          </Grid>
          {watchAlgorithm?.toLowerCase() !== 'all' && (
            <>
              {/* <Box borderBottom="2px dashed #767676" my={2} width="100%" /> */}
              <Box my={2} id="hyperParametersAccordion">
                <Typography fontSize={14} fontWeight={600} textAlign="left" color="#5a5a5a" py={2}>
                  Model Hyper Parameters
                </Typography>
                {renderHyperParameters(algorithms[0].value) && (
                  <Box id="exponential">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="trend">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Trend
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_EXPONENTIAL_TREND} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.trend?.message}>
                            <Select {...register('trend')} {...utils.form.getFieldProp(fields, 'trend')}>
                              {trend.map((solver) => (
                                <MenuItem value={solver.value} key={solver.value}>
                                  {solver.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.trend?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.trend?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="dampedTrend">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            dampedTrend
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_EXPONENTIAL_DAMPED_TREND} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.dampedTrend?.message}>
                            <Select {...register('dampedTrend')} {...utils.form.getFieldProp(fields, 'dampedTrend')}>
                              {dampedTrend.map((dampedTrend) => (
                                <MenuItem value={dampedTrend.value} key={dampedTrend.value}>
                                  {dampedTrend.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.dampedTrend?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.dampedTrend?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="seasonal">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            seasonal
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_EXPONENTIAL_SEASONAL} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.seasonal?.message}>
                            <Select {...register('seasonal')} {...utils.form.getFieldProp(fields, 'seasonal')}>
                              {exponentialSeasonal.map((seasonal) => (
                                <MenuItem value={seasonal.value} key={seasonal.value}>
                                  {seasonal.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.seasonal?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.seasonal?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="seasonalPeriods">
                        <Box sx={{ textAlign: 'left' }}>
                        <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            seasonal periods
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_EXPONENTIAL_SEASONAL_PERIODS} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.seasonalPeriods?.message}>
                            <Select {...register('seasonalPeriods')} {...utils.form.getFieldProp(fields, 'seasonalPeriods')}>
                              {seasonalPeriod.map((seasonalPeriods) => (
                                <MenuItem value={seasonalPeriods.value} key={seasonalPeriods.value}>
                                  {seasonalPeriods.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.seasonalPeriods?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.seasonalPeriods?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                     {seasonalPeriodsVal === 'specificValue' ?
                       <Grid item xs={6}>
                       <Box py={2} id="constantValue">
                         <TextField
                           id="specificValueForSeasonalPeriods"
                           placeholder="Enter specific value"
                           variant="outlined"
                           value={specificValue}
                           type="number"
                           size="small"
                           sx={{ width: '100%' }}
                           onChange={(e) => handlers.handleSpecificValue(e)}
                         />
                       </Box>
                     </Grid>
                     :<></>}
                      <Grid item xs={6}>
                        <Box id="freq">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            freq
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_EXPONENTIAL_FREQ} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.freq?.message}>
                            <Select {...register('freq')} {...utils.form.getFieldProp(fields, 'freq')}>
                              {freq.map((freq) => (
                                <MenuItem value={freq.value} key={freq.value}>
                                  {freq.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.freq?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.freq?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[1].value) && (
                  <Box id="arima">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="arimaSeasonal">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Seasonal
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_ARIMA_SEASONAL} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.arimaSeasonal?.message}>
                            <Select {...register('arimaSeasonal')} {...utils.form.getFieldProp(fields, 'arimaSeasonal')}>
                              {arimaSeasonal.map((arimaSeasonal) => (
                                <MenuItem value={arimaSeasonal.value} key={arimaSeasonal.value}>
                                  {arimaSeasonal.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.arimaSeasonal?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.arimaSeasonal?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="seasonalDifferencing">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Seasonal Differencing
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_ARIMA_SEASONAL_DIFFERENCING} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <TextField
                            {...register('seasonalDifferencing')}
                            {...utils.form.getFieldProp(fields, 'seasonalDifferencing')}
                            error={!!errors?.seasonalDifferencing?.message}
                            inputProps={{ min: '0.001', max: '10', step: '0.01' }}
                          />
                          {!!errors?.seasonalDifferencing?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.seasonalDifferencing?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[2].value) && (
                  <Box id="prophet">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="prophetSeasonalityMode">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Seasonality Mode
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY_MODE} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.prophetSeasonalityMode?.message}>
                            <Select {...register('prophetSeasonalityMode')} {...utils.form.getFieldProp(fields, 'prophetSeasonalityMode')}>
                              {seasonalityMode.map((prophetSeasonalityMode) => (
                                <MenuItem value={prophetSeasonalityMode.value} key={prophetSeasonalityMode.value}>
                                  {prophetSeasonalityMode.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.prophetSeasonalityMode?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.prophetSeasonalityMode?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="prophetYearlySeasonality">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Yearly Seasonality
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.prophetYearlySeasonality?.message}>
                            <Select
                              {...register('prophetYearlySeasonality')}
                              {...utils.form.getFieldProp(fields, 'prophetYearlySeasonality')}
                            >
                              {seasonality.map((prophetYearlySeasonality) => (
                                <MenuItem value={prophetYearlySeasonality.value} key={prophetYearlySeasonality.value}>
                                  {prophetYearlySeasonality.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.prophetYearlySeasonality?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.prophetYearlySeasonality?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="prophetWeeklySeasonality" >
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Weekly Seasonality
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.prophetWeeklySeasonality?.message}>
                            <Select
                              {...register('prophetWeeklySeasonality')}
                              {...utils.form.getFieldProp(fields, 'prophetWeeklySeasonality')}
                            >
                              {seasonality.map((prophetWeeklySeasonality) => (
                                <MenuItem value={prophetWeeklySeasonality.value} key={prophetWeeklySeasonality.value}>
                                  {prophetWeeklySeasonality.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.prophetWeeklySeasonality?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.prophetWeeklySeasonality?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="prophetDailySeasonality">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Daily Seasonality
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.prophetDailySeasonality?.message}>
                            <Select
                              {...register('prophetDailySeasonality')}
                              {...utils.form.getFieldProp(fields, 'prophetDailySeasonality')}
                            >
                              {prophetDailySeasonality.map((prophetDailySeasonality) => (
                                <MenuItem value={prophetDailySeasonality.value} key={prophetDailySeasonality.value}>
                                  {prophetDailySeasonality.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.prophetDailySeasonality?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.prophetDailySeasonality?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[3].value) && (
                  <Box id="neuralProphet">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="npSeasonalityMode">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Seasonality Mode
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY_MODE} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.npSeasonalityMode?.message}>
                            <Select {...register('npSeasonalityMode')} {...utils.form.getFieldProp(fields, 'npSeasonalityMode')}>
                              {seasonalityMode.map((npSeasonalityMode) => (
                                <MenuItem value={npSeasonalityMode.value} key={npSeasonalityMode.value}>
                                  {npSeasonalityMode.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.npSeasonalityMode?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.npSeasonalityMode?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="npYearlySeasonality">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Yearly Seasonality
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.npYearlySeasonality?.message}>
                            <Select {...register('npYearlySeasonality')} {...utils.form.getFieldProp(fields, 'npYearlySeasonality')}>
                              {seasonality.map((npYearlySeasonality) => (
                                <MenuItem value={npYearlySeasonality.value} key={npYearlySeasonality.value}>
                                  {npYearlySeasonality.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.npYearlySeasonality?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.npYearlySeasonality?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="npWeeklySeasonality">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Weekly Seasonality
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.npWeeklySeasonality?.message}>
                            <Select {...register('npWeeklySeasonality')} {...utils.form.getFieldProp(fields, 'npWeeklySeasonality')}>
                              {seasonality.map((npWeeklySeasonality) => (
                                <MenuItem value={npWeeklySeasonality.value} key={npWeeklySeasonality.value}>
                                  {npWeeklySeasonality.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.npWeeklySeasonality?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.npWeeklySeasonality?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="npDailySeasonality">
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Daily Seasonality
                          </Typography>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                            <BootstrapTooltip title={consts.TOOLTIP.TIMESERIES_SEASONALITY} placement={'bottom'}>
                              <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                            </BootstrapTooltip>
                          </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.npDailySeasonality?.message}>
                            <Select {...register('npDailySeasonality')} {...utils.form.getFieldProp(fields, 'npDailySeasonality')}>
                              {seasonality.map((npDailySeasonality) => (
                                <MenuItem value={npDailySeasonality.value} key={npDailySeasonality.value}>
                                  {npDailySeasonality.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.npDailySeasonality?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.npDailySeasonality?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          )}
            <Box id="justificationRemarks" mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box id="justificationRemarks">
                  <Typography textAlign="left" pb={0.5} fontSize={14}>
                    Justification Remarks
                  </Typography>
                  <FormControl sx={{ width: '100%' }} error={!!errors?.justificationRemarks?.message}>
                    <TextareaAutosize
                      {...register('justificationRemarks')}
                      {...utils.form.getFieldProp(fields, 'justificationRemarks')}
                      className="textAreaStyles"
                    />

                    {!!errors?.justificationRemarks?.message && (
                      <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors.justificationRemarks.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box id="actionsButtons" sx={{ background: '#d2d2d2' }} px={4} py={2}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                borderRadius: '99px',
                p: '0.4rem 1.5rem',
              }}
              type="submit"
              onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
            >
              {`${inputDataFileNameFromApi ? 'Update Graphs' : 'Analyze'}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </>
  );
}
