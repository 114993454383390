import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getTotalActiveUser = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getTotalActiveUserRequest());
  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'totalActiveUsers',
    })
    .then((res) => {
      dispatch(getTotalActiveUserSuccess( res?.data || {} ));
    })
    .catch((error) => {
      dispatch(getTotalActiveUserError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getTotalActiveUser'));
    });
};

export const getTotalActiveUserRequest = () => {
  return {
    type: 'GET_TOATAL_ACTIVE_USERS_REQUEST',
  };
};

export const getTotalActiveUserSuccess = (data) => {
  return {
    type: 'GET_TOATAL_ACTIVE_USERS_SUCCESS',
    payload: data,
  };
};

export const getTotalActiveUserError = (error) => {
  return {
    type: 'GET_TOATAL_ACTIVE_USERS_FAILURE',
    payload: error,
  };
};
