import React from 'react';
import BVITModelView from './BVITModel.view';

const BVITModel = ({ filterDetails, handleFiltersChange, model, setModel, vehicleTypeError, setVehicleTypeError,
    modeError, setModeError }) => {

    return(
        <BVITModelView filterDetails={filterDetails} handleFiltersChange={handleFiltersChange}
        model={model} setModel={setModel} vehicleTypeError={vehicleTypeError} 
        setVehicleTypeError={setVehicleTypeError} modeError={modeError} 
        setModeError={setModeError} />
    )
}

export default BVITModel;