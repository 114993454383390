import React from 'react';
import { Checkbox, FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';

import './BVITModel.css';

const BVITModelView = ({ filterDetails, handleFiltersChange, model, setModel, vehicleTypeError,
  setVehicleTypeError, modeError, setModeError }) => {
  const filterData = filterDetails?.model;
  return (
    <div className='tab-model'>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small" error={vehicleTypeError}>
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>*Vehicle Type</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          label="Vehicle Type"
          size="small"
          value={model.vehicle_type}
          onChange={(e) => {
            setModel(prevState => ({...prevState, vehicle_type: e.target.value}));
            setVehicleTypeError(e.target.value === '');
            const details = filterDetails?.vehicle_load_data?.filter((data) => data["Vehicle Type"] === e.target.value);
            handleFiltersChange(details);
          }}>
          {filterData?.vehicle_type?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              {data}
            </MenuItem>
          ))}
        </Select>
        {vehicleTypeError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', marginRight: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please select vehicle type</FormHelperText>}
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small" error={modeError}>
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>*Mode</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          label="Mode"
          size="small"
          value={model.mode}
          onChange={(e) => {
            setModel(prevState => ({...prevState, mode: e.target.value}));
            setModeError(e.target.value === '');
            const details = filterDetails?.vehicle_load_data?.filter((data) => data["Mode"] === e.target.value);
            handleFiltersChange(details);
          }}>
          {filterData?.mode?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              {data}
            </MenuItem>
          ))}
        </Select>
        {modeError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', marginRight: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please select mode type</FormHelperText>}
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Model</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Model"
            size="small"
            value={model.model || []}
            renderValue={(selected) => selected.join(', ')}
            onChange={(e) =>  {
              setModel(prevState => ({...prevState, model: e.target.value}));
            }}
            onBlur={(e) => {
              const newModel = e.target.value;
              const details = filterDetails?.vehicle_load_data?.filter((data) => newModel.includes(data["Model"]));
              handleFiltersChange(details);
            }}
            >
            {filterData?.model?.map((data, i) => (
             <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={model.model.includes(data)} color='primary' />
              {data}
             </MenuItem>
            ))}
          </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Stage</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Stage"
          size="small"
          value={model.stage || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setModel(prevState => ({...prevState, stage: e.target.value}))}
          onBlur={(e) => {
            const newStage = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newStage.includes(data["Stage"]));
            handleFiltersChange(details);
          }}>
          {filterData?.stage?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={model.stage.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Platform</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Platform"
          size="small"
          value={model.platform || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setModel(prevState => ({...prevState, platform: e.target.value}))}
          onBlur={(e) => {
            const newPlatform = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newPlatform.includes(data["Platform"]));
            handleFiltersChange(details);
          }}>
          {filterData?.platform?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={model.platform.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
    </div>
  );
};

export default BVITModelView;
