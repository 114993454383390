import React from 'react';
import BVITClutchSpecificationsView from './BVITClutchSpecifications.view';

const BVITClutchSpecifications = ({ filterDetails, handleFiltersChange, clutchSpecifications,
     setClutchSpecifications }) => {

    return(
        <BVITClutchSpecificationsView filterDetails={filterDetails} handleFiltersChange={handleFiltersChange}
         clutchSpecifications={clutchSpecifications} setClutchSpecifications={setClutchSpecifications} />
    )
}

export default BVITClutchSpecifications;