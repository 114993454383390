import React from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

// app
import { Confirm } from 'components';
import {
  AddEditUser,
  AddEditCategory,
  AddEditWebsite,
  AddEditEDAForm,
  AddEditClassificationForm,
  AddEditRegressionForm,
  AddEditCarModelsForm,
  AddEditTimeSeriesForm,
  AddEditEDAUnsupervisedForm,
  AddEditClusteringForm
} from 'forms';
import ViewCrawlerRecords from './ViewCrawlerRecordsModal/ViewCrawlerRecordsModal';
import ViewAcceleratorMatrix from './ViewAcceleratorMatrix/ViewAcceleratorMatrix';
import { hideModal } from 'stores';

// mui
import { Button, Dialog, DialogTitle, DialogActions, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle p={2} {...other}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
        <Grid item>{children}</Grid>
        <Grid item>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </DialogTitle>
  );
}

export default function Modal() {
  const dispatch = useDispatch();
  const uiModal = useSelector((state) => get(state, 'ui.modal'));
  const components = {
    ADD_EDIT_CATEGORY: AddEditCategory,
    ADD_EDIT_WEBSITE: AddEditWebsite,
    ADD_EDIT_USER: AddEditUser,
    CONFIRM: Confirm,
    VIEW_CRAWLER_RECORDS: ViewCrawlerRecords,
    VIEW_ACCELERATOR_MATRIX: ViewAcceleratorMatrix,
    ADD_EDIT_EDA: AddEditEDAForm,
    ADD_EDIT_EDAU: AddEditEDAUnsupervisedForm,
    ADD_EDIT_CLASSIFICATION: AddEditClassificationForm,
    ADD_EDIT_REGRESSION: AddEditRegressionForm,
    ADD_EDIT_CAR_MODELS: AddEditCarModelsForm,
    ADD_EDIT_TIMESERIES: AddEditTimeSeriesForm,
    ADD_EDIT_CLUSTERING: AddEditClusteringForm,
  };

  const handleClose = (type) => {
    dispatch(hideModal(type));
    return null;
  };

  // abort
  if (!uiModal[0]?.type) return null;

  return uiModal.map((modal) => {
    const ModalContent = components[modal.type];
    const showDivider = modal?.props?.showDivider ?? true;

    return (
      <BootstrapDialog
        key={modal?.type}
        onClose={() => handleClose(modal?.type)}
        aria-labelledby="customized-dialog-title"
        open={modal?.visible}
        maxWidth={modal?.props?.maxWidth}
        fullWidth
        fullScreen={modal?.props?.fullScreen}
      >
        {modal?.props?.title && (
          <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(modal.type)}>
            {modal?.props?.title}
          </BootstrapDialogTitle>
        )}
        {Boolean(showDivider) && <Divider />}

        <ModalContent {...modal.props.componentProps} handleClose={() => handleClose(modal.type)} />

        {get(modal, 'actions', []).length > 0 && (
          <DialogActions>
            {modal.actions.map((action, idx) => {
              let btnAction = null;
              if (action.type === 'ok' || action.type === 'cancel') {
                btnAction = handleClose(modal.type);
              } else {
                btnAction = action.callback;
              }

              return (
                <Button
                  key={idx}
                  {...(btnAction && { onClick: btnAction })}
                  {...(action.variant && { variant: action.variant })}
                  {...(action.color && { color: action.color })}
                  {...(action.size && { size: action.size })}
                  text={action.label}
                />
              );
            })}
          </DialogActions>
        )}
      </BootstrapDialog>
    );
  });
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
