import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

// app
import { setConfigVars } from 'stores';
import { Loader } from 'components';

export const ConfigLoader = ({ children }) => {
  const dispatch = useDispatch();
  const configVars = useSelector((state) => get(state, 'config.vars'));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/config/config.json', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then(() => {
            return Promise.reject({
              ok: response.ok,
              message: 'Configuration fetch response invalid',
              ...(response.status && { status: response.status }),
              ...(response.type && { status: response.type }),
              ...(response.statusText && { status: response.statusText }),
            });
          });
        }
      })
      .then((json) => {
        if (json && json.env) {
          dispatch(setConfigVars(json));
          return json;
        } else {
          return Promise.reject({ message: 'Configuration property missing or corrupted' });
        }
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loader label="Config Loading..." />;
  }

  return configVars && configVars.env && children;
};
