const clusterStaticData = {
  algorithms: [
    { value: 'agglomerativeClustering', label: 'Agglomerative Clustering' },
    { value: 'kmeans', label: 'Kmeans Clustering' },
    { value: 'kmodes', label: 'Kmodes Clustering' },
    { value: 'auto_n_cluster', label: 'Auto N Clusters' },
  ],
  agglomotiveMetricClusteringList: [
    { value: 'euclidean', label: 'Euclidean' },
    { value: 'l1', label: 'L1' },
    { value: 'l2', label: 'L2' },
    { value: 'manhattan', label: 'Manhattan' },
    { value: 'cosine', label: 'Cosine' },
  ],
  agglomotiveLinkageClusteringList: [
    { value: 'ward', label: 'Ward' },
    { value: 'complete', label: 'Complete' },
    { value: 'average', label: 'Average' },
    { value: 'single', label: 'Single' },
  ],
  KmeansInitList: [
    { value: 'k-means++', label: 'k-means++' },
    { value: 'random', label: 'random' },
  ],
  KmeansAlgorithmList: [
    { value: 'lloyd', label: 'Lloyd' },
    { value: 'elkan', label: 'Elkan' },
    { value: 'auto', label: 'Auto' },
    { value: 'full', label: 'Full' },
  ],
  KmodesInitList: [
    { value: 'Huang', label: 'Huang' },
    { value: 'Cao', label: 'Cao' },
    { value: 'random', label: 'Random' },
  ],
};
export default clusterStaticData;
