import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getMostActiveUser = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getMostActiveUserRequest());
  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'mostActiveUser',
    })
    .then((res) => {
      dispatch(getMostActiveUserSuccess( res?.data || {} ));
    })
    .catch((error) => {
      dispatch(getMostActiveUserError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getMostActiveUser'));
    });
};

export const getMostActiveUserRequest = () => {
  return {
    type: 'GET_MOST_ACTIVE_USERS_REQUEST',
  };
};

export const getMostActiveUserSuccess = (data) => {
  return {
    type: 'GET_MOST_ACTIVE_USERS_SUCCESS',
    payload: data,
  };
};

export const getMostActiveUserError = (error) => {
  return {
    type: 'GET_MOST_ACTIVE_USERS_FAILURE',
    payload: error,
  };
};
