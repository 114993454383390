const initialState = {
  userLoggedIn: false,
  auth: {
    accessToken: '',
  },
  userDetails: {},
  userInfo: {},
  userRole: {
    isAdmin: true,
  },
  verticalList:[],
  departmentList:[],
};

const userReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'USER_LOGGED_IN':
      return {
        ...state,
        userLoggedIn: action.payload,
      };
    case 'USER_DETAILS_SET_ID_TOKEN':
      return {
        ...state,
        auth: {
          ...state.auth,
          accessToken: action.payload || '',
        },
      };
    case 'USER_DETAILS_GET_SUCCESS':
      return {
        ...state,
      };

    case 'GET_USER_AD_DETAILS':
      return {
        ...state,
        userDetails: action.payload,
      };

    case 'GET_USER_SUCCESS':
      return {
        ...state,
        userInfo: { isAdmin: action.payload?.roleName?.toLowerCase() === 'admin', ...action.payload },
      };
    case 'GET_VERTICAL_LIST_SUCCESS':
      return {
        ...state,
        verticalList: action.payload || [],
        
      };
    case 'GET_DEPARTMENT_LIST_SUCCESS':
      return {
        ...state,
        departmentList: action.payload || [],
        
      };
    case 'CLEAR_USER_DATA':
      return {}; // *** Dangerous Action ***

    default:
      return state;
  }
};

export default userReducers;
