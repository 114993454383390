import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox } from '@mui/material';

import './BVITPowerSpecifications.css';

const BVITPowerSpecificationsView = ({ filterDetails, handleFiltersChange, powerSpecifications,
  setPowerSpecifications }) => {
  const filterData = filterDetails?.power_train_specifications;
  return (
    <div className='tab-power'>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Engine Type</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Engine Type"
          size="small"
          value={powerSpecifications.engine_type || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setPowerSpecifications(prevState => ({...prevState, engine_type: e.target.value}))}
          onBlur={(e) => {
            const newEngineType = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newEngineType.includes(data["Engine Type"]));
            handleFiltersChange(details);
          }}>
          {filterData?.engine_type?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={powerSpecifications.engine_type.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Fuel Type</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Fuel Type"
          size="small"
          value={powerSpecifications.fuel_type || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setPowerSpecifications(prevState => ({...prevState, fuel_type: e.target.value}))}
          onBlur={(e) => {
            const newFuelType = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newFuelType.includes(data["Fuel Type"]));
            handleFiltersChange(details);
          }}>
          {filterData?.fuel_type?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={powerSpecifications.fuel_type.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Engine Max Torque</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
          label="Engine Max Torque"
          size="small"
          value={powerSpecifications.engine_max_torque_rpm}
          onChange={(e) => {
            setPowerSpecifications(prevState => ({...prevState, engine_max_torque_rpm: e.target.value}));
            const details = filterDetails?.vehicle_load_data?.filter((data) => data["Engine Max Torque()@RPM"] === e.target.value);
            handleFiltersChange(details);
          }}>
          {filterData?.engine_max_torque_rpm?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Engine Max RPM</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Engine Max RPM"
          size="small"
          value={powerSpecifications.engine_max_rpm || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setPowerSpecifications(prevState => ({...prevState, engine_max_rpm: e.target.value}))}
          onBlur={(e) => {
            const newEngineMaxRpm = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newEngineMaxRpm.includes(data["Max Engine\r\nRPM "]));
            handleFiltersChange(details);
          }}>
          {filterData?.engine_max_rpm?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={powerSpecifications.engine_max_rpm.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Transmission Type</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Transmission Type"
          size="small"
          value={powerSpecifications.transmission_type || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setPowerSpecifications(prevState => ({...prevState, transmission_type: e.target.value}))}
          onBlur={(e) => {
            const newTransmissionType = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newTransmissionType.includes(data["Transmission Type"]));
            handleFiltersChange(details);
          }}>
          {filterData?.transmission_type?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={powerSpecifications.transmission_type.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
    </div>
  );
};

export default BVITPowerSpecificationsView;
