export default function scatterPlotOptions(testPattern, groupKey, torqueType, torqueValue) {

    const options = {
        chart: {
            type: 'scatter',
            zoomType: 'xy',
        },
        title: {
            text: testPattern,
            align: 'left'
        },
        xAxis: {
            categories: groupKey,
            title: {
                text: 'Target Model'
            }
        },
        yAxis: {
            title: {
                text: torqueType
            },
        },
        series: [
            {
                name: 'Torque Value',
                data: torqueValue
            }
        ]
    };
    return options;
}