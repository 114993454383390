import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox } from '@mui/material';

import './BVITOthers.css';

const BVITOthersView = ({ filterDetails, handleFiltersChange, others, setOthers }) => {
  const filterData = filterDetails?.others;
  return (
    <div className='tab-others'>
    <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Pattern</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Pattern"
          size="small"
          value={others.pattern || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setOthers(prevState => ({...prevState, pattern: e.target.value}))}
          onBlur={(e) => {
            const newPattern = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newPattern.includes(data["Pattern"]));
            handleFiltersChange(details);
          }}>
          {filterData?.pattern?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={others.pattern.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Measurement Type</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          label="Measurement Type"
          size="small"
          value={others.measurement_type}
          onChange={(e) => {
            setOthers(prevState => ({...prevState, measurement_type: e.target.value}));
            const details = filterDetails?.vehicle_load_data?.filter((data) => data["Measurement Type"] === e.target.value);
            handleFiltersChange(details);
          }}>
         {filterData?.measurement_type?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
  </div>
  );
};

export default BVITOthersView;
