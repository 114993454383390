import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// app
import { LoaderView } from './Loader.view';

Loader.propTypes = {
  visible: PropTypes.bool,
  label: PropTypes.string,
};

export default function Loader({ label }) {
  const uiLoaderQueue = useSelector((state) => get(state, 'ui.loader.queue', []));

  const visible = uiLoaderQueue.length > 0;
  const message = uiLoaderQueue.length > 0 ? uiLoaderQueue[0].message : label;

  return <LoaderView message={message} visible={visible} />;
}
