import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridTable } from 'components';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const CarriersView = ({rows,columns, handleFileUploadClick, handleChangeFileUpload, hiddenFileInput}) => {
    return(
        <Box py={1}>
        <Box>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 700, fontSize: '18px' }} color={(theme) => theme.palette.otherColor.blue70}>
                Carriers
              </Typography>
            </Grid>
            <Grid item>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                px={5}
                sx={{ textTransform: 'capitalize', fontSize: '13px', borderRadius: '20px'}}
                endIcon={<UploadFileIcon />}
                onClick={handleFileUploadClick}
              >
                <input type="file" onChange={handleChangeFileUpload} ref={hiddenFileInput} style={{ display: 'none' }} />
                Upload
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <DataGridTable
            checkboxSelection={false}
            columns={columns}
            rows={rows}
            uniqRowId="_id"
            height={270}
            emptyRowsText="No user(s) found"
          />
        </Box>
      </Box>
    );
}

export default CarriersView;