import React, { useState, useRef, useEffect, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash';
import { useDropzone } from 'react-dropzone';

//app
import * as utils from 'utils';
import { AddEditEDAUnsupervisedFormView } from './AddEditEDAUnsupervisedForm.view';
import { config, consts } from 'globalsData';
import {
  addLoader,
  getLastProcessedData,
  getPresignedUrl,
  postProcessingInput,
  removeLoader,
  snackbarNotification,
  uploadFileToS3,
  editAccSummary,
  hideModal,
} from 'stores';

AddEditEDAUnsupervisedForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

export default function AddEditEDAUnsupervisedForm({ data, submitHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInput = useRef();

  const isUpdateEda = Boolean(data?.input_data?.fileName);
  const inputFormDataFromApi = isUpdateEda ? data?.input_data : {};
  const fileNameFromApi = data?.input_data?.fileName;
  const trackerIdFromApi = data?.trackerId;
  const selectedSpecialCharactersFromAPi = inputFormDataFromApi?.specialCharacterReplace?.split(',') || [];

  const [file, setFile] = useState(null);
  const [inputDataCols, setInputDataCols] = useState([]);
  const [targetVarableColumns, setTargetVarableColumns] = useState([]);
  const [featureCompColumns, setFeatureCompColumns] = useState([]);
  const [columnnError, setColumnError] = useState(false);
  const [constantValue, setConstantValue] = useState(
    typeof inputFormDataFromApi?.missingValImpMethod === 'number' ? inputFormDataFromApi?.missingValImpMethod : ''
  );
  const [isNoFileUploaded, setIsNoFileUploaded] = useState(false);
  const [specialCharacterList, setSpecialCharacterList] = useState(selectedSpecialCharactersFromAPi || []);
  const [lastProcessedData, setLastProcessedData] = useState({});
  const [isInValidFileFormat, setIsInValidFileFormat] = useState(false);
  const [isInValidFileSize, setIsInValidFileSize] = useState(false);

  const isLastProcessDataInprogress = false;
  // Remove false and uncomment below lines if client wants to disable when other job is running...
  // Boolean(lastProcessedData?.isLastProDataAvail) &&
  // lastProcessedData?.status !== consts.acceleratorStatus.complete &&
  // !lastProcessedData?.status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus);

  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload(acceptedFiles);
  }, []);
  let { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({ onDrop });

  const learningTypeList = [
    { value: 'classification', label: 'Classification' },
    { value: 'regression', label: 'Regression' },
  ];

  const missingValueImputationMethodList = [
    { value: 'mean', label: 'Mean' },
    { value: 'median', label: 'Median' },
    { value: 'most_frequent', label: 'Most Frequent' },
    { value: 'constantValue', label: 'Constant Value' },
  ];

  const hypothesisTestingList = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const missingValuePercentageMarks = [
    {
      value: 60,
      label: '60',
    },
    {
      value: 95,
      label: '95',
    },
  ];

  const labelEncodingMarks = [
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
  ];

  const specialCharacters = consts.acceleratorSpecialCharacters;

  const handleSpecialCharacterList = (event) => {
    const {
      target: { value },
    } = event;
    setSpecialCharacterList(typeof value === 'string' ? value.split(',') : value);
  };

  const fields = [
    {
      name: 'justificationRemarks',
      id: 'justificationRemarks',
      size: 'small',
      required: true,
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Justification Remarks required').min(30, 'Min 30 characters').max(150, 'Max 150 characters'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'valuePer',
      id: 'valuePer',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      min: 60,
      max: 95,
      defaultValue: inputFormDataFromApi?.valuePer || 60,
      // marks: missingValuePercentageMarks,
      required: true,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number().min(60, 'Min value can be 60').max(95, 'Max value can be 95').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'missingValImpMethod',
      id: 'missingValImpMethod',
      size: 'small',
      defaultValue:
        typeof inputFormDataFromApi?.missingValImpMethod === 'number'
          ? 'constantValue'
          : inputFormDataFromApi?.missingValImpMethod || 'mean',
      required: true,
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'labelEncThreshold',
      id: 'labelEncThreshold',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      min: 5,
      max: 10,
      defaultValue: inputFormDataFromApi?.labelEncThreshold || 7,
      // marks: labelEncodingMarks,
      required: true,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number().min(5, 'Min value can be 5').max(10, 'Max value can be 10'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'specialCharacterReplace',
      id: 'specialCharacterReplace',
      size: 'small',
      multiple: true,
      value: specialCharacterList,
      placeholder: 'None',
      onChange: handleSpecialCharacterList,
      renderValue: (selected) => selected.join(', '),
      required: false,
      disabled: isLastProcessDataInprogress,
      validation: Yup.array().notRequired().nullable(),
      sx: { textAlign: 'left' },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchLearningType = watch('learningType');
  const watchTargetVariable = watch('targetVariable');
  const watchFeatureOfComparision = watch('featureComparison');
  const watchMissingValueImputationMethod = watch('missingValImpMethod');
  const watchLabelEncThreshold = watch('labelEncThreshold');
  const watchValuePer = watch('valuePer');

  useEffect(() => {
    if (!!watchTargetVariable && !!watchFeatureOfComparision && watchTargetVariable === watchFeatureOfComparision) {
      setColumnError(true);
    } else {
      setColumnError(false);
    }
  }, [watchTargetVariable, watchFeatureOfComparision]);

  const setColumnsForTargetVariableAndFeatureForComparision = (columns, learningType) => {
    const numCols = columns?.filter((col) => col?.label?.includes('Num'));
    const catCols = columns?.filter((col) => col?.label?.includes('Cat'));
    if (learningType?.toLowerCase() === 'classification') {
      const targetVariableList = [...orderBy(catCols, ['key'], ['asc']), ...orderBy(numCols, ['key'], ['asc'])];
      const featureCompList = [...orderBy(numCols, ['key'], ['asc']), ...orderBy(catCols, ['key'], ['asc'])];
      setTargetVarableColumns(targetVariableList);
      setFeatureCompColumns(featureCompList);
    }
    if (learningType?.toLowerCase() === 'regression') {
      const targetVariableList = [...orderBy(numCols, ['key'], ['asc']), ...orderBy(catCols, ['key'], ['asc'])];
      const featureCompList = [...orderBy(catCols, ['key'], ['asc']), ...orderBy(numCols, ['key'], ['asc'])];
      setTargetVarableColumns(targetVariableList);
      setFeatureCompColumns(featureCompList);
    }
  };

  useEffect(() => {
    if (watchLearningType?.toLowerCase() === 'classification' || watchLearningType?.toLowerCase() === 'regression') {
      setColumnsForTargetVariableAndFeatureForComparision(inputDataCols, watchLearningType);
    }
  }, [watchLearningType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isUpdateEda) {
      dispatch(getLastProcessedData({ type: 'eda' })).then((res) => {
        if (res?.status === 200) {
          setLastProcessedData(res?.data);
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processData = (dataString) => {
    const columns = utils.app.getFileColumnReader(dataString, consts.EDA);
    setInputDataCols(columns);
    setColumnsForTargetVariableAndFeatureForComparision(columns, watchLearningType);
    dispatch(removeLoader('readingFile...'));
  };

  const handleFileUpload = (fileData) => {
    const uploadedFile = fileData[0];
    if (uploadedFile?.length !== 0) {
      const validFileFormat = consts.formAllowedFileFormats?.includes(uploadedFile?.name?.split('.')[1]);
      setIsInValidFileFormat(false);
      setIsInValidFileSize(false);

      if (validFileFormat) {
        setIsInValidFileFormat(false);
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setIsInValidFileSize(false);
          setFile(uploadedFile);
          setIsNoFileUploaded(false);
          dispatch(addLoader('readingFile...'));
          utils.app.getFileReader(processData, uploadedFile);
        } else {
          setFile(null);
          setIsInValidFileSize(true);
          // setIsInValidFileFormat(true);
        }
      } else {
        setFile(null);
        setIsInValidFileFormat(true);
      }
      return;
    }
    setIsInValidFileFormat(false);
    setIsInValidFileSize(false);
  };

  const onDelete = () => {
    // acceptedFiles = [];
    setFile(null);
    setTargetVarableColumns([]);
    setFeatureCompColumns([]);
    setIsNoFileUploaded(true);
  };
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const onSubmit = (formData) => {
    const { specialCharacterReplace, ...rest } = formData;

    const payload = {
      ...rest,
      ...(specialCharacterReplace?.length ? { specialCharacterReplace: formData?.specialCharacterReplace.join(',') } : {}),
      ...{ type: 'eda' },
      ...(watchMissingValueImputationMethod === 'constantValue' && { missingValImpMethod: Number(constantValue) }),
      ...{ learningType: 'none', targetVariable: 'none', featureComparison: 'none', hypothesisTesting: 'none' },
    };

    if (fileNameFromApi && trackerIdFromApi) {
      const reqParams = {
        ...payload,
        trackerId: trackerIdFromApi,
      };

      dispatch(editAccSummary(reqParams)).then((res) => {
        if (res?.status === 200) {
          dispatch(snackbarNotification('EDA Unsupervised Data updated successully, Soon you will get prediction graphs', 'success'));
          submitHandler();
          dispatch(hideModal('ADD_EDIT_EDAU'));
        }
      });
    } else {
      dispatch(getPresignedUrl({ type: 'eda', fileName: file?.name })).then((res) => {
        if (res?.status === 200) {
          const preSignedUrl = res?.data?.data?.inputPreSignedUrl;
          const trackerId = res?.data?.trackerId;
          if (preSignedUrl && trackerId) {
            dispatch(uploadFileToS3({ preSignedUrl, file,isThisForCsvOrXl:true  })).then((s3res) => {
              if (s3res?.status === 200) {
                const reqParams = {
                  ...payload,
                  trackerId: trackerId,
                };
                dispatch(postProcessingInput(reqParams)).then((ppres) => {
                  if (ppres?.status === 200) {
                    dispatch(snackbarNotification('Data submitted successully, Soon you will get prediction graphs', 'success'));
                    navigate(`${config.routes.accelerators.type}/exploratory-data-analysis-unsupervised/${trackerId}`);
                  }
                });
              }
            });
          }
        }
      });
    }
  };

  const onError = () => {
    if (!file) {
      setIsNoFileUploaded(true);
    } else {
      setIsNoFileUploaded(false);
    }
  };

  const handleConstantValue = (event) => {
    setConstantValue(event.target.value);
  };

  const viewLastProcessData = (lastProcessData) => {
    if (!lastProcessData?.trackerId) return;
    navigate(`${config.routes.accelerators.type}/exploratory-data-analysis/${lastProcessData.trackerId}`);
  };

  return (
    <AddEditEDAUnsupervisedFormView
      fields={fields}
      file={file}
      isInValidFileSize={isInValidFileSize}
      isInValidFileFormat={isInValidFileFormat}
      watchLearningType={watchLearningType}
      isLastProcessDataInprogress={isLastProcessDataInprogress}
      missingValuePercentageMarks={missingValuePercentageMarks}
      labelEncodingMarks={labelEncodingMarks}
      targetVarableColumns={targetVarableColumns}
      featureCompColumns={featureCompColumns}
      fileInput={fileInput}
      hypothesisTestingList={hypothesisTestingList}
      isNoFileUploaded={isNoFileUploaded}
      columnnError={columnnError}
      learningTypeList={learningTypeList}
      constantValue={constantValue}
      specialCharacters={specialCharacters}
      specialCharacterList={specialCharacterList}
      labelEncodingThreshold={watchLabelEncThreshold}
      missingValuePercentage={watchValuePer}
      missingValueImputationMethodList={missingValueImputationMethodList}
      missingValueImputationMethodValue={watchMissingValueImputationMethod}
      lastProcessedData={lastProcessedData}
      fileNameFromApi={fileNameFromApi}
      formProps={{ errors, register, handleSubmit }}
      handlers={{
        onSubmit,
        onError,
        onDelete,
        handleFileUpload,
        handleConstantValue,
        viewLastProcessData,
      }}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragActive={isDragActive}
      files={files}
      acceptedFiles={acceptedFiles}
    />
  );
}
