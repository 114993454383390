import React from 'react';
import './BVITSummaryStatistics.css';

const BVITSummaryStatisticsView = ({ summaryDetails, label }) => {

  return (
    <div className='tab-summary'>
     <div className='graph-summary'>
      {label === 'model' &&
      <>
      <div className='summary-mainText'>
         VEHICLE TYPE
        <div className='summary-subText'>{summaryDetails.vehicle_type ? summaryDetails.vehicle_type : '-'}</div>
       </div>
       <div className='summary-mainText'>
         MODE
        <div className='summary-subText'>{summaryDetails.mode ? summaryDetails.mode : '-'}</div>
       </div>
       <div className='summary-mainText'>
         MODEL
        <div className='summary-subText'>{summaryDetails.model && summaryDetails.model.length ? summaryDetails.model.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         STAGE
        <div className='summary-subText'>{summaryDetails.stage && summaryDetails.stage.length ? summaryDetails.stage.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         PLATFORM
        <div className='summary-subText'>{summaryDetails.platform && summaryDetails.platform.length ? summaryDetails.platform.join(', ') : '-'}</div>
       </div>
       </>}
    {label === 'power' &&
      <>
       <div className='summary-mainText'>
         ENGINE TYPE
        <div className='summary-subText'>{summaryDetails.engine_type && summaryDetails.engine_type.length ? summaryDetails.engine_type.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         FUEL TYPE
        <div className='summary-subText'>{summaryDetails.fuel_type && summaryDetails.fuel_type.length ? summaryDetails.fuel_type.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         ENGINE MAX TORQUE
        <div className='summary-subText'>{summaryDetails.engine_max_torque_rpm ? summaryDetails.engine_max_torque_rpm : '-'}</div>
       </div>
       <div className='summary-mainText'>
         ENGINE MAX RPM
        <div className='summary-subText'>{summaryDetails.engine_max_rpm && summaryDetails.engine_max_rpm.length ? summaryDetails.engine_max_rpm.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         TRANSMISSION TYPE
        <div className='summary-subText'>{summaryDetails.transmission_type && summaryDetails.transmission_type.length ? summaryDetails.transmission_type.join(', ') : '-'}</div>
       </div>
      </>
    }
     {label === 'clutch' &&
      <>
       <div className='summary-mainText'>
         CLUTCH MAKER
        <div className='summary-subText'>{summaryDetails.clutch_maker && summaryDetails.clutch_maker.length ? summaryDetails.clutch_maker.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         CLUTCH RELEASE SYSTEM MAKER
        <div className='summary-subText'>{summaryDetails.clutch_release_system_maker && summaryDetails.clutch_release_system_maker.length ? summaryDetails.clutch_release_system_maker.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         CLUTCH SIZE
        <div className='summary-subText'>{summaryDetails.clutch_size && summaryDetails.clutch_size.length ? summaryDetails.clutch_size.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         DISC MATERIAL
        <div className='summary-subText'>{summaryDetails.disc_material && summaryDetails.disc_material.length ? summaryDetails.disc_material.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         CLAMP LOAD MAXIMUM
        <div className='summary-subText'>{summaryDetails.clamp_load_maximum && summaryDetails.clamp_load_maximum.length ? summaryDetails.clamp_load_maximum.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         CLAMP LOAD MEASURED
        <div className='summary-subText'>{summaryDetails.clamp_load_measured && summaryDetails.clamp_load_measured.length ? summaryDetails.clamp_load_measured.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         CLAMP LOAD MINIMUM
        <div className='summary-subText'>{summaryDetails.clamp_load_minimum && summaryDetails.clamp_load_minimum.length ? summaryDetails.clamp_load_minimum.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         CLUTCH RELEASE SYSTEM
        <div className='summary-subText'>{summaryDetails.clutch_release_system && summaryDetails.clutch_release_system.length ? summaryDetails.clutch_release_system.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         PTL/SIZE
        <div className='summary-subText'>{summaryDetails.ptl_size && summaryDetails.ptl_size.length ? summaryDetails.ptl_size.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         STIFFNESS DRIVE
        <div className='summary-subText'>{summaryDetails.stiffness_drive && summaryDetails.stiffness_drive.length ? summaryDetails.stiffness_drive.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         STIFFNESS COAST
        <div className='summary-subText'>{summaryDetails.stiffness_coast && summaryDetails.stiffness_coast.length ? summaryDetails.stiffness_coast.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         CUSHION
        <div className='summary-subText'>{summaryDetails.cushion && summaryDetails.cushion.length ? summaryDetails.cushion.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         COF
        <div className='summary-subText'>{summaryDetails.cof && summaryDetails.cof.length ? summaryDetails.cof.join(', ') : '-'}</div>
       </div>
      </>
    }
    {label === 'vehicle' &&
      <>
       <div className='summary-mainText'>
         VEHICLE WEIGHT
        <div className='summary-subText'>{summaryDetails.vehicle_weight && summaryDetails.vehicle_weight.length ? summaryDetails.vehicle_weight.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         FRONT RXN
        <div className='summary-subText'>{summaryDetails.front_rxn && summaryDetails.front_rxn.length ? summaryDetails.front_rxn.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         REAR RXN
        <div className='summary-subText'>{summaryDetails.rear_rxn && summaryDetails.rear_rxn.length ? summaryDetails.rear_rxn.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         WEIGHT DISTRIBUTION
        <div className='summary-subText'>{summaryDetails.weight_distribution && summaryDetails.weight_distribution.length ? summaryDetails.weight_distribution.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         TYRE SPECIFICATIONS
        <div className='summary-subText'>{summaryDetails.tyre_specifications && summaryDetails.tyre_specifications.length ? summaryDetails.tyre_specifications.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         TYRE ROLLING RADIUS
        <div className='summary-subText'>{summaryDetails.tyre_rolling_radius && summaryDetails.tyre_rolling_radius.length ? summaryDetails.tyre_rolling_radius.join(', ') : '-'}</div>
       </div>
      </>
    }
     {label === 'other' &&
      <>
       <div className='summary-mainText'>
         PATTERN
        <div className='summary-subText'>{summaryDetails.pattern && summaryDetails.pattern.length ? summaryDetails.pattern.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         MEASUREMENT TYPE
        <div className='summary-subText'>{summaryDetails.measurement_type ? summaryDetails.measurement_type : '-'}</div>
       </div>
      </>
    }
     {label === 'graph' &&
      <>
       <div className='summary-mainText'>
         CHART TYPE
        <div className='summary-subText'>{summaryDetails.chart_type ? summaryDetails.chart_type : '-'}</div>
       </div>
       <div className='summary-mainText'>
         TEST PATTERN
        <div className='summary-subText'>{summaryDetails.test_pattern && summaryDetails.test_pattern.length ? summaryDetails.test_pattern.join(', ') : '-'}</div>
       </div>
       <div className='summary-mainText'>
         TORQUE TYPE
        <div className='summary-subText'>{summaryDetails.torque_type ? summaryDetails.torque_type : '-'}</div>
       </div>
      </>
    }
    </div>
   </div>
  );
};

export default BVITSummaryStatisticsView;
