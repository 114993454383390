import React from 'react';
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { BVITFileDropZone, BVITTable, TabComponent } from 'components';
import FileUpload from 'assets/png/file-upload.png';
import ProceedBtn from 'assets/png/proceed.png';
import DeleteBtn from 'assets/png/delete.png';
import GenratePlot from 'assets/png/Genrate-Plot.png';
import BVITGraphVisualization from 'modules/BVITGraphVisualization/BVITGraphVisualization';
import './BVITDataVisualization.css';

const BVITDataVisualizationView = ({ 
  tabs, filterDetails, file, isFileUploaded, onProceedFile, onDeleteFile, handleFileUpload,
  model, powerSpecifications, clutchSpecifications, vehicleSpecifications, others,
   othersDropdown, setOthersDropdown, torqueValues, isGeneratePlotOpen, closeGeneratePlot, 
   onGeneratePlot, chartError, setChartError, testPatternError, setTestPatternError, torqueError,
   setTorqueError, bvitGraphData, reportsDetails, handleOldReportDownload, onResetFilters }) => {
  const filterData = filterDetails?.others_dropdown;

  return (
   <div>
    {!isGeneratePlotOpen ?
    <>
    {!file ?
      <BVITFileDropZone handleFileUpload={handleFileUpload} /> :
      <div className='file-uploaded'>
        <div className='file-container'>
        <img src={FileUpload} alt="file upload" width={20} height={20} />
        <div className='file-details'>
          <div className='file-name'>{file?.name}</div>
          <div className='file-size'>{file?.size/1000000} MB</div>
        </div>
        </div>
        {!isFileUploaded && <button className='proceed-btn' onClick={() => onProceedFile()}><img src={ProceedBtn} alt="Proceed icon" width={10} height={10} className='upload-img' />Proceed</button>}
        <button className='delete-btn' onClick={() => onDeleteFile()}><img src={DeleteBtn} alt="Delete icon" width={10} height={10} className='upload-img' />Delete</button>
      </div>
    }
      <div className='bvit-data-select'>
      <TabComponent tabs={tabs} />
      
      <div className='reset-filters'>
      <button className='reset-btn' onClick={() => onResetFilters()}>Reset Filters</button>
      <div className='type-select'>
      <FormControl sx={{ minWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small" error={chartError}>
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>*Chart Type</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
          label="Chart Type"
          size="small"
          value={othersDropdown.chart_type}
          onChange={(e) =>  {
            setOthersDropdown(prevState => ({...prevState, chart_type: e.target.value}));
            setChartError(e.target.value === '')
          }}>
          {filterData?.chart_type?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              {data}
            </MenuItem>
          ))}
        </Select>
        {chartError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please select chart type</FormHelperText>}
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small" error={testPatternError}>
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>*Test Pattern</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
            label="*Test Pattern"
            size="small"
            value={othersDropdown.test_pattern || []}
            renderValue={(selected) => selected.join(', ')}
            onChange={(e) =>  {
              setOthersDropdown(prevState => ({...prevState, test_pattern: e.target.value}));
              setTestPatternError(!e.target.value.length)
            }}>
           {filterData?.test_pattern?.map((data, i) => (
             <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={othersDropdown.test_pattern.includes(data)} color='primary' />
              {data}
             </MenuItem>
            ))}
          </Select>
          {testPatternError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', marginRight: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please select test pattern</FormHelperText>}
      </FormControl>
      <FormControl sx={{ minWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small" error={torqueError}>
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>*Torque</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
          label="Torque"
          size="small"
          value={othersDropdown.torque_type}
          onChange={(e) => { 
            setOthersDropdown(prevState => ({...prevState, torque_type: e.target.value}));
            setTorqueError(e.target.value === '')
            }}>
           {torqueValues?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              {data}
            </MenuItem>
          ))}
        </Select>
        {torqueError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', marginRight: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please select torque type</FormHelperText>}
      </FormControl>
      <button className='generate-btn' onClick={() => onGeneratePlot()}><img src={GenratePlot} alt="Generate Plot" width={15} height={15} className='generate-img' />Generate Plot</button>
      </div>
      </div>
      </div>
      <BVITTable reportsDetails={reportsDetails} handleOldReportDownload={handleOldReportDownload} />
    </> : <BVITGraphVisualization closeGeneratePlot={closeGeneratePlot} bvitGraphData={bvitGraphData} model={model} 
            powerSpecifications={powerSpecifications} clutchSpecifications={clutchSpecifications}
            vehicleSpecifications={vehicleSpecifications} others={others} othersDropdown={othersDropdown} />}
    </div>
  );
};

export default BVITDataVisualizationView;