import React from 'react';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';
import { Banner, BootstrapTooltip, Breadcrumbs, Layout } from 'components';

// mui
import { Box, Grid, Typography, List, ListItem } from '@mui/material';

import IconButton from '@mui/material/IconButton';

import catListImg from '../../assets/png/catList.png';
import CompetitionSparesPricingCommercial from '../../assets/png/catList.png';
import CommercialLeads from '../../assets/png/commercialLeads.png';
import CompetitionNetwork from '../../assets/png/competitionNetwork.png';
import All from '../../assets/png/allWebsites.png';
import CompetitionSparesPrice from '../../assets/png/competitionSpares .png';
import MarutiGenuineAccessories from '../../assets/png/marutiGenuine.png';
import SocialMedia from '../../assets/png/socialMedia.png';
import CommercialSalesLeads from '../../assets/png/commercialSalesLead.png';
import Test from '../../assets/png/catList.png';
// import catListImg from '../../assets/png/iauData.png';
// import catListImg from '../../assets/png/catList.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';

Categoriesview.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  categoryList: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    onCatSearch: PropTypes.func.isRequired,
    onSearchClear: PropTypes.func.isRequired,
    handleAddCat: PropTypes.func.isRequired,
    handleCatDetails: PropTypes.func.isRequired,
    handleUpdateCat: PropTypes.func.isRequired,
    handleCatEnable: PropTypes.func.isRequired,
  }),
  breadcrumbs: PropTypes.array.isRequired,
  // bannerProps: PropTypes.object.isRequired,
};

export function Categoriesview({ isAdmin, searchText, categoryList, handlers, breadcrumbs }) {
  const catList = isAdmin ? categoryList : categoryList?.filter((cat) => cat?.status?.toLowerCase() === 'enabled');
  const ITEM_HEIGHT = 20;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeWhiteSpace = (str) => {
    if (str) {
      // eslint-disable-next-line array-callback-return
      return str.split(' ').filter((item) => {if (item !== '–') return item}).join('');
    }
  };

  return (
    <>
      {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
      <Box py={1} mt={1}>
        {/* <Banner {...bannerProps} /> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              <Grid container justifyContent="space-between" alignItems="center">
                {/* <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, fontSize: '14px' }}
                    color={(theme) => theme.palette.otherColor.solutionTextColor}
                  >
                    Data crawlers
                  </Typography>
                </Grid> */}
                {/* {isAdmin && (
                  <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={searchText}
                        onChange={handlers.onCatSearch}
                        placeholder="Search here"
                        inputProps={{
                          style: {
                            padding: '4.5px 14px',
                          },
                        }}
                        InputProps={{
                          startAdornment: <SearchIcon fontSize="small" color="action" />,
                          endAdornment: (
                            <IconButton
                              title="Clear"
                              aria-label="Clear"
                              size="small"
                              style={{
                                visibility: searchText ? 'visible' : 'hidden',
                                borderRadius: '57%',
                                paddingRight: '1px',
                                margin: '0',
                                fontSize: '1.25rem',
                              }}
                              onClick={handlers.onSearchClear}
                            >
                              <ClearIcon fontSize="small" color="action" />
                            </IconButton>
                          ),
                        }}
                        sx={{
                          width: { xs: 1, sm: 'auto' },
                          mr: 1,
                        }}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        sx={{
                          textTransform: 'none',
                          '&& > span': {
                            marginRight: '5px',
                          },
                          borderRadius: '20px',
                        }}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handlers.handleAddCat}
                      >
                        New Category
                      </Button>
                    </Box>
                  </Grid>
                )} */}
              </Grid>
            </Box>
            {/* <Divider /> */}
            <Box pt={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List sx={{ width: '100%', maxWidth: 250 }}>
                    {catList?.length ? (
                      catList?.map((cat, idx) => (
                        <ListItem
                          key={idx}
                          sx={{
                            backgroundColor: 'peach',
                            marginBottom: '10px',
                            bgcolor: 'background.paper',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            padding: '8px 8px 0px 8px',
                          }}
                          onClick={() => handlers.handleCatDetails(cat)}
                        >
                          <Box sx={{ width: 'calc(20% - 0px)' }}>
                            <Box>
                              {removeWhiteSpace(cat?.categoryName) === 'CommercialLeads' ? (
                                <img src={CommercialLeads} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'All' ? (
                                <img src={All} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'CompetitionNetwork' ? (
                                <img src={CompetitionNetwork} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'CompetitionSparesPrice' ? (
                                <img src={CompetitionSparesPrice} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'MarutiGenuineAccessories' ? (
                                <img src={MarutiGenuineAccessories} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'SocialMedia' ? (
                                <img src={SocialMedia} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'CommercialSalesLeads' ? (
                                <img src={CommercialSalesLeads} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'Test' ? (
                                <img src={Test} alt="catListImg" height="40" width="40" />
                              ) : removeWhiteSpace(cat?.categoryName) === 'CompetitionSparesPricingCommercial' ? (
                                <img src={CompetitionSparesPricingCommercial} alt="catListImg" height="40" width="40" />
                              ) : (
                                <img src={catListImg} alt="catListImg" height="40" width="40" />
                              )}
                            </Box>
                          </Box>
                          <BootstrapTooltip title={cat?.categoryName?.length > 20 ? cat?.categoryName:''} arrow>
                            <Box sx={{ width: 'calc(65% - 0px)' }}>
                              <Box sx={{ px: 0.25 }}>
                                <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>
                                  {utils.app.getEllipsisString(cat?.categoryName, 20)}
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: '11px', color: 'grey' }}>
                                  {cat?.websiteCount} websites
                                </Typography>
                              </Box>
                            </Box>
                          </BootstrapTooltip>

                          <Box sx={{ width: 'calc(15% - 0px)' }}>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              // onClick={handleClick}
                              onClick={() => handlers.handleUpdateCat(cat)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Box>
                        </ListItem>
                      ))
                    ) : (
                      <Box px={2}>
                        <Typography variant="body1">No Categorie(s) found</Typography>
                      </Box>
                    )}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
