import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const shareOfBusinessFileDownload = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!params) {
    return Promise.reject({ message: 'Request params are missing' });
  }

  return api
    .get({
      endpoint: endpoint.shareOfBusinessService,
      path: 'getSobReport',
      params
    })
    .then((res) => {
      dispatch(shareOfBusinessFileDownloadSuccess(res.data))
    })
    .catch((error) => {
      dispatch(getShareOfBusinessFileDownloadError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('shareOfBusinessFileDownload'));
    });
};

export const getShareOfBusinessFileDownloadError = (error) => {
    return {
      type: 'GET_SHARE_OF_BUSINESS_FILE_DOWNLOAD_FAILURE',
      payload: error,
    };
  };

  export const shareOfBusinessFileDownloadSuccess = (data) => {
    return {
      type: 'GET_SHARE_OF_BUSINESS_FILE_DOWNLOAD_SUCCESS',
      payload: data,
    };
  };