const initialState = {
  edaFormData: {},
  summaryDeteails: {},
  isRedirectFromEdaCharts: false,
  isSummaryDetailsLoading: false,
  inputTextFileDetails: '',
  signedUrl: '',
};

const acceleratorsReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'POST_PROCESSING_INPUT_SUCCESS':
      return {
        ...state,
        edaFormData: action.payload?.inputData || {},
      };

    case 'GET_SUMMARY_DETAILS_REQUEST':
      return {
        ...state,
        isSummaryDetailsLoading: true,
      };

    case 'GET_SUMMARY_DETAILS_SUCCESS':
      return {
        ...state,
        summaryDeteails: action.payload?.data || {},
        isSummaryDetailsLoading: false,
      };

    case 'REDIRECT_FROM_EDA_CHART':
      return {
        ...state,
        isRedirectFromEdaCharts: action.payload,
      };

    case 'GET_INPUT_TEXT_FILE_DETAILS_SUCCESS':
      return {
        ...state,
        inputTextFileDetails: action.payload?.data || action.payload.body || '',
      };

    case 'DOWNLOAD_ALL_DATA_SUCCESS':
      return {
        ...state,
        signedUrl: action.payload || '',
      };

    default:
      return state;
  }
};

export default acceleratorsReducers;
