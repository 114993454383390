import { addLoader, removeLoader, snackbarNotification } from 'stores';
import { api } from 'utils';

export const getOtherSolutionsList = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getOtherSolutionsListRequest());
  dispatch(addLoader('getOtherSolutionsList'));

  return api
    .get({
      endpoint: endpoint.categoryService,
      path: 'v1/solutionLinks',
    })
    .then((res) => {
      dispatch(getOtherSolutionsListSuccess(res?.data || []));
      return res;
    })
    .catch((error) => {
      dispatch(getOtherSolutionsListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getOtherSolutionsList'));
    });
};

export const getOtherSolutionsListRequest = () => {
  return {
    type: 'GET_OTHER_SOLUTIONS_LIST_REQUEST',
  };
};

export const getOtherSolutionsListSuccess = (data) => {
  return {
    type: 'GET_OTHER_SOLUTIONS_LIST_SUCCESS',
    payload: data,
  };
};

export const getOtherSolutionsListError = (error) => {
  return {
    type: 'GET_OTHER_SOLUTIONS_LIST_FAILURE',
    payload: error,
  };
};
