import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

//app
import HomeView from './Home.view';
import * as utils from 'utils';
import { config, consts } from 'globalsData';
import { getFeatureAccess } from 'stores';
import DCMP from 'assets/svg/DCMP.svg';
import DLP from 'assets/svg/DLP.svg';
import Accelerators from '../../assets/png/newAcceleratorHome.png';
import DataAnalyticsGoogle from '../../assets/png/newGoogleAnalyticsImg.png';
import DataAnalyticsIBM from '../../assets/png/newIntroToDataAnalytics.png';
import MLPython from '../../assets/png/newMLPython.png';
import DataScience from '../../assets/png/newIBMDataScienceCertification.png';
import BusinessSolumtions from '../../assets/png/newBusinessSolution.png';
import DataCrwalers from '../../assets/png/newDataCrawlerHome.png';
// import EAP from 'assets/svg/EAP.svg';
import EAP from 'assets/png/EAP_HomePageLogo.png';
import Accelerator from 'assets/svg/Accelerators.svg';

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => get(state, 'user.userInfo', {}));
  const { pathname } = useLocation();
  const hasAcceleratorsAccess = utils.app.hasFeatureAccess(userInfo, consts.FEATURE_LIST_OBJ.accelerators);
  const hasASAccess = utils.app.hasFeatureAccess(userInfo, consts.FEATURE_LIST_OBJ.as);
  const hasDCMPAccess = utils.app.hasFeatureAccess(userInfo, consts.FEATURE_LIST_OBJ.dcmp);
  const hasOSAccess = utils.app.hasFeatureAccess(userInfo, consts.FEATURE_LIST_OBJ.os);

  const bannerProps = {
    icon: EAP,
    title: `${consts.APP_TITLE}`,
    paragraph1: 'Single platform for everything related to Data Analytics & AI/ML',
    // paragraph2: 'One central solution for the design, deployment, and management of AI/ML applications',
  };

  const tiles = [
    {
      id: 'accelerator-tile',
      icon: Accelerator,
      title: consts.FEATURE_LIST_OBJ.accelerators,
      image:Accelerators,
      description:'Swiftly transform raw data into actionable insights',
      btnText: hasAcceleratorsAccess ? 'Explore' : 'Request Access',
      hasAccess: hasAcceleratorsAccess,
      link: `${config.routes.accelerators.type}/exploratory-data-analysis`,
    },
    // {
    //   id: 'analytics-studio-tile',
    //   icon: AnalyticsStudio,
    //   title: consts.FEATURE_LIST_OBJ.as,
    //   image:BusinessSolumtions,
    //   description:'Swiftly transform raw data into actionable insights',
    //   btnText: hasASAccess ? 'Explore' : 'Request Access',
    //   hasAccess: hasASAccess,
    //   link: config.routes.analyticsStudio.root,
    // },
    {
      id: 'data-crawler-management-platform-tile',
      icon: DCMP,
      title: consts.FEATURE_LIST_OBJ.dcmp,
      image:DataCrwalers,
      description:'Seamlessly gather online data for data driven decisions',
      btnText: hasDCMPAccess ? 'Explore' : 'Request Access',
      hasAccess: hasDCMPAccess,
      // link: config.routes.dcmp.root,
      link: `${config.routes.dcmp.websiteManagment}/Competition-Spares-Pricing-–-Commercial/5afefc60-9ae2-11ed-811a-ebbbfa59ab8d`,
    },
    {
      id: 'data-lake-platform-tile',
      icon: DLP,
      title: consts.FEATURE_LIST_OBJ.os,
      image:BusinessSolumtions,
      description:'Diverse analytical projects across multiple business verticals',
      btnText: hasOSAccess ? 'Explore' : 'Request Access',
      hasAccess: hasOSAccess,
      link: config.routes.otherSolutions.root,
    },
  ];

  const coursesTiles = [
    {
      id: 'dataAnalytics',
      icon: Accelerator,
      title: 'Google Data Analytics Certification',
      image:DataAnalyticsGoogle,
      description:'Google',
      link: "https://www.coursera.org/professional-certificates/google-data-analytics",
    },
    {
      id: 'dataAnalyst',
      icon: Accelerator,
      title: 'Introduction to Data Analytics',
      image:DataAnalyticsIBM,
      description:'IBM',
      link: "https://www.coursera.org/learn/introduction-to-data-analytics",
    },
    {
      id: 'dataScience',
      icon: Accelerator,
      title: 'IBM Data Science Certification',
      image:DataScience,
      description:'IBM',
      link: "https://www.coursera.org/professional-certificates/ibm-data-science",
    },
    {
      id: 'dataDataEverywhere',
      icon: Accelerator,
      title: 'Machine Learning With Python',
      image:MLPython,
      description:'IBM',
      link: "https://www.coursera.org/learn/machine-learning-with-python",
    }
  ];

  const getBtnLink = (tile) => {
    // if (tile.title === consts.FEATURE_LIST_OBJ.os) {
    //   return window.open(tile?.link);
    // }
    navigate(tile?.link);
  };

  const handleClick = (tile) => {
    if (tile?.hasAccess) {
      getBtnLink(tile);
    } else {
      dispatch(getFeatureAccess());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <HomeView tiles={tiles} coursesTiles={coursesTiles} bannerProps={bannerProps} handleClick={handleClick} />;
}
