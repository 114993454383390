import React from 'react';
import { isNumber, times } from 'lodash';
import PropTypes from 'prop-types';

//mui
import { Box, Skeleton } from '@mui/material';

MuiSkeleton.propTypes = {
  height: PropTypes.number,
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
  variant: PropTypes.oneOf(['circular', 'rectangular', 'rounded', 'text']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayNumber: PropTypes.number.isRequired,
  skeletonMarginBottom: PropTypes.number,
};

MuiSkeleton.defaultProps = {
  height: 20,
  animation: 'wave',
  variant: 'rectangular',
  width: '100%',
  skeletonMarginBottom: 0,
};

export default function MuiSkeleton({ height, animation, variant, width, displayNumber, skeletonMarginBottom }) {
  const skeltons = times(displayNumber, String);

  if (!displayNumber || isNumber(displayNumber))
    return (
      <>
        {skeltons.map((value) => (
          <Box mb={skeletonMarginBottom} key={`skeleton-${value}`}>
            <Skeleton width={width} height={height} animation={animation} variant={variant} />
          </Box>
        ))}
      </>
    );
}
