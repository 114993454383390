import React from 'react';
import PropTypes from 'prop-types';

//app
import * as utils from 'utils';
import { Breadcrumbs, Layout, BootstrapTooltip } from 'components';
import { useMedia } from 'globalsData';
import './OtherSolutions.css';

//mui
import { Avatar, Box, Divider, Typography, Grid, Card, CardContent, CardActions, Button, useTheme } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

OtherSolutionsView.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  //   bannerProps: PropTypes.object.isRequired,

  otherSolutionsTiles: PropTypes.array.isRequired,
  marketingTiles: PropTypes.array.isRequired,
  qualityAssuranceTiles: PropTypes.array.isRequired,

  handleClick: PropTypes.func.isRequired,
};

export function OtherSolutionsView({ breadcrumbs, otherSolutionsTiles, marketingTiles, qualityAssuranceTiles, handleClick }) {
  const theme = useTheme();
  const { mobile, wideUp } = useMedia();
  const getWidth = () => {
    if (mobile) return '100%';
    if (wideUp) return '84%';
    return '94%';
  };
  return (
    <Layout>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={10}>
          {/* <Breadcrumbs breadcrumbs={breadcrumbs} paddingLeftRight={0} /> */}
        </Grid>
      </Grid>
      <Box py={2} mt={1}>
        {/* <Banner {...bannerProps} /> */}
        <Box>
          <Grid container spacing={2}>
            <Grid container spacing={2} px={3}>
              <Grid item xs={12} sx={{ px: 2 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', py: 2, color: '#000000' }}>Quality Assurance</Typography>
              </Grid>
              <Box
                sx={{ width: 'calc(99% - 0px)', overflowX: 'auto', whiteSpace: 'nowrap', height: '320px' }}
                className="businessSolutionHolderStyle"
              >
                {qualityAssuranceTiles?.map((tile, idx) => {
                  return (
                    <Grid item xs={12} sm={4} md={3} lg={3} key={idx} sx={{ display: 'inline-block', marginRight: '16px' }}>
                      <Card sx={{ borderRadius: '16px' }}>
                        <Box sx={{ padding: '12px 10px 0px 10px', borderBottom: '1px solid #edf2f6' }}>
                          <Box>
                            <img src={tile?.img} style={{ width: '100%' }} alt="cardImage" />
                          </Box>
                          <Box sx={{ py: 2, borderBottom: '1px solid #edf2f6' }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{ fontWeight: '700', fontSize: '0.875em', color: 'black' }}
                            >
                              {utils.app.getEllipsisString(tile?.title, 32)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="p"
                              component="div"
                              sx={{ fontWeight: '400', fontSize: '0.75rem', color: theme.palette.primary.textColor, whiteSpace: 'wrap' }}
                            >
                              {utils.app.getEllipsisString(tile?.description, 90)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ p: 1 }}>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', width: 'calc(75% - 0px)' }}>
                            <Button
                              // variant="contained"
                              size="medium"
                              px={5}
                              sx={{
                                textTransform: 'none',
                                color: theme.palette.otherColor.solutionTextColor,
                                fontWeight: '600',
                                justifyContent: 'flex-start',
                                width: '100%',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                              endIcon={<EastIcon />}
                              onClick={() => {
                                handleClick(tile);
                              }}
                            >
                              {tile?.hasAccess ? 'Explore' : 'Request Access'}
                            </Button>
                          </Box>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', width: 'calc(25% - 0px)' }}>
                            <Box
                              sx={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}
                              onClick={() => {
                                window.open(tile.pdfFile, '_blank');
                              }}
                            >
                              <VisibilityOutlinedIcon sx={{ fontSize: '14px' }} />
                            </Box>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={tile.tooltip}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
            <Grid container spacing={2} p={3} pb={0}>
              <Grid item xs={12} sx={{ px: 2 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', py: 2, color: '#000000' }}>Marketing & Sales</Typography>
              </Grid>
              <Box sx={{ width: 'calc(99% - 0px)', overflowX: 'auto', height: '320px' }} className="businessSolutionHolderStyle">
                {marketingTiles?.map((tile, idx) => {
                  return (
                    <Grid item xs={12} sm={4} md={3} lg={3} key={idx} sx={{ display: 'inline-block', marginRight: '16px' }}>
                      <Card sx={{ borderRadius: '16px' }}>
                        <Box sx={{ padding: '12px 10px 0px 10px', borderBottom: '1px solid #edf2f6' }}>
                          <Box>
                            <img src={tile?.img} style={{ width: '100%' }} alt="cardImage" />
                          </Box>
                          <Box sx={{ py: 2, borderBottom: '1px solid #edf2f6' }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{ fontWeight: '700', fontSize: '0.875em', color: 'black' }}
                            >
                              {utils.app.getEllipsisString(tile?.title, 32)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="p"
                              component="div"
                              sx={{ fontWeight: '400', fontSize: '0.75rem', color: theme.palette.primary.textColor, whiteSpace: 'wrap' }}
                            >
                              {utils.app.getEllipsisString(tile?.description, 90)}
                              {}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ p: 1 }}>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', width: 'calc(75% - 0px)' }}>
                            <Button
                              // variant="contained"
                              size="medium"
                              px={5}
                              sx={{
                                textTransform: 'none',
                                color: theme.palette.otherColor.solutionTextColor,
                                fontWeight: '600',
                                justifyContent: 'flex-start',
                                width: '100%',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                              endIcon={<EastIcon />}
                              onClick={() => {
                                handleClick(tile);
                              }}
                            >
                              {tile?.hasAccess ? 'Explore' : 'Request Access'}
                            </Button>
                          </Box>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', width: 'calc(25% - 0px)' }}>
                            <Box
                              sx={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}
                              onClick={() => {
                                window.open(tile.pdfFile, '_blank');
                              }}
                            >
                              <VisibilityOutlinedIcon sx={{ fontSize: '14px' }} />
                            </Box>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={tile.tooltip}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
            {/* New card  code start here */}
            <Grid container spacing={2} px={3}>
              <Grid item xs={12} sx={{ px: 2, paddingTop: '0px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', py: 2, color: '#000000' }}>Others</Typography>
              </Grid>
              <Box
                sx={{ width: 'calc(99% - 0px)', overflowX: 'auto', whiteSpace: 'nowrap', height: '320px' }}
                className="businessSolutionHolderStyle"
              >
                {otherSolutionsTiles?.map((tile, idx) => {
                  return (
                    <Grid item xs={12} sm={4} md={3} lg={3} key={idx} sx={{ display: 'inline-block', marginRight: '16px' }}>
                      <Card sx={{ borderRadius: '16px' }}>
                        <Box sx={{ padding: '12px 10px 0px 10px', borderBottom: '1px solid #edf2f6' }}>
                          <Box>
                            <img src={tile?.img} style={{ width: '100%' }} alt="cardImage" />
                          </Box>
                          <Box sx={{ py: 2, borderBottom: '1px solid #edf2f6' }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{ fontWeight: '700', fontSize: '0.875em', color: 'black' }}
                            >
                              {utils.app.getEllipsisString(tile?.title, 32)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="p"
                              component="div"
                              sx={{ fontWeight: '400', fontSize: '0.75rem', color: theme.palette.primary.textColor, whiteSpace: 'wrap' }}
                            >
                              {utils.app.getEllipsisString(tile?.description, 90)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ p: 1 }}>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', width: 'calc(75% - 0px)' }}>
                            <Button
                              // variant="contained"
                              size="medium"
                              px={5}
                              sx={{
                                textTransform: 'none',
                                color: theme.palette.otherColor.solutionTextColor,
                                fontWeight: '600',
                                justifyContent: 'flex-start',
                                width: '100%',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                              endIcon={<EastIcon />}
                              onClick={() => {
                                handleClick(tile);
                              }}
                            >
                              {tile?.hasAccess ? 'Explore' : 'Request Access'}
                            </Button>
                          </Box>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle', width: 'calc(25% - 0px)' }}>
                            <Box
                              sx={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}
                              onClick={() => {
                                window.open(tile.pdfFile, '_blank');
                              }}
                            >
                              <VisibilityOutlinedIcon sx={{ fontSize: '14px' }} />
                            </Box>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={tile.tooltip}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
            {/* New card  code ends here */}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}
