import React from 'react';
import PropTypes from 'prop-types';

//app
import { consts } from 'globalsData';
import * as utils from 'utils';
import { FieldLabelWithValueHorizontal, BootstrapTooltip } from 'components';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

TimeseriesSummary.propTypes = {
  summaryDeteails: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  onDownloadCsv: PropTypes.func.isRequired,
  onDownloadHtml: PropTypes.func.isRequired,
};

export default function TimeseriesSummary({ summaryDeteails, status, onDownloadCsv, onDownloadHtml }) {
  const theme = useTheme();
  const modelHyperParameters = summaryDeteails?.input_data?.modelHyperParameters ?? {};
  const isObject = utils.generic.isValidObject(modelHyperParameters);

  const fileName = summaryDeteails?.input_data?.fileName;
  const predictionFileName = summaryDeteails?.input_data?.predictionFileName;
  const targetVariable = summaryDeteails?.input_data?.targetVariable;
  const testSizeRatio = summaryDeteails?.input_data?.testSizeRatio;
  const algorithm = summaryDeteails?.input_data?.algorithm;

  const isCompleted = status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase();

  const renderHyperParameters = () =>
    Object.entries(modelHyperParameters)?.map((item) => ({
      label: item[0],
      value: item[1],
    }));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} sx={{ borderRight: `1px dashed ${theme.palette.otherColor.blue30}` }}>
        <Box pr={1}>
          {fileName && (
            <FieldLabelWithValueHorizontal label="Input Data File" value={fileName} valueColor={(ftheme) => ftheme.palette.primary.main} />
          )}
          {predictionFileName && (
            <FieldLabelWithValueHorizontal
              label="Prediction Data File"
              value={predictionFileName}
              valueColor={(stheme) => stheme.palette.primary.main}
            />
          )}
          {targetVariable && <FieldLabelWithValueHorizontal label="Target Variable" value={targetVariable} />}
          {testSizeRatio && <FieldLabelWithValueHorizontal label="Test Size Ratio" value={testSizeRatio} />}
          {algorithm && <FieldLabelWithValueHorizontal label="Algorithm" value={algorithm} />}
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ borderRight: `1px dashed ${theme.palette.otherColor.blue30}` }}>
        <Box px={1}>
          {Boolean(isObject) &&
            renderHyperParameters()?.map(({ label, value }) => (
              <FieldLabelWithValueHorizontal key={label} label={utils.generic.capitalize(label)} value={value} />
            ))}
          <Box display="flex" flexDirection="row" py={0.5}>
            <Typography fontSize={16} fontWeight={600} pr={1} color={theme.palette.otherColor.textGrey2}>
              Status:
            </Typography>
            {status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus) && (
              <Typography fontSize={16} fontWeight={400} color={theme.palette.otherColor.textGrey2}>
                Error
              </Typography>
            )}
            {!status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus) && (
              <Grid container spacing={1}>
                <Grid item>
                  <BootstrapTooltip title={status.length > 40 ? status : ''}>
                    <Typography fontSize={16} fontWeight={400} color={theme.palette.otherColor.textGrey2}>
                      {Boolean(status) ? utils.app.getEllipsisString(status, 40) : utils.app.getEllipsisString(summaryDeteails?.status, 40)}
                    </Typography>
                  </BootstrapTooltip>
                </Grid>
                {!(isCompleted || status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus)) && (
                  <Grid item>
                    <CircularProgress size={16} />
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} pl={3}>
        <Box px={1}>
          <Typography fontSize={16} fontWeight={600} pr={1} sx={{ color: theme.palette.otherColor.textGrey2 }}>
            Click to Download:
          </Typography>
          {predictionFileName && (
            <IconButton disabled={!isCompleted} aria-label="download csv" onClick={onDownloadCsv}>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                  <DownloadIcon
                    sx={{ color: (theme) => (isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3) }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    sx={{ color: isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3 }}
                  >
                    CSV
                  </Typography>
                </Grid>
              </Grid>
            </IconButton>
          )}
          <IconButton disabled={!isCompleted} aria-label="download model" onClick={onDownloadHtml}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Grid item>
                <DownloadIcon sx={{ color: (theme) => (isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3) }} />
              </Grid>
              <Grid item>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  sx={{ color: isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3 }}
                >
                  PKL
                </Typography>
              </Grid>
            </Grid>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
