import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// app
import { BootstrapTooltip, Breadcrumbs } from 'components';
import { consts, useMedia } from 'globalsData';
import * as utils from 'utils';
import { ReactComponent as UserIcon } from 'assets/svg/UserIcon.svg';
import { ReactComponent as DownCaretIcon } from 'assets/svg/DownCaretIcon.svg';
import { config } from 'globalsData';
// import { ReactComponent as MarutiSuzukiArena } from 'assets/svg/MarutiSuzukiArena.svg';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import User from 'assets/png/newUserProfile.png';
import { ReactComponent as SearchIcon } from 'assets/svg/searchIcon.svg';

// mui
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { MenuItem, Menu, Grid, Typography, Toolbar, IconButton, Divider, Box, Hidden, Paper, InputBase } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { theme } from 'highcharts';
import { palette } from '@mui/system';

HeaderView.propTypes = {
  // breadcrumbs: PropTypes.array.isRequired,
  anchorEl: PropTypes.object,
  ellipsisLength: PropTypes.number.isRequired,
  userEmail: PropTypes.string,
  mobile: PropTypes.bool.isRequired,
  handlers: PropTypes.shape({
    handleDrawerOpen: PropTypes.func.isRequired,
    handleMenu: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    appLogoClick: PropTypes.func.isRequired,
  }).isRequired,
};

export function HeaderView({ anchorEl, ellipsisLength, userEmail, handlers }) {
  const { mobile } = useMedia();
  const navigate = useNavigate();
  let userEmailId = userEmail?.split('@')[0];
  if (userEmailId.toLowerCase().includes('happiestminds_')) {
    userEmailId = userEmailId?.split('_')[1];
  }
  let userName;
  if (userEmailId?.includes('_')) {
    userName = utils.generic.capitalizeFirstLetterOfString(userEmailId?.replace('_', ' '));
  } else {
    userName = utils.generic.capitalizeFirstLetterOfString(userEmailId?.replace('.', ' '));
  }

  return (
    <AppBar position="fixed" open={false} sx={{ boxShadow: 'none', backgroundColor: (theme) => theme.palette.primary.white }}>
      <Toolbar sx={{ marginLeft: '240px' }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={10}></Grid>
          <Grid item xs={2} textAlign="right">
            <Hidden smDown>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="170px"
                margin="auto 0 auto auto"
                padding="6px 3px"
                sx={{ cursor: 'pointer' }}
                onClick={handlers.handleMenu}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  sx={{ padding: '0 8px 0 4px' }}
                >
                  <img src={User} height="15px" width="12px" alt="profileIcon" />
                </IconButton>

                <BootstrapTooltip title={userName?.length > 10 ? userName : ''}>
                  <Box sx={{ fontSize: '14px', color: '#737791' }}>{utils.app.getEllipsisString(userName, 15)}</Box>
                </BootstrapTooltip>
              </Box>
            </Hidden>
            <Hidden smUp>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                sx={{ padding: '0 8px 0 4px' }}
                onClick={handlers.handleMenu}
              >
                <UserIcon width={18} height={18} />
                <DownCaretIcon width={10} />
              </IconButton>
            </Hidden>

            <Menu
              id="menu-appbar"
              sx={{ mt: '35px' }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handlers.handleClose}
            >
              {userEmail && (
                <Box pb={1}>
                  <Box py={1} px={2} display="flex">
                    <EmailOutlinedIcon />
                    <BootstrapTooltip title={userEmail?.length > 25 ? userEmail : ''}>
                      <Typography pl={1}>{utils.app.getEllipsisString(userEmail, 25)}</Typography>
                    </BootstrapTooltip>
                  </Box>
                  <Divider />
                </Box>
              )}
              <MenuItem onClick={handlers.logout}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography pl={0.1}>Logout</Typography>
                  </Grid>
                  <Grid item>
                    <LogoutOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
