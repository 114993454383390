import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// app
import { consts } from 'globalsData';
import * as uitls from 'utils';
import { BootstrapTooltip, Footer } from 'components';

// mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, List, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';

SideDrawerList.propTypes = {
  open: PropTypes.bool.isRequired,
  sideBarListFiltered: PropTypes.array.isRequired,
  handleSideDrawerIconClick: PropTypes.func.isRequired,
};

export default function SideDrawerList({ open, sideBarListFiltered, handleSideDrawerIconClick }) {
  const theme = useTheme();
  const drawerOpen = useSelector((state) => get(state, 'ui.sidebar.expanded', false));
  return (
    <React.Fragment>
      <DrawerHeader />
      <Box className='listtest'>
        <List disablePadding>
          {sideBarListFiltered.map((item,idx) => (
            <p key={idx}>{item.title}</p>
            // <BootstrapTooltip
            //   title={!drawerOpen || (drawerOpen && item?.title?.length > consts.SIDEBAR_MENU_TITLE_ELLIPISIS_LENGTH) ? item?.title : ''}
            //   placement={!drawerOpen ? 'right' : 'bottom'}
            //   key={item.title}
            // >
            //   <ListItem disablePadding sx={{ display: 'block' }}>
            //     <ListItemButton
            //       sx={{
            //         minHeight: 52,
            //         justifyContent: open ? 'initial' : 'center',
            //         pl: 2.5,
            //         ...(item.isSelected && { background: theme.palette.primary.sidebarBackground }),
            //       }}
            //       onClick={() => handleSideDrawerIconClick(item.title, item.link)}
            //     >
            //       <ListItemIcon
            //         sx={{
            //           minWidth: 0,
            //           mr: open ? 2 : 'auto',
            //           justifyContent: 'center',
            //           ...(item.isSelected && { color: theme.palette.primary.main, filter: 'invert(1) sepia(1) saturate(0)' }),
            //         }}
            //       >
            //         {item.icon}
            //       </ListItemIcon>
            //       {open && (
            //         <Typography
            //           fontSize={16}
            //           sx={{
            //             fontWeight: item.isSelected ? 600 : 400,
            //             color: item.isSelected ? (ttheme) => ttheme.palette.primary.white : (ftheme) => ftheme.palette.primary.textColor,
            //           }}
            //         >
            //           {uitls.app.getEllipsisString(item?.title, consts.SIDEBAR_MENU_TITLE_ELLIPISIS_LENGTH)}
            //         </Typography>
            //       )}
            //     </ListItemButton>
            //   </ListItem>
            // </BootstrapTooltip>
          ))}
        </List>
      </Box>
      <Footer drawerOpen={drawerOpen} />
    </React.Fragment>
  );
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
