import { ReactComponent as IconEDA } from 'assets/svg/IconExploratoryDataAnalysis.svg';
import { ReactComponent as Classification } from 'assets/svg/IconClassification.svg';
import { ReactComponent as Regression } from 'assets/svg/IconRegression.svg';
import { ReactComponent as IconTimeSeries } from 'assets/svg/IconTimeSeries.svg';
import { ReactComponent as IconClustering } from 'assets/svg/IconClustering.svg';
import { ReactComponent as IconNaturalLanguageProcessing } from 'assets/svg/IconNaturalLanguageProcessing.svg';
import { ReactComponent as IconAnomalyDetection } from 'assets/svg/IconAnomalyDetection.svg';
import { ReactComponent as IconArtificialIntelligence } from 'assets/svg/IconArtificialIntelligence.svg';

import { config, consts } from 'globalsData';

const generalDescription = 'Coming soon !';
const regressionDescription =
  'Regression is a statistical method to model the relationship between a dependent (target) and independent (predictor) variables with one or more independent variables.';
const edaDescription = 'Exploratory Data Analysis (EDA) is an approach to analyze the data using visual techniques.';
const classificationDescription =
  'The Classification algorithm is a Supervised Learning technique that is used to identify the category of new observations based on training data';

const highlights = {
  eda: [
    'Checking outliers, missing value & bad value treatment',
    'Visualizing and checking the distribution of the data on heatmap',
    'Removal of multi collinear variables by variation inflation factor analysis',
    'Univariate, bivariate analysis with respect to Target variable',
    'Normalization and custom Encoding',
    'Scaling of variables, optional hypothesis testing',
  ],
  classification: [
    'Automatic result report generation',
    'Custom train-test ratio selection',
    'Multiple classification Algorithms are embedded',
    'Tuning of hyperparameters with respect to model',
    'Feature importance, AUC and confusion matrix generation',
    'Comparison table of all models',
  ],
  regression: [
    'Automatic result report generation',
    'Custom train-test ratio selection',
    'Multiple regression Algorithms are embedded',
    'Tuning of hyperparameters with respect to model',
    'Feature importance, Prediction plot and residual plot generation',
    'Comparison table of all models',
  ],
  clustering: ['Clustering highlights'],
  timeseries: ['TimeSeries highlights'],
  naturalLanguageProcessing: ['NaturalLanguageProcessing highlights'],
  anomalyDetection: ['AnomalyDetection highlights'],
  artificialIntelligence: ['ArtificialIntelligence highlights'],
};
export const machineLearning = [
  {
    name: consts.EDA,
    title: 'Exploratory Data Analysis Supervised Learning',
    description: edaDescription,
    highlights: highlights.eda,
    link: `${config.routes.accelerators.type}/exploratory-data-analysis`,
    videoLink: 'https://d3c1dwt1daxow9.cloudfront.net/videos/EDA_Demo.mp4',
    icon: IconEDA,
    enabled: true,
  },
  {
    name: consts.EDAU,
    title: 'Exploratory Data Analysis Unsupervised Learning',
    description: edaDescription,
    highlights: highlights.eda,
    link: `${config.routes.accelerators.type}/exploratory-data-analysis`,
    videoLink: 'https://d3c1dwt1daxow9.cloudfront.net/videos/EDA_Demo.mp4',
    icon: IconEDA,
    enabled: true,
  },
  {
    name: consts.CLASSIFICATION,
    title: consts.ACCELERATORS.CLASSIFICATION,
    description: classificationDescription,
    highlights: highlights.classification,
    link: `${config.routes.accelerators.type}/classification`,
    videoLink: 'https://d3c1dwt1daxow9.cloudfront.net/videos/Classification_Demo.mp4',
    icon: Classification,
    enabled: true,
  },
  {
    name: consts.REGRESSION,
    title: consts.ACCELERATORS.REGRESSION,
    description: regressionDescription,
    highlights: highlights.regression,
    link: `${config.routes.accelerators.type}/regression`,
    videoLink: 'https://d3c1dwt1daxow9.cloudfront.net/videos/Regression_Demo.mp4',
    icon: Regression,
    enabled: true,
  },
  {
    name: consts.TIMESERIES,
    title: consts.ACCELERATORS.TIME_SERIES,
    description: generalDescription,
    highlights: highlights.timeseries,
    link: `${config.routes.accelerators.type}/timeseries`,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconTimeSeries,
    enabled: true,
  },
  {
    name: consts.CLUSTERING,
    title: consts.ACCELERATORS.CLUSTERING,
    description: generalDescription,
    highlights: highlights.clustering,
    link: `${config.routes.accelerators.type}/clustering`,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconClustering,
    enabled: true,
  },
];
export const artificialIntelligence = [
  {
    name: consts.NATURAL_LANGUAGE_PROCESSING,
    // title: consts.ACCELERATORS.NATURAL_LANGUAGE_PROCESSING,
    title: 'Named Entity Recognition',
    description: generalDescription,
    highlights: highlights.naturalLanguageProcessing,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconNaturalLanguageProcessing,
    enabled: false,
  },
  {
    name: consts.ANOMALY_DETECTION,
    // title: consts.ACCELERATORS.ANOMALY_DETECTION,
    title: 'Text to Speech',
    description: generalDescription,
    highlights: highlights.anomalyDetection,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconAnomalyDetection,
    enabled: true,
  },
  {
    name: consts.ARTIFICIAL_INTELLIGENCE,
    // title: consts.ACCELERATORS.ARTIFICIAL_INTELLIGENCE,
    title: 'Speech to Text',
    description: generalDescription,
    highlights: highlights.artificialIntelligence,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconArtificialIntelligence,
    enabled: true,
  },
  {
    name: consts.CLUSTERING,
    // title: consts.ACCELERATORS.CLUSTERING,
    title: 'Language Translation',
    description: generalDescription,
    highlights: highlights.clustering,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconClustering,
    enabled: false,
  },
];

const accelerators = [
  {
    name: consts.EDA,
    title: consts.ACCELERATORS.EXPLORATORY_DATA_ANALYSIS,
    description: edaDescription,
    highlights: highlights.eda,
    link: `${config.routes.accelerators.type}/exploratory-data-analysis`,
    videoLink: 'https://d3c1dwt1daxow9.cloudfront.net/videos/EDA_Demo.mp4',
    icon: IconEDA,
    enabled: true,
  },
  {
    name: consts.CLASSIFICATION,
    title: consts.ACCELERATORS.CLASSIFICATION,
    description: classificationDescription,
    highlights: highlights.classification,
    link: `${config.routes.accelerators.type}/classification`,
    videoLink: 'https://d3c1dwt1daxow9.cloudfront.net/videos/Classification_Demo.mp4',
    icon: Classification,
    enabled: true,
  },
  {
    name: consts.REGRESSION,
    title: consts.ACCELERATORS.REGRESSION,
    description: regressionDescription,
    highlights: highlights.regression,
    link: `${config.routes.accelerators.type}/regression`,
    videoLink: 'https://d3c1dwt1daxow9.cloudfront.net/videos/Regression_Demo.mp4',
    icon: Regression,
    enabled: true,
  },
  {
    name: consts.CLUSTERING,
    title: consts.ACCELERATORS.CLUSTERING,
    description: generalDescription,
    highlights: highlights.clustering,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconClustering,
    enabled: true,
  },
  {
    name: consts.TIMESERIES,
    title: consts.ACCELERATORS.TIME_SERIES,
    description: generalDescription,
    highlights: highlights.timeseries,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconTimeSeries,
    enabled: false,
  },
  {
    name: consts.NATURAL_LANGUAGE_PROCESSING,
    title: consts.ACCELERATORS.NATURAL_LANGUAGE_PROCESSING,
    description: generalDescription,
    highlights: highlights.naturalLanguageProcessing,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconNaturalLanguageProcessing,
    enabled: false,
  },
  {
    name: consts.ANOMALY_DETECTION,
    title: consts.ACCELERATORS.ANOMALY_DETECTION,
    description: generalDescription,
    highlights: highlights.anomalyDetection,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconAnomalyDetection,
    enabled: false,
  },
  {
    name: consts.ARTIFICIAL_INTELLIGENCE,
    title: consts.ACCELERATORS.ARTIFICIAL_INTELLIGENCE,
    description: generalDescription,
    highlights: highlights.artificialIntelligence,
    link: config.routes.blank.root,
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
    icon: IconArtificialIntelligence,
    enabled: false,
  },
];

export default accelerators;
