import React from 'react';
import {
  Box,
  Paper,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Autocomplete,
  TextField,
  Chip,
  InputLabel,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import * as utils from 'utils';
import './ModelCarrierDashboard.css';
import { Controller } from 'react-hook-form';
const ModelCarrierDashboardView = ({
  carrierTypes,
  carModels,
  fields,
  formProps,
  handlers,
  tableData,
  carModelsOptions,
  setValue,
  carModelList,
}) => {
  const { control, register, handleSubmit, errors } = formProps;
  const manageCarModelsField = utils.form.getFieldProp(fields, 'carModel');
  return (
    <Box>
      <Paper elevation={3} sx={{ padding: '0px !important', marginBottom: '24px' }}>
        <Box id="model-carrier-form" component="form" p={1}>
          <Grid container>
            <Grid item xs={3} p={0.5}>
              <FormControl sx={{ width: '100%', marginTop: '3px' }} error={!!errors?.carrierType?.message}>
                <InputLabel id="demo-simple-select-autowidth-label">Carrier Type *</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  size="small"
                  label="Carrier Type"
                  {...register('carrierType')}
                  {...utils.form.getFieldProp(fields, 'carrierType')}
                >
                  {carrierTypes.map((item) => (
                    <MenuItem value={item.carrier} key={item.carrier}>
                      {item.carrier}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors?.carrierType?.message && (
                  <FormHelperText sx={{ fontSize: '14px', marginLeft: '0' }}>{errors?.carrierType?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} p={0.5}>
              <Grid item xs={12} p={0.5}>
                <Controller
                  name={manageCarModelsField?.name}
                  control={control}
                  defaultValue={[]}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        value={field.value}
                        disablePortal={false}
                        filterSelectedOptions
                        multiple
                        size="small"
                        getOptionDisabled={(option) => option.disabled}
                        getOptionLabel={manageCarModelsField?.getOptionLabel}
                        isOptionEqualToValue={(option, value) => option.carModel === value.carModel}
                        onChange={(event, values) => {
                          field.onChange(values);
                          setValue('carModel', values);
                        }}
                        options={carModels}
                        renderTags={(value, getTagProps) => {
                          return value?.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              variant="outlined"
                              size="small"
                              label={option[manageCarModelsField?.optionLabel]}
                            />
                          ));
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={!!error}
                            helperText={error?.message}
                            id={manageCarModelsField?.name}
                            label={manageCarModelsField?.label}
                            name={manageCarModelsField?.name}
                            type="search"
                            inputRef={ref}
                            {...params}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={3} p={0.5} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                px={5}
                sx={{ textTransform: 'none', borderRadius: '20px' }}
                startIcon={<EastIcon />}
                onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box
        id="modelCarrierTable"
        className="modelCarrierTableStyle"
        sx={{ whiteSpace: 'nowrap', overflowX: 'scroll', borderRadius: '5px', border: '1px solid grey' }}
      >
        <Box className="tableHeaderStyle">
          <div className="mcHeaderCellStyles headerCombStringStyle">Combination String</div>
          <div className="mcHeaderCellStyles headerPositionStyle">Positioning</div>
          <div className="mcHeaderCellStyles">Cars Model</div>
          <div className="mcHeaderCellStyles">Trip Code</div>
          <div className="mcHeaderCellStyles">Type</div>
          <div className="mcHeaderCellStyles">Group</div>
          <div className="mcHeaderCellStyles headerLoadFactorStyle">Load Factor</div>
        </Box>
        <Box className="tableBody">
          {tableData?.result?.map((row, idx) => {
            return (
              <Box
                className="tableRow"
                sx={{
                  bgcolor: '#ffffff',
                  alignItems: 'center',
                  borderTop: '0px',
                }}
                key={idx}
              >
                <Box className="mcRowCellStyles combString">
                  {row?.combString?.map((item, idx) => {
                    return (
                      <span key={idx} className={`combStringStyle background${item}Color`}>
                        {item}
                      </span>
                    );
                  })}
                </Box>
                <Box className="mcRowCellStyles positionCell">
                  <Box sx={{ width: '100%' }}>
                    <Box className="deckLblStyle">Upper Deck</Box>
                    <Box className="deckStyle upperDeckStyle">
                      {row?.position?.upperDeck?.map((item, idx) => {
                        return (
                          <span key={idx} className={`deckItemStyle background${item}Color`}>
                            {item}
                          </span>
                        );
                      })}
                    </Box>
                  </Box>
                  <Box>
                    <Box className="deckLblStyle">Lower Deck</Box>
                    <Box className="deckStyle">
                      {row?.position?.lowerDeck?.map((item, idx) => {
                        return (
                          <span key={idx} className={`deckItemStyle background${item}Color`}>
                            {item}
                          </span>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
                <Box className="carsModelCustomCellStyle mcRowCellStyles carsModelCell">
                  {row?.carsModel?.map((item, idx) => {
                    return (
                      <Box key={idx} className="carModelsStyle">
                        <span>{item.carModelName} - </span>
                        <span> {item.category} </span>
                        <span>({item.total})</span>
                      </Box>
                    );
                  })}
                </Box>
                <Box className="mcRowCellStyles tripCodeCell">{row?.tripCode}</Box>
                <Box className="mcRowCellStyles combinationTypeCell">{row?.type}</Box>
                <Box className="groupCustomCellStyle groupCell">
                  {row?.group?.map((item, idx) => {
                    return <Box key={idx} className="carModelsStyle">{`${item?.category} - (${item?.total})`}</Box>;
                  })}
                </Box>
                <Box className="mcRowCellStyles loadFactorCell">{row?.loadFactor}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ModelCarrierDashboardView;
