import { useEffect, useRef, useState } from 'react';
import AddArtificialIntelligenceFormView from './AddArtificialIntelligenceForm.view';
import { useDispatch } from 'react-redux';
import { consts, config } from 'globalsData';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  addLoader,
  removeLoader,
  getPresignedUrl,
  uploadFileToS3,
  postProcessingInput,
  snackbarNotification,
  fetchSourceAndTargetLanguages,
} from 'stores';
import * as utils from 'utils';
import { useNavigate } from 'react-router-dom';
import RecordRTC from 'recordrtc';
import { useForm } from 'react-hook-form';

const AddArtificialIntelligenceForm = () => {
  
  const dispatch = useDispatch();
  const inputDataRef = useRef();
  const navigate = useNavigate();

  const [files, setFiles] = useState({ inputDataFile: null });
  const [sourceLanguageList, setSourceLanguageList] = useState([]);
  const [targetLanguageList, setTargetLanguageList] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('');
  const [recorder, setRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [startRecorderButton, setStartRecorderButton] = useState(true);
  const [isNoFilesUploaded, setIsNoFilesUploaded] = useState({ inputDataFile: false });
  const [selectedAudioSourceValue, setSelectedAudioSourceValue] = useState('file');
  const [isValidFileFormats, setIsValidFileFormats] = useState({ inputDataFile: true });
  const [isValidFileSizes, setIsValidFileSizes] = useState({ inputDataFile: true });
  const fileUploadFieldList = [{ field: 'inputData', label: 'Upload Input Data ', file: files.inputDataFile, ref: inputDataRef }];
  const [justificationRemarks, setJustificationRemarks] = useState('');
  const [fileError, setFileError] = useState('');
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [audio] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [recorderComponent, setRecorderComponent] = useState(false);

  const fields = [
    {
      name: 'justificationRemarks',
      id: 'justificationRemarks',
      size: 'small',
      required: true,
      validation: Yup.string().min(30, 'Min 30 characters').max(150, 'Max 150 characters').required('Justification Remarks required'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'sourceLanguage',
      id: 'sourceLanguage',
      required: true,
      defaultValue: sourceLanguage,
      validation: Yup.string().required('Please Select Source Language'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'targetLanguage',
      id: 'targetLanguage',
      required: true,
      defaultValue: targetLanguage,
      validation: Yup.string().required('Please Select Target Language'),
      sx: { textAlign: 'left', width: '100%' },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(
          fetchSourceAndTargetLanguages({
            type: consts.ACCELERATORS.SPEECH_TO_TEXT,
          })
        );
        setSourceLanguageList(res?.data?.source);
        setTargetLanguageList(res?.data?.target);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const playAudio = () => {
    if (!audio.src) {
      audio.src = URL.createObjectURL(audioBlob);
    }

    if (audio.paused) {
      audio.play();
    }

    setIsPlaying(true);
  };

  const pauseAudio = () => {
    audio.pause();
    setIsPlaying(false);
  };

  const processData = (dataString) => {
    dispatch(removeLoader('readingFile...'));
  };

  const handleFileUpload = (files, field) => {
    const uploadedFile = files[0];
    const isValidFileFormat =
      uploadedFile && uploadedFile.name && consts.formAllowedFileFormatsForMp3?.includes(uploadedFile.name.split('.').pop().toLowerCase());
    if (fileUploadFieldList[0].field === field) {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: uploadedFile }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
          setFileError('');
          dispatch(addLoader('readingFile...'));
          utils.app.getFileReader(processData, uploadedFile);
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
      }
    } else {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles }));
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles }));
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles }));
      }
    }
  };

  const handleChange = (event) => {
    if (event.target.value === 'file') {
      setAudioBlob(null);
      setIsRecordingStarted(false);
      setIsPlaying(false);
      setRecorderComponent(false);
      pauseAudio();
      setCurrentTime(0);
      setTotalDuration(0);
    } else {
      setFiles({ inputDataFile: null });
    }
    setSourceLanguage('');
    setTargetLanguage('');
    setFileError('');
    reset();
    setJustificationRemarks('');
    setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
    setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
    setSelectedAudioSourceValue(event.target.value);
  };

  const startRecording = async () => {
    try {
      setStartRecorderButton(!startRecorderButton);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = {
        mimeType: 'audio/wav',
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
      };
      const recordInstance = new RecordRTC(stream, options);
      recordInstance.startRecording();
      setRecorder(recordInstance);
      setIsRecordingStarted(true);
      setRecorderComponent(true);
      setCurrentTime(0);
      setTotalDuration(0);
      setFileError('');
      const intervalId = setInterval(() => {
        setTotalDuration((prevTotalTime) => prevTotalTime + 1);
      }, 1000);

      const currentTimeIntervalId = setInterval(() => {
        setCurrentTime(recordInstance.getInternalRecorder().getTimestamp());
      }, 1000);

      recordInstance.intervalIds = [intervalId, currentTimeIntervalId];
    } catch (error) {
      console.error('Error accessing microphone:', error);

      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        console.log('User declined microphone permissions.');
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  const stopRecording = () => {
    setStartRecorderButton(!startRecorderButton);
    setIsRecordingStarted(false);
    if (recorder) {
      recorder.stopRecording(() => {
        clearInterval(recorder.intervalIds[0]);
        clearInterval(recorder.intervalIds[1]);
        setAudioBlob(recorder.getBlob());
      });
      setFileError('');
    }
  };

  const onDelete = (field) => {
    switch (field) {
      case fileUploadFieldList[0].field:
        setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: null }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
        break;
      default:
        setFiles((prevFiles) => ({ ...prevFiles }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData }));
    }
  };

  const postFormData = (reqParams, trackerId) => {
    dispatch(postProcessingInput(reqParams)).then((res) => {
      if (res?.status === 200) {
        dispatch(snackbarNotification('Data submitted successully, Soon you will get prediction graphs', 'success'));
        navigate(`${config.routes.accelerators.type}/speechtotext/${trackerId}`);
      }
    });
  };

  const handleDropDownChange = (e) => {
    const { value, name } = e.target;
    if (name === 'targetLanguage') {
      setTargetLanguage(value);
    } else {
      setSourceLanguage(value);
    }
  };

  const onSubmit = async () => {
    if (!files.inputDataFile && selectedAudioSourceValue === 'file') {
      setFileError('Plese attach the input file');
      return;
    }

    if (!audioBlob && selectedAudioSourceValue !== 'file') {
      setFileError('Plese record the audio');
      return;
    }
    const blob = audioBlob && (await new Response(audioBlob).arrayBuffer());
    const uint8Array = new Uint8Array(blob);
    const file = new File([uint8Array], 'RecordedAudio', { type: audioBlob?.type });
    const sourceLanguageCode = sourceLanguageList
      ?.filter((item) => item.language === sourceLanguage)
      .map((item) => item.code)
      .toString();
    const targetLanguageCode = targetLanguageList
      ?.filter((item) => item.language === targetLanguage)
      .map((item) => item.code)
      .toString();

    dispatch(
      getPresignedUrl({
        type: consts.ACCELERATORS.SPEECH_TO_TEXT,
        fileName: files?.inputDataFile?.name || 'RecordedAudio.wav',
      })
    ).then((res) => {
      const inputPreSignedUrl = res?.data?.data?.inputPreSignedUrl;
      const trackerId = res?.data?.trackerId;
      const reqParams = {
        targetLanguage: targetLanguageCode,
        sourceLanguage: sourceLanguageCode,
        filetype: files?.inputDataFile?.name?.split('.')[1] || file?.type.split('/')[1],
        trackerId,
        justificationRemarks: justificationRemarks,
        type: 'speechtotext',
      };

      if (inputPreSignedUrl && trackerId) {
        dispatch(uploadFileToS3({ preSignedUrl: inputPreSignedUrl, file: files?.inputDataFile || file, isThisForAudio: true })).then(
          (fS3res) => {
            if (fS3res?.status === 200) {
              postFormData(reqParams, trackerId);
            }
          }
        );
      }
    });
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleResetAudio = () => {
    setTotalDuration(0);
    setCurrentTime(0);
    setAudioBlob(null);
    setIsRecordingStarted(false);
    setRecorderComponent(false);
  };

  useEffect(() => {
    audio.addEventListener('ended', () => {
      setIsPlaying(false);
    });

    audio.addEventListener('timeupdate', () => {
      setCurrentTime(audio.currentTime);
    });

    audio.addEventListener('loadedmetadata', () => {
      setTotalDuration(audio.duration);
    });

    return () => {
      audio.removeEventListener('ended', () => {});
      audio.removeEventListener('timeupdate', () => {});
      audio.removeEventListener('loadedmetadata', () => {});
    };
  }, [audio]);

  useEffect(() => {
    return () => {
      // Cleanup when the component unmounts
      if (recorder) {
        clearInterval(recorder.intervalIds[0]);
        clearInterval(recorder.intervalIds[1]);
      }
    };
  }, [recorder]);

  return (
    <div>
      <AddArtificialIntelligenceFormView
        dispatch={dispatch}
        algorithms={[]}
        recorder={recorder}
        audioBlob={audioBlob}
        startRecorderButton={startRecorderButton}
        isNoFilesUploaded={isNoFilesUploaded}
        selectedAudioSourceValue={selectedAudioSourceValue}
        isValidFileFormats={isValidFileFormats}
        isValidFileSizes={isValidFileSizes}
        fileUploadFieldList={fileUploadFieldList}
        processData={processData}
        handleFileUpload={handleFileUpload}
        handleChange={handleChange}
        startRecording={startRecording}
        stopRecording={stopRecording}
        onDelete={onDelete}
        setAudioBlob={setAudioBlob}
        onSubmit={onSubmit}
        sourceLanguageList={sourceLanguageList}
        targetLanguageList={targetLanguageList}
        sourceLanguage={sourceLanguage}
        targetLanguage={targetLanguage}
        handleDropDownChange={handleDropDownChange}
        justificationRemarks={justificationRemarks}
        setJustificationRemarks={setJustificationRemarks}
        formProps={{ errors, register, handleSubmit, control }}
        fields={fields}
        fileError={fileError}
        isRecordingStarted={isRecordingStarted}
        playAudio={playAudio}
        pauseAudio={pauseAudio}
        isPlaying={isPlaying}
        formatTime={formatTime}
        currentTime={currentTime}
        totalDuration={totalDuration}
        recorderComponent={recorderComponent}
        handleResetAudio={handleResetAudio}
      />
    </div>
  );
};

export default AddArtificialIntelligenceForm;
