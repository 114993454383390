import { api } from 'utils';
import { snackbarNotification } from 'stores';

export const getSummaryDetails = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!params?.type || !params?.trackerId) return Promise.reject({ message: 'Request params are missing' });

  dispatch(getSummaryDetailsRequest(params));

  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'getSummaryDetails',
      params: { trackerId: params.trackerId, type: params.type },
    })
    .then((res) => {
      dispatch(getSummaryDetailsSuccess(res?.data));
      return res;
    })
    .catch((error) => {
      dispatch(getSummaryDetailsError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    });
};

export const getSummaryDetailsRequest = (params) => {
  return {
    type: 'GET_SUMMARY_DETAILS_REQUEST',
    payload: params,
  };
};

export const getSummaryDetailsSuccess = (data) => {
  return {
    type: 'GET_SUMMARY_DETAILS_SUCCESS',
    payload: data,
  };
};

export const getSummaryDetailsError = (error) => {
  return {
    type: 'GET_SUMMARY_DETAILS_FAILURE',
    payload: error,
  };
};
