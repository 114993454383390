const initialState = {
  totalActiveUsers: {},
  mostActiveUser: {},
  avgResponseTime: {},
  acceleratorsWeeklyUsage:{},
  verticalList:[],
  overallAcceleratorsUsage:[],
  avgAppLoadTime:{},
  verticalPieChartData:{},
  departmentPieChartData:{},
  isReportsLoading: false,
};

const reportsReducers = (state = initialState, action = []) => {
  switch (action.type) {
    case 'GET_TOATAL_ACTIVE_USERS_REQUEST':
      return {
        ...state,
        isReportsLoading: true,
      };

    case 'GET_TOATAL_ACTIVE_USERS_SUCCESS':
      return {
        ...state,
        totalActiveUsers: action.payload || {},
        isReportsLoading: false,
      };
    case 'GET_MOST_ACTIVE_USERS_SUCCESS':
      return {
        ...state,
        mostActiveUser: action.payload || {},
        isReportsLoading: false,
      };
    case 'GET_AVERAGE_RESPONSE_TIME_SUCCESS':
      return {
        ...state,
        avgResponseTime: action.payload || {},
        isReportsLoading: false,
      };
    case 'GET_ACCELERATOR_WEEKLY_USAGE_SUCCESS':
      return {
        ...state,
        acceleratorsWeeklyUsage: action.payload || {},
        isReportsLoading: false,
      };
    case 'GET_OVERALL_ACCELERATORS_USAGE_SUCCESS':
      return {
        ...state,
        overallAcceleratorsUsage: action.payload || [],
        isReportsLoading: false,
      };
    case 'GET_OVERALL_APP_RESPONSE_TIME_SUCCESS':
      return {
        ...state,
        avgAppLoadTime: action.payload || [],
        isReportsLoading: false,
      };
    case 'GET_VERTICAL_PIE_CHART_DATA_SUCCESS':
      return {
        ...state,
        verticalPieChartData: action.payload || {},
        isReportsLoading: false,
      };
    case 'GET_DEPARTMENT_PIE_CHART_DATA_SUCCESS':
      return {
        ...state,
        departmentPieChartData: action.payload || {},
        isReportsLoading: false,
      };
    default:
      return state;
  }
};

export default reportsReducers;
