import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getDepartmentList = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  // const params = {
  //   verticalId: reqParams
  // }
  dispatch(getDepartmentListRequest());
  return api
    .get({
      endpoint: endpoint.userManagementService,
      path: 'v1/um/departmentList',
      // params
    })
    .then((res) => {
      dispatch(getDepartmentListSuccess( res?.data || [] ));
    })
    .catch((error) => {
      dispatch(getDepartmentListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getDepartmentList'));
    });
};

export const getDepartmentListRequest = () => {
  return {
    type: 'GET_DEPARTMENT_LIST_REQUEST',
  };
};

export const getDepartmentListSuccess = (data) => {
  return {
    type: 'GET_DEPARTMENT_LIST_SUCCESS',
    payload: data,
  };
};

export const getDepartmentListError = (error) => {
  return {
    type: 'GET_DEPARTMENT_LIST_FAILURE',
    payload: error,
  };
};
