const consts = {
  APP_TITLE: 'Enterprise Analytics & AI Platform',
  MOBILE_APP_TITLE_ELLIPSIS_LENGTH: 20,
  TABLET_APP_TITLE_ELLIPSIS_LENGTH: 15,
  SIDEBAR_MENU_TITLE_ELLIPISIS_LENGTH: 19,
  WEBSITE_STATUS_LIST_TO_DISABLE_RUN_SCRIPT: ['crawling_in_progress', 'triggered'],
  SIDE_DRAWER_WIDTH: 240,
  SNACKBAR_AUTO_HIDE_DURATION: 3000,
  ACCELERATORS_MAX_FILE_SIZE: 40000000,
  BVIT_MAX_FILE_SIZE: 50000000,
  ACCELERATORS_MAX_TXT_FILE_SIZE: 8000,
  ACCELERATORS: {
    EXPLORATORY_DATA_ANALYSIS: 'Exploratory Data Analysis',
    EXPLORATORY_DATA_ANALYSIS_UNSUPERVISED: 'Exploratory Data Analysis Unsupervised',
    CLASSIFICATION: 'Classification',
    REGRESSION: 'Regression',
    CLUSTERING: 'Clustering',
    TIME_SERIES: 'TimeSeries',
    NATURAL_LANGUAGE_PROCESSING: 'Natural Language Processing',
    ANOMALY_DETECTION: 'Anomaly Detection',
    ARTIFICIAL_INTELLIGENCE: 'Artificial Intelligence',
    SPEECH_TO_TEXT: 'speechtotext',
  },
  TOOLTIP: {
    LEARNING_TYPE:
      'The learning type determines the nature of the analysis, such as regression for predicting numerical values or classification for predicting categories',
    TARGET_VARIABLE_CATEGORICAL:
      'The target variable is the attribute you want to predict or classify based on other features in your data',
    TARGET_VARIABLE_NUMERICAL:
      'The numerical feature selected for comparison helps analyse relationships and patterns in the data, aiding in understanding its behaviour',
    FEATURE_FOR_COMPARISION_NUMERICAL:
      'The numerical feature selected for comparison helps analyse relationships and patterns in the data, aiding in understanding its behaviour',
    HYPOTHESIS_TESTING:
      'Hypothesis testing assesses the significance of observed patterns or differences in the data to draw meaningful conclusions',
    DATA_CLEANING_PARAMETER_PERCENTAGE:
      'This parameter sets a threshold for the proportion of missing values in a feature, guiding decisions on whether to handle or drop those features',
    DATA_CLEANING_PARAMETER_IMPUTATION:
      'It defines the technique used to estimate and fill in missing values in the dataset, ensuring a more complete and accurate analysis',
    DATA_CLEANING_PARAMETER_THRESHOLD:
      'This threshold determines when categorical features should be label-encoded based on their unique values, streamlining the analysis process',
    DATA_CLEANING_PARAMETER_REPLACE:
      'It specifies the method for handling special characters in the data, ensuring consistency and avoiding potential issues during analysis',
    TARGET_VARIABLE:
      'The target variable is what you are trying to predict, the outcome or category you want the model to identify in your data',
    TEST_SIZE_RATIO:
      'Test size ratio determines the proportion of the dataset set aside for testing the model performance, helping assess its generalization ability',
    ALGORITHM:
      'The algorithm is the mathematical method or approach the model uses to learn patterns and make predictions from the input data',
    MODEL_HYPERPARAMETERS_C_REGULATION:
      'The regularization parameter (C) helps control the trade-off between fitting the training data well and avoiding overfitting, influencing the models complexity',
    SOLVER:
      'The solver is the optimization algorithm used by the classification model to find the best parameters that minimize the error in prediction',
    TOLERANCE:
      'Tolerance sets the level of precision the model requires for convergence during training, impacting the stopping criterion for the optimization process',
    MODEL_METRICS:
      'Model metrics are performance measures, such as accuracy or precision, used to evaluate how well the classification model is performing on the given task',
    PENALTY:
      'Penalty determines the type of regularization applied, with options like L1 or L2 regularization, influencing how the model penalizes complex patterns',
    CLASSIFICATION_BOOSTER: 'The booster in XGB Classifier is the underlying decision tree model',
    CLASSIFICATION_IMPORTANCE_TYPE: ' Importance_type defines how feature importance is calculated for identifying key predictors',
    CLASSIFICATION_LEARNING_RATE: 'Learning Rate adjusts the step size in model training, influencing convergence speed',
    CLASSIFICATION_N_ESTIMATOR:
      'The count of trees in the ensemble, n_estimators, determines the model overall complexity and predictive ability',
    CLASSIFICATION_CRITERION:
      'It is the measure used to evaluate the quality of a split in the Gradient Boosting Classifier, commonly set as "gini" or "entropy."',
    CLASSIFICATION_CLASSIFIER_LEARNING_RATE:
      'It adjusts the step size in each iteration, impacting the contribution of each tree in the ensemble during Gradient Boosting',
    CLASSIFICATION_CLASSIFIER_LOSS:
      'It specifies the loss function to minimize during training, determining how model errors are penalized',
    CLASSIFICATION_CLASSIFIER_MAX_FEATURES:
      'It sets the maximum number of features considered for a split, controlling the diversity of trees in the Gradient Boosting Classifier',
    CLASSIFICATION_CLASSIFIER_N_ESTIMATOR:
      'The count of boosting stages, n_estimators, defines the total number of trees in the ensemble and influences the model complexity and performance',
    CLASSIFICATIONZ_ADA_BOOST_N_ESTIMATOR:
      'The count of weak learners (typically decision trees) combined in the AdaBoost ensemble, influencing the model overall predictive power',
    CLASSIFICATION_ADA_BOOST_LEARNING_RATE:
      'Learning Rate adjusts the contribution of each weak learner, controlling how much the model adapts to the errors made by the previous ones during AdaBoost training',
    TEXT_TO_SPEECH_INPUT_TEXT: 'Only plain text allowed. Maximum text size: 5000 characters',
    TEXT_TO_SPEECH_UPLOAD_FILE: 'Only .txt file allowed. Maximum text file size: 8kb',
    ARTIFICIAL_INTELLIGENCE_SOURCE_LANGUAGE: 'Choose the language of the spoken or uploaded content for accurate translation.',
    ARTIFICIAL_INTELLIGENCE_TARGET_LANGUAGE: 'Choose the desired language for the translated output.',
    ARTIFICIAL_INTELLIGENCE_UPLOAD_AUDIO: 'Start capturing audio for language translation by clicking here.',
    ARTIFICIAL_INTELLIGENCE_AUDIO_SOURCE: `Choose the audio source: opt for 'File' to upload audio files, or select 'Microphone Recording' for live audio input.`,
    CLUSTERING_KMODE_NCLUSTERS: 'Choose the number of groups you want the algorithm to find in your data.',
    CLUSTERING_KMODE_INIT:
      "Decide how the algorithm should start organizing the groups: 'Huang', 'Cao', or 'random' give different starting points.",
    CLUSTERING_KMEANS_NCLUSTERS: 'Choose the number of groups you want the algorithm to find in your data.',
    CLUSTERING_KMEANS_INIT: 'Choose the method for placing initial cluster centroids: k-means++ (recommended) or random.',
    CLUSTERING_KMEANS__TOL: 'Set the tolerance to control the convergence speed; smaller values may increase precision.',
    CLUSTERING_KMEANS_ALGORITHM:
      'Select the algorithm for cluster computation: lloyd for standard KMeans, elkan for potentially faster results, auto to let the algorithm decide, or full for the classic EM-style algorithm.',
    CLUSTERING_AGGLOMERATIVE_NCLUSTERS: 'Choose the number of groups you want the algorithm to find in your data.',
    CLUSTERING_AGGLOMERATIVE_METRIC: 'Choose the measure of distance between instances: euclidean, l1, l2, manhattan, or cosine.',
    CLUSTERING_AGGLOMERATIVE_LINKAGE:
      'Select the linkage algorithm determining how to merge clusters: ward for minimum variance, complete for maximum pairwise distance, average for average pairwise distance, or single for minimum pairwise distance.',
    CLUSTERING_SELECTALGORITHM: 'Select clustering algorithm based on your data.',
    CLUSTERING_INPUTDATAFILE: 'Select the input data to be used for cluster creation.',
    CLUSTERING_PREDICTIONDATAFILE: 'Select if you have prediction input data; ensure the columns match those of the input data.',
    CLUSTERING_IDCOLUMNS:
      'Please select the ID column(s) from your dataset, which uniquely identify each record and will be used for referencing and analysis in the clustering process.',
    TIMESERIES_ALGORITHM:
      'The algorithm is the mathematical method that model uses to learn patterns and make future forecast based on the historical data',
    TIMESERIES_FORECAST_VARIABLE: 'The variable that needs to be forecasted',
    TIMESERIES_TIME_STEP: `Time step must be parsable into a datetime type. e.g. DD/MM/YYYY, DD-MM-YYYY,DD-Month Name-YYYY `,
    TIMESERIES_DAY_FIRST: 'The date parsing order',
    TIMESERIES_FORECAST_LENGTH: 'The future duration for which forecast needs to be done',
    TIMESERIES_TEST_SIZE_RATIO: 'Size of test set or validation set',
    TIMESERIES_DIFFERENCING_VALUE: 'The differencing value to remove temporal dependence',
    TIMESERIES_ARIMA_SEASONAL: 'Whether time series has seasonality or not',
    TIMESERIES_ARIMA_SEASONAL_DIFFERENCING: 'The differencing value at a lag equal to the number of seasons to remove seasonal effects',
    TIMESERIES_EXPONENTIAL_TREND: 'Type of trend component',
    TIMESERIES_EXPONENTIAL_DAMPED_TREND: 'The trend component need to be damped or not',
    TIMESERIES_EXPONENTIAL_SEASONAL: 'Type of seasonal component',
    TIMESERIES_EXPONENTIAL_SEASONAL_PERIODS: 'The length of the repeating pattern or cycle in the data',
    TIMESERIES_EXPONENTIAL_FREQ: 'The frequency of the time-series',
    TIMESERIES_SEASONALITY_MODE: 'Type of seasonality i.e. additive or multiplicative',
    TIMESERIES_SEASONALITY: 'Type of seasonality component i.e. yearly, weekly or daily',
  },
  EDA: 'exploratory-data-analysis',
  EDAU: 'exploratory-data-analysis-unsupervised',
  CLASSIFICATION: 'classification',
  REGRESSION: 'regression',
  CLUSTERING: 'clustering',
  TIMESERIES: 'timeseries',
  TEXT_TO_SPEECH: 'texttospeech',
  NATURAL_LANGUAGE_PROCESSING: 'naturalLanguageProcessing',
  ANOMALY_DETECTION: 'anomalyDetection',
  ARTIFICIAL_INTELLIGENCE: 'artificialIntelligence',
  FEATURE_LIST_ARR: ['Accelerators', 'Analytics Studio', 'Data Crawlers', 'Business Solutions'],
  FEATURE_LIST_OBJ: {
    home: 'Home',
    accelerators: 'Accelerators',
    as: 'Analytics Studio',
    dcmp: 'Data Crawlers',
    os: 'Business Solutions',
    userManagement: 'User Management',
    reports: 'Reports',
  },
  EAP_BUSINESS_SOLUTION_OBJ: {
    lsp: 'Logistics Service Provider',
    mc: 'Model Carrier',
  },
  acceleratorSpecialCharacters: [
    '!',
    '@',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '+',
    '-',
    '=',
    '{',
    '}',
    '|',
    '\\',
    '[',
    ']',
    ':',
    ';',
    '"',
    "'",
    '<',
    '>',
    '.',
    '/',
  ],
  acceleratorStatus: {
    inputDataUpdated: 'inputDataUpdated',
    fetchedInputFile: 'Fetched input file from s3',
    triggeredAnalyticsApi: 'Triggered analytics Api',
    complete: 'Completed API run',
    exitWithErrors: 'exit with errors',
    errorStatus: 'error:',
  },
  accelerators: {
    acceptFileTypes: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  },
  formAllowedFileFormats: ['csv', 'xlsx'],
  sobAllowedFileFormats: ['csv'],
  formAllowedFileFormatsForMp3: ['mp3', 'wav', 'ogg', 'flac', 'opus'],
  textToSpeechFileFormats: ['txt'],
  bvitAllowedFileFormats: ['xlsx'],
  sobValidationMessages: {
    SELECT_ONE_MODE: 'Please select one mode',
    SELECT_AT_LEAST_ONE_TRANSPORTER: 'Select at least one transporter',
    SELECT_AT_LEAST_ONE_ZONE: 'Select at least one zone',
    SELECT_FROM_DATE: 'Please select from date',
    SELECT_TO_DATE: 'Please select to date',
  },
  modelCarrierValidationMessages: {
    SELECT_CARRIER_TYPE: 'Please select a carrier type',
    SELECT_AT_LEAST_ONE_CAR_MODEL: 'Select atleast one carModel',
    CATEGORY_MAX_ONE_CHARACTER: 'Category must be at most 1 characters',
    CATEGORY_REQUIRED: 'Category is required',
    MODEL_NAME_MAX_FIFTY_CHARACTER: 'Model Name must be at most 50 characters',
    MODEL_NAME_REQUIRED: 'Model Name is required',
    LOAD_FACTOR_MAX_TWO_DIGITS: 'Load Factor must be at most 2 digits',
    LOAD_FACTOR_REQUIRED: 'Load Factor is required',
    LENGTH_MAX_FOUR_DIGITS: 'Length must be at most 4 digits',
    LENGTH_REQUIRED: 'Length is required',
    WIDTH_MAX_FOUR_DIGITS: 'Width must be at most 4 digits',
    WIDTH_REQUIRED: 'Width is required',
    HEIGHT_MAX_FOUR_DIGITS: 'Height must be at most 4 digits',
    HEIGHT_REQUIRED: 'Height is required',
  },
  VEHICLE: 'vehicle',
  graphType : {
    BAR_GRAPH: 'Bar Graph',
    LINE_GRAPH: 'Line Graph',
    SCATTER_PLOT: 'Scatter plot'
  },
  VEHICLE_TYPE: { FF: 'FF', FR: 'FR'},
  VEHICLE_MODE: { Two : '2WD', Four: '4WD'},
  VEHICLE_FF_2WD: ['FL Torque', 'FR Torque', 'Total Torque'],
  VEHICLE_FR_2WD: ['Prop-1 Torque', 'FR Total Torque']
};
export default consts;
