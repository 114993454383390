import React, { useState } from 'react';
import TabComponentView from './TabComponent.view';

export default function TabComponent({ tabs }) {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
      setActiveTab(index); 
    };

    return(
        <TabComponentView tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
    )
}