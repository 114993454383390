import React from 'react';
import PropTypes from 'prop-types';

//app
import * as utils from 'utils';
import { ReactComponent as UploadCloud } from 'assets/svg/uploadCloud.svg';
import { consts, useMedia } from 'globalsData';
import { BootstrapTooltip } from 'components';
import '../../Pages/Home/Home.css';

// mui
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  Slider,
  Checkbox,
  ListItemText,
  FormHelperText,
  TextareaAutosize,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

AddEditEDAFormView.propTypes = {
  fields: PropTypes.array.isRequired,
  file: PropTypes.any,
  isInValidFileFormat: PropTypes.bool.isRequired,
  isInValidFileSize: PropTypes.bool.isRequired,
  watchLearningType: PropTypes.string,
  isLastProcessDataInprogress: PropTypes.bool.isRequired,
  targetVarableColumns: PropTypes.array.isRequired,
  featureCompColumns: PropTypes.array.isRequired,
  missingValuePercentageMarks: PropTypes.array.isRequired,
  labelEncodingMarks: PropTypes.array.isRequired,
  fileInput: PropTypes.object.isRequired,
  columnnError: PropTypes.bool.isRequired,
  learningTypeList: PropTypes.array.isRequired,
  isNoFileUploaded: PropTypes.bool.isRequired,
  formProps: PropTypes.object.isRequired,
  constantValue: PropTypes.any.isRequired,
  specialCharacters: PropTypes.array.isRequired,
  specialCharacterList: PropTypes.array.isRequired,
  labelEncodingThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hypothesisTestingList: PropTypes.array.isRequired,
  missingValuePercentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  missingValueImputationMethodList: PropTypes.array.isRequired,
  missingValueImputationMethodValue: PropTypes.any.isRequired,
  lastProcessedData: PropTypes.object,
  fileNameFromApi: PropTypes.string,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    handleConstantValue: PropTypes.func.isRequired,
    viewLastProcessData: PropTypes.func.isRequired,
  }),
  getRootProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  isDragActive: PropTypes.bool.isRequired,
  acceptedFiles: PropTypes.array.isRequired,
};

export function AddEditEDAFormView({
  fields,
  formProps,
  file,
  isInValidFileFormat,
  isInValidFileSize,
  watchLearningType,
  isLastProcessDataInprogress,
  fileInput,
  targetVarableColumns,
  featureCompColumns,
  columnnError,
  learningTypeList,
  isNoFileUploaded,
  constantValue,
  specialCharacters,
  specialCharacterList,
  labelEncodingThreshold,
  hypothesisTestingList,
  missingValuePercentage,
  missingValueImputationMethodList,
  missingValueImputationMethodValue,
  lastProcessedData,
  handlers,
  fileNameFromApi,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
  acceptedFiles,
}) {
  const { tablet, desktop } = useMedia();
  const { register, handleSubmit, errors } = formProps;
  const editFormOverFlow = fileNameFromApi ? { overflowX: 'hidden', overflowY: 'auto' } : {};
  const desktopFileTitleEllipsis = desktop ? 35 : 60;
  const fileTitleEllipsis = tablet ? 50 : desktopFileTitleEllipsis;

  return (
    <>
    {!fileNameFromApi &&
    <Box sx={{ backgroundColor: '#D6E7FB', borderRadius: '18px', marginBottom: '10px', padding: '15px', textAlign: 'left', fontSize: '14px' }}>
      <Typography fontSize={14} textAlign="left" pb={0.5} >
        Data Guidelines
      </Typography>
      <ul style={{ margin: '0', paddingLeft: '30px' }}>
        <li>Ensure your dataset is free from Primary Key or ID variables before uploading. </li>
        <li>Remove irrelevant variables before uploading.</li>
      </ul>
    </Box>}
    <Box sx={{ backgroundColor: '#fff', borderRadius: '18px', boxShadow: '0px 2px 16px 2px rgb(133 133 133 / 25%)', ...editFormOverFlow }}>
      <Box px={4} py={2}>
        <Box
          id="eda-form"
          mt={2}
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(handlers.onSubmit)}
          sx={fileNameFromApi ? {} : { overflowY: 'auto', overflow: 'auto' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box id="upoadInputFile">
                  <Typography fontSize={14} textAlign="left" pb={0.5} >
                    {file || fileNameFromApi ? 'Input Data' : 'Upload Input Data'}
                  </Typography>
                <Box border="1px dashed #cfcaca" px={2} py={0.5} borderRadius="6px">
                  {!!fileNameFromApi && (
                    <BootstrapTooltip title={fileNameFromApi?.length > fileTitleEllipsis ? fileNameFromApi : ''}>
                      <Typography fontSize={14}>{utils.app.getEllipsisString(fileNameFromApi, fileTitleEllipsis)}</Typography>
                    </BootstrapTooltip>
                  )}

                  {!fileNameFromApi && !file?.name && (
                    <section className="container">
                      <div style={{ padding: '16px' }} {...getRootProps({ className: 'dropzone' })}>
                        <Box>
                          <Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            <UploadCloud width={45} height={45} />
                          </Box>
                          <Box sx={{ display: 'inline-block', width: 'calc(100% - 15%)', verticalAlign: 'middle', px: 2 }}>
                            <Box>
                              <input {...getInputProps()} />
                              <Box sx={{ textAlign: 'left' }}>Select a file or drag and drop here</Box>
                            </Box>
                            {!fileNameFromApi && (
                              <Typography fontSize={14} variant="body1" mt={0.5} textAlign="left" color="#a1a1a1">
                                Files Supported: CSV, XLSX, Maximum Size: {consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </div>
                    </section>
                  )}

                  {/* {!fileNameFromApi && file?.name && ( */}
                  {!fileNameFromApi && acceptedFiles[0]?.name && file !== null ? (
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                      <Grid item>
                        <BootstrapTooltip title={acceptedFiles[0]?.name?.length > fileTitleEllipsis ? acceptedFiles[0]?.name : ''}>
                          <Typography fontSize={14}>{utils.app.getEllipsisString(acceptedFiles[0]?.name, fileTitleEllipsis)}</Typography>
                        </BootstrapTooltip>
                      </Grid>
                      <Grid item>
                        <IconButton
                          color="inherit"
                          aria-label="delete file"
                          onClick={handlers.onDelete}
                          edge="start"
                          size="small"
                          sx={{
                            marginLeft: 1,
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Box>
                {/* shifting validation messages here starts */}
                <Box>
                  <Box>
                    {isInValidFileFormat && (
                      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
                        File Format should be (.csv or .xslx) and should not contains multiple (.) dots in file name
                      </FormHelperText>
                    )}
                    {isInValidFileSize && (
                      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
                        File Size must be less than {consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
                {/* shifting validation messages here ends */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="learningType">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography fontSize={14} textAlign="left" pb={0.5} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Learning Type
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.LEARNING_TYPE} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.learningType?.message}>
                  <Select
                    {...register('learningType')}
                    {...utils.form.getFieldProp(fields, 'learningType')}
                    sx={{ border: '1px solid #e5e5ed', textAlign: 'left', color: '#768396', fontSize: '14px', p: 0.5 }}
                  >
                    {learningTypeList.map((lt) => (
                      <MenuItem key={lt.value} value={lt.value}>
                        {lt.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.learningType?.message && (
                    <FormHelperText sx={{ fontSize: '14px', marginLeft: '0' }}>{errors.learningType.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="targetVariable">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography fontSize={14} textAlign="left" pb={0.5} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    {`Target Variable ${watchLearningType?.toLowerCase() === 'classification' ? '(Categorical Feature)' : '(Numerical Feature)'}`}
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px:'4px', cursor: 'pointer' }}>
                    <BootstrapTooltip title={`${watchLearningType?.toLowerCase() === 'classification' ? consts.TOOLTIP.TARGET_VARIABLE_CATEGORICAL : consts.TOOLTIP.TARGET_VARIABLE_NUMERICAL}`} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>

                <FormControl sx={{ width: '100%' }} error={!!errors?.targetVarialble?.message}>
                  {fileNameFromApi ? (
                    <TextField
                      sx={{ width: '100%' }}
                      {...register('targetVariable')}
                      {...utils.form.getFieldProp(fields, 'targetVariable')}
                    />
                  ) : (
                    <>
                      <Select {...register('targetVariable')} {...utils.form.getFieldProp(fields, 'targetVariable')}>
                        {targetVarableColumns?.map((header) => (
                          <MenuItem value={header.name} key={header.key}>
                            {header.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!errors?.targetVariable?.message && (
                        <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                          {errors.targetVariable.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="featureComparison">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    {`Feature for Comparison ${watchLearningType?.toLowerCase() === 'classification' ? '(Numerical Feature)' : '(Categorical Feature)'}`}
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: '4px', cursor: 'pointer' }}>
                    <BootstrapTooltip title={`${watchLearningType?.toLowerCase() === 'classification' ? consts.TOOLTIP.FEATURE_FOR_COMPARISION_NUMERICAL : '(Categorical Feature)'}`} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.featureComparison?.message}>
                  {fileNameFromApi ? (
                    <TextField
                      sx={{ width: '100%' }}
                      {...register('featureComparison')}
                      {...utils.form.getFieldProp(fields, 'featureComparison')}
                    />
                  ) : (
                    <>
                      <Select {...register('featureComparison')} {...utils.form.getFieldProp(fields, 'featureComparison')}>
                        {featureCompColumns?.map((header) => (
                          <MenuItem value={header.name} key={header.key}>
                            {header.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!errors?.featureComparison?.message && (
                        <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                          {errors.featureComparison.message}
                        </FormHelperText>
                      )}
                      {columnnError && (
                        <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                          Feature for comparision value should not be same as Target Variable
                        </FormHelperText>
                      )}
                    </>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="hypothesisTesting">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography fontSize={14} textAlign="left" pb={0.5} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Hypothesis Testing
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.HYPOTHESIS_TESTING} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '98%', marginLeft: '2px' }}>
                  <Select
                    {...register('hypothesisTesting')}
                    {...utils.form.getFieldProp(fields, 'hypothesisTesting')}
                    sx={{ border: '1px solid #e5e5ed', textAlign: 'left', color: '#768396', fontSize: '14px', p: 0.5 }}
                  >
                    {hypothesisTestingList?.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.hypothesisTesting?.message && (
                    <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors.hypothesisTesting.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Box mt={2} id="hyperParameters">
            <Typography sx={{ py: 1 }} fontSize={14} fontWeight={600} textAlign="left" color="#5a5a5a">
              Data Cleaning Parameter
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box id="valuePer">
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography fontSize={14} textAlign="left" pb={0.5} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                      Missing Value Percentage
                    </Typography>
                    <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                      <BootstrapTooltip title={consts.TOOLTIP.DATA_CLEANING_PARAMETER_PERCENTAGE} placement={'bottom'}>
                        <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                      </BootstrapTooltip>
                    </Box>
                  </Box>
                  <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '8px' }}>
                    <Grid item xs={11}>
                      <Slider {...register('valuePer')} {...utils.form.getFieldProp(fields, 'valuePer')} />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" pt={0.5}>
                        {missingValuePercentage}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box id="missingValImpMethod">
                <Box sx={{ textAlign: 'left' }}>
                    <Typography fontSize={14} textAlign="left" pb={0.5} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Missing Value Imputation Method
                    </Typography>
                    <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                      <BootstrapTooltip title={consts.TOOLTIP.DATA_CLEANING_PARAMETER_IMPUTATION} placement={'bottom'}>
                        <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                      </BootstrapTooltip>
                    </Box>
                  </Box>
                  <FormControl sx={{ width: '100%' }} error={!!errors?.missingValueImputationMethod?.message}>
                    <Select {...register('missingValImpMethod')} {...utils.form.getFieldProp(fields, 'missingValImpMethod')}>
                      {missingValueImputationMethodList.map((method) => (
                        <MenuItem value={method.value} key={method.value}>
                          {method.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors?.missingValImpMethod?.message && (
                      <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors?.missingValImpMethod?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              {missingValueImputationMethodValue === 'constantValue' && (
                <Grid item xs={6}>
                  <Box py={2} id="constantValue">
                    <TextField
                      id="missing-value-imputation-method-value"
                      placeholder="Enter constant value to impute"
                      variant="outlined"
                      value={constantValue}
                      type="number"
                      size="small"
                      sx={{ width: '100%' }}
                      onChange={(e) => handlers.handleConstantValue(e)}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={6}>
                <Box id="labelEncThreshold">
                <Box sx={{ textAlign: 'left' }}>
                    <Typography fontSize={14} textAlign="left" pb={0.5} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Label Encoding Threshold
                    </Typography>
                    <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                      <BootstrapTooltip title={consts.TOOLTIP.DATA_CLEANING_PARAMETER_THRESHOLD} placement={'bottom'}>
                        <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                      </BootstrapTooltip>
                    </Box>
                  </Box>
                  <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '8px' }}>
                    <Grid item xs={11}>
                      <Slider {...register('labelEncThreshold')} {...utils.form.getFieldProp(fields, 'labelEncThreshold')} />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" pt={0.5}>
                        {labelEncodingThreshold}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box id="specialCharacterReplace">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Special Character Replace
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                      <BootstrapTooltip title={consts.TOOLTIP.DATA_CLEANING_PARAMETER_REPLACE} placement={'bottom'}>
                        <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                      </BootstrapTooltip>
                    </Box>
                  </Box>
                  <FormControl sx={{ width: '100%' }} error={!!errors?.specialCharacterReplace?.message}>
                    <Select {...register('specialCharacterReplace')} {...utils.form.getFieldProp(fields, 'specialCharacterReplace')}>
                      {specialCharacters.map((sc) => (
                        <MenuItem key={sc} value={sc}>
                          <Checkbox checked={specialCharacterList.indexOf(sc) > -1} />
                          <ListItemText primary={sc} />
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors?.specialCharacterReplace?.message && (
                      <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors?.specialCharacterReplace?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box id="justificationRemarks">
                  <Typography textAlign="left" pb={0.5} fontSize={14}>
                    Justification Remarks
                  </Typography>
                  <FormControl sx={{ width: '100%' }} error={!!errors?.justificationRemarks?.message}>
                    <TextareaAutosize
                      {...register('justificationRemarks')}
                      {...utils.form.getFieldProp(fields, 'justificationRemarks')}
                      className="textAreaStyles"
                    />

                    {!!errors?.justificationRemarks?.message && (
                      <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors.justificationRemarks.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box id="actionsButtons" sx={{ background: '#d2d2d2' }} px={4} py={2}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                borderRadius: '99px',
                p: '0.4rem 1.5rem',
              }}
              type="submit"
              disabled={isLastProcessDataInprogress}
              onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
            >
              {`${fileNameFromApi ? 'Update Analysis' : 'Analyze'}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
    </>
  );
}
