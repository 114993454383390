import { api } from 'utils';
import { removeLoader, snackbarNotification } from 'stores';
import { consts } from 'globalsData';

export const getInputTextFileDetails = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getInputTextFileDetailsRequest(reqParams));

  const { type } = reqParams;

  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: type === consts.ACCELERATORS.SPEECH_TO_TEXT.toLocaleLowerCase() ? 'download-output-text' : 'download-input-text',
      params: reqParams,
    })
    .then((res) => {
      dispatch(getInputTextFileDetailsSuccess(res?.data || res?.data));
      return res;
    })
    .catch((error) => {
      dispatch(getInputTextFileDetailsError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getInputTextFileDetails'));
    });
};

export const getInputTextFileDetailsRequest = (params) => {
  return {
    type: 'GET_INPUT_TEXT_FILE_DETAILS_REQUEST',
    payload: params,
  };
};

export const getInputTextFileDetailsSuccess = (data) => {
  return {
    type: 'GET_INPUT_TEXT_FILE_DETAILS_SUCCESS',
    payload: data,
  };
};

export const getInputTextFileDetailsError = (error) => {
  return {
    type: 'GET_INPUT_TEXT_FILE_DETAILS_FAILURE',
    payload: error,
  };
};
