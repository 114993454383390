import React from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Typography, Tooltip as MuiToolTip, List, ListItem } from '@mui/material';

TileTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  openToolTip: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  highlights: PropTypes.array,
  onCloseToolTip: PropTypes.func.isRequired,
};

export default function TileTooltip({ children, openToolTip, title, description, highlights, onCloseToolTip }) {
  return (
    <MuiToolTip
      PopperProps={{
        disablePortal: true,
      }}
      arrow
      placement="top"
      sx={{
        '+div': { cursor: 'default' },
        '+div > div': {
          borderRadius: '12px',
          backgroundColor: '#7a7c7e',
        },
      }}
      onClose={onCloseToolTip}
      open={openToolTip}
      disableFocusListener
      disableHoverListener
      title={<TooltipContent title={title} description={description} highlights={highlights} />}
    >
      {children}
    </MuiToolTip>
  );
}

function TooltipContent({ title, description, highlights }) {
  return (
    <Box textAlign="left" p={2}>
      <Typography fontSize="18px" fontWeight="600" pb={1}>
        {title}
      </Typography>
      {description && <Typography variant="body2">{description}</Typography>}
      {highlights && (
        <>
          <Typography variant="body2" fontWeight="600" pt={2}>
            Highlights:
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            {highlights?.map((item, index) => (
              <ListItem key={index} sx={{ display: 'list-item', padding: '0' }}>
                <Typography fontSize="14px">{item}</Typography>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
}
