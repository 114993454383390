import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const updateWebsite = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(updateWebsiteRequest());
  dispatch(addLoader('updateWebsite'));

  const { catId = '', websiteName, websiteDescription, websiteUrl, categoryList, crawlerScript, websiteId } = reqParams;

  const data = {
    websiteName,
    description: websiteDescription,
    websiteUrl,
    categoryList,
    ...(crawlerScript && { crawlerScript }),
  };

  return api
    .multipartPost({
      endpoint: endpoint.websiteService,
      path: `v1/websiteMgmt/update/${websiteId}`,
      data,
    })
    .then((res) => {
      dispatch(updateWebsiteSuccess({ id: catId, data: res?.data?.data }));
      dispatch(snackbarNotification(res?.data?.message, 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(updateWebsiteError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('updateWebsite'));
    });
};

export const updateWebsiteRequest = () => {
  return {
    type: 'UPDATE_WEBSITE_REQUEST',
  };
};

export const updateWebsiteSuccess = (data) => {
  return {
    type: 'UPDATE_WEBSITE_SUCCESS',
    payload: data,
  };
};

export const updateWebsiteError = (error) => {
  return {
    type: 'UPDATE_WEBSITE_FAILURE',
    payload: error,
  };
};
