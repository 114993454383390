import React, { useEffect } from 'react';
import ModelCarrierDashboardView from './ModelCarrierDashboard.view';
import * as utils from 'utils';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {consts} from 'globalsData';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

// app
import {  getModelCarrierTableData } from 'stores';

const ModelCarrierDashboard = () => {
  const mcTableData = useSelector((state) => get(state, 'modelCarrier.mcTableData', []));
  const carModelList = useSelector((state) => get(state, 'modelCarrier.carModelList', []));
  const carrierList = useSelector((state) => get(state, 'modelCarrier.carrierList', []));
  const dispatch = useDispatch();

  const tableData = [
    {
      combString: ['A', 'A', 'A', 'A', 'B', 'B', 'B', 'B', 'B', 'B'],
      position: {
        upperDeck: ['A', 'A', 'A', 'A', 'B'],
        lowerDeck: ['B', 'B', 'B', 'B', 'B'],
      },
      carsModel: [
        {
          carModelName: 'Alto',
          category: 'A',
          total: 4,
        },
        {
          carModelName: 'Swift',
          category: 'B',
          total: 6,
        },
      ],
      tripCode: 'TR',
      type: 'Existing',
      group: [
        { category: 'A', total: 4 },
        { category: 'B', total: 6 },
      ],
      loadFactor: 5,
    },
    {
      combString: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
      position: {
        upperDeck: ['A', 'B', 'C', 'D', 'E'],
        lowerDeck: ['F', 'G', 'H', 'I', 'J'],
      },
      carsModel: [
        {
          carModelName: 'Alto',
          category: 'A',
          total: 1,
        },
        {
          carModelName: 'Swift',
          category: 'B',
          total: 1,
        },
        {
          carModelName: 'Ertiga',
          category: 'C',
          total: 1,
        },
        {
          carModelName: 'Celerio',
          category: 'D',
          total: 1,
        },
        {
          carModelName: 'Tour-s',
          category: 'E',
          total: 1,
        },
        {
          carModelName: 'Dzire',
          category: 'F',
          total: 1,
        },
        {
          carModelName: 'Brezza',
          category: 'G',
          total: 1,
        },
        {
          carModelName: 'Baleno',
          category: 'H',
          total: 1,
        },
        {
          carModelName: 'Ciaz',
          category: 'I',
          total: 1,
        },
        {
          carModelName: 'Glanza',
          category: 'I',
          total: 1,
        },
      ],
      tripCode: 'TR',
      type: 'New',
      group: [
        { category: 'A', total: 1 },
        { category: 'B', total: 1 },
        { category: 'C', total: 1 },
        { category: 'D', total: 1 },
        { category: 'E', total: 1 },
        { category: 'F', total: 1 },
        { category: 'G', total: 1 },
        { category: 'H', total: 1 },
        { category: 'I', total: 1 },
        { category: 'J', total: 1 },
      ],
      loadFactor: 5,
    },
  ];

    const carModels = utils.generic.getArrayOfObjects(carModelList ?? [], 'carModel');
    const carrierTypes = utils.generic.getArrayOfObjects(carrierList ?? [], 'carrier');

  const fields = [
    {
      name: 'carrierType',
      id: 'carrierType',
      size: 'small',
      required: true,
      defaultValue: '',
      validation: Yup.string().required(consts.modelCarrierValidationMessages.SELECT_CARRIER_TYPE),
      sx: { textAlign: 'left' },
    },
    {
      name: 'carModel',
      label: 'Car Model',
      id: 'carModel',
      size: 'small',
      defaultValue: [],
      multiple: true,
      optionLabel: 'carModel',
      filterSelectedOptions: true,
      getOptionLabel: (option) => option.carModel,
      validation: Yup.array().min(1, consts.modelCarrierValidationMessages.SELECT_AT_LEAST_ONE_CAR_MODEL),
    },
  ];
  // const carrierTypes = ['TK4', 'TR8'];
  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);
  const carModelsOptions = [{ carModel: 'Swift' }, { carModel: 'Alto' }];

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const onSubmit = (formData) => {
    const payload = {};
    payload.carrier_list = [formData?.carrierType];
    payload.model_list = utils.generic.getArrayOfStrings(formData?.carModel);
    dispatch(getModelCarrierTableData(payload));
  };
 
  return (
    <ModelCarrierDashboardView
      tableData={mcTableData}
      carModelList={carModelList}
      fields={fields}
      carrierTypes={carrierTypes}
      carModels={carModels}
      carModelsOptions={carModelsOptions}
      formProps={{ errors, register, handleSubmit, control, setValue }}
      handlers={{
        onSubmit,
      }}
    />
  );
};

export default ModelCarrierDashboard;
