import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox } from '@mui/material';

import './BVITClutchSpecifications.css';

const BVITClutchSpecificationsView = ({ filterDetails, handleFiltersChange, clutchSpecifications,
  setClutchSpecifications }) => {
  const filterData = filterDetails?.clutch_specifications;
  return (
    <div className='tab-clutch'>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Clutch Maker</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Clutch Maker"
          size="small"
          value={clutchSpecifications.clutch_maker || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, clutch_maker: e.target.value}))}
          onBlur={(e) => {
            const newClutchMaker = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newClutchMaker.includes(data["clutch maker"]));
            handleFiltersChange(details);
          }}>
          {filterData?.clutch_maker?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.clutch_maker.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Clutch Release System Maker</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Clutch Release System Maker"
          size="small"
          value={clutchSpecifications.clutch_release_system_maker || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, clutch_release_system_maker: e.target.value}))}
          onBlur={(e) => {
            const newClutchReleaseSystemMaker = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newClutchReleaseSystemMaker.includes(data["clutch release system maker"]));
            handleFiltersChange(details);
          }}>
           {filterData?.clutch_release_system_maker?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.clutch_release_system_maker.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Clutch Size</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Clutch Size"
          size="small"
          value={clutchSpecifications.clutch_size || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, clutch_size: e.target.value}))}
          onBlur={(e) => {
            const newClutchSize = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newClutchSize.includes(data["Cltuch Size"]));
            handleFiltersChange(details);
          }}>
           {filterData?.clutch_size?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.clutch_size.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Disc Material</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Disc Material"
          size="small"
          value={clutchSpecifications.disc_material || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, disc_material: e.target.value}))}
          onBlur={(e) => {
            const newDiscMaterial = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newDiscMaterial.includes(data["Disc Material"]));
            handleFiltersChange(details);
          }}>
           {filterData?.disc_material?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.disc_material.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Clamp Load Maximum</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Clamp Load Maximum"
          size="small"
          value={clutchSpecifications.clamp_load_maximum || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, clamp_load_maximum: e.target.value}))}
          onBlur={(e) => {
            const newClampMaximum = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newClampMaximum.includes(data["Clamp Load(N) Maximum"]));
            handleFiltersChange(details);
          }}>
         {filterData?.clamp_load_maximum?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.clamp_load_maximum.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Clamp Load Measured</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Clamp Load Measured"
          size="small"
          value={clutchSpecifications.clamp_load_measured || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, clamp_load_measured: e.target.value}))}
          onBlur={(e) => {
            const newClampMeasured = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newClampMeasured.includes(data["Clamp Load(N) Measured"]));
            handleFiltersChange(details);
          }}>
           {filterData?.clamp_load_measured?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.clamp_load_measured.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Clamp Load Minimum</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Clamp Load Minimum"
          size="small"
          value={clutchSpecifications.clamp_load_minimum || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, clamp_load_minimum: e.target.value}))}
          onBlur={(e) => {
            const newClampMinimum = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newClampMinimum.includes(data["Clamp Load(N) Minimum"]));
            handleFiltersChange(details);
          }}>
           {filterData?.clamp_load_minimum?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.clamp_load_minimum.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Clutch Release System</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Clutch Release System"
          size="small"
          value={clutchSpecifications.clutch_release_system || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, clutch_release_system: e.target.value}))}
          onBlur={(e) => {
            const newClutchReleaseSystem = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newClutchReleaseSystem.includes(data["Clutch Release system"]));
            handleFiltersChange(details);
          }}>
         {filterData?.clutch_release_system?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.clutch_release_system.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>PTL/Size</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="PTL/Size"
          size="small"
          value={clutchSpecifications.ptl_size || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, ptl_size: e.target.value}))}
          onBlur={(e) => {
            const newPtlSize = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newPtlSize.includes(data["PTL / Size(mm)"]));
            handleFiltersChange(details);
          }}>
           {filterData?.ptl_size?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.ptl_size.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Stiffness Drive</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Stiffness Drive"
          size="small"
          value={clutchSpecifications.stiffness_drive || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, stiffness_drive: e.target.value}))}
          onBlur={(e) => {
            const newStiffnessDrive = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newStiffnessDrive.includes(data["Stiffness Drive (N-m/°)"]));
            handleFiltersChange(details);
          }}>
           {filterData?.stiffness_drive?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.stiffness_drive.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Stiffness Coast</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Stiffness Coast"
          size="small"
          value={clutchSpecifications.stiffness_coast || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, stiffness_coast: e.target.value}))}
          onBlur={(e) => {
            const newStiffnessCoast = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newStiffnessCoast.includes(data["Stiffness Coast (N-m/°)"]));
            handleFiltersChange(details);
          }}>
           {filterData?.stiffness_coast?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.stiffness_coast.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Cushion</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Cushion"
          size="small"
          value={clutchSpecifications.cushion || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, cushion: e.target.value}))}
          onBlur={(e) => {
            const newCushion = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newCushion.includes(data["Cushion"]));
            handleFiltersChange(details);
          }}>
          {filterData?.cushion?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.cushion.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>CoF</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="CoF"
          size="small"
          value={clutchSpecifications.cof || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => setClutchSpecifications(prevState => ({...prevState, cof: e.target.value}))}
          onBlur={(e) => {
            const newCof = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newCof.includes(data["CoF"]));
            handleFiltersChange(details);
          }}>
          {filterData?.cof?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={clutchSpecifications.cof.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
    </div>
  );
};

export default BVITClutchSpecificationsView;
