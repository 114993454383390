import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getFiltersData = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getFiltersDataRequest());
  dispatch(addLoader({ key: 'getFiltersData', message: 'Fetching filters data...' }));

  return api
    .get({
      endpoint: endpoint.bvitService,
      path: 'getFiltersData'
    })
    .then((res) => {
      dispatch(getFiltersDataSuccess(res?.data));
      return res;
    })
    .catch((error) => {
      dispatch(getFiltersDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getFiltersData'));
    });
};

export const getFiltersDataRequest = () => {
  return {
    type: 'GET_FILTERS_DATA_REQUEST'
  };
};

export const getFiltersDataSuccess = (data) => {
  return {
    type: 'GET_FILTERS_DATA_SUCCESS',
    payload: data,
  };
};

export const getFiltersDataError = (error) => {
  return {
    type: 'GET_FILTERS_DATA_FAILURE',
    payload: error,
  };
};
