import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const accessRequest = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(accessRequestRequest());
  dispatch(addLoader('accessRequest'));

  return api
    .get({
      endpoint: endpoint.requestAccessService,
      path: 'analytics-studio-access',
    })
    .then(async (res) => {
      if (res?.data) {
        const url = res?.data;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', `analyticsStudio.xlxs`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        dispatch(snackbarNotification('Url not found', 'error'));
      }
    })
    .catch((error) => {
      dispatch(accessRequestError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('accessRequest'));
    });
};

export const accessRequestRequest = () => {
  return {
    type: 'ACCESS_REQUEST_REQUEST',
  };
};

export const accessRequestSuccess = (data) => {
  return {
    type: 'ACCESS_REQUEST_SUCCESS',
    payload: data,
  };
};

export const accessRequestError = (error) => {
  return {
    type: 'ACCESS_REQUEST_FAILURE',
    payload: error,
  };
};
