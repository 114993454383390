import { get } from 'lodash';
import { app } from 'utils';

const initialState = {
  categoryList: [],
  otherSolutionsList: [],
};

const categoriesReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_CATEGORY_LIST_SUCCESS':
      return {
        ...state,
        categoryList: [...action.payload],
        categoryListOriginal: [...action.payload],
      };

    case 'CLEAR_CATEGORY_LIST':
      return {
        ...state,
        categoryList: [],
      };

    case 'UPDATE_CATEGORY_SUCCESS':
      const updatedCatList = get(state, 'categoryList', [])?.map((cat) => {
        if (cat.categoryId?.toString()?.toLowerCase() === action.payload.categoryId?.toString()?.toLowerCase()) {
          return { ...cat, ...action.payload };
        }
        return cat;
      });

      return {
        ...state,
        categoryList: [...updatedCatList],
        categoryListOriginal: [...updatedCatList],
      };

    case 'SEARCH_CATEGORIES':
      const searchRegex = new RegExp(app.escapeRegExp(action.payload), 'i');
      const filteredRows = get(state, 'categoryListOriginal', [])?.filter((row) => {
        return Object.keys(row)?.some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });

      return {
        ...state,
        categoryList: [...filteredRows],
      };

    case 'CLEAR_SEARCH_CATEGORIES':
      return {
        ...state,
        categoryList: [...state.categoryListOriginal],
      };

    case 'GET_OTHER_SOLUTIONS_LIST_SUCCESS':
      return {
        ...state,
        otherSolutionsList: [...action.payload],
      };

    case 'CLEAR_OTHER_SOLUTIONS_LIST':
      return {
        ...state,
        otherSolutionsList: [],
      };
    default:
      return state;
  }
};

export default categoriesReducers;
