import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0177FB',
      backgroundColor: '#fafafa',
      sidebarBackground: '#0177FB',
      titleColor: '#33378f',
      textColor: '#66696b',
      white: '#fff',
    },
    secondary: {
      main: '#54c8f0',
    },
    otherColor: {
      main: '#999',
      errorText: '#D32F2F',
      blue10: '#f0f1f7',
      blue30: '#9C9ECA',
      blue50: '#6366AD',
      blue70: '#33378F',
      blue90: '#0177FB',
      blue95: '#0F1362',
      grey90: '#1E1F20',
      textGrey1: '#343536',
      textGrey2: '#66696B',
      textGrey3: '#9EA1A7',
      silver30: '#DADCE0',
      silver50: '#CED0D4',
      silver75: '#7A7C7E',
      silver90: '#97999B',
      solutionTextColor: '#000000',
    },
  },
});
