import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const uploadCarrier = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  const { file } = reqParams;
  dispatch(uploadCarrierRequest(reqParams));
  dispatch(addLoader({ key: 'uploadCarrier', message: 'Uploading File...' }));

  return api
    .multipartPut({
      endpoint: endpoint.modelCarrier,
      path:'Carrier/Upload',
      data: {file},
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(uploadCarrierError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('uploadCarrier'));
    });
};

export const uploadCarrierRequest = (params) => {
  return {
    type: 'UPLOAD_CARRRIER_REQUEST',
    payload: params,
  };
};

export const uploadCarrierSuccess = (data) => {
  return {
    type: 'UPLOAD_CARRRIER_SUCCESS',
    payload: data,
  };
};

export const uploadCarrierError = (error) => {
  return {
    type: 'UPLOAD_CARRRIER_FAILURE',
    payload: error,
  };
};
