import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const addCategory = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
  dispatch(addCategoryRequest(params));
  dispatch(addLoader('addCategory'));

  const data = {
    categoryName: params?.categoryName,
    powerBIURL: params?.powerBiUrl || '',
  };

  return api
    .post({
      endpoint: endpoint.categoryService,
      path: 'v1/category',
      data,
    })
    .then((res) => {
      dispatch(addCategorySuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message, 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(addCategoryError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('addCategory'));
    });
};

export const addCategoryRequest = (params) => {
  return {
    type: 'ADD_CATEGORY_REQUEST',
    payload: params,
  };
};

export const addCategorySuccess = (data) => {
  return {
    type: 'ADD_CATEGORY_SUCCESS',
    payload: data,
  };
};

export const addCategoryError = (error) => {
  return {
    type: 'ADD_CATEGORY_FAILURE',
    payload: error,
  };
};
