const timeSeriesStaticData = {
    algorithms: [
      { value: 'exponentialSmoothing', label: 'Exponential Smoothing' },
      { value: 'arima', label: 'ARIMA' },
      { value: 'prophet', label: 'Prophet' },
      { value: 'neuralProphet', label: 'Neural Prophet' },
      { value: 'all', label: 'All' },
    ],
    arimaSeasonal: [
      {
        value: true,
        label: 'True',
      },
      {
        value: false,
        label: 'False',
      },
    ],
    dayFirst: [
      {
        value: true,
        label: 'True',
      },
      {
        value: false,
        label: 'False',
      }
    ],
    dampedTrend: [
      {
        value: true,
        label: 'True',
      },
      {
        value: false,
        label: 'False',
      },
      {
        value: 'none',
        label: 'None',
      },
    ],
    trend: [
      { value: 'add', label: 'Add' },
      { value: 'mul', label: 'Mul' },
      { value: 'additive', label: 'Additive' },
      { value: 'multiplicative', label: 'Multiplicative' },
      { value: 'none', label: 'None' },
    ],
    exponentialSeasonal: [
      { value: 'add', label: 'Add' },
      { value: 'mul', label: 'Mul' },
      { value: 'additive', label: 'Additive' },
      { value: 'multiplicative', label: 'Multiplicative' },
      { value: 'none', label: 'None' },
    ],
    freq: [
      { value: 'D', label: 'D' },
      { value: 'W', label: 'W' },
      { value: 'M', label: 'M' },
      { value: 'A', label: 'A' },
      { value: 'Q', label: 'Q' },
      { value: 'none', label: 'None' },
    ],
    seasonalityMode: [
      { value: 'additive', label: 'Additive' },
      { value: 'multiplicative', label: 'Multiplicative' },
    ],
    seasonalPeriod: [
      { value: 'none', label: 'None' },
      { value: 'specificValue', label: 'Specify Value' },
    ],
    seasonality: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
      { value: 'auto', label: 'Auto' },
    ],
    prophetDailySeasonality:[
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
      { value: 'auto', label: 'Auto' },
      { value: 'test', label: 'Test' },
    ],
    rfMaxFeaturesList: [
      { value: 'auto', label: 'auto' },
      { value: 'sqrt', label: 'sqrt' },
      { value: 'log2', label: 'log2' },
    ],
    nEstimatorsMarks: [
      {
        value: 10,
        label: '10',
      },
      {
        value: 1000,
        label: '1000',
      },
    ],
  };
  
  export default timeSeriesStaticData;
  