/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import * as Yup from 'yup';
import * as utils from 'utils';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { TimeSeriesStaticData } from 'globalsData';

export default function Blank() {
  const fields = [
    {
      name: 'forecastCol',
      id: 'forecastCol',
      size: 'small',
      required: true,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'algorithm',
      id: 'algorithm',
      size: 'small',
      required: true,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    // trend and dmapedTrend is for exponential
    {
      name: 'trend',
      id: 'trend',
      size: 'small',
      required: true,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'dampedTrend',
      id: 'dampedTrend',
      size: 'small',
      required: true,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    // arimaSeasonal and seasonalDifferencing is for arima algo
    {
      name: 'arimaSeasonal',
      id: 'arimaSeasonal',
      size: 'small',
      required: true,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'seasonalDifferencing',
      id: 'seasonalDifferencing',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.001, max: 10, step: 0.01 } },
      required: true,
      defaultValue: 1,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.001, 'Min value can be 0.001')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
  ];
  const algorithms = TimeSeriesStaticData.algorithms;

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchAlgorithm = watch('algorithm');

  const renderHyperParameters = (algorithm) => algorithm === watchAlgorithm;

  const onSubmit = (formData) => {
    console.log(formData, 'formdata from blank component');
  }
  const onError = (error) => {
   console.log(error,'error from submit form')
  };
  return (
    <Box id="timeseries-form" mt={2} component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={6}>
        <Box id="algorithm">
          <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
            Algorithm
          </Typography>
          <FormControl sx={{ width: '100%' }} error={!!errors?.algorithm?.message}>
            <Select
              {...register('algorithm')}
              {...utils.form.getFieldProp(fields, 'algorithm')}
              sx={{ fontSize: '14px', textAlign: 'left' }}
            >
              {algorithms.map((method) => (
                <MenuItem value={method.value} key={method.value} sx={{ fontSize: '14px' }}>
                  {method.label}
                </MenuItem>
              ))}
            </Select>
            {!!errors?.algorithm?.message && (
              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                {errors?.algorithm?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box id="forecastCol">
          <Typography fontSize={14} textAlign="left" pb={0.5}>
            Forecast Variable
          </Typography>

          <FormControl sx={{ width: '100%' }} error={!!errors?.targetVarialble?.message}>
            <Select
              {...register('forecastCol')}
              {...utils.form.getFieldProp(fields, 'forecastCol')}
              sx={{ fontSize: '14px', textAlign: 'left' }}
            >
              <MenuItem value="forecastvalone" key="forecastvalone" sx={{ fontSize: '14px' }}>
                forecast val one
              </MenuItem>
              <MenuItem value="valtwo" key="valtwo" sx={{ fontSize: '14px' }}>
                forecast val two
              </MenuItem>
            </Select>
            {!!errors?.forecastCol?.message && (
              <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                {errors.forecastCol.message}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Box>
        <Typography>Model hyper parameters</Typography>
        {renderHyperParameters(algorithms[0].value) && (
          <Box>
            <Grid item xs={6}>
              <Box id="trend">
                <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                  Trend
                </Typography>
                <FormControl sx={{ width: '100%' }} error={!!errors?.trend?.message}>
                  <Select {...register('trend')} {...utils.form.getFieldProp(fields, 'trend')}>
                    <MenuItem value="trendValOne" key="trendValOne">
                      trendvalone
                    </MenuItem>
                    <MenuItem value="trendValTwo" key="trendValTwo">
                      trendvaltwo
                    </MenuItem>
                  </Select>
                  {!!errors?.trend?.message && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.trend?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="dampedTrend">
                <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                  dampedTrend
                </Typography>
                <FormControl sx={{ width: '100%' }} error={!!errors?.dampedTrend?.message}>
                  <Select {...register('dampedTrend')} {...utils.form.getFieldProp(fields, 'dampedTrend')}>
                    <MenuItem value="dampedValone" key="dampedValone">
                      dampedvalone
                    </MenuItem>
                    <MenuItem value="dampedValtwo" key="dampedValtwo">
                      dampedvalTwo
                    </MenuItem>
                  </Select>
                  {!!errors?.dampedTrend?.message && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.dampedTrend?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Box>
        )}
        {renderHyperParameters(algorithms[1].value) && (
          <Box>
            <Grid item xs={6}>
              <Box id="arimaSeasonal">
                <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                  Seasonal
                </Typography>
                <FormControl sx={{ width: '100%' }} error={!!errors?.arimaSeasonal?.message}>
                  <Select {...register('arimaSeasonal')} {...utils.form.getFieldProp(fields, 'arimaSeasonal')}>
                    <MenuItem value="seasonalValOne" key="seasonalValOne">
                      Seasonal val one
                    </MenuItem>
                    <MenuItem value="seasonalValTwo" key="seasonalValTwo">
                      Seasonal val two
                    </MenuItem>
                  </Select>
                  {!!errors?.arimaSeasonal?.message && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.arimaSeasonal?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="seasonalDifferencing">
                <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                  Seasonal Differencing
                </Typography>
                <TextField
                  {...register('seasonalDifferencing')}
                  {...utils.form.getFieldProp(fields, 'seasonalDifferencing')}
                  error={!!errors?.seasonalDifferencing?.message}
                />
                {!!errors?.seasonalDifferencing?.message && (
                  <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
                    {errors?.seasonalDifferencing?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
      <Button
        variant="contained"
        sx={{
          textTransform: 'none',
          borderRadius: '99px',
          p: '0.4rem 1.5rem',
        }}
        type="submit"
        onClick={handleSubmit(onSubmit, onError)}
      >
        submit
      </Button>
    </Box>
  );
}
