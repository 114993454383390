import getAlgorithmHyperParameters from 'globalsData/getAlgorithmHyperParameters/getAlgorithmHyperParameters';
import { consts } from 'globalsData';

const getClusteringPayload = (formData) => {
  if (!formData) return;
  const { algorithm, justificationRemarks,testSizeRatio,idCols } = formData;
  const hyperParameters = getAlgorithmHyperParameters(consts.CLUSTERING, formData);
  if (algorithm === 'agglomerativeClustering') {
    const { agglomative_n_cluster, agglomerativeMetricListAlgorithm, agglomerativeLinkageListAlgorithm } = hyperParameters;
    return {
      algorithm,
      justificationRemarks,
      testSizeRatio,
      idCols,
      modelHyperParameters: {
        n_clusters:agglomative_n_cluster,
        metric:agglomerativeMetricListAlgorithm,
        linkage:agglomerativeLinkageListAlgorithm,
      },
    };
  } else if (algorithm === 'kmeans') {
    const { kmeansInitListAlgorithm, kmeans_algorithm, tol, kmeans_n_cluster, } = hyperParameters;
    return {
      algorithm,
      justificationRemarks,
      idCols,
      modelHyperParameters: {
        n_clusters:kmeans_n_cluster,
        init:kmeansInitListAlgorithm,
        tol:tol,
        algorithm:kmeans_algorithm,
      },
    };
  } else if (algorithm === 'kmodes') {
    const { kmod_n_cluster, kmodesInitList } = hyperParameters;
    return {
      algorithm,
      justificationRemarks,
      idCols,
      modelHyperParameters: {
        n_clusters:kmod_n_cluster,
        init:kmodesInitList,
      },
    };
  } else {
    return {
      algorithm,
      idCols,
      modelHyperParameters:{},
      justificationRemarks,
    };
  }
};

export default getClusteringPayload;
