//svg images
import { ReactComponent as ASBP } from 'assets/svg/AutoStockBalancingPlatform_adobe_express.svg';
import { ReactComponent as DLP } from 'assets/svg/DataLakePlatform.svg';
import { ReactComponent as EnginePerformanceScreen } from 'assets/svg/EnginePerformanceScreen_adobe_express.svg';
import { ReactComponent as InboundLogisticsScreen } from 'assets/svg/InboundLogisticsScreen_adobe_express.svg';
import { ReactComponent as SparesPricingSystem } from 'assets/svg/SparesPricingSystem_adobe_express.svg';

import DataCrwalers from '../../assets/png/dataCrawler.png';
import EnginePerformanceTesting from '../../assets/png/newEnginePerformanceTesting.png';
import AutoStockBalancingPlatform from '../../assets/png/autoStockBalancingPlatform.png';
import InboundLogisticsPlatform from '../../assets/png/newInboundLogisticsPlatform.png';
import DataLakePlatform from '../../assets/png/newDataLakePlatform.png';
import LogisticsServiceProvider from '../../assets/png/newLogisticsServiceProvider.png';
import ModelCarrier from '../../assets/png/newModelCarrier.png';
import SparesPricingSystems from '../../assets/png/newSparesPricingSystem.png';
import EnginePerformanceTestingPDF from '../../assets/files/enginePerformanceTestingOptimization.pdf';
import SparesPricingSystemsPDF from '../../assets/files/sparesPricingRecomendation.pdf';
import InboundLogisticsPlatformPDF from '../../assets/files/inboundLogisticPlatform.pdf';
import DataLakePlatformPDF from '../../assets/files/inboundLogisticPlatform.pdf';
import LogisticsServiceProviderPDF from '../../assets/files/logisticServiceProvider.pdf';
import ModelCarrierPDF from '../../assets/files/modelCarrierCombinationAnalytics.pdf';
import PriceBenchMarkingPDF from '../../assets/files/capitalProcurementPriceBenchmarking.pdf';

const otherSolutionsTiles = [
  {
    name: 'Engine Performance Testing Analytics',
    title: 'Engine Performance Testing',
    description: 'Enhance engine performance through machine parameter analysis',
    tooltip: 'Enhance engine performance by capturing and analysing parameter values from measuring machines. Visualize engine performance parameters obtained from MTB and inspection machines in the assembly shop. This project aims to optimize processes for improved overall engine efficiency.',
    tileLink:'https://app.powerbi.com/groups/me/reports/971144ab-10d6-4463-a2c3-cd8d8694ce0b/ReportSection?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
    vertical: 'Production',
    // btnText: 'Explore',
    icon: EnginePerformanceScreen,
    img: EnginePerformanceTesting,
    pdfFile: EnginePerformanceTestingPDF,
  },
  // {
  //   name: 'DMS Click Link',
  //   title: 'DMS Click Link',
  //   description: 'DMS Click Link',
  //   description: 'DMS Click Link',
  //   tileLink: 'http://msilbi.maruti.com/',
  //   vertical: 'DMS',
  //   btnText: 'Explore',
  //   icon: DMSClickLink,
  // },

  {
    name: 'Spares Pricing System',
    title: 'Spares Pricing System',
    description: 'Optimize spare parts pricing and automate processes',
    tooltip: 'Enhance spares business performance by implementing optimal pricing strategies for spare parts. Streamline operations by reducing manual processes and dependencies, aiming to boost revenue and profitability. This project focuses on maximizing efficiency and financial outcomes in the spares business.',
    tileLink: 'https://marutisuzukipaccpricing.com/',
    vertical: 'PACCL',
    // btnText: 'Explore',
    icon: SparesPricingSystem,
    img: SparesPricingSystems,
    pdfFile: SparesPricingSystemsPDF,
  },
  // {
  //   name: 'Auto Stock Balancing Platform',
  //   title: 'Auto Stock Balancing Platform',
  //   description: 'Auto Stock Balancing Platform',
  //   tooltip: 'Auto Stock Balancing Platform',
  //   tileLink: 'https://msgp-asbportal.com',
  //   vertical: 'PACCL',
  //   icon: ASBP,
  //   img: AutoStockBalancingPlatform,
  // },
  {
    name: '	Inbound Logistics Platform',
    title: 'Inbound Logistics Platform',
    description: 'Ensure live traceability for timely truck supplies',
    tooltip: 'End-to-end visibility of trucks for real-time traceability and tracking of supplies, ensuring timely material deliveries from vendors to MSIL. This project aims to enhance supply chain efficiency and reliability through comprehensive tracking mechanisms.',
    tileLink: 'https://msilvendortrucknav.com/',
    vertical: 'SC',
    // btnText: 'Explore',
    icon: InboundLogisticsScreen,
    img: InboundLogisticsPlatform,
    pdfFile:InboundLogisticsPlatformPDF,
  },
  // {
  //   name: 'Sales Cluster Analysis For Super Carry',
  //   title: 'Sales Cluster Analysis For Super Carry',
  //   description: 'Sales Cluster Analysis For Super Carry',
  //   description: 'Sales Cluster Analysis For Super Carry',
  //   tileLink: '',
  //   vertical: 'COMS',
  //   btnText: 'Explore',
  //   icon: SalesClusterAnalysisForSuperCarry,
  // },
  {
    name: 'Data Lake Platform',
    title: 'Data Lake Platform',
    description: 'Seamlessly gather online data for understanding',
    tooltip: 'A system to track the daily share of business for each Logistics Service Provider (LSP) at the zonal level across various transportation modes (Road, Surface Courier, and Air Courier). This project aims to provide detailed insights for optimized business management and decision-making.',
    tileLink: 'https://marutisuzukidatafabric.com/ ',
    vertical: 'Digital Enterprise',
    // btnText: 'Explore',
    icon: DLP,
    img: DataLakePlatform,
    pdfFile: DataLakePlatformPDF,
  },
  {
    name: 'Logistics Service Provider',
    title: 'Logistics Service Provider',
    description: 'Track daily business share for LSPs in transportation modes',
    tooltip: 'A system to track the daily share of business for each Logistics Service Provider (LSP) at the zonal level across various transportation modes (Road, Surface Courier, and Air Courier). This project aims to provide detailed insights for optimized business management and decision-making.',
    // tileLink: 'https://qa.marutisuzukidatafabric.com/',
    vertical: 'PACCL',
    btnText: 'Explore',
    icon: DLP,
    img: LogisticsServiceProvider,
    pdfFile: LogisticsServiceProviderPDF,
  },
  {
    name: 'Model Carrier',
    title: 'Model Carrier',
    description: 'Tools for performing baseline and modelling which helps people',
    tooltip: 'Simulate car & carrier combinations for all possibilities',
    // tileLink: 'https://qa.marutisuzukidatafabric.com/',
    vertical: 'PACCL',
    btnText: 'Explore',
    icon: DLP,
    img: ModelCarrier,
    pdfFile: ModelCarrierPDF,
  },
  // {
  //   name: 'Art of Analytics For Pitstop',
  //   title: 'Art of Analytics For Pitstop',
  //   description: 'Art of Analytics For Pitstop',
  //   description: 'Art of Analytics For Pitstop',
  //   tileLink: 'http://newpitstop/news/-/asset_publisher/cj8j2Y2I9s4H/content/learn-with-the-art-of-analytics-comic-series',
  //   vertical: 'Digital Enterprise',
  //   btnText: 'Explore',
  //   icon: ArtOfAnalyticsForPitstop,
  // },
  // once VAPT done when app will be ready then need to uncomment this line
  {
    name: 'Capital Procurement & Price Benchmarking  Platform',
    title: 'Capital Procurement',
    description: 'Price benchmarking platform for procured items',
    tooltip: 'Centralize procurement data for improved negotiation efficiency',
    tileLink: 'https://cppb.msilsupplychain.com/',
    vertical: 'SC',
    // btnText: 'Explore',
    icon: DLP,
    img: DataLakePlatform,
    pdfFile: PriceBenchMarkingPDF,
  },
  {
    name: 'BVIT',
    title: 'BVIT',
    description: 'Shock load test automation for error reduction & resource optimization',
    tooltip: 'The objective of this automation initiative is to streamline repetitive tasks, thereby conserving time and resources and enabling us to concentrate on tasks demanding human expertise and creativity.',
    // tileLink: 'https://qa.marutisuzukidatafabric.com/',
    vertical: 'PACCL',
    btnText: 'Explore',
    icon: DLP,
    img: ModelCarrier,
    // pdfFile: ModelCarrierPDF
  },
  {
    name: 'EMU LPP',
    title: 'EMU LPP Directory',
    description: 'Tools for performing baseline and modelling which helps people',
    tooltip: 'Simulate car & carrier combinations for all possibilities',
    // tileLink: 'https://qa.marutisuzukidatafabric.com/',
    vertical: 'PACCL',
    btnText: 'Explore',
    icon: DLP,
    img: ModelCarrier,
    pdfFile: ModelCarrierPDF,
  }
];

export default otherSolutionsTiles;
