import React, { useEffect, useRef } from 'react';
import CarriersView from './Carriers.view';
import { BootstrapTooltip } from 'components';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { get } from 'lodash';
import { useDispatch, useSelector} from 'react-redux';
import { consts } from 'globalsData';

// app
import { getCarrier, hideModal, showModal, deleteCarrier, uploadCarrier, snackbarNotification } from 'stores';

const Carriers = () => {

  const dispatch = useDispatch();
  const carriers = useSelector((state) => get(state, 'modelCarrier.carriers', []));
  const hiddenFileInput = useRef(null);

  const columns = [
    {
      field: 'Carrier_Type',
      headerName: 'Carrier Type',
      width: 130,
      editable: true,
    },
    {
      field: 'Length_Lower',
      headerName: 'Length Lower',
      type: 'number',
      width: 130,
      editable: true,
    },
    {
      field: 'Length_Upper',
      headerName: 'Length Upper',
      type: 'number',
      width: 130,
      editable: true,
    },
    {
      field: 'B2B_clearance',
      headerName: 'B2B Clearance',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'Height_clearance',
      headerName: 'Height clearance',
      type: 'number',
      width: 130,
      editable: true,
    },
    {
      field: 'Total_usable_height',
      headerName: 'Total Usable Height',
      type: 'number',
      width: 150,
      editable: true,
    },
    {
      field: 'lower_Deck_min_height',
      headerName: 'Lower Deck Min Height',
      type: 'number',
      width: 170,
      editable: true,
    },
    {
      field: 'upper_Deck_min_height',
      headerName: 'Upper Deck Min Height',
      type: 'number',
      width: 170,
      editable: true,
    },
    {
      field: 'Min_load_factor',
      headerName: 'Min Load Factor',
      type: 'number',
      width: 130,
      editable: true,
    },
    {
      field: 'Max_load_factor',
      headerName: 'Max Load Factor',
      type: 'number',
      width: 130,
      editable: true,
    },
    {
      field: 'L1',
      headerName: 'L1 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'L2',
      headerName: 'L2 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'L3',
      headerName: 'L3 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'L4',
      headerName: 'L4 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'U1',
      headerName: 'U1 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'U2',
      headerName: 'U2 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'U3',
      headerName: 'U3 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'U4',
      headerName: 'U4 Height',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 80,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
            <BootstrapTooltip title={'Delete'} placement={'bottom'}>
              <IconButton aria-label="Delete" onClick={(e) => onDataDeleteHandler(e, params?.row)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </BootstrapTooltip>
          </div>
        );
      },
    },
  ];

  useEffect(()=>{
    dispatch(getCarrier());
  },[])

  const onDataDeleteHandler = (e, row) => {
    e.stopPropagation();

    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: 'Delete Car Model',
          maxWidth: 'xs',
          componentProps: {
            confirmText: `Are you sure you want to delete ${row?.Model}?`,
            confirmButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('CONFIRM'));
            },
            submitHandler: () => {
              dispatch(
                deleteCarrier({
                  carrierId: row?._id,
                })
              ).then((res) => {
                if (res.status === 200) {
                  dispatch(getCarrier());
                  dispatch(hideModal('CONFIRM'));
                }
              });
            },
          },
        },
      })
    );
  };

  const handleFileUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChangeFileUpload = (event) => {
    let fileUploaded = event.target.files[0];
    var blob = fileUploaded.slice(0, fileUploaded.size, 'text/xlsx');
    let file = new File([blob], 'carrier.xlsx', { type: 'text/xlsx' });

    const validFileFormat = consts.formAllowedFileFormats?.includes(file?.name?.split('.')[1]);
    if (validFileFormat) {
      dispatch(uploadCarrier({ file })).then((res) => {
        if (res?.status === 200) {
          dispatch(snackbarNotification('Submitted successully', 'success'));
        }
      });
    } else {
      dispatch(snackbarNotification('This file type is not allowed, upload only csv and xlsx format', 'warning'));
    }
  };
  const rows = [
    { id: 1, carrierType: 'TK5', lengthLower: 123, lengthUpper: 1, b2bclearance: 3395, heightClearance: 1490, totalUsableHeight: 1475, lowerDeckMinHeight: 1475, displacement: 1475, typeOfPitch: 1475, pitchAdjustment: 1475,  minPossibleDeckHeightDifference: 1475, noOfRamps:876, heightAdjustment:234, minLoadFactor:456, maxLoadFactor:445, l1Height:876, l2Height:458, l3Height:876, l4Height:999, u1Height:799, u2Height:654, u3Height:234, u4Height:765, rampHeight:999, rampLength:777, rampAngle:555  },
    { id: 2, carrierType: 'TK5', lengthLower: 123, lengthUpper: 1, b2bclearance: 3395, heightClearance: 1490, totalUsableHeight: 1475, lowerDeckMinHeight: 1475, displacement: 1475, typeOfPitch: 1475, pitchAdjustment: 1475,  minPossibleDeckHeightDifference: 1475, noOfRamps:876, heightAdjustment:234, minLoadFactor:456, maxLoadFactor:445, l1Height:876, l2Height:458, l3Height:876, l4Height:999, u1Height:799, u2Height:654, u3Height:234, u4Height:765, rampHeight:999, rampLength:777, rampAngle:555  },
  ];

  return (
    <CarriersView columns={columns} rows={carriers} onDataDeleteHandler={onDataDeleteHandler}  carriers={carriers}
    hiddenFileInput={hiddenFileInput}
  handleFileUploadClick={handleFileUploadClick} handleChangeFileUpload={handleChangeFileUpload}
    />
  );
};

export default Carriers;
