import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getCarrier = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

 
  dispatch(getCarrierRequest());

  return api
    .get({
      endpoint: endpoint.modelCarrier,
      path: 'getCarriers'
    })
    .then((res) => {
      dispatch(getCarrierSuccess(res?.data));
      return res;
    })
    .catch((error) => {
      dispatch(getCarrierError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getCarrier'));
    });
};

export const getCarrierRequest = (params) => {
  return {
    type: 'GET_CARRIER_REQUEST',
    payload: params,
  };
};

export const getCarrierSuccess = (data) => {
  return {
    type: 'GET_CARRIER_SUCCESS',
    payload: data,
  };
};

export const getCarrierError = (error) => {
  return {
    type: 'GET_CARRIER_FAILURE',
    payload: error,
  };
};
