import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//app
import { accessRequest } from 'stores';
import { config, analyticsStudioTiles, consts } from 'globalsData';
import { AnalyticsStudioView } from './AnalyticsStudio.view';
import AnalyticsStudioMainLogo from 'assets/svg/AnalyticsStudioMainLogo.svg';

//mui
import { Link, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function AnalyticsStudio() {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTileTooltip, setCurrentTileTooltip] = useState('');
  const [videoInfo, setVideoInfo] = useState({ title: '', link: '' });
  const [tileMenu, setTileMenu] = useState(null);
  const [tileMenuInfo, setTileMenuInfo] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bannerProps = {
    title: 'Analytics Studio',
    paragraph1:
      'A GUI based platform for Exploratory Data Analysis and Experimentations with various Analytics and AI-ML algorithms to get business insights and derive value from data',
    icon: AnalyticsStudioMainLogo,
  };

  const openTileMenu = (event, tile) => {
    event.stopPropagation();
    setTileMenuInfo(tile);
    setTileMenu(event.currentTarget);
  };

  const closeTileMenu = (event) => {
    event.stopPropagation();
    setTileMenuInfo({});
    setTileMenu(null);
  };

  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  const handleTileClick = (cardInfo) => {
    if (cardInfo?.tileLink) window.open(cardInfo.tileLink, '_blank');
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const openToolTip = (event) => {
    event.stopPropagation();
    setCurrentTileTooltip(event.target.getAttribute('data-tile'));
  };

  const closeToolTip = (event) => {
    event.stopPropagation();
    setCurrentTileTooltip('');
  };

  const videoHandler = (event, { title, videoLink }) => {
    event.stopPropagation();
    openModal();
    setVideoInfo({ title, link: videoLink });
  };

  const handleAccessRequest = () => {
    dispatch(accessRequest());
  };

  const breadcrumbs = [
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.home.root)}>
      {consts.APP_TITLE}
    </Link>,
    <Typography key="3" color="text.primary" sx={{fontSize:'14px'}}>
      {consts.FEATURE_LIST_OBJ.as}
    </Typography>,
  ];

  const menuList = [
    {
      name: 'playVideo',
      label: 'Play Video',
      icon: PlayCircleOutlineIcon,
      onMenuItemClick: videoHandler,
    },
    {
      name: 'developeGuide',
      label: 'Visit Developer Guide',
      icon: VisibilityIcon,
      onMenuItemClick: (e, info) => {
        e.stopPropagation();
        if (info?.infoLink) window.open(info.infoLink, '_blank');
      },
    },
  ];

  return (
    <AnalyticsStudioView
      modalActions={{ modalOpen, closeModal }}
      videoInfo={videoInfo}
      currentTileTooltip={currentTileTooltip}
      bannerProps={bannerProps}
      tileMenuInfo={tileMenuInfo}
      breadcrumbs={breadcrumbs}
      analyticsStudioTiles={analyticsStudioTiles}
      tileMenu={tileMenu}
      menuList={menuList}
      handlers={{
        handleTileClick,
        videoHandler,
        handleAccessRequest,
        openTileMenu,
        closeTileMenu,
        openToolTip,
        closeToolTip,
      }}
    />
  );
}
