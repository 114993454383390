import { addLoader, removeLoader, snackbarNotification } from 'stores';
import { api } from 'utils';

export const getUserList = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
  dispatch(getUserListRequest());
  dispatch(addLoader('getUserList'));

  return api
    .get({
      endpoint: endpoint.userManagementService,
      path: 'v1/um/listUser',
    })
    .then((res) => {
      dispatch(getUserListSuccess(res?.data || []));
      return res;
    })
    .catch((error) => {
      dispatch(getUserListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getUserList'));
    });
};

export const getUserListRequest = () => {
  return {
    type: 'GET_USER_LIST_REQUEST',
  };
};

export const getUserListSuccess = (data) => {
  return {
    type: 'GET_USER_LIST_SUCCESS',
    payload: data,
  };
};

export const getUserListError = (error) => {
  return {
    type: 'GET_USER_LIST_FAILURE',
    payload: error,
  };
};
