import React from 'react';
import PropTypes from 'prop-types';

//app
import * as utils from 'utils';
import { BootstrapTooltip } from 'components';

//mui
import { Grid, Typography } from '@mui/material';

FieldLabelWithValueHorizontal.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  labelFontSize: PropTypes.number,
  valueFontSize: PropTypes.number,
  labelFontWeight: PropTypes.number,
  valueFontWeight: PropTypes.number,
  labelColor: PropTypes.func,
  valueColor: PropTypes.func,
};

FieldLabelWithValueHorizontal.defaultProps = {
  value: '',
  labelFontSize: 16,
  valueFontSize: 16,
  labelFontWeight: 600,
  valueFontWeight: 400,
  labelColor: (theme) => theme.palette.otherColor.textGrey2,
  valueColor: (theme) => theme.palette.otherColor.textGrey2,
};

export default function FieldLabelWithValueHorizontal({
  label,
  value,
  labelFontSize,
  valueFontSize,
  labelFontWeight,
  valueFontWeight,
  labelColor,
  valueColor,
}) {
  return (
    <Grid container py={0.5}>
      <Grid item>
        <Typography fontSize={labelFontSize} fontWeight={labelFontWeight} pr={1} color={labelColor}>
          {label}:
        </Typography>
      </Grid>
      <Grid item>
        <BootstrapTooltip title={value?.toString().length > 24 ? value : ''}>
          <Typography fontSize={valueFontSize} fontWeight={valueFontWeight} color={valueColor}>
            {utils.app.getEllipsisString(value?.toString(), 24)}
          </Typography>
        </BootstrapTooltip>
      </Grid>
    </Grid>
  );
}
