import React from 'react';
// import Download from 'assets/png/Download.png';
import './BVITTable.css';

export default function BVITTableView({ reportsDetails, handleOldReportDownload }) {
  return (
  <table className='bvit-data-table'>
    <thead>
      <tr>
        <th>Model</th>
        <th>Power Train Specifications</th>
        <th>Clutch Specifications</th>
        <th>Vehicle Specifications</th>
        <th>Objective</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {reportsDetails && reportsDetails.length && reportsDetails?.map((data, i) => 
        <tr key={i}>
         <td>
          <div>{data?.model?.model?.join(', ')}</div>
        </td>
        <td>
          <div>{data?.power_train_specifications?.engine_type?.join(', ')}</div>
          <div>{data?.power_train_specifications?.transmission_type?.join(', ')}</div>
        </td>
        <td>
          <div>{data?.clutch_specifications?.clutch_maker?.join(', ')}</div>
        </td>
        <td>
          <div>{data?.vehicle_specifications?.vehicle_weight?.join(', ')}</div>
        </td>
        <td>{data?.objective}</td>
        <td><button className="download-btn" onClick={() => handleOldReportDownload(data?.reportId)}>
          {/* <img src={Download} alt="Download" width={10} height={10} color='#fff' className='download-img' /> */}
          Download</button></td>
      </tr>
      )}
    </tbody>
  </table>
  );
};