import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const deleteUser = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
  dispatch(deleteUserRequest(params));
  dispatch(addLoader('deleteUser'));

  const { userId } = params;

  return api
    .post({
      endpoint: endpoint.userManagementService,
      path: `v1/um/delete/${userId}`,
    })
    .then((data) => {
      dispatch(deleteUserSuccess(data?.data));
      dispatch(snackbarNotification(data?.data?.message, 'success'));
      return data;
    })
    .catch((error) => {
      dispatch(deleteUserError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('deleteUser'));
    });
};

export const deleteUserRequest = (params) => {
  return {
    type: 'DELETE_USER_REQUEST',
    payload: params,
  };
};

export const deleteUserSuccess = (data) => {
  return {
    type: 'DELETE_USER_SUCCESS',
    payload: data,
  };
};

export const deleteUserError = (error) => {
  return {
    type: 'DELETE_USER_FAILURE',
    payload: error,
  };
};
