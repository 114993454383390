export default function lineChartOptions(testPattern, groupKey, torqueType, torqueValue) {
    const options = {
        chart: {
            type: 'line',
            zoomType: 'xy',
        },
        title: {
            text: testPattern,
            align: 'left'
        },
        xAxis: {
            categories: groupKey,
            title: {
                text: 'Target Model'
            }
        },
        yAxis: {
            title: {
                text: torqueType
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'Torque Value',
                data: torqueValue
            }
        ]
    };
    return options;
}