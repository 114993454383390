import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

// app
import './shareOfBusinessTableView.css';
import '../../Pages/ShareOfBusiness/ShareOfBusiness.css';

const shareOfBusinessTableView = ({ tableData }) => {
  return (
    <Grid className="sobTableStyle" sx={{ whiteSpace: 'nowrap', overflowX: 'scroll', borderRadius: '5px', border: '1px solid grey' }}>
      <Box
        id="tableHeaderRow"
        sx={{
        
          p: 1,
          bgcolor: (theme) => theme.palette.primary.main,
          borderRadius: '5px 5px 0px 0px',
          width: 'fit-content',
        }}
      >
        <div className="headerCellStyles ">Mode</div>
        <div className="headerCellStyles areaOfOperationCell">Area of Operation</div>
        <div className="headerCellStyles ">Warehouse</div>
        <div className="headerCellStyles ">Total Volume</div>
        <div className="headerCellStyles ">Transporter</div>
        <div className="headerCellStyles ">Approved SOB %</div>
        <div className="headerCellStyles ">Actual SOB %</div>
        <div className="headerCellStyles ">Approved Volume Kg</div>
        <div className="headerCellStyles ">Actual Volume Kg</div>
        <div className="headerCellStyles ">Vol Kg (Actual - Approved)</div>
      </Box>
      <Box id="tableBody">
        {typeof tableData !== 'string' ? (
          <>
            {tableData?.map((row, idx) => {
              return (
                <Box
                  className="tableRow"
                  sx={{
                   
                    padding: '10px 2px',
                    bgcolor: '#ffffff',
                    borderBottom: '1px solid grey',
                    alignItems: 'center',
                    borderTop: '0px',
                    width: 'fit-content',
                  }}
                  key={idx}
                >
                  <div className="rowCellStyles modeCell">{row?.mode}</div>
                  <div className="rowCellStyles areaOfOperationCell">{row?.areaOfOperation}</div>
                  <div className="rowCellStyles warehouseCell">{row?.warehouse}</div>
                  <div className="rowCellStyles totVolCell">{row?.totalVolume}</div>
                  <div className="subTableStyles">
                    {row?.transportersInfo?.map((subRow, idx) => {
                      return (
                        <div key={idx}>
                          <div className="rowInnerCellStyles transporterCell">{subRow?.transporter}</div>
                          <div className="rowInnerCellStyles approvedSobCell">{subRow?.approvedSOBpercentage}</div>
                          <div className="rowInnerCellStyles actualSobCell">{subRow?.actualSOBpercentage}</div>
                          <div className="rowInnerCellStyles approvedVolCell">{subRow?.approvedVolumeKg}</div>
                          <div className="rowInnerCellStyles actualVolCell">{subRow?.actualVolumeKg}</div>
                          <div className="rowInnerCellStyles actualMinusApprovedCell">{subRow?.volDifferenceKg}</div>
                        </div>
                      );
                    })}
                  </div>
                </Box>
              );
            })}
          </>
        ) : (
          <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight:'bold', p: 1 }}>No Data Available...!</Typography>
        )}
      </Box>
    </Grid>
  );
};

export default shareOfBusinessTableView;
