import getAlgorithmHyperParameters from 'globalsData/getAlgorithmHyperParameters/getAlgorithmHyperParameters';
import { consts } from 'globalsData';

const getTimeseriesPayload = (formData) => {
  if (!formData) return;
  const { forecastCol, algorithm, timeStepCol, dayFirst, differencingValue, forecastLength, justificationRemarks, testSizeRatio} = formData;
  const hyperParameters = getAlgorithmHyperParameters(consts.TIMESERIES, formData);

  if (algorithm === 'exponentialSmoothing') {
    const { trend, dampedTrend, seasonal, seasonalPeriods, freq } = hyperParameters;
    return {
      algorithm,
      timeStepCol,
      dayFirst,
      forecastCol,
      forecastLength,
      testSizeRatio,
      differencingVal: differencingValue,
      justificationRemarks,
      modelHyperParameters: {
        trend,
        damped_trend: dampedTrend,
        seasonal,
        seasonal_periods: seasonalPeriods,
        freq,
      },
    };
  } else if (algorithm === 'arima') {
    const { arimaSeasonal, seasonalDifferencing } = hyperParameters;
    return {
      algorithm,
      timeStepCol,
      dayFirst,
      forecastCol,
      forecastLength,
      testSizeRatio,
      justificationRemarks,
      modelHyperParameters: {
        seasonal: arimaSeasonal,
        seasonal_differencing: seasonalDifferencing,
      },
    };
  } else if (algorithm === 'prophet') {
    const {  prophetWeeklySeasonality, prophetYearlySeasonality, prophetDailySeasonality, prophetSeasonalityMode } =
      hyperParameters;
    return {
      algorithm,
      timeStepCol,
      dayFirst,
      forecastCol,
      forecastLength,
      testSizeRatio,
      justificationRemarks,
      modelHyperParameters: {
        seasonality_mode: prophetSeasonalityMode,
        yearly_seasonality: prophetYearlySeasonality,
        weekly_seasonality: prophetWeeklySeasonality,
        daily_seasonality: prophetDailySeasonality,
      },
    };
  } else if (algorithm === 'neuralProphet') {
    const { npSeasonalityMode,  npYearlySeasonality, npWeeklySeasonality, npDailySeasonality } = hyperParameters;
    return {
      algorithm,
      timeStepCol,
      dayFirst,
      forecastCol,
      forecastLength,
      testSizeRatio,
      justificationRemarks,
      modelHyperParameters: {
        seasonality_mode: npSeasonalityMode,
        yearly_seasonality: npYearlySeasonality,
        weekly_seasonality: npWeeklySeasonality,
        daily_seasonality: npDailySeasonality,
      },
    };
  } else {
    return {
      algorithm,
      timeStepCol,
      dayFirst,
      forecastCol,
      forecastLength,
      testSizeRatio,
      justificationRemarks,
      modelHyperParameters: ""
    };
  }
};

export default getTimeseriesPayload;
