import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const updateUser = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
  dispatch(updateUserRequest(reqParams));
  dispatch(addLoader('updateUser'));

  const { email, roleName, categoryList, featureList, otherSolutionsList, userId, verticalId, departmentId } = reqParams;

  const data = {
    email,
    roleName,
    categoryList,
    featureList,
    otherSolutionsList,
    verticalId,
    departmentId,
  };

  return api
    .post({
      endpoint: endpoint.userManagementService,
      path: `v1/um/update/${userId}`,
      data,
    })
    .then((data) => {
      dispatch(updateUserSuccess(data?.data));
      dispatch(snackbarNotification(data?.data?.message || 'User Updated Successfully', 'success'));
      return data;
    })
    .catch((error) => {
      dispatch(updateUserError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('updateUser'));
    });
};

export const updateUserRequest = (params) => {
  return {
    type: 'UPDATE_USER_REQUEST',
    payload: params,
  };
};

export const updateUserSuccess = (data) => {
  return {
    type: 'UPDATE_USER_SUCCESS',
    payload: data,
  };
};

export const updateUserError = (error) => {
  return {
    type: 'UPDATE_USER_FAILURE',
    payload: error,
  };
};
