import React, { useEffect, useState } from 'react';
import BVITDataVisualizationView from './BVITDataVisualization.view';
import BVITPowerSpecifications from './BVITDataSubModules/BVITPowerSpecifications/BVITPowerSpecifications';
import BVITClutchSpecifications from './BVITDataSubModules/BVITClutchSpecifications/BVITClutchSpecifications';
import BVITVehicleSpecifications from './BVITDataSubModules/BVITVehicleSpecifications/BVITVehicleSpecifications';
import BVITOthers from './BVITDataSubModules/BVITOthers/BVITOthers';
import BVITModel from './BVITDataSubModules/BVITModel/BVITModel';
import { useDispatch, useSelector } from 'react-redux';
import { addVehicleData, downloadOldReport, getBvitGraphData, getBvitPresignedUrl, getFiltersData, uploadBvitFileToS3 } from 'stores';
import { get, isEmpty } from 'lodash';
import { consts } from 'globalsData';
import { getReportsData } from 'stores/bvit/bvit.actions.getReportsData';

const BVITDataVisualization = () => {
  const dispatch = useDispatch();
  const initialModel = { vehicle_type: '', mode: '', model: [], stage: [], platform: [] };
  const initialPowerSpecifications = { engine_type: [], fuel_type: [], engine_max_torque_rpm: '', engine_max_rpm: [], transmission_type: [] };
  const initialClutchSpecifications = { clutch_maker: [], clutch_release_system_maker: [], clutch_size: [], disc_material: [], clamp_load_maximum: [], clamp_load_measured: [], clamp_load_minimum: [], clutch_release_system: [], ptl_size: [], stiffness_drive: [], stiffness_coast: [], cushion: [], cof: [] };
  const initialVehicleSpecifications = { vehicle_weight: [], front_rxn: [], rear_rxn: [], weight_distribution: [], tyre_specifications: [], tyre_rolling_radius: [] };
  const initialOthers = { pattern: [], measurement_type: '' };
  const initialOthersDropdown = { chart_type: '', test_pattern: [], torque_type: '' };

  const filtersData = useSelector((state) => get(state, 'bvit.filtersData', {}));
  const bvitGraphData = useSelector((state) => get(state, 'bvit.bvitGraphData', {}));
  const reportsData = useSelector((state) => get(state, 'bvit.reportsData', {}));

  const [file, setFile] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isGeneratePlotOpen, setIsGeneratePlotOpen] = useState(false);
  const [model, setModel] = useState(initialModel);
  const [powerSpecifications, setPowerSpecifications] = useState(initialPowerSpecifications);
  const [clutchSpecifications, setClutchSpecifications] = useState(initialClutchSpecifications);
  const [vehicleSpecifications, setVehicleSpecifications] = useState(initialVehicleSpecifications);
  const [others, setOthers] = useState(initialOthers);
  const [othersDropdown, setOthersDropdown] = useState(initialOthersDropdown);
  const [torqueValues, setTorqueValues] = useState([]);
  const [filterDetails, setFilterDetails] = useState({});
  const [reportsDetails, setReportsDetails] = useState({});

  const [vehicleTypeError, setVehicleTypeError] = useState(false);
  const [modeError, setModeError] = useState(false);
  const [chartError, setChartError] = useState(false);
  const [testPatternError, setTestPatternError] = useState(false);
  const [torqueError, setTorqueError] = useState(false);

  const handleFiltersChange = (details) => {
    if(details.length){
      const newFilterDetails = { 
        "model": {
          "vehicle_type": [...new Set(details.map((data) => data["Vehicle Type"]))],
          "mode": [...new Set(details.map((data) => data["Mode"]))],
          "model": [...new Set(details.map((data) => data["Model"]))],
          "stage": [...new Set(details.map((data) => data["Stage"]))],
          "platform": [...new Set(details.map((data) => data["Platform"]))]
        },
        "power_train_specifications": {
          "engine_type": [...new Set(details.map((data) => data["Engine Type"]))],
          "fuel_type": [...new Set(details.map((data) => data["Fuel Type"]))],
          "engine_max_torque_rpm": [...new Set(details.map((data) => data["Engine Max Torque()@RPM"]))],
          "engine_max_rpm": [...new Set(details.map((data) => data["Max Engine\r\nRPM "]))],
          "transmission_type": [...new Set(details.map((data) => data["Transmission Type"]))]
        },
        "clutch_specifications": {
           "clutch_maker": [...new Set(details.map((data) => data["clutch maker"]))],
           "clutch_release_system_maker": [...new Set(details.map((data) => data["clutch release system maker"]))],
           "clutch_size": [...new Set(details.map((data) => data["Cltuch Size"]))],
           "disc_material": [...new Set(details.map((data) => data["Disc Material"]))],
           "clamp_load_maximum": [...new Set(details.map((data) => data["Clamp Load(N) Maximum"]))],
           "clamp_load_measured": [...new Set(details.map((data) => data["Clamp Load(N) Measured"]))],
           "clamp_load_minimum": [...new Set(details.map((data) => data["Clamp Load(N) Minimum"]))],
           "clutch_release_system": [...new Set(details.map((data) => data["Clutch Release system"]))],
           "ptl_size": [...new Set(details.map((data) => data["PTL / Size(mm)"]))],
           "stiffness_drive": [...new Set(details.map((data) => data["Stiffness Drive (N-m/°)"]))],
           "stiffness_coast": [...new Set(details.map((data) => data["Stiffness Coast (N-m/°)"]))],
           "cushion": [...new Set(details.map((data) => data["Cushion"]))],
           "cof": [...new Set(details.map((data) => data["CoF"]))]
        },
        "vehicle_specifications": {
           "vehicle_weight": [...new Set(details.map((data) => data[" Vehicle Weight(kg) *"]))],
           "front_rxn": [...new Set(details.map((data) => data["Front Rxn(Kg)*"]))],
           "rear_rxn": [...new Set(details.map((data) => data["Rear Rxn(Kg)*"]))],
           "weight_distribution": [...new Set(details.map((data) => data["Weight Distribution*"]))],
           "tyre_specifications": [...new Set(details.map((data) => data["Tyre Specification"]))],
           "tyre_rolling_radius": [...new Set(details.map((data) => data["Tyre Rolling Radius(m)"]))]
        },
        "others": {
            "pattern": [...new Set(details.map((data) => data["Pattern"]))],
            "measurement_type": [...new Set(details.map((data) => data["Measurement Type"]))]
        },
        "vehicle_load_data": details,
        "others_dropdown": filterDetails.others_dropdown
      };
      setFilterDetails(newFilterDetails);
    }
  }

  const tabsData = [
    { label: 'Model', content: <BVITModel filterDetails={filterDetails} handleFiltersChange={handleFiltersChange} model={model} setModel={setModel} vehicleTypeError={vehicleTypeError} setVehicleTypeError={setVehicleTypeError} modeError={modeError} setModeError={setModeError} /> },
    { label: 'Power Train Specifications', content: <BVITPowerSpecifications filterDetails={filterDetails} handleFiltersChange={handleFiltersChange} powerSpecifications={powerSpecifications} setPowerSpecifications={setPowerSpecifications} /> },
    { label: 'Clutch Specifications', content: <BVITClutchSpecifications filterDetails={filterDetails} handleFiltersChange={handleFiltersChange} clutchSpecifications={clutchSpecifications} setClutchSpecifications={setClutchSpecifications} /> },
    { label: 'Vehicle Specifications', content: <BVITVehicleSpecifications filterDetails={filterDetails} handleFiltersChange={handleFiltersChange} vehicleSpecifications={vehicleSpecifications} setVehicleSpecifications={setVehicleSpecifications} /> },
    { label: 'Others', content: <BVITOthers filterDetails={filterDetails} handleFiltersChange={handleFiltersChange} others={others} setOthers={setOthers} /> }
  ];

  useEffect(() => {
    dispatch(getFiltersData());
    dispatch(getReportsData());
  }, []);

  useEffect(() => {
    if(!isEmpty(filtersData)){
      setFilterDetails(filtersData);
      setModel(initialModel);
      setPowerSpecifications(initialPowerSpecifications);
      setClutchSpecifications(initialClutchSpecifications);
      setVehicleSpecifications(initialVehicleSpecifications);
      setOthers(initialOthers);
      setOthersDropdown(initialOthersDropdown);
      setTorqueValues([]);
    }
  }, [filtersData]);

  useEffect(() => {
    if(!isEmpty(reportsData)){
      setReportsDetails(reportsData);
    }
  }, [reportsData]);

  useEffect(() => {
    if(model.vehicle_type === consts.VEHICLE_TYPE.FF || model.vehicle_type === consts.VEHICLE_TYPE.FR){
      if(model.mode === consts.VEHICLE_MODE.Four){
        const values = filterDetails.others_dropdown.torque_type[model.vehicle_type];
        setTorqueValues(values)
      }
    }
    if(model.vehicle_type === consts.VEHICLE_TYPE.FF){
      if(model.mode === consts.VEHICLE_MODE.Two){
        const values = consts.VEHICLE_FF_2WD;
        setTorqueValues(values);
      }
    }
    if(model.vehicle_type === consts.VEHICLE_TYPE.FR){
      if(model.mode === consts.VEHICLE_MODE.Two){
        const values = consts.VEHICLE_FR_2WD;
        setTorqueValues(values);
      }
    }
  }, [model.vehicle_type, model.mode]);

  const handleFileUpload = (files) => {
    const file = files[0];
    const isValidFileFormat = consts.bvitAllowedFileFormats?.includes(file?.name?.split('.')[1]);
    if(isValidFileFormat){
      if (file && file?.size <= consts.BVIT_MAX_FILE_SIZE) {
        setFile(file);
      }
    }
  };

  const onProceedFile = () => {
    dispatch(
      getBvitPresignedUrl({
        type: consts.VEHICLE,
        filename: file?.name
      })
    ).then((res) => {
      if (res?.status === 200) {
        const preSignedUrl = res?.data?.data?.inputPreSignedUrl;
        const uploadId = res?.data?.uploadId;
        const reqParams = {
          uploadId: uploadId,
          filename: file?.name,
          type: consts.VEHICLE
        };

        if (preSignedUrl && uploadId) {
          dispatch(uploadBvitFileToS3({ preSignedUrl, file })).then(
            (fS3res) => {
              if (fS3res?.status === 200) {
               dispatch(addVehicleData(reqParams)).then((res) => {
               if (res?.status === 200) {
                 dispatch(getFiltersData());
                 setIsFileUploaded(true);
               }
              });
            }
          }
        )}
      }
    });
  }

  const onDeleteFile = () => {
    setFile(null);
    setIsFileUploaded(false);
  }

const removeEmptyArrays = (obj) => {
  for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
          if (Array.isArray(obj[prop])) {
              if (obj[prop].length === 0) {
                  delete obj[prop];
              }
          } else if (typeof obj[prop] === 'object') {
            if (isEmpty(obj[prop])) {
              delete obj[prop];
            }
            else {
              removeEmptyArrays(obj[prop]);
            }
          } else if (obj[prop] === '') {
            delete obj[prop];
        }
      }
  }
}

  const onGeneratePlot = () => {
    if(isEmpty(filterDetails) && !file){
      return;
    }
    else{
      if(!model.vehicle_type || !model.mode || !othersDropdown?.chart_type || !othersDropdown?.test_pattern?.length || !othersDropdown?.torque_type){
        if(!model.vehicle_type)
          setVehicleTypeError(true);
        if(!model.mode)
          setModeError(true);
        if(!othersDropdown.chart_type)
          setChartError(true);
        if(!othersDropdown.test_pattern.length)
          setTestPatternError(true);
        if(!othersDropdown.torque_type)
          setTorqueError(true);
        return;
      }
      const graphData = {
        model: model,
        power_train_specifications: powerSpecifications,
        clutch_specifications: clutchSpecifications,
        vehicle_specifications: vehicleSpecifications,
        others: others,
        others_dropdown: othersDropdown,
        type: consts.VEHICLE
      }

      removeEmptyArrays(graphData);
      removeEmptyArrays(graphData);
      dispatch(getBvitGraphData(graphData));
      setIsGeneratePlotOpen(true);
    }
  }

  const closeGeneratePlot = () => {
    setFile(null);
    setIsFileUploaded(false);
    setFilterDetails(filtersData);
    setModel(initialModel);
    setPowerSpecifications(initialPowerSpecifications);
    setClutchSpecifications(initialClutchSpecifications);
    setVehicleSpecifications(initialVehicleSpecifications);
    setOthers(initialOthers);
    setOthersDropdown(initialOthersDropdown);
    setIsGeneratePlotOpen(false);
    setTorqueValues([]);
    dispatch(getReportsData());
  }

  const onResetFilters = () => {
    if(!isEmpty(filtersData)){
      setFilterDetails(filtersData);
      setModel(initialModel);
      setPowerSpecifications(initialPowerSpecifications);
      setClutchSpecifications(initialClutchSpecifications);
      setVehicleSpecifications(initialVehicleSpecifications);
      setOthers(initialOthers);
      setOthersDropdown(initialOthersDropdown);
      setTorqueValues([]);
    }
  }

  const base64ToBlob = (base64String, contentType) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return blob;
  }

  const handleOldReportDownload = (reportId) => {
    dispatch(downloadOldReport({ reportId })).then((res) => {
      if (res?.status === 200) {
        const base64Response = res?.data;
        const contentType = 'application/pdf';
        const blob = base64ToBlob(base64Response, contentType);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.setAttribute('download', `vehicle_load_${reportId}`);
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
      }
     });
  }

  return(
    <BVITDataVisualizationView 
      tabs={tabsData} 
      filterDetails={filterDetails}
      file={file}
      isFileUploaded={isFileUploaded}
      onProceedFile={onProceedFile}
      onDeleteFile={onDeleteFile}
      handleFileUpload={handleFileUpload}
      model={model}
      powerSpecifications={powerSpecifications}
      clutchSpecifications={clutchSpecifications}
      vehicleSpecifications={vehicleSpecifications}
      others={others}
      othersDropdown={othersDropdown}
      setOthersDropdown={setOthersDropdown}
      torqueValues={torqueValues}
      isGeneratePlotOpen={isGeneratePlotOpen}
      closeGeneratePlot={closeGeneratePlot}
      onGeneratePlot={onGeneratePlot}
      chartError={chartError}
      setChartError={setChartError}
      testPatternError={testPatternError}
      setTestPatternError={setTestPatternError}
      torqueError={torqueError}
      setTorqueError={setTorqueError}
      bvitGraphData={bvitGraphData}
      reportsDetails={reportsDetails}
      handleOldReportDownload={handleOldReportDownload}
      onResetFilters={onResetFilters}
    />
  )
}

export default BVITDataVisualization;