import React from 'react';
import PropTypes from 'prop-types';

//mui
import {
  Box,
  Link,
  Button,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
  FormControlLabel,
  TextField,
  Select,
  Slider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  TextareaAutosize,
} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//app
import * as utils from 'utils';
import { consts } from 'globalsData';
import { ReactComponent as Regression } from 'assets/svg/Regression.svg';
import { BootstrapTooltip, FileDropZone } from 'components';
import '../../Pages/Home/Home.css';

AddEditRegressionFormView.propTypes = {
  inputDataFileNameFromApi: PropTypes.string,
  predictionFileNameFromApi: PropTypes.string,
  pList: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  algorithms: PropTypes.array.isRequired,
  solverList: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  adaLossList: PropTypes.array.isRequired,
  weightsList: PropTypes.array.isRequired,
  boosterList: PropTypes.array.isRequired,
  gbrLossList: PropTypes.array.isRequired,
  selectionList: PropTypes.array.isRequired,
  rfCriterionList: PropTypes.array.isRequired,
  watchAlgorithm: PropTypes.string.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  summaryDeteails: PropTypes.object.isRequired,
  kNNalgorithmList: PropTypes.array.isRequired,
  gbrCriterionList: PropTypes.array.isRequired,
  getFileFieldTitle: PropTypes.func.isRequired,
  rfMaxFeaturesList: PropTypes.array.isRequired,
  lastProcessedData: PropTypes.object.isRequired,
  gbrMaxFeaturesList: PropTypes.array.isRequired,
  isNoFilesUploaded: PropTypes.object.isRequired,
  importanceTypeList: PropTypes.array.isRequired,
  isValidFileFormats: PropTypes.object.isRequired,
  isValidFileSizes: PropTypes.object.isRequired,
  fileUploadFieldList: PropTypes.array.isRequired,
  renderHyperParameters: PropTypes.func.isRequired,
  isRedirectFromEdaCharts: PropTypes.bool.isRequired,
  isLastProcessDataInprogress: PropTypes.bool.isRequired,
  nNeighbors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  brNestimators: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rfNestimators: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  testSizeRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  brMaxFeatures: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  xgbNestimators: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  adaNestimators: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  gbrNestimators: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handlers: PropTypes.shape({
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    viewLastProcessData: PropTypes.func.isRequired,
  }),
};

export function AddEditRegressionFormView({
  inputDataFileNameFromApi,
  predictionFileNameFromApi,
  pList,
  fields,
  columns,
  handlers,
  formProps,
  algorithms,
  nNeighbors,
  solverList,
  adaLossList,
  weightsList,
  boosterList,
  gbrLossList,
  brNestimators,
  rfNestimators,
  testSizeRatio,
  selectionList,
  brMaxFeatures,
  watchAlgorithm,
  gbrNestimators,
  xgbNestimators,
  adaNestimators,
  summaryDeteails,
  rfCriterionList,
  gbrCriterionList,
  kNNalgorithmList,
  handleFileUpload,
  rfMaxFeaturesList,
  lastProcessedData,
  getFileFieldTitle,
  isNoFilesUploaded,
  isValidFileFormats,
  isValidFileSizes,
  gbrMaxFeaturesList,
  importanceTypeList,
  fileUploadFieldList,
  renderHyperParameters,
  isRedirectFromEdaCharts,
  isLastProcessDataInprogress,
}) {
  const { register, handleSubmit, errors } = formProps;
  const editFormOverFlow = inputDataFileNameFromApi ? { overflowX: 'hidden', overflowY: 'auto' } : {};
  const HelperText = ({ helperTextProp }) => {
    return (
      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
        {helperTextProp}
      </FormHelperText>
    );
  };

  return (
    <>
    {!inputDataFileNameFromApi &&
      <Box sx={{ backgroundColor: '#D6E7FB', borderRadius: '18px', marginBottom: '10px', padding: '15px', textAlign: 'left', fontSize: '14px' }}>
        <Typography fontSize={14} textAlign="left" pb={0.5} >
          Data Guidelines
        </Typography>
        <ul style={{ margin: '0', paddingLeft: '30px' }}>
          <li style={{ fontSize: '14px' }}>Exclude Primary Key or ID variables and choose a numerical variable as the target.</li>
          <li style={{ fontSize: '14px' }}>Remove irrelevant variables before uploading.</li>
        </ul>
      </Box>}
    <Box sx={{ backgroundColor: '#fff', borderRadius: '18px', boxShadow: '0px 2px 5px 2px rgb(133 133 133 / 5%)', ...editFormOverFlow }}>
      <Box px={4} py={2}>
        <Box
          id="classification-form"
          mt={2}
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(handlers.onSubmit)}
          sx={
            inputDataFileNameFromApi
              ? {}
              : {
                  overflowY: 'auto', height: `${watchAlgorithm?.toLowerCase() !== 'all' && watchAlgorithm !== 'linearRegression' ? '100%' : '100%'}`, overflow: 'auto',
                }
          }
        >
          <Box>
            {inputDataFileNameFromApi && watchAlgorithm?.toLowerCase() === 'all' && <HelperText helperTextProp="You are not allowed to edit the model hyperparameters as you have selected 'All' for algorithm." />}
          </Box>
          <Grid container spacing={2}>
            {inputDataFileNameFromApi || predictionFileNameFromApi ? (
              <>
                {inputDataFileNameFromApi && (
                  <Grid item xs={12}>
                    <Typography textAlign="left" pb={0.5} fontSize={14}>
                      Input Data
                    </Typography>
                    <Box border="2px dashed #767676" px={1} py={0.5} borderRadius="6px">
                      {inputDataFileNameFromApi}
                    </Box>
                  </Grid>
                )}
                {predictionFileNameFromApi && (
                  <Grid item xs={12}>
                    <Typography textAlign="left" pb={0.5} fontSize={14}>
                      Prediciton Data
                    </Typography>
                    <Box border="2px dashed #767676" px={1} py={0.5} borderRadius="6px">
                      {predictionFileNameFromApi}
                    </Box>
                  </Grid>
                )}
              </>
            ) : (
              <>
                {fileUploadFieldList.map((item, i) => (
                  <Grid item xs={12} key={item.field}>
                    <Box id="uploadInputFile" sx={{ width: '350px' }}>
                      <Typography textAlign="left" pb={0.5} fontSize={14}>
                        {isRedirectFromEdaCharts && i === 0 ? 'Input Data' : getFileFieldTitle(item)}
                      </Typography>
                      <Box border="1px dashed #cfcaca" px={1} py={0.5} borderRadius="6px">
                        {isRedirectFromEdaCharts && i === 0 ? (
                          <>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                              <Grid item>
                                <Typography fontSize={14}>{summaryDeteails?.input_data?.fileName}</Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            {!item.file?.name ? (
                              <FileDropZone field={item.field} handleFileUpload={handleFileUpload} />
                            ) : (
                              <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                <Grid item>
                                  <Typography fontSize={14}>{item.file?.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    color="inherit"
                                    aria-label="delete file"
                                    onClick={() => handlers.onDelete(item.field)}
                                    edge="start"
                                    sx={{
                                      marginLeft: 1,
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </Box>
                      {isValidFileFormats.inputDataFile &&
                        isValidFileSizes.inputDataFile &&
                        item.field === fileUploadFieldList[0].field &&
                        isNoFilesUploaded.inputDataFile &&
                        !item.file && <HelperText helperTextProp="Please Attach (.csv or .xlsx) Input Data File" />}
                      {!isValidFileFormats.inputDataFile && item.field === fileUploadFieldList[0].field && (
                        <HelperText helperTextProp="Input data file format should be (.csv or .xlsx) and should not contains multiple (.) dots in file name" />
                      )}
                      {!isValidFileFormats.predictionDataFile && item.field === fileUploadFieldList[1].field && (
                        <HelperText helperTextProp="Prediction data file format should be (.csv or .xlsx) and should not contains multiple (.) dots in file name" />
                      )}
                      {!isValidFileSizes.inputDataFile && item.field === fileUploadFieldList[0].field && (
                        <HelperText
                          helperTextProp={`Input data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                        />
                      )}
                      {!isValidFileSizes.predictionDataFile && item.field === fileUploadFieldList[1].field && (
                        <HelperText
                          helperTextProp={`Prediction data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
              </>
            )}

            <Grid item xs={6}>
              <Box id="targetVariable">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Target Variable
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.TARGET_VARIABLE} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <Typography fontSize={14} textAlign="left" pb={0.5}></Typography>
                {inputDataFileNameFromApi && (
                  <TextField {...register('targetVariable')} {...utils.form.getFieldProp(fields, 'targetVariable')} />
                )}
                {!inputDataFileNameFromApi && (
                  <>
                    {!isRedirectFromEdaCharts ? (
                      <FormControl sx={{ width: '100%' }} error={!!errors?.targetVarialble?.message}>
                        <Select
                          {...register('targetVariable')}
                          {...utils.form.getFieldProp(fields, 'targetVariable')}
                          sx={{ fontSize: '14px', textAlign: 'left' }}
                        >
                          {columns?.map((header) => (
                            <MenuItem value={header.name} key={header.key} sx={{ fontSize: '14px' }}>
                              {header.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors?.targetVariable?.message && (
                          <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                            {errors.targetVariable.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <TextField {...register('targetVariable')} {...utils.form.getFieldProp(fields, 'targetVariable')} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="testSizeRatio">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Test Size Ratio
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.TEST_SIZE_RATIO} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                  <Grid item xs={11}>
                    <Slider {...register('testSizeRatio')} {...utils.form.getFieldProp(fields, 'testSizeRatio')} />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle2" pt={0.5}>
                      {testSizeRatio}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="algorithm">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Algorithm
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.ALGORITHM} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.algorithm?.message}>
                  <Select
                    {...register('algorithm')}
                    {...utils.form.getFieldProp(fields, 'algorithm')}
                    sx={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {algorithms.map((method) => (
                      <MenuItem value={method.value} key={method.value} sx={{ fontSize: '14px' }}>
                        {method.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.algorithm?.message && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.algorithm?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {watchAlgorithm?.toLowerCase() !== 'all' && watchAlgorithm !== 'linearRegression' && (
            <>
              <Box my={2} id="hyperParametersAccordion">
                <Typography fontSize={14} fontWeight={600} textAlign="left" color="#5a5a5a" sx={{ py: 2 }}>
                  Model Hyper Parameters
                </Typography>

                {renderHyperParameters(algorithms[1].value) && (
                  <Box id="ridgeRegression">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="alpha">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            alpha
                          </Typography>
                          <TextField
                            {...register('alpha')}
                            {...utils.form.getFieldProp(fields, 'alpha')}
                            error={!!errors?.alpha?.message}
                          />
                          {!!errors?.alpha?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.alpha?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="solver">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Solver
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.ALGORITHM} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.solver?.message}>
                            <Select {...register('solver')} {...utils.form.getFieldProp(fields, 'solver')}>
                              {solverList.map((solver) => (
                                <MenuItem value={solver.value} key={solver.value}>
                                  {solver.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.solver?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.solver?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Tolerance(tol)
                          </Typography>
                          <TextField {...register('tol')} {...utils.form.getFieldProp(fields, 'tol')} error={!!errors?.tol?.message} />
                          {!!errors?.tol?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tol?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[2].value) && (
                  <Box id="lassoRegression">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="alpha">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            alpha
                          </Typography>
                          <TextField
                            {...register('alpha')}
                            {...utils.form.getFieldProp(fields, 'alpha')}
                            error={!!errors?.alpha?.message}
                          />
                          {!!errors?.alpha?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.alpha?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="selection">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            selection
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('selection')} {...utils.form.getFieldProp(fields, 'selection')}>
                              {selectionList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('selection')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Tolerance(tol)
                          </Typography>
                          <TextField {...register('tol')} {...utils.form.getFieldProp(fields, 'tol')} error={!!errors?.tol?.message} />
                          {!!errors?.tol?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tol?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[3].value) && (
                  <Box id="randomForestRegressor">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="rfCriterion">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Criterion
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('rfCriterion')} {...utils.form.getFieldProp(fields, 'rfCriterion')}>
                              {rfCriterionList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('rfCriterion')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="rfMax_features">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Maximum Feature(max_features)
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('rfMax_features')} {...utils.form.getFieldProp(fields, 'rfMax_features')}>
                              {rfMaxFeaturesList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('rfMax_features')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="rfN_estimators">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Number of estimators(n_estimators)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={10}>
                              <Slider {...register('rfN_estimators')} {...utils.form.getFieldProp(fields, 'rfN_estimators')} />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {rfNestimators}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[4].value) && (
                  <Box id="kNNRegressor">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="kNNalgorithm">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              algorithm
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.ALGORITHM} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.kNNalgorithm?.message}>
                            <Select {...register('kNNalgorithm')} {...utils.form.getFieldProp(fields, 'kNNalgorithm')}>
                              {kNNalgorithmList.map((algorithm) => (
                                <MenuItem value={algorithm.value} key={algorithm.value}>
                                  {algorithm.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.kNNalgorithm?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.kNNalgorithm?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="n_neighbors">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Number of nearest neighbors(n_neighbors)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider {...register('n_neighbors')} {...utils.form.getFieldProp(fields, 'n_neighbors')} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {nNeighbors}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="p">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            p
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('p')} {...utils.form.getFieldProp(fields, 'p')}>
                              {pList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('p')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="weights">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            weights
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('weights')} {...utils.form.getFieldProp(fields, 'weights')}>
                              {weightsList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('weights')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[5].value) && (
                  <Box id="baggingRegressor">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="brMax_features">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Maximum Feature(max_features)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider {...register('brMax_features')} {...utils.form.getFieldProp(fields, 'brMax_features')} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {brMaxFeatures}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="brN_estimators">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Number of estimator(n_estimators)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={10}>
                              <Slider {...register('brN_estimators')} {...utils.form.getFieldProp(fields, 'brN_estimators')} />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {brNestimators}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[6].value) && (
                  <Box id="adaBoostRegressor">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="learning_rate">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            learning_rate
                          </Typography>
                          <TextField
                            {...register('learning_rate')}
                            {...utils.form.getFieldProp(fields, 'learning_rate')}
                            error={!!errors?.learning_rate?.message}
                          />
                          {!!errors?.learning_rate?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.learning_rate?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="adaLoss">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            loss
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('adaLoss')} {...utils.form.getFieldProp(fields, 'adaLoss')}>
                              {adaLossList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('adaLoss')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="adaN_estimators">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Number of estimators(n_estimators)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={10}>
                              <Slider {...register('adaN_estimators')} {...utils.form.getFieldProp(fields, 'adaN_estimators')} />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {adaNestimators}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[7].value) && (
                  <Box id="gradientBoostingRegressor">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="gbrCriterion">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Criterion
                          </Typography>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.gbrCriterion?.message}>
                            <Select {...register('gbrCriterion')} {...utils.form.getFieldProp(fields, 'gbrCriterion')}>
                              {gbrCriterionList.map((criterion) => (
                                <MenuItem value={criterion.value} key={criterion.value}>
                                  {criterion.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.gbrCriterion?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.gbrCriterion?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="gbrLoss">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            loss
                          </Typography>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.gbrLoss?.message}>
                            <Select {...register('gbrLoss')} {...utils.form.getFieldProp(fields, 'gbrLoss')}>
                              {gbrLossList.map((loss) => (
                                <MenuItem value={loss.value} key={loss.value}>
                                  {loss.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.gbrLoss?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.gbrLoss?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="gbrMaxFeatures">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Maximum Features(max_features)
                          </Typography>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.gbrMaxFeatures?.message}>
                            <Select {...register('gbrMaxFeatures')} {...utils.form.getFieldProp(fields, 'gbrMaxFeatures')}>
                              {gbrMaxFeaturesList.map((maxFeature) => (
                                <MenuItem value={maxFeature.value} key={maxFeature.value}>
                                  {maxFeature.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.gbrMaxFeatures?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.gbrMaxFeatures?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="gbrN_estimators">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Number of estimators(n_estimators)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={10}>
                              <Slider {...register('gbrN_estimators')} {...utils.form.getFieldProp(fields, 'gbrN_estimators')} />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {gbrNestimators}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Tolerance(tol)
                          </Typography>
                          <TextField {...register('tol')} {...utils.form.getFieldProp(fields, 'tol')} error={!!errors?.tol?.message} />
                          {!!errors?.tol?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tol?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[8].value) && (
                  <Box id="XGBRegressor">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="booster">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            booster
                          </Typography>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.booster?.message}>
                            <Select {...register('booster')} {...utils.form.getFieldProp(fields, 'booster')}>
                              {boosterList.map((booster) => (
                                <MenuItem value={booster.value} key={booster.value}>
                                  {booster.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.booster?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.booster?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="importance_type">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            importance_type
                          </Typography>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.importance_type?.message}>
                            <Select {...register('importance_type')} {...utils.form.getFieldProp(fields, 'importance_type')}>
                              {importanceTypeList.map((impType) => (
                                <MenuItem value={impType.value} key={impType.value}>
                                  {impType.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.importance_type?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.importance_type?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="learning_rate">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            learning_rate
                          </Typography>
                          <TextField
                            {...register('learning_rate')}
                            {...utils.form.getFieldProp(fields, 'learning_rate')}
                            error={!!errors?.learning_rate?.message}
                          />
                          {!!errors?.learning_rate?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.learning_rate?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="xgbN_estimators">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Number of estimators(n_estimators)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={10}>
                              <Slider {...register('xgbN_estimators')} {...utils.form.getFieldProp(fields, 'xgbN_estimators')} />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {xgbNestimators}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box id="justificationRemarks" mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box id="justificationRemarks">
                  <Typography textAlign="left" pb={0.5} fontSize={14}>
                    Justification Remarks
                  </Typography>
                  <FormControl sx={{ width: '100%' }} error={!!errors?.justificationRemarks?.message}>
                    <TextareaAutosize
                      {...register('justificationRemarks')}
                      {...utils.form.getFieldProp(fields, 'justificationRemarks')}
                      className="textAreaStyles"
                    />

                    {!!errors?.justificationRemarks?.message && (
                      <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors.justificationRemarks.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box id="actionsButtons" sx={{ background: '#d2d2d2' }} px={4} py={2}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                borderRadius: '99px',
                p: '0.4rem 1.5rem',
              }}
              type="submit"
              onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
              disabled={isLastProcessDataInprogress}
            >
              {`${inputDataFileNameFromApi ? 'Update Analysis' : 'Analyze'}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
    </>
  );
}
