import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const addWebsite = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(addWebsiteRequest(reqParams));
  dispatch(addLoader('addWebsite'));

  const { websiteName, websiteUrl, websiteDescription, categoryList, crawlerScript } = reqParams;

  const data = {
    websiteName,
    description: websiteDescription,
    websiteUrl,
    categoryList,
    crawlerScript,
  };

  return api
    .multipartPost({
      endpoint: endpoint.websiteService,
      path: 'v1/websiteMgmt',
      data,
    })
    .then((res) => {
      dispatch(addWebsiteSuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message, 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(addWebsiteError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
      
      return error;
    })
    .finally(() => {
      dispatch(removeLoader('addWebsite'));
    });
};

export const addWebsiteRequest = (params) => {
  return {
    type: 'ADD_WEBSITE_REQUEST',
    payload: params,
  };
};

export const addWebsiteSuccess = (data) => {
  return {
    type: 'ADD_WEBSITE_SUCCESS',
    payload: data,
  };
};

export const addWebsiteError = (error) => {
  return {
    type: 'ADD_WEBSITE_FAILURE',
    payload: error,
  };
};
