import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

// app
import { DataGridCellExpand, DataGridTable } from 'components';
import * as utils from 'utils';
import { useMedia } from 'globalsData';

// mui
import { Box } from '@mui/material';
import { clearViewRecordsList } from 'stores';

export default function ViewCrawlerRecordsModal() {
  const dispatch = useDispatch();
  const { mobile, tablet } = useMedia();
  const viewTopRecords = useSelector((state) => get(state, 'categoryManagement.viewRecordsList', []));

  useEffect(() => {
    // clean up
    return () => {
      dispatch(clearViewRecordsList());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const capitalizeFirstLetter = (str) => {
    // converting first letter to uppercase
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const columns = utils.generic.isValidArray(viewTopRecords, true)
    ? Object.keys(viewTopRecords[0])
        ?.filter((c) => c?.toLocaleLowerCase() !== 'cdid')
        ?.map((c, i, arr) => ({
          field: c,
          headerName: c
            ?.split(/([A-Z][a-z]+)/)
            ?.map((x) => capitalizeFirstLetter(x))
            ?.filter(Boolean)
            ?.join(' '),

          flex: arr?.length / 100,
          renderCell: (params) => <DataGridCellExpand value={params.value || ''} whiteSpace="break-spaces" />,
          ...((mobile || tablet) && { minWidth: 150 }),
        }))
    : [];

  const rows = utils.generic.isValidArray(viewTopRecords, true) ? viewTopRecords : [];

  if (!utils.generic.isValidArray(columns, true)) {
    return null;
  }

  return (
    <Box p={2} style={{ height: 800 }}>
      <DataGridTable
        checkboxSelection={false}
        columns={columns}
        rows={rows}
        uniqRowId={'cdId'}
        isSearchable={false}
        pageSize={10}
        rowsPerPageOptions={[10]}
        height={450}
        emptyRowsText="No record(s) found"
      />
    </Box>
  );
}
