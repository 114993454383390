const initialState = {
    filtersData: {},
    bvitGraphData: {},
    reportsData: {}
  };
  
  const bvitReducers = (state = initialState, action = {}) => {
    switch (action.type) {
      case 'GET_FILTERS_DATA_SUCCESS':
        return {
          ...state,
          filtersData: action.payload || {},
        };
        case 'GET_BVIT_GRAPH_DATA_SUCCESS':
          return {
            ...state,
            bvitGraphData: action.payload || {},
          };
        case 'GET_REPORTS_DATA_SUCCESS':
          return {
            ...state,
            reportsData: action.payload || {},
          };
    
      default:
        return state;
    }
  };
  
  export default bvitReducers;  