import React from 'react';
import PropTypes from 'prop-types';

// app
import { Breadcrumbs, DataGridTable } from 'components';
import Categories from '../../Pages/Categories/Categories';

// mui
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactComponent as NewWebsite } from 'assets/svg/newWebsite.svg';
import { ReactComponent as Refresh } from 'assets/svg/refresh.svg';

CategoryManagmentView.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  categoryName: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  websiteId: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    handleRefreshWebsiteList: PropTypes.func.isRequired,
    runMultiScripts: PropTypes.func.isRequired,
    handleAddWebsite: PropTypes.func.isRequired,
    handleRowSelectable: PropTypes.func.isRequired,
    selectedRowIds: PropTypes.func.isRequired,
  }),
};

export function CategoryManagmentView({ categoryName, isAdmin, rows, columns, websiteId, selectedItems, breadcrumbs, handlers }) {
  const isMultiWebSitesSelected = Array.isArray(selectedItems) && selectedItems?.length > 1;

  return (
    <div>
      {/* <Box px={4} py={1} sx={{ backgroundColor: (theme) => theme.palette.primary.white }}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Divider /> */}
      <Box px={4} py={2} >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '18px', py:1}}>
             Data Crawlers
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '14px' }}>
              {categoryName}
            </Typography>
          </Grid>

          <Grid item> 
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Box onClick={handlers.handleRefreshWebsiteList} sx={{cursor:'pointer'}}>
                <Refresh width={30} height={30} />
                </Box>
                {/* <Button
                  variant="text"
                  size="small"
                  sx={{
                    textTransform: 'none',
                    color: '#000000',
                    '&& > span': {
                      marginRight: '5px',
                    },
                  }}
                  startIcon={<AutorenewIcon sx={{ color: '#666666' }} />}
                  onClick={handlers.handleRefreshWebsiteList}
                >
                  Refresh
                </Button> */}
              </Grid>
              {isAdmin && (
                <>
                  {/* <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        textTransform: 'none',
                        color: '#000000',
                        '&& > span': {
                          marginRight: '5px',
                        },
                      }}
                      startIcon={<RefreshIcon sx={{ color: isMultiWebSitesSelected ? '#666666' : 'disabled' }} />}
                      disabled={!isMultiWebSitesSelected}
                      onClick={handlers.runMultiScripts}
                    >
                      Run Script
                    </Button>
                  </Grid> */}
                  <Grid item>
                    <Box onClick={handlers.handleAddWebsite} sx={{cursor:'pointer'}}>
                      <NewWebsite width={30} height={30} />
                    </Box>
                    {/* <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        textTransform: 'none',
                        backgroundColor: '#EFF0FD',
                        '&& > span': {
                          marginRight: '5px',
                        },
                      }}
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={handlers.handleAddWebsite}
                    >
                      New Website
                    </Button> */}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* <Divider /> */}
      <Grid container>
        <Grid item xs={3}>
          <Categories />
        </Grid>
        <Grid item xs={9}>
          <Box mx={2} mt={4}>
            <DataGridTable
              checkboxSelection={!!isAdmin}
              columns={columns}
              rows={rows}
              uniqRowId={websiteId}
              handleRowSelectable={handlers.handleRowSelectable}
              handlers={{ selectedRowIds: handlers.selectedRowIds }}
              emptyRowsText="No website(s) found"
              height={350}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
