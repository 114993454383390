import React from 'react';
import PropTypes from 'prop-types';

//app
import { useMedia } from 'globalsData';

// mui
import { Typography, Grid } from '@mui/material';

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph1: PropTypes.string,
  paragraph2: PropTypes.string,
  icon: PropTypes.any,
};

export default function Banner({ title, paragraph1, paragraph2, icon }) {
  const { mobile, tablet } = useMedia();

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start" pb={2}>
      <Grid item xs={12} md={8}>
        <Typography variant="subtitle1" fontSize={30} fontWeight={700} pb={2} color={(theme) => theme.palette.primary.titleColor}>
          {title}
        </Typography>
        {paragraph1 && (
          <Typography variant="body1" fontSize={16} pb={1} color={(ftheme) => ftheme.palette.primary.textColor}>
            {paragraph1}
          </Typography>
        )}
        {paragraph2 && (
          <Typography variant="body1" fontSize={16} color={(stheme) => stheme.palette.primary.textColor}>
            {paragraph2}
          </Typography>
        )}
      </Grid>
      {!(mobile || tablet) && (
        <Grid item md={4} textAlign="center">
          <img src={icon} alt={title} style={{ height: '160px', width: '160px' }} />
        </Grid>
      )}
    </Grid>
  );
}
