import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getAverageResponseTime = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

 
  dispatch(getAverageResponseTimeRequest());
 
  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'averageAPIResponseTime'
    })
    .then((res) => {
      dispatch(getAverageResponseTimeSuccess(res?.data || {}));
    })
    .catch((error) => {
      dispatch(getAverageResponseTimeError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getAverageResponseTime'));
    });
};

export const getAverageResponseTimeRequest = () => {
  return {
    type: 'GET_AVERAGE_RESPONSE_TIME_REQUEST',
  };
};

export const getAverageResponseTimeSuccess = (data) => {
  return {
    type: 'GET_AVERAGE_RESPONSE_TIME_SUCCESS',
    payload: data,
  };
};

export const getAverageResponseTimeError = (error) => {
  return {
    type: 'GET_AVERAGE_RESPONSE_TIME_FAILURE',
    payload: error,
  };
};
