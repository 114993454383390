import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';
import { consts, config } from 'globalsData';
export const downloadFile = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!params?.trackerId || !params?.fileType || !params?.fileName) {
    return Promise.reject({ message: 'request params are missing' });
  }

  dispatch(downloadFileRequest());
  dispatch(addLoader('downloadFile'));

  const type = params?.type;
  const path = params?.path || '';
  const isForAudioDownload = params?.isForDownload || false;

  let qparams = {
    trackerId: params?.trackerId,
    fileType: params?.fileType,
    fileName: params?.fileName,
  };

  if (type === consts.ACCELERATORS.SPEECH_TO_TEXT) {
    qparams = { ...qparams, ...(type !== undefined && { type }) };
  }

  const downloadFileUsingUrl = (url, fileName, fileType) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `${fileName}.${fileType}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      dispatch(snackbarNotification('Missing signed URL', 'error'));
    }
  };

  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: path ? path : 'download-file',
      params: qparams,
    })
    .then(async (res) => {
      if (res?.data?.singedUrl) {
        if ((type === consts.ACCELERATORS.SPEECH_TO_TEXT || type === consts.TEXT_TO_SPEECH) && !isForAudioDownload) {
          dispatch(downloadFileSuccess(res?.data?.singedUrl));
          return res?.data?.singedUrl;
        }
        const fileNames = params?.fileName?.split(',');
        downloadFileUsingUrl(res?.data?.singedUrl[0][fileNames[0]?.split('.')[0]], fileNames[0]?.split('.')[0], params?.fileType);
        if (type === 'eda' && fileNames?.length > 1) {
          downloadFileUsingUrl(res?.data?.singedUrl[1][fileNames[1]?.split('.')[0]], fileNames[1]?.split('.')[0], params?.fileType);
        }
      } else {
        dispatch(snackbarNotification('Url not found', 'error'));
      }
    })
    .catch((error) => {
      dispatch(downloadFileError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('downloadFile'));
    });
};

export const downloadFileRequest = () => {
  return {
    type: 'DOWNLOAD_ALL_DATA_REQUEST',
  };
};

export const downloadFileSuccess = (data) => {
  return {
    type: 'DOWNLOAD_ALL_DATA_SUCCESS',
    payload: data,
  };
};

export const downloadFileError = (error) => {
  return {
    type: 'DOWNLOAD_ALL_DATA_FAILURE',
    payload: error,
  };
};
