import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import BVITGraphVisualizationView from './BVITGraphVisualization.view';
import { barChartOptions, consts, lineChartOptions, scatterPlotOptions } from 'globalsData';
import BVITSummaryStatistics from './BVITGraphSubModules/BVITSummaryStatistics/BVITSummaryStatistics';
import { saveReportData } from 'stores/bvit/bvit.actions.saveReportData';
import { snackbarNotification } from 'stores';
import * as htmlToImage from "html-to-image";

const BVITGraphVisualization = ({ closeGeneratePlot, bvitGraphData, model, powerSpecifications,
  clutchSpecifications, vehicleSpecifications, others, othersDropdown }) => {
  const dispatch = useDispatch();
  const initialGroupKeyValues = Array(othersDropdown.test_pattern.length).fill('');
  const chartRefs = useRef([]);

  const [chartOptions, setChartOptions] = useState([]);
  const [minGraphValues, setMinGraphValues] = useState([]);
  const [maxGraphValues, setMaxGraphValues] = useState([]);
  const [tmValues, setTmValues] = useState([]);
  const [tmErrorValues, setTmErrorValues] = useState([]);
  const [groupKeyValues, setGroupKeyValues] = useState(initialGroupKeyValues);
  const [tableData, setTableData] = useState([]);
  const [objective, setObjective] = useState('');
  const [background, setBackground] = useState('');
  const [specialRemarks, setSpecialRemarks] = useState('');
  const [conclusion, setConclusion] = useState('');

  const [objectiveError, setObjectiveError] = useState(false);
  const [backgroundError, setBackgroundError] = useState(false);
  const [conclusionError, setConclusionError] = useState(false);
  const groupKeys = bvitGraphData.length && bvitGraphData?.map((data) => data?.groupKey);

  const tabsData = [
    { label: 'Model', content: <BVITSummaryStatistics summaryDetails={model} label={'model'} /> },
    { label: 'Power Train Specifications', content: <BVITSummaryStatistics summaryDetails={powerSpecifications} label={'power'} /> },
    { label: 'Clutch Specifications', content: <BVITSummaryStatistics summaryDetails={clutchSpecifications} label={'clutch'} /> },
    { label: 'Vehicle Specifications', content: <BVITSummaryStatistics summaryDetails={vehicleSpecifications} label={'vehicle'} /> },
    { label: 'Others', content: <BVITSummaryStatistics summaryDetails={others} label={'other'} /> },
    { label: 'Graph Specifications', content: <BVITSummaryStatistics summaryDetails={othersDropdown} label={'graph'} /> }
  ];
    
  const convertToSnakeCase = (str) => {
    return str.toLowerCase().replace(/-/g, " ").replace(/\s+/g, '_');
  }

  useEffect(() => {
    let graphOptions = [];
    let newOptions = [];
    if(bvitGraphData.length) {
      othersDropdown.test_pattern.map((pattern) => {
        let options = {
          testPattern: pattern,
          groupKey: bvitGraphData?.map((data) => data?.groupKey),
          torqueType: othersDropdown.torque_type,
          torqueValue: ''
      };
      const valueType = convertToSnakeCase(options.torqueType + " " + pattern);
      const torqueValue = bvitGraphData?.map((data) => data[valueType]);
      options.torqueValue = torqueValue;
      graphOptions.push(options);
    })

    if(othersDropdown.chart_type === consts.graphType.BAR_GRAPH){
     graphOptions.map((option) => {
      const barChart = barChartOptions(option.testPattern, option.groupKey, option.torqueType, option.torqueValue);
        newOptions.push(barChart);
      })
     }

     if(othersDropdown.chart_type === consts.graphType.LINE_GRAPH){
       graphOptions.map((option) => {
        const lineChart = lineChartOptions(option.testPattern, option.groupKey, option.torqueType, option.torqueValue);
        newOptions.push(lineChart);
      })
    }

    if(othersDropdown.chart_type === consts.graphType.SCATTER_PLOT){
      graphOptions.map((option) => {
       const scatterPlot = scatterPlotOptions(option.testPattern, option.groupKey, option.torqueType, option.torqueValue);
       newOptions.push(scatterPlot);
      })
     }
    }
    setChartOptions(newOptions);
  }, [bvitGraphData]);

  const onMinTorqueChange = (event, index) => {
    if(event.target.value){
      let minValue = Number(event.target.value);
      if (/^-?\d*$/.test(minValue)) {
        if (chartRefs.current && chartRefs.current[index]) {
          const chart = chartRefs.current[index].chart;
          chart.yAxis[0].setExtremes(minValue, maxGraphValues[index]);
          const newValues = [...minGraphValues];
          newValues[index] = minValue;
          setMinGraphValues(newValues);
        }
      }
    }
  }

  const onMaxTorqueChange = (event, index) => {
    if(event.target.value){
      let maxValue = Number(event.target.value);
      if (/^-?\d*$/.test(maxValue)) {
        if (chartRefs.current && chartRefs.current[index]) {
          const chart = chartRefs.current[index].chart;
          chart.yAxis[0].setExtremes(minGraphValues[index], maxValue);
          const newValues = [...maxGraphValues];
          newValues[index] = maxValue;
          setMaxGraphValues(newValues);
        }
      }
    }
  }

    const onTMLimitChange = (event, option, index) => {
      if(event.target.value){
        if(option?.chart?.type === "column" || option?.chart?.type === "line"){
          const plotLines = [{
            color: 'green',
            value: event.target.value,
            width: 2,
            zIndex: 5
          }];
         
          const newOptions = chartOptions.map(item => {
            if (item.title.text !== option.title.text) {
                return item;
            }
    
          const updatedDetails = {
            ...item.yAxis,
            ['plotLines']: plotLines
          };
  
          return {
            ...item,
            yAxis: updatedDetails
         };
        });
        setChartOptions(newOptions);
        }
        else if(option?.chart?.type === "scatter"){
          const newOptions = chartOptions.map(item => {
          if (item.title.text !== option.title.text) {
            return item;
          }

          const horizontalLineData = item.xAxis.categories.map((category) => {
            return [category, Number(event.target.value)];
          });

          const horizontalLine = {
            name: 'Horizontal Line',
            type: 'line',
            data: horizontalLineData,
            color: 'green',
            marker: {
              enabled: false
            }
          }
          const itemSeries = item.series[0];
          return {
            ...item,
            series: [itemSeries, horizontalLine]
         };
        });
        setChartOptions(newOptions);
        }
      }
      const newTMValues = [...tmValues];
      newTMValues[index] = Number(event.target.value);
      setTmValues(newTMValues);
      const newErrorValues = [...tmErrorValues];
      newErrorValues[index] = false;
      setTmErrorValues(newErrorValues); 
    }

    const onGroupKeyChange = (event, option, index) => {
      if(event.target.value){
        if (tmValues.length && tmValues[index]) {
          const newGroupValues = [...groupKeyValues];
          newGroupValues[index] = event.target.value;
          setGroupKeyValues(newGroupValues);
          const groupIndex = option.xAxis.categories.indexOf(event.target.value);
          const maxTorque = option.series[0].data[groupIndex];
          let percentageMargin = ((maxTorque - tmValues[index])/tmValues[index])*100;
          percentageMargin = percentageMargin.toFixed(2);
          const newData = { test_pattern: option.title.text, group_key: event.target.value, max_torque: maxTorque, tm_limit: tmValues[index], percent_margin: percentageMargin, remarks: '' };
          setTableData([...tableData, newData]);
        }
        else{
          const newErrorValues = [...tmErrorValues];
          newErrorValues[index] = true;
          setTmErrorValues(newErrorValues);
        }
      }
    }

    const handleRemarksChange = (event, index) => {
      const newTableData = [...tableData];
      newTableData[index] = { ...tableData[index], remarks: event.target.value };
      setTableData(newTableData);
    }

    const onSaveReport = async () => {
      if(!objective || !background || !conclusion){
        if(!objective)
          setObjectiveError(true);
        if(!background)
          setBackgroundError(true);
        if(!conclusion)
          setConclusionError(true);
        return;
      }
      const chartImages = [];
      var elements = document.getElementsByClassName('custom-chart');
      for (let i = 0; i < elements.length; i++) {
        const el = elements.item(i);
        const imgData = await htmlToImage.toPng(el);
        chartImages.push(imgData);
      }

      const newTableData = tableData.map((data) => {
        if (data.hasOwnProperty('remarks') && !data['remarks']) {
          delete data['remarks'];
        }
        return data;
      })

      const reportsData = {
        "model": model,
        "power_train_specifications": powerSpecifications,
        "clutch_specifications": clutchSpecifications,
        "vehicle_specifications": vehicleSpecifications,
        "others": others,
        "others_dropdown": othersDropdown,
        "tm_limit_data": newTableData,
        "type": consts.VEHICLE,
        "objective": objective,
        "background": background,
        "conclusion": conclusion,
        "chartImages": chartImages
    }
    if(specialRemarks){
      reportsData['remarks'] = specialRemarks;
    }

    dispatch(saveReportData(reportsData)).then((res) => {
      if (res?.status === 200) {
        dispatch(snackbarNotification('Report saved successfully', 'success'));
      }
    });
  }

  return(
    <BVITGraphVisualizationView 
      closeGeneratePlot={closeGeneratePlot} 
      chartOptions={chartOptions}
      onTMLimitChange={onTMLimitChange}
      groupKeyValues={groupKeyValues}
      onGroupKeyChange={onGroupKeyChange}
      tmErrorValues={tmErrorValues}
      bvitGraphData={bvitGraphData}
      groupKeys={groupKeys}
      tabs={tabsData}
      chartRefs={chartRefs}
      onMinTorqueChange={onMinTorqueChange}
      onMaxTorqueChange={onMaxTorqueChange}
      tableData={tableData}
      handleRemarksChange={handleRemarksChange}
      setObjective={setObjective}
      setBackground={setBackground}
      setSpecialRemarks={setSpecialRemarks}
      setConclusion={setConclusion}
      onSaveReport={onSaveReport}
      objectiveError={objectiveError}
      setObjectiveError={setObjectiveError}
      backgroundError={backgroundError}
      setBackgroundError={setBackgroundError}
      conclusionError={conclusionError}
      setConclusionError={setConclusionError} />
  )
}

export default BVITGraphVisualization;