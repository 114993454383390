//svg images
import { ReactComponent as ComponentPerformanceScreen } from 'assets/svg/ComponentPerformanceScreen.svg';
import { ReactComponent as FailureProbabilityScreen } from 'assets/svg/FailureProbabilityScreen.svg';
import { ReactComponent as QualityIntelligenceScreen } from 'assets/svg/QualityIntelligenceScreen.svg';
import { ReactComponent as SocialMediaScreen } from 'assets/svg/SocialMediaScreen.svg';
import { ReactComponent as VendorPerformanceScreen } from 'assets/svg/VendorPerformanceScreen.svg';
import VendorPerformanceAnalytics from '../../assets/png/newVendorPerformanceAnalytics.png';
import BusinessSolumtions from '../../assets/png/businessSolution.png';
import DataCrwalers from '../../assets/png/dataCrawler.png';
import SocialMediaAnalytics from '../../assets/png/newSocialMediaAnalytics.png';
import FailureProbabilityAnalytics from '../../assets/png/newFailureProbabilityAnalytics.png';
import QualityIntelligenceAnalytics from '../../assets/png/newQualityIntelligenceAnalytics.png';
import ComponentPerformance from '../../assets/png/newComponentPerformance.png';
import VendorPerformanceAnalyticsPDF from '../../assets/files/vendorPerformanceAnalytics.pdf';
import SocialMediaAnalyticsPDF from '../../assets/files/socialMediaAnalytics.pdf';
import FailureProbabilityAnalyticsPDF from '../../assets/files/failureProbabilityAnalytics.pdf';
import QualityIntelligenceAnalyticsPDF from '../../assets/files/qualityIntelligenceAnalytics.pdf';
import ComponentPerformancePDF from '../../assets/files/componentPerformance.pdf';

const qualityAssuranceTiles = [
  {
    name: 'Vendor Performance Analytics',
    title: 'Vendor Performance Analytics',
    // icon: ASIconDataPreparation,
    description: 'Analyze vendor data for enhanced quality performance insights',
    tooltip: 'Gain insights into supplier quality through integrated data, assessing performance at various levels. Evaluate delivery reliability and product quality for informed decision-making, fostering strong partnerships, and enhancing overall supply chain efficiency.',
    // highlights: highlights.DataPreparation,
    tileLink: 'https://app.powerbi.com/groups/me/reports/f3310b8d-7449-45dc-9657-a90c0facf419?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
    vertical: 'Quality Assurance',
    // btnText: 'Explore',
    icon: VendorPerformanceScreen,
    img: VendorPerformanceAnalytics,
    pdfFile: VendorPerformanceAnalyticsPDF,
  },
  {
    name: 'Social Media Analytics',
    title: 'Social Media Analytics',
    description: 'Utilize social media data to assess quality perceptions',
    tooltip: 'Monitor social media platforms to gauge public sentiment and perceptions regarding product quality. Leverage this real-time data to proactively address potential concerns, enhance brand reputation, and align marketing strategies with customer expectations.',
    tileLink: 'https://app.powerbi.com/groups/me/reports/77da7128-43dc-4879-adf4-d008bcc2efaf?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
    vertical: 'Quality Assurance',
    // btnText: 'Explore',
    icon: SocialMediaScreen,
    img: SocialMediaAnalytics,
    pdfFile: SocialMediaAnalyticsPDF,
  },
  {
    name: 'Failure Probability Analytics',
    title: 'Failure Probability Analytics',
    description: 'Predict failure probabilities for proactive quality management',
    tooltip: 'Utilize predictive analytics to identify potential failure points in components. Anticipate and mitigate risks through proactive countermeasures, preventing recurrence and ensuring product reliability. Enhance overall quality performance by addressing critical areas with data-driven precision.',
    tileLink: 'https://app.powerbi.com/groups/me/reports/9b0e8880-bef8-49f8-88f1-7f1aef585c57?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
    vertical: 'Quality Assurance',
    // btnText: 'Explore',
    icon: FailureProbabilityScreen,
    img: FailureProbabilityAnalytics,
    pdfFile: FailureProbabilityAnalyticsPDF,
  },
  {
    name: 'Quality Intelligence Analytics',
    title: 'Quality Intelligence Analytics',
    description: 'Forecasting & reporting of warranty claims for top components',
    tooltip: 'A system for forecasting and reporting warranty claims, emphasizing top components. Analyse metrics such as the number of claims, cost per vehicle, and claim ratio to optimize efficiency, reduce costs, and enhance overall warranty management.',
    tileLink:'https://app.powerbi.com/groups/me/reports/5730f1cb-376b-4016-a707-ad16dcc40c33/ReportSectiona4edefef60de73b097bc?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5&bookmarkGuid=Bookmark8f832dcde82886cca460',
    vertical: 'Quality Assurance',
    // btnText: 'Explore',
    icon: QualityIntelligenceScreen,
    img: QualityIntelligenceAnalytics,
    pdfFile: QualityIntelligenceAnalyticsPDF,
  },
  {
    name: 'Component Performance Analytics',
    title: 'Component Performance',
    description: 'Evaluate component performance for effective quality control',
    tooltip: 'Evaluate the performance of individual components to ensure adherence to quality standards. Utilize integrated data to assess reliability and functionality, identifying areas for improvement. Enable targeted enhancements for specific components, contributing to overall product quality and customer satisfaction.',
    tileLink:'https://app.powerbi.com/groups/me/reports/2e9c4fc3-0f8f-4365-ac9a-582165df82c5/ReportSectionc655a6ff9eddedcb7181?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
    vertical: 'Quality Assurance',
    // btnText: 'Explore',
    icon: ComponentPerformanceScreen,
    img: ComponentPerformance,
    pdfFile: ComponentPerformancePDF,
  },
];

export default qualityAssuranceTiles;
