import React from 'react';
import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  IconButton,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  Typography,
  Button,
  TextareaAutosize,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { consts } from 'globalsData';
import { BootstrapTooltip, FileDropZone } from 'components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as utils from 'utils';

export default function AddAnomalyDetectionFormView({
  fields,
  inputText,
  inputTextCount,
  isInputNotUpdated,
  handleInputTextChange,
  file,
  isNoFileUploaded,
  isInValidFileSize,
  isInValidFileFormat,
  handleFileUpload,
  radioValue,
  handleRadioValueChange,
  sourceLanguageList,
  targetLanguageList,
  formProps,
  handlers,
}) {
  const { register, handleSubmit, errors } = formProps;
  const HelperText = ({ helperTextProp }) => {
    return (
      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText, width: '100%' }}>
        {helperTextProp}
      </FormHelperText>
    );
  };

  return (
    <>
    <Box sx={{ backgroundColor: '#D6E7FB', borderRadius: '18px', marginBottom: '10px', padding: '15px', textAlign: 'left', fontSize: '14px' }}>
      <Typography fontSize={14} textAlign="left" pb={0.5} >
        Data Guidelines
      </Typography>
      <ul style={{ margin: '0', paddingLeft: '30px' }}>
        <li style={{ fontSize: '14px' }}>Language Selection: Indicate the source language of the text file and the target language for the audio output.</li>
        <li style={{ fontSize: '14px' }}>Language Accuracy: For translation accelerators, ensure the selected languages accurately match the content to avoid translation errors.</li>
      </ul>
    </Box>
    <Box sx={{ borderRadius: '18px', backgroundColor: '#fff', height: '100%', overflowY: 'auto', overflow: 'auto' }}>
      <Box sx={{ padding: '1rem' }}>
        <Box id="text-to-speech-form" mt={2} component="form" autoComplete="off" onSubmit={handleSubmit(handlers.onSubmit)}>
          <Grid container spacing={1} height={radioValue === 'inputText' ? 120 : 'auto'}>
            <Grid item xs={6} sx={{ borderRight: 'none' }}>
              <Typography variant="h6" mb={1} display="flex" fontSize={14}>
                Text Source
              </Typography>
              <RadioGroup
                sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
                value={radioValue}
                onChange={(e) => handleRadioValueChange(e)}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: '#768396',
                    borderColor: '#c1c2c4',
                    textTransform: 'capitalize',
                    height: '35px',
                    padding: 0,
                    paddingX: '1rem',
                  }}
                >
                  <FormControlLabel
                    value="inputText"
                    control={<Radio size="sm" />}
                    label="Input text"
                    sx={{ '& .MuiTypography-root': { fontSize: '0.8rem' } }}
                  />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    color: '#768396',
                    borderColor: '#c1c2c4',
                    textTransform: 'capitalize',
                    height: '35px',
                    padding: 0,
                    paddingX: '1rem',
                  }}
                >
                  <FormControlLabel
                    value="file"
                    control={<Radio size="sm" />}
                    label="Upload File"
                    sx={{ '& .MuiTypography-root': { fontSize: '0.8rem' } }}
                  />
                </Button>
              </RadioGroup>
            </Grid>
            {radioValue === 'inputText' && (
              <Grid item xs={6}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pl={1} pb={1} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Input Text
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.TEXT_TO_SPEECH_INPUT_TEXT} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '97%', left: '4px' }}>
                  <TextareaAutosize
                    value={inputText}
                    className="textAreaStyles"
                    placeholder="Enter text here..."
                    onChange={(e) => handleInputTextChange(e)}
                  />
                </FormControl>
                <div style={{ marginLeft: '.5rem', display: 'flex' }}>
                  <div style={{ width: '90%' }}>{isInputNotUpdated && <HelperText helperTextProp="Input Text is required" />}</div>
                  <Typography variant="h6" fontSize={10}>
                    {inputTextCount}/5000
                  </Typography>
                </div>
              </Grid>
            )}
            {radioValue !== 'inputText' && (
              <Grid item xs={6}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pl={1} pb={1} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Upload Text File
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.TEXT_TO_SPEECH_UPLOAD_FILE} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <Box border="1px dashed #cfcaca" px={1} py={0.5} borderRadius="6px" ml={1}>
                  {!file?.name ? (
                    <FileDropZone handleFileUpload={handleFileUpload} isItForTextFile />
                  ) : (
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid file>
                        <Typography fontSize={14}>{file?.name}</Typography>
                      </Grid>
                      <Grid file>
                        <IconButton
                          color="inherit"
                          aria-label="delete file"
                          onClick={() => handlers.onDelete()}
                          edge="start"
                          sx={{
                            marginLeft: 1,
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <div
                  style={{
                    marginTop: '.5rem',
                    marginBottom: '.5rem',
                    marginLeft: '.5rem',
                  }}
                >
                  {!file && isNoFileUploaded && <HelperText helperTextProp="Please Attach (.txt) Input Data File" />}
                  {!file && isInValidFileFormat && (
                    <HelperText helperTextProp="Input data file format should be (.txt) and should not contains multiple (.) dots in file name" />
                  )}
                  {!file && isInValidFileSize && (
                    <HelperText
                      helperTextProp={`Input data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                    />
                  )}
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container mt={1} spacing={3}>
            <Grid item xs={6}>
              <Box id="sourceLanguage">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Source Language
                  </Typography>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.sourceLanguage?.message}>
                  <Select
                    displayEmpty
                    renderValue={(value) => (value === undefined ? 'Select a language' : value)}
                    sx={{ fontSize: '14px', textAlign: 'left', '& .MuiSelect-select': { padding: '10px' } }}
                    {...register('sourceLanguage')}
                    {...utils.form.getFieldProp(fields, 'sourceLanguage')}
                  >
                    {sourceLanguageList.map((method) => (
                      <MenuItem value={method.language} key={method.code} sx={{ fontSize: '14px' }}>
                        {method.language}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.sourceLanguage?.message && (
                    <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.sourceLanguage?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="targetLanguage">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Target Language
                  </Typography>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.targetLanguage?.message}>
                  <Select
                    displayEmpty
                    renderValue={(value) => (value === undefined ? 'Select a language' : value)}
                    sx={{ fontSize: '14px', textAlign: 'left', '& .MuiSelect-select': { padding: '10px' } }}
                    {...register('targetLanguage')}
                    {...utils.form.getFieldProp(fields, 'targetLanguage')}
                  >
                    {targetLanguageList.map((method) => (
                      <MenuItem value={method.language} key={method.code} sx={{ fontSize: '14px' }}>
                        {method.language}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.targetLanguage?.message && (
                    <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.targetLanguage?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Box id="justificationRemarks">
              <Typography textAlign="left" pb={0.5} fontSize={14}>
                Justification Remarks
              </Typography>
              <FormControl sx={{ width: '100%' }} error={!!errors?.justificationRemarks?.message}>
                <TextareaAutosize
                  className="textAreaStyles"
                  {...register('justificationRemarks')}
                  {...utils.form.getFieldProp(fields, 'justificationRemarks')}
                  placeholder="Enter text here..."
                />
                {!!errors?.justificationRemarks?.message && (
                  <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                    {errors.justificationRemarks.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box id="actionsButtons" py={4}>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  borderRadius: '10px',
                  p: '0.4rem 10.5rem',
                }}
                type="submit"
                onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
              >
                Translate
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
   </>
  );
}
