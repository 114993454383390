import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const downloadScript = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(downloadScriptRequest());
  dispatch(addLoader('downloadScript'));

  return api
    .get({
      endpoint: endpoint.crawlerScriptService,
      path: `v1/scraping/downloadScript/${params?.websiteId}`,
    })
    .then((res) => {
      const url = res?.data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${'pythonScript'}.${'py'}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => {
      dispatch(downloadScriptError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('downloadScript'));
    });
};

export const downloadScriptRequest = () => {
  return {
    type: 'DOWNLOAD_SCRIPT_REQUEST',
  };
};

export const downloadScriptSuccess = (data) => {
  return {
    type: 'DOWNLOAD_SCRIPT_SUCCESS',
    payload: data,
  };
};

export const downloadScriptError = (error) => {
  return {
    type: 'DOWNLOAD_SCRIPT_FAILURE',
    payload: error,
  };
};
