import React from 'react';
import './BVITTable.css';
import { TextField } from '@mui/material';

export default function BVITGraphTableView({ tableData, handleRemarksChange }) {
  return (
  <table className='bvit-data-table'>
    <thead>
      <tr>
        <th>Test Pattern</th>
        <th>Target Model</th>
        <th>Max Torque (Nm)</th>
        <th>TM Limit (Nm)</th>
        <th>% Margin W.R.T Limit</th>
        <th>Remarks</th>
      </tr>
    </thead>
    <tbody>
      {tableData.map((data, i) => 
      <tr key={i}>
      <td>{data.test_pattern}</td>
      <td>{data.group_key}</td>
      <td>{data.max_torque}</td>
      <td>{data.tm_limit}</td>
      <td>{data.percent_margin}</td>
      <td><TextField label='Remarks' size='small'
        onBlur={(e) => handleRemarksChange(e, i)}
          InputProps={{ 
            style: { 
              width: '177px',
              height: '36px'
            },
          }}
          InputLabelProps={{ 
            style: { 
              fontSize: '14px'
            },
          }} /></td>
    </tr>)}
    </tbody>
  </table>
  );
};