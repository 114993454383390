import React, { useEffect, useRef } from 'react';
import ModelCarrierCarModelView from './ModelCarrierCarModels.view';
import { BootstrapTooltip } from 'components';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { showModal, getCarModel, hideModal, deleteCarModel, snackbarNotification, uploadCarrier } from 'stores';
import { get } from 'lodash';

const ModelCarrierCarModels = () => {
  const dispatch = useDispatch();
  const carModels = useSelector((state) => get(state, 'modelCarrier.carModels', []));
  const columns = [
    {
      field: 'category',
      headerName: 'Category',
      width: 150,
      editable: true,
    },
    {
      field: 'Model',
      headerName: 'Model Name',
      width: 200,
      editable: true,
    },
    {
      field: 'load_factor',
      headerName: 'Load Factor',
      type: 'number',
      width: 120,
      editable: true,
    },
    {
      field: 'length',
      headerName: 'Length',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'width',
      headerName: 'Width',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'height',
      headerName: 'Height',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'bonnet_height',
      headerName: 'Bonnet Height',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'bonnet_length',
      headerName: 'Bonnet Length',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'middle_height',
      headerName: 'Middle Height',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'middle_length',
      headerName: 'Middle Length',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'back_height',
      headerName: 'Back Height',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'back_length',
      headerName: 'Back Length',
      type: 'number',
      width: 140,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 100,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
            <BootstrapTooltip title={'Edit'} placement={'bottom'}>
              <IconButton sx={{ cursor: 'pointer' }} aria-label="Edit" onClick={(e) => onDataEditHandler(e, params?.row)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title={'Delete'} placement={'bottom'}>
              <IconButton aria-label="Delete" onClick={(e) => onDataDeleteHandler(e, params?.row)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </BootstrapTooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getCarModel());
  }, []);

  const onDataDeleteHandler = (e, row) => {
    e.stopPropagation();

    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: 'Delete Car Model',
          maxWidth: 'xs',
          componentProps: {
            confirmText: `Are you sure you want to delete ${row?.Model}?`,
            confirmButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('CONFIRM'));
            },
            submitHandler: () => {
              dispatch(
                deleteCarModel({
                  carModelId: row?._id,
                })
              ).then((res) => {
                if (res.status === 200) {
                  dispatch(getCarModel());
                  dispatch(hideModal('CONFIRM'));
                }
              });
            },
          },
        },
      })
    );
  };
  const onDataEditHandler = (e, carModel) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'ADD_EDIT_CAR_MODELS',
        props: {
          title: 'Update Car Model',
          maxWidth: 'sm',
          componentProps: {
            carModel,
          },
        },
      })
    );
  };

  const handleAddNewCarModel = () => {
    dispatch(
      showModal({
        component: 'ADD_EDIT_CAR_MODELS',
        props: {
          title: 'Add Car Model',
          maxWidth: 'sm',
          componentProps: {},
        },
      })
    );
  };

  return <ModelCarrierCarModelView columns={columns} rows={carModels} handleAddNewCarModel={handleAddNewCarModel} carModels={carModels} />;
};

export default ModelCarrierCarModels;
