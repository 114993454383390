import React from 'react';
import PropTypes from 'prop-types';

//app
import * as utils from 'utils';
import { consts } from 'globalsData';
import { FieldLabelWithValueHorizontal, BootstrapTooltip } from 'components';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Grid, IconButton, Link, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

EDASummary.propTypes = {
  summaryDeteails: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  onDownloadCsv: PropTypes.func.isRequired,
  onDownloadHtml: PropTypes.func.isRequired,
  onLearningTypeClick: PropTypes.func.isRequired,
};

export default function EDASummary({ summaryDeteails, status, onDownloadCsv, onDownloadHtml, onLearningTypeClick }) {
  const theme = useTheme();
  const fileName = summaryDeteails?.input_data?.fileName;
  const learningType = summaryDeteails?.input_data?.learningType;
  const targetVariable = summaryDeteails?.input_data?.targetVariable;
  const featureComparison = summaryDeteails?.input_data?.featureComparison;
  const hypothesisTesting = summaryDeteails?.input_data?.hypothesisTesting;
  const valuePer = summaryDeteails?.input_data?.valuePer;
  const missingValImpMethod = summaryDeteails?.input_data?.missingValImpMethod;
  const labelEncThreshold = summaryDeteails?.input_data?.labelEncThreshold;
  const specialCharacterReplace = summaryDeteails?.input_data?.specialCharacterReplace;

  const isCompleted = status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase();

  const getMissingValueMethod = () => {
    if (typeof missingValImpMethod === 'number') return `Constant Value(${missingValImpMethod})`;
    if (missingValImpMethod === 'mean') return 'Mean';
    if (missingValImpMethod === 'median') return 'Median';
    if (missingValImpMethod === 'most_frequent') return 'Most Frequent';
    return '';
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} sx={{ borderRight: `1px dashed ${theme.palette.otherColor.blue30}` }}>
        <Box pr={1}>
          {fileName && (
            <FieldLabelWithValueHorizontal label="Input Data" value={fileName} valueColor={(ftheme) => ftheme.palette.primary.main} />
          )}
          {learningType && isCompleted ? (
            <Box display="flex" flexDirection="row" py={0.5}>
              <Typography fontSize={16} fontWeight={600} pr={1} color={theme.palette.otherColor.textGrey2}>
                Learning Type:
              </Typography>
              <Typography fontSize={16} fontWeight={400} color={theme.palette.otherColor.textGrey2}>
                {learningType === 'none' ? (
                  <Typography fontSize={16} fontWeight={600} pr={1}>
                    {learningType}
                  </Typography>
                ) : (
                  <Link component="button" onClick={(e) => onLearningTypeClick(e, learningType)}>
                    <Typography fontSize={16} fontWeight={600} pr={1}>
                      {learningType}
                    </Typography>
                  </Link>
                )}
              </Typography>
            </Box>
          ) : (
            learningType && <FieldLabelWithValueHorizontal label=" Learning Type" value={learningType} />
          )}
          {targetVariable && <FieldLabelWithValueHorizontal label="Target Variable" value={targetVariable} />}
          {featureComparison && <FieldLabelWithValueHorizontal label="Feature for comparision" value={featureComparison} />}
          {hypothesisTesting && <FieldLabelWithValueHorizontal label="Hypothesis Testing" value={hypothesisTesting} />}
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ borderRight: `1px dashed ${theme.palette.otherColor.blue30}` }}>
        <Box px={1}>
          {valuePer && <FieldLabelWithValueHorizontal label="Missing Value Percentage" value={valuePer} />}
          {missingValImpMethod && <FieldLabelWithValueHorizontal label="Missing Value Imputation Method" value={getMissingValueMethod()} />}
          {labelEncThreshold && <FieldLabelWithValueHorizontal label="Label Encoding Threshold" value={labelEncThreshold} />}
          {specialCharacterReplace && <FieldLabelWithValueHorizontal label="Special Character Replace" value={specialCharacterReplace} />}
          <Box display="flex" flexDirection="row" py={0.5}>
            <Typography fontSize={16} fontWeight={600} pr={1} color={theme.palette.otherColor.textGrey2}>
              Status:
            </Typography>
            {status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus) && (
              <Typography fontSize={16} fontWeight={400} color={theme.palette.otherColor.textGrey2}>
                Error
              </Typography>
            )}
            {!status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus) && (
              <Grid container spacing={1}>
                <Grid item>
                  <BootstrapTooltip title={status.length > 40 ? status : ''}>
                    <Typography fontSize={16} fontWeight={400} color={theme.palette.otherColor.textGrey2}>
                      {Boolean(status) ? utils.app.getEllipsisString(status, 40) : utils.app.getEllipsisString(summaryDeteails?.status, 40)}
                    </Typography>
                  </BootstrapTooltip>
                </Grid>
                {!(isCompleted || status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus)) && (
                  <Grid item>
                    <CircularProgress size={16} />
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box px={1}>
          <Typography fontSize={16} fontWeight={600} pr={1} sx={{ color: theme.palette.otherColor.textGrey2 }}>
            Click to Download:
          </Typography>
          <IconButton disabled={!isCompleted} aria-label="download csv" onClick={onDownloadCsv}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Grid item>
                <DownloadIcon sx={{ color: (theme) => (isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3) }} />
              </Grid>
              <Grid item>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  sx={{ color: isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3 }}
                >
                  CSV
                </Typography>
              </Grid>
            </Grid>
          </IconButton>
          <IconButton disabled={!isCompleted} aria-label="download html" onClick={onDownloadHtml}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Grid item>
                <DownloadIcon sx={{ color: (theme) => (isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3) }} />
              </Grid>
              <Grid item>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  sx={{ color: isCompleted ? theme.palette.primary.main : theme.palette.otherColor.textGrey3 }}
                >
                  HTML
                </Typography>
              </Grid>
            </Grid>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
