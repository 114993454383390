import React from 'react';
import BackBtn from 'assets/png/back-button.png';
import SaveBtn from 'assets/png/save-button.png';
import EmailReport from 'assets/png/email-report.png';
import './BVITGraphVisualization.css';
import { BVITGraphTable, Charts, TabComponent } from 'components';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const BVITGraphVisualizationView = ({ closeGeneratePlot, chartOptions, onTMLimitChange, 
  groupKeyValues, onGroupKeyChange, tmErrorValues, bvitGraphData, groupKeys, tabs, chartRefs,
  onMinTorqueChange, onMaxTorqueChange, tableData, handleRemarksChange, setObjective,
  setBackground, setSpecialRemarks, setConclusion, onSaveReport, objectiveError, setObjectiveError,
  backgroundError, setBackgroundError, conclusionError, setConclusionError }) => {
  return (
    <>
     <div className='graphs-heading'>Summarized Statistics</div>
     <TabComponent tabs={tabs} />
      <div className='bvit-graphs'>
        Graph Reports
        {bvitGraphData.length ?
        <>
        <div className='bvit-charts'>
          {chartOptions.map((option, i) => 
          <div key={i} className='bvit-chart'>
            <Charts chartOptions={option} chartRefs={chartRefs} data={i} />
            <div className='chart-option'>
            <TextField label='Min Torque value' size='small' onBlur={(e) => onMinTorqueChange(e, i)}
            type="number"
            sx={{ margin: '10px 0 0 10px' }}
            InputProps={{ 
              style: { 
                width: '177px',
                height: '36px'
              },
            }}
            InputLabelProps={{ 
              style: { 
                fontSize: '14px'
              },
            }} />
            <TextField label='Max Torque value' size='small' onBlur={(e) => onMaxTorqueChange(e, i)}
            type="number"
            sx={{ margin: '10px 0 0 10px' }}
            InputProps={{ 
              style: { 
                width: '177px',
                height: '36px'
              },
            }}
            InputLabelProps={{ 
              style: { 
                fontSize: '14px'
              },
            }} />
            <FormControl sx={{ minWidth: 177, maxHeight: 50 }} size="small" error={tmErrorValues[i]}>
            <TextField label='TM Limit' size='small' onBlur={(e) => onTMLimitChange(e, option, i)}
            sx={{ margin: '10px 0 0 10px' }}
            error={tmErrorValues[i]}
            InputProps={{ 
              style: { 
                width: '177px',
                height: '36px'
              },
            }}
            InputLabelProps={{ 
              style: { 
                fontSize: '14px'
              },
            }} />
            {tmErrorValues[i] && <FormHelperText sx={{ fontSize: '14px', marginLeft: '10px', marginTop: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please select TM limit</FormHelperText>}
            </FormControl>
            <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
              <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Target Model</InputLabel>
              <Select
                sx={{ maxHeight: 36 }}
                label="Target Model"
                size="small"
                value={groupKeyValues[i]}
                onChange={(e) => onGroupKeyChange(e, option, i)}
                >
                {groupKeys.map((data, i) => (
                  <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
          </div>
          )}
        </div>
        {tableData && tableData.length > 0 &&
        <BVITGraphTable tableData={tableData} handleRemarksChange={handleRemarksChange} />}
        <div className='input-fields'>
        <FormControl size="small" error={objectiveError}>
          <TextField label='*Objective' multiline rows={3} sx={{ width: '220px' }}
          onChange={(e) => {
            setObjective(e.target.value);
            setObjectiveError(e.target.value === '');
          }}
          error={objectiveError}
          InputLabelProps={{ 
              style: { 
                fontSize: '14px'
              },
            }}  />
            {objectiveError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', marginRight: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please enter objective</FormHelperText>}
          </FormControl>
          <FormControl size="small" error={backgroundError}>
          <TextField label='*Background' multiline rows={3} sx={{ width: '220px' }}
          onChange={(e) => {
            setBackground(e.target.value);
            setBackgroundError(e.target.value === '');
          }}
          error={backgroundError}
          InputLabelProps={{ 
              style: { 
                fontSize: '14px'
              },
            }} />
            {backgroundError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', marginRight: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please enter background</FormHelperText>}
          </FormControl>
          <TextField label='Special Remarks' multiline rows={3} sx={{ width: '220px' }}
          onChange={(e) => setSpecialRemarks(e.target.value)}
          InputLabelProps={{ 
              style: { 
                fontSize: '14px'
              },
            }} />
          <FormControl size="small" error={conclusionError}>
          <TextField label='*Conclusion' multiline rows={3} sx={{ width: '220px' }}
          onChange={(e) => {
            setConclusion(e.target.value);
            setConclusionError(e.target.value === '');
          }}
          error={conclusionError}
          InputLabelProps={{ 
              style: { 
                fontSize: '14px'
              },
            }} />
            {conclusionError && <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', marginTop: '0', marginRight: '0', color: (theme) => theme.palette.otherColor.errorText }}>Please enter conclusion</FormHelperText>}
          </FormControl>
        </div>
        </> : 
        <div className='graph-nodata'>
          <div className='graph-error'><span className='error-msg'>OOPS!</span> Seems like there are no automated results.</div>
          <div className='graph-suberror'>There were no automated results, please go back and try to double check the details and show results again.</div>
        </div>}
        <div className='graph-buttons'>
          <button className='back-btn' onClick={() => closeGeneratePlot()}><img src={BackBtn} alt="Back Btn" width={6} height={8} className='graph-btns' />Back</button>
          <button className={!bvitGraphData.length ? 'save-btn disabled' : 'save-btn'} onClick={() => onSaveReport()}><img src={SaveBtn} alt="Save Btn" width={8} height={10} className='graph-btns' />Save Report</button>
          {/* <button className={!bvitGraphData.length ? 'email-btn disabled' : 'email-btn'}><img src={EmailReport} alt="Email Report" width={10} height={10} className='graph-btns' />Email Report</button> */}
        </div>
      </div>
    </>
  );
};

export default BVITGraphVisualizationView;