import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox } from '@mui/material';

import './BVITVehicleSpecifications.css';

const BVITVehicleSpecificationsView = ({ filterDetails, handleFiltersChange, vehicleSpecifications,
   setVehicleSpecifications }) => {
  const filterData = filterDetails?.vehicle_specifications;
  return (
    <div className='tab-vehicle'>
       <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Vehicle Weight</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Vehicle Weight"
          size="small"
          value={vehicleSpecifications.vehicle_weight || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setVehicleSpecifications(prevState => ({...prevState, vehicle_weight: e.target.value}))}
          onBlur={(e) => {
            const newVehicleWeight = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newVehicleWeight.includes(data[" Vehicle Weight(kg) *"]));
            handleFiltersChange(details);
          }}>
           {filterData?.vehicle_weight?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={vehicleSpecifications.vehicle_weight.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Front Rxn</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Front Rxn"
          size="small"
          value={vehicleSpecifications.front_rxn || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setVehicleSpecifications(prevState => ({...prevState, front_rxn: e.target.value}))}
          onBlur={(e) => {
            const newFrontRxn = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newFrontRxn.includes(data["Front Rxn(Kg)*"]));
            handleFiltersChange(details);
          }}>
           {filterData?.front_rxn?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={vehicleSpecifications.front_rxn.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Rear Rxn</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Rear Rxn"
          size="small"
          value={vehicleSpecifications.rear_rxn || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setVehicleSpecifications(prevState => ({...prevState, rear_rxn: e.target.value}))}
          onBlur={(e) => {
            const newRearRxn = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newRearRxn.includes(data["Rear Rxn(Kg)*"]));
            handleFiltersChange(details);
          }}>
         {filterData?.rear_rxn?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={vehicleSpecifications.rear_rxn.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <div>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px' }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Weight Distribution</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Weight Distribution"
          size="small"
          value={vehicleSpecifications.weight_distribution || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setVehicleSpecifications(prevState => ({...prevState, weight_distribution: e.target.value}))}
          onBlur={(e) => {
            const newWeightDistribution = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newWeightDistribution.includes(data["Weight Distribution*"]));
            handleFiltersChange(details);
          }}>
           {filterData?.weight_distribution?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={vehicleSpecifications.weight_distribution.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Tyre Specification</InputLabel>
        <Select
          sx={{ maxHeight: 36 }}
          multiple
          label="Tyre Specification"
          size="small"
          value={vehicleSpecifications.tyre_specifications || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setVehicleSpecifications(prevState => ({...prevState, tyre_specifications: e.target.value}))}
          onBlur={(e) => {
            const newTyreSpecifications = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newTyreSpecifications.includes(data["Tyre Specification"]));
            handleFiltersChange(details);
          }}>
          {filterData?.tyre_specifications?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={vehicleSpecifications.tyre_specifications.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 177, maxWidth: 177, margin: '10px 0 0 10px', maxHeight: 50 }} size="small">
        <InputLabel sx={{ color: '#768396', fontSize: '13px' }}>Tyre rolling radius</InputLabel>
        <Select
        sx={{ maxHeight: 36 }}
        multiple
          label="Tyre rolling radius"
          size="small"
          value={vehicleSpecifications.tyre_rolling_radius || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) =>  setVehicleSpecifications(prevState => ({...prevState, tyre_rolling_radius: e.target.value}))}
          onBlur={(e) => {
            const newTyreRollingRadius = e.target.value;
            const details = filterDetails?.vehicle_load_data?.filter((data) => newTyreRollingRadius.includes(data["Tyre Rolling Radius(m)"]));
            handleFiltersChange(details);
          }}>
           {filterData?.tyre_rolling_radius?.map((data, i) => (
            <MenuItem value={data} key={i} sx={{ fontSize: '14px' }}>
              <Checkbox checked={vehicleSpecifications.tyre_rolling_radius.includes(data)} color='primary' />
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
    </div>
  );
};

export default BVITVehicleSpecificationsView;
