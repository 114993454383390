import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsMore from 'highcharts/highcharts-more';

//app
import { Charts } from 'components';
//mui
import { Box, Grid } from '@mui/material';

ViewAcceleratorMatrix.propTypes = {
  chart: PropTypes.object.isRequired,
};

HighchartsHeatmap(Highcharts);
HighchartsMore(Highcharts);

export default function ViewAcceleratorMatrix({ chart }) {
  const algorithemCharts = chart?.EDACharts;
  const chartName = chart?.name?.toLowerCase();
  const chartsLength = algorithemCharts?.length;

  const getIndependentVariableSpairPlotXS = () => {
    if (chartsLength === 1) return 12;
    if (chartsLength === 4) return 6;
    if (chartsLength === 9) return 6;
    if (chartsLength > 16) return 3;
    if (chartsLength > 25) return 2.4;
    if (chartsLength > 36) return 2;
    return 12;
  };

  const getBiUnivariateXS = () => {
    if (chartsLength === 1) return 12;
    if (chartsLength === 2) return 6;
    if (chartsLength === 3) return 4;
    if (chartsLength === 4) return 3;
    return 2;
  };

  return (
    <Box p={2} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
      {algorithemCharts.length ? (
        <Grid container spacing={2}>
          {algorithemCharts.map((aChart, i) => {
            if (chartName === 'independentvariablespairplot') {
              return (
                <Grid item key={i} xs={getIndependentVariableSpairPlotXS()}>
                  <Charts chartOptions={aChart} />
                </Grid>
              );
            } else if (
              chartName === 'bivariateanalyisnumericalplots' ||
              chartName === 'univariateanalysisplots' ||
              chartName === 'bivariateanalyiscategoricalplots'
            ) {
              return (
                <Grid item key={i} xs={6}>
                  <Charts chartOptions={aChart} />
                </Grid>
              );
            } else {
              return (
                <Grid item key={i} xs={getBiUnivariateXS()}>
                  <Charts chartOptions={aChart} />
                </Grid>
              );
            }
          })}
        </Grid>
      ) : (
        <Box>No Chart Available</Box>
      )}
    </Box>
  );
}
