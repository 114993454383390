import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  IconButton,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  Typography,
  Button,
  TextareaAutosize,
} from '@mui/material';
import * as utils from 'utils';
import { ReactComponent as UploadCloud } from 'assets/svg/uploadCloud.svg';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './AddArtificialIntelligenceForm.css';
import { consts } from 'globalsData';
import { FileDropZone, BootstrapTooltip } from 'components';
import micsvg from '../../assets/svg/mic.svg';
import doneIcon from '../../assets/svg/greentickdone.svg';
import sinewave from '../../assets/svg/sinewave.svg';
import BarsAnimation from './BarsAnimation';
import playbutton from '../../assets/svg/playbutton.svg';
import pausebutton from '../../assets/svg/pausebutton.svg';
import micwithoutanimationIcon from '../../assets/png/micwithoutanimation.png';
import resetButton from '../../assets/png/reset button.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import stopIcon from '../../assets/svg/stopIcon.svg';

function AddArtificialIntelligenceFormView({
  audioBlob,
  isNoFilesUploaded,
  isValidFileFormats,
  isValidFileSizes,
  fileUploadFieldList,
  handleFileUpload,
  handleChange,
  startRecording,
  stopRecording,
  onDelete,
  onSubmit,
  sourceLanguageList,
  targetLanguageList,
  sourceLanguage,
  targetLanguage,
  handleDropDownChange,
  justificationRemarks,
  setJustificationRemarks,
  selectedAudioSourceValue,
  formProps,
  fields,
  fileError,
  isRecordingStarted,
  playAudio,
  pauseAudio,
  isPlaying,
  formatTime,
  currentTime,
  totalDuration,
  recorderComponent,
  handleResetAudio,
}) {
  const HelperText = ({ helperTextProp }) => {
    return (
      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText, width: '100%' }}>
        {helperTextProp}
      </FormHelperText>
    );
  };
  const { register, handleSubmit, errors } = formProps;
  return (
  <>
    <Box sx={{ backgroundColor: '#D6E7FB', borderRadius: '18px', marginBottom: '10px', padding: '15px', textAlign: 'left', fontSize: '14px' }}>
      <Typography fontSize={14} textAlign="left" pb={0.5} >
        Data Guidelines
      </Typography>
      <ul style={{ margin: '0', paddingLeft: '30px' }}>
        <li style={{ fontSize: '14px' }}>Language Selection: Specify the source language of the audio file and the target language for the text output.</li>
        <li style={{ fontSize: '14px' }}>Language Accuracy: For translation accelerators, ensure the selected languages accurately match the content to avoid translation errors.</li>
      </ul>
    </Box>
    <Box sx={{ borderRadius: '18px', backgroundColor: '#fff', height: '100%', overflowY: 'auto', overflow: 'auto' }}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          padding: '1rem',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography variant="h6" mb={1} display="flex" fontSize="14px">
                Audio Source
              </Typography>
              <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                <BootstrapTooltip title={consts.TOOLTIP.ARTIFICIAL_INTELLIGENCE_AUDIO_SOURCE} placement={'bottom'}>
                  <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                </BootstrapTooltip>
              </Box>
            </Box>
            <RadioGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'flex-start',
              }}
              value={selectedAudioSourceValue}
              onChange={handleChange}
              defaultValue="file"
            >
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  color: 'Black',
                  border: '1px solid #E5E5ED',
                  textTransform: 'capitalize',
                  height: '40px',
                  padding: 0,

                  borderRadius: '0.5625rem',
                  width: '9rem',
                  textAlign: 'left',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingX: '.5rem',
                }}
              >
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  label="File"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
              </Button>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  color: 'Black',
                  borderColor: '#c1c2c4',
                  textTransform: 'capitalize',
                  height: '40px',
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  borderRadius: '0.5625rem',
                  width: '10rem',
                  paddingX: '.5rem',
                }}
              >
                <FormControlLabel
                  value="microphone"
                  control={
                    <Radio
                      sx={{
                        fontSize: '14px',
                      }}
                    />
                  }
                  label="Microphone"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
              </Button>
            </RadioGroup>
          </Grid>

          {selectedAudioSourceValue === 'microphone' && (
            <Grid item xs={6} mb={4}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{
                  display:'flex'
                }}>
                <Typography variant="h6" mb={1} display="flex" fontSize="14px">
                  Record Speech
                </Typography>
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                <BootstrapTooltip title={consts.TOOLTIP.ARTIFICIAL_INTELLIGENCE_AUDIO_SOURCE} placement={'bottom'}>
                  <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                </BootstrapTooltip>
               
              </Box>
              </div>

                {recorderComponent && audioBlob && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '1rem',
                      cursor: 'pointer',
                    }}
                    onClick={handleResetAudio}
                  >
                    {' '}
                    <img
                      alt="resetButton"
                      src={resetButton}
                      style={{
                        width: '0.875rem',
                        height: '0.9375rem',
                      }}
                    />{' '}
                    <span
                      style={{
                        color: '#999',
                        textAlign: 'right',
                        fontFamily: 'Poppins',
                        fontSize: '0.75rem',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                      }}
                    >
                      Reset
                    </span>
                  </div>
                )}
              </div>
              {recorderComponent ? (
                <>
                  <div className="audioContainer">
                    <div>
                      {audioBlob ? (
                        <img src={isPlaying ? pausebutton : playbutton} onClick={isPlaying ? pauseAudio : playAudio} alt="" />
                      ) : (
                        <img src={micsvg} alt="" />
                      )}
                    </div>

                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      {isRecordingStarted || (audioBlob && isPlaying) ? (
                        <BarsAnimation />
                      ) : (
                        <img
                          src={sinewave}
                          alt=""
                          style={{
                            width: '6rem',
                            height: '2rem',
                          }}
                        />
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                        justifyContent:'center',
                        alignContent:'center'
                      }}
                    >
                      <div
                        style={{
                          color: 'rgba(0, 0, 0, 0.40)',
                          textAlign: 'end',
                          alignSelf: 'center',
                          margin: 'auto 0',
                          font: '400 12px Inter, sans-serif ',
                        }}
                      >
                        {formatTime(currentTime)}/{formatTime(totalDuration)}
                      </div>

                      {!audioBlob && <img src={stopIcon} onClick={stopRecording} alt="stopIcon" />}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: '9px',
                      border: '1px solid rgba(0, 0, 0, 0.25)',
                      backgroundColor: '#FFF',
                      display: 'flex',
                      maxWidth: '403px',
                      gap: '20px',
                      padding: '27px 25px',
                    }}
                  >
                    <img loading="lazy" src={micwithoutanimationIcon} alt="micwithoutanimationIcon" />
                    <span
                      style={{
                        color: '#FFF',
                        textAlign: 'center',
                        borderRadius: '9px',
                        backgroundColor: '#0177FB',
                        alignSelf: 'stretch',
                        flexGrow: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 20px',
                        font: '500 13px/150% Inter, sans-serif ',
                        cursor: 'pointer',
                        border: 'none',
                      }}
                      onClick={startRecording}
                    >
                      Record voice
                    </span>
                  </div>
                </>
              )}
              {fileError && (
                <div
                  style={{
                    marginTop: '.5rem',
                    marginBottom: '.5rem',
                  }}
                >
                  <HelperText helperTextProp={fileError} />
                </div>
              )}
            </Grid>
          )}

          {selectedAudioSourceValue !== 'microphone' && (
            <Grid item xs={6}>
              <>
                {fileUploadFieldList.map((item, i) => (
                  <>
                    <Box
                      id="uploadInputFile"
                      width={300}
                      mb={
                        (!isValidFileFormats.inputDataFile && item.field === fileUploadFieldList[0].field) ||
                        (isValidFileFormats.inputDataFile &&
                          isValidFileSizes.inputDataFile &&
                          item.field === fileUploadFieldList[0].field &&
                          isNoFilesUploaded.inputDataFile &&
                          !item.file)
                          ? ''
                          : 4
                      }
                    >
                      <Typography variant="h6" mb={1}  display="flex" fontSize="14px">
                        Upload Audio File
                      </Typography>

                      <Box border="1px dashed #cfcaca" px={1} py={0.5} borderRadius="6px" mt={1} ml={1}>
                        <>
                          {!item.file?.name ? (
                            <FileDropZone field={item.field} handleFileUpload={handleFileUpload} isItForMp3Files />
                          ) : (
                            <Grid container justifyContent="space-between" alignItems="center">
                              <Grid item>
                                <Typography fontSize={14}>{item.file?.name}</Typography>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  color="inherit"
                                  aria-label="delete file"
                                  onClick={() => onDelete(item?.field)}
                                  edge="start"
                                  sx={{
                                    marginLeft: 1,
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      </Box>
                    </Box>
                    <div
                      style={{
                        marginTop: '.5rem',
                        marginBottom: '.5rem',
                      }}
                    >
                      {' '}
                      {isValidFileFormats.inputDataFile &&
                        isValidFileSizes.inputDataFile &&
                        item.field === fileUploadFieldList[0].field &&
                        isNoFilesUploaded.inputDataFile &&
                        !item.file && <HelperText helperTextProp="Please Attach (.mp3, .wav, .ogg, .flac, .opus) Input Data File" />}
                      {!isValidFileFormats.inputDataFile && item.field === fileUploadFieldList[0].field && (
                        <HelperText helperTextProp="Input data file format supported (mp3,wav,ogg,flac,opus) and should not contains multiple (.) dots in file name" />
                      )}
                      {!isValidFileSizes.inputDataFile && item.field === fileUploadFieldList[0].field && (
                        <HelperText
                          helperTextProp={`Input data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                        />
                      )}
                      {fileError && <HelperText helperTextProp={fileError} />}
                    </div>
                  </>
                ))}
              </>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          spacing={3}
  
        >
          <Grid item xs={6}>
            <Box id="algorithm">
              <Box sx={{ textAlign: 'left' }}>
                <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  Source Language
                </Typography>
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                  <BootstrapTooltip title={consts.TOOLTIP.ARTIFICIAL_INTELLIGENCE_SOURCE_LANGUAGE} placement={'bottom'}>
                    <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                  </BootstrapTooltip>
                </Box>
              </Box>
              <FormControl sx={{ width: '100%' }}>
                <Select
                  {...register('sourceLanguage')}
                  {...utils.form.getFieldProp(fields, 'sourceLanguage')}
                  sx={{ fontSize: '14px', textAlign: 'left' }}
                  value={sourceLanguage}
                  onChange={(e) => handleDropDownChange(e)}
                >
                  {sourceLanguageList.map((method) => (
                    <MenuItem value={method.language} key={method.value} sx={{ fontSize: '14px' }}>
                      {method.language}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors?.sourceLanguage?.message && (
                  <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                    {errors?.sourceLanguage?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box id="algorithm">
              <Box sx={{ textAlign: 'left' }}>
                <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  Target Language
                </Typography>
                <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                  <BootstrapTooltip title={consts.TOOLTIP.ARTIFICIAL_INTELLIGENCE_TARGET_LANGUAGE} placement={'bottom'}>
                    <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                  </BootstrapTooltip>
                </Box>
              </Box>
              <FormControl sx={{ width: '100%' }}>
                <Select
                  {...register('targetLanguage')}
                  {...utils.form.getFieldProp(fields, 'targetLanguage')}
                  value={targetLanguage}
                  onChange={(e) => handleDropDownChange(e)}
                  sx={{ fontSize: '14px', textAlign: 'left' }}
                >
                  {targetLanguageList.map((method) => (
                    <MenuItem value={method.language} key={method.value} sx={{ fontSize: '14px' }}>
                      {method.language}
                    </MenuItem>
                  ))}
                </Select>

                {!!errors?.targetLanguage?.message && (
                  <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                    {errors?.targetLanguage?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Box id="justificationRemarks">
              <Typography textAlign="left" pb={0.5} fontSize={14}>
                Justification Remarks
              </Typography>
              <FormControl sx={{ width: '100%' }}>
                <TextareaAutosize
                  {...register('justificationRemarks')}
                  {...utils.form.getFieldProp(fields, 'justificationRemarks')}
                  className="textAreaStyles"
                  value={justificationRemarks}
                  onChange={(e) => setJustificationRemarks(e.target.value)}
                />

                {!!errors?.justificationRemarks?.message && (
                  <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                    {errors.justificationRemarks.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Box id="actionsButtons" py={4}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  p: '0.4rem 1.5rem',
                  width: '31rem',
                  borderRadius: '0.5625rem',
                  fontWeight: 500,
                  lineHeight: '1.24788rem',
                }}
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={''}
              >
                Translate
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
   </>
  );
}

export default AddArtificialIntelligenceFormView;
