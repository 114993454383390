import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as utils from 'utils';
import { consts,config } from 'globalsData';
import AddAnomalyDetectionFormView from './AddAnomalyDetectionForm.view';
import { addLoader, removeLoader, getPresignedUrl , uploadFileToS3, postProcessingInput, 
  snackbarNotification, fetchSourceAndTargetLanguages} from 'stores';

export default function AddAnomalyDetectionForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [radioValue, setRadioValue] = useState('inputText');
  const [inputText, setInputText] = useState('');
  const [inputTextCount, setInputTextCount] = useState(0);
  const [isInputNotUpdated, setIsInputNotUpdated] = useState(false);
  const [file, setFile] = useState(null);
  const [isNoFileUploaded, setIsNoFileUploaded] = useState(false);
  const [isInValidFileFormat, setIsInValidFileFormat] = useState(false);
  const [isInValidFileSize, setIsInValidFileSize] = useState(false);
  const [sourceLanguageList, setSourceLanguageList] = useState([]);
  const [targetLanguageList, setTargetLanguageList] = useState([]);

  const fields = [
    {
      name: 'sourceLanguage',
      id: 'sourceLanguage',
      required: true,
      validation: Yup.string().required('Please select atleast one option'),
    },
    {
      name: 'targetLanguage',
      id: 'targetLanguage',
      required: true,
      displayEmpty: true,
      validation: Yup.string().required('Please select atleast one option'),
    },
    {
      name: 'justificationRemarks',
      id: 'justificationRemarks',
      size: 'small',
      required: true,
      validation: Yup.string().required('Justification Remarks is required').min(30, 'Min 30 characters').max(150, 'Max 150 characters'),
    }]

  const validationSchema = utils.form.getValidationSchema(fields);
  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors
  } = useForm({
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  useEffect(() => {
    const fetchData = async () => {
    const res = await dispatch(
      fetchSourceAndTargetLanguages({
        type: 'speechtotext',
      })
    );
    setSourceLanguageList(res?.data?.source);
    setTargetLanguageList(res?.data?.target);
    };

    fetchData();
  }, []);

  const handleRadioValueChange = (e) => {
    setRadioValue(e.target.value); 
    clearErrors();
    setIsInputNotUpdated(false);
    setIsNoFileUploaded(false);
    setIsInValidFileFormat(false);
    setIsInValidFileSize(false);
  }

  const handleInputTextChange = (e) => {
    setIsInputNotUpdated(false);
    const { value } = e.target;
    if(value.length <= 5000){
      setInputText(value);
      setInputTextCount(value.length);
    }
  }

  const processData = (dataString) => {
    dispatch(removeLoader('readingFile...'));
  };

  const handleFileUpload = (fileData) => {
    setIsNoFileUploaded(false);
    const uploadedFile = fileData[0];
    if(uploadedFile?.length !== 0){
      const validFileFormat = consts.textToSpeechFileFormats?.includes(uploadedFile?.name?.split('.')[1]);
  
      if (validFileFormat) {
        setIsInValidFileFormat(false);
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_TXT_FILE_SIZE) {
          setIsInValidFileSize(false);
          setFile(uploadedFile);
          dispatch(addLoader('readingFile...'));
          utils.app.getFileReader(processData, uploadedFile);
        } else {
          setFile(null);
          setIsInValidFileSize(true);
          setIsInValidFileFormat(false);
        }
      } else {
        setFile(null);
        setIsInValidFileFormat(true);
        setIsInValidFileSize(false);
      }
    }
  };

  const onError = (error) => {
    if(radioValue === 'inputText' && !inputText){
      setIsInputNotUpdated(true);
    }
    else if(radioValue === 'file' && !file) {
      setIsNoFileUploaded(true);
      setIsInValidFileFormat(false);
      setIsInValidFileSize(false);
    }
  };

  const onDelete = () => {
    setFile(null);
    setIsInValidFileFormat(false);
    setIsInValidFileSize(false);
  };

  const postFormData = (reqParams, trackerId) => {
    dispatch(postProcessingInput(reqParams)).then((res) => {
      if (res?.status === 200) {
        dispatch(snackbarNotification('Data submitted successully, Soon you will get converted speech', 'success'));
       navigate(`${config.routes.accelerators.type}/${consts.TEXT_TO_SPEECH}/${trackerId}`);
      }
    });
  };

  const onSubmit = (formData) => {
    let fileData = {};
    if(radioValue === 'inputText'){
      if(inputText){
        let json = JSON.stringify(inputText);
        fileData.file = new Blob([json], {type: "text/plain"});
        fileData.name = 'input_text.txt';
      }
      else{
        setIsInputNotUpdated(true);
      }
    }
    else if(radioValue === 'file') {
      if(file){
        fileData.file = file;
        fileData.name = file.name;
      }
      else{
        setIsNoFileUploaded(true);
        setIsInValidFileFormat(false);
        setIsInValidFileSize(false);
      }
    }
      
      dispatch(
        getPresignedUrl({
          type: consts.TEXT_TO_SPEECH,
          fileName: fileData.name
        })
      ).then((res) => {
      if (res?.status === 200) {
        const inputPreSignedUrl = res?.data?.data?.inputPreSignedUrl;
        const trackerId = res?.data?.trackerId;
        const reqParams = {
           type: consts.TEXT_TO_SPEECH,
           justificationRemarks: formData.justificationRemarks,
           trackerId,
           targetLanguage: targetLanguageList.filter((item) => item.language === formData.targetLanguage).map((item) => item.code).toString(),
           sourceLanguage: sourceLanguageList.filter((item) => item.language === formData.sourceLanguage).map((item) => item.code).toString()
        };
      if (inputPreSignedUrl && trackerId) {
        dispatch(uploadFileToS3({ preSignedUrl: inputPreSignedUrl, file: fileData.file, isThisForCsvOrXl: true })).then((fS3res) => {
        if (fS3res?.status === 200) {
           postFormData(reqParams, trackerId);
        }
        });
       }
      }
     });
  }

  return (
    <AddAnomalyDetectionFormView
      fields={fields}
      inputText={inputText}
      inputTextCount={inputTextCount}
      isInputNotUpdated={isInputNotUpdated}
      handleInputTextChange={handleInputTextChange}
      file={file}
      isNoFileUploaded={isNoFileUploaded}
      isInValidFileSize={isInValidFileSize}
      isInValidFileFormat={isInValidFileFormat}
      handleFileUpload={handleFileUpload}
      radioValue={radioValue}
      handleRadioValueChange={handleRadioValueChange}
      sourceLanguageList={sourceLanguageList}
      targetLanguageList={targetLanguageList}
      formProps={{ errors, register, handleSubmit }}
      handlers={{
        onSubmit,
        onError,
        onDelete
      }}
    />
  );
};