const initialState = {
    routePath: [],
  };
  
  const acceleratorsReducers = (state = initialState, action = {}) => {
    switch (action.type) {
      case 'APP_ROUTE_PATH':
        return {
          ...state,
          routePath: action.payload || [],
        };
  
      default:
        return state;
    }
  };
  
  export default acceleratorsReducers;
  