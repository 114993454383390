import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

//app
import { BootstrapTooltip } from 'components';

// mui
import { Box } from '@mui/material';

const DataGridCellExpand = memo(({ value, whiteSpace }) => {
  const wrapper = useRef(null);
  const cellValue = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const isOverflown = (element) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  };

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowTooltip(isCurrentlyOverflown);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
      py={0.5}
    >
      <BootstrapTooltip title={showTooltip ? value : ''}>
        <Box ref={cellValue} sx={{ whiteSpace, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {value}
        </Box>
      </BootstrapTooltip>
    </Box>
  );
});

export default DataGridCellExpand;

DataGridCellExpand.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  whiteSpace: PropTypes.oneOf(['normal', 'nowrap', 'pre', 'pre-wrap', 'pre-line', 'break-spaces']),
};

DataGridCellExpand.defaultProps = {
  whiteSpace: 'nowrap',
};
