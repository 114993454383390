import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getTransportersList = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getTransportersListRequest());

  return api
    .get({
      endpoint: endpoint.shareOfBusinessService,
      path: 'getTransporter',
    })
    .then((res) => {
      dispatch(getTransportersListSuccess( res?.data || [] ));
    })
    .catch((error) => {
      dispatch(getTransportersListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getCategoryManagementList'));
    });
};

export const getTransportersListRequest = () => {
  return {
    type: 'GET_TRANSPORTERS_LIST_REQUEST',
  };
};

export const getTransportersListSuccess = (data) => {
  return {
    type: 'GET_TRANSPORTERS_LIST_SUCCESS',
    payload: data,
  };
};

export const getTransportersListError = (error) => {
  return {
    type: 'GET_TRANSPORTERS_LIST_FAILURE',
    payload: error,
  };
};
