import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getCarrierList = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
 
  dispatch(getCarrierListRequest());

  return api
    .get({
      endpoint: endpoint.modelCarrier,
      path: 'getCarrierNames'
    })
    .then((res) => {
      dispatch(getCarrierListSuccess(res?.data));
      return res;
    })
    .catch((error) => {
      dispatch(getCarrierListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getCarrierList'));
    });
};

export const getCarrierListRequest = (params) => {
  return {
    type: 'GET_CARRIER_LIST_REQUEST',
    payload: params,
  };
};

export const getCarrierListSuccess = (data) => {
  return {
    type: 'GET_CARRIER_LIST_SUCCESS',
    payload: data,
  };
};

export const getCarrierListError = (error) => {
  return {
    type: 'GET_CARRIER_LIST_FAILURE',
    payload: error,
  };
};
