import React from 'react';
import BVITPowerSpecificationsView from './BVITPowerSpecifications.view';

const BVITPowerSpecifications = ({ filterDetails, handleFiltersChange, powerSpecifications,
     setPowerSpecifications }) => {

    return(
        <BVITPowerSpecificationsView filterDetails={filterDetails} 
        handleFiltersChange={handleFiltersChange} powerSpecifications={powerSpecifications}
        setPowerSpecifications={setPowerSpecifications} />
    )
}

export default BVITPowerSpecifications;