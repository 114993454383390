import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Typography, Box} from '@mui/material';
import BVITUploadCloud from 'assets/png/BvitUploadCloud.png';

BVITFileDropZone.propTypes = {
  field: PropTypes.string,
  handleFileUpload: PropTypes.func,
};

BVITFileDropZone.defaultProps = {
  handleFileUpload: () => {},
};

export default function BVITFileDropZone({ handleFileUpload }) {
  
  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload(acceptedFiles);
  }, []);

  let { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div style={{ margin: '10px 0' }} {...getRootProps({ className: 'dropzone' })}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#E5E5F2', padding: '15px 10px', borderRadius: '10px' }}>
      <div style={{ display: 'inline-block', width: 'calc(100% - 15%)', verticalAlign: 'middle', paddingLeft: '16px' }} onClick={(e) => e.stopPropagation()}>
        <Box>
          <input {...getInputProps()} />
          <Box sx={{ textAlign: 'left', fontSize: '14px', color: '#2D3394' }}>Select or drag to upload Vehicle Shock Load Data Visualization</Box>
        </Box>
        <Box>
          <Typography variant="body1" textAlign="left" color="#8587AF" fontSize={11} mt={0.5}>
            .xls file and 50 MB max file size.
          </Typography>
        </Box>
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#fff', borderRadius: '9px', padding: '10px', width: '120px' }}>
        <img src={BVITUploadCloud} alt="Upload Cloud" width={20} height={15} color='#2D3394' />
        <Typography textAlign="left" color="#2D3394" fontSize={14} mt={0.25}>
            Upload File
        </Typography>
      </Box>
    </Box>
  </div>
  );
}
