const initialState = {
    carModels: [],
    carriers: [],
    mcTableData:[],
    carModelList:[],
    carrierList:[],
    isModelCarrierLoading: false,
  };
  
  const acceleratorsReducers = (state = initialState, action = []) => {
    switch (action.type) {
      case 'GET_CAR_MODEL_REQUEST':
        return {
          ...state,
          isModelCarrierLoading: true,
        };
  
      case 'GET_CAR_MODEL_SUCCESS':
        return {
          ...state,
          carModels: action.payload || [],
          isModelCarrierLoading: false,
        };
      case 'GET_CARRIER_SUCCESS':
        return {
          ...state,
          carriers: action.payload || [],
          isModelCarrierLoading: false,
        };
      case 'GET_MODEL_CARRIER_TABLE_DATA_SUCCESS':
        return {
          ...state,
          mcTableData: action.payload || [],
          isModelCarrierLoading: false,
        };
      case 'GET_CAR_MODEL_LIST_SUCCESS':
        return {
          ...state,
          carModelList: action.payload || [],
          isModelCarrierLoading: false,
        };
      case 'GET_CARRIER_LIST_SUCCESS':
        return {
          ...state,
          carrierList: action.payload || [],
          isModelCarrierLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default acceleratorsReducers;
  