import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

//app
import * as utils from 'utils';
import { config, accelerators, consts } from 'globalsData';
import { machineLearning, artificialIntelligence } from '../../globalsData/accelerators/accelerators';
import ComingSoonIcon from 'assets/svg/ComingSoonIcon.svg';
import EdaNoBackground from 'assets/svg/EdaNoBackground.svg';
import { AcceleratorDetailsView } from './AcceleratorDetails.view';
import { AddEditEDAForm, AddEditClassificationForm, AddEditRegressionForm, AddEditTimeSeriesForm, AddEditClusteringForm, AddEditEDAUnsupervisedForm ,AddArtificialIntelligenceForm, AddAnomalyDetectionForm} from 'forms';
import RegressionNoBackground from 'assets/svg/RegressionNoBackground.svg';
import ClusteringNoBackground from 'assets/svg/ClusteringNoBackground.svg';
import TimeSeriesNoBackground from 'assets/svg/TimeSeriesNoBackground.svg';
import ClassificationNoBackground from 'assets/svg/ClassificationNoBackground.svg';
import AnomalyDetectionNoBackground from 'assets/svg/AnomalyDetectionNoBackground.svg';
import ArtificialIntelligenceNoBackground from 'assets/svg/ArtificialIntelligenceNoBackground.svg';
import NaturalLanguageProcessingNoBackground from 'assets/svg/NaturalLanguageProcessingNoBackground.svg';

//mui
import { Link, Typography } from '@mui/material';

export default function AcceleratorDetails() {
  const navigate = useNavigate();
  // const location = useLocation();
  // const { type: acceleratorType } = useParams();
  const { type: acceleratorType } = useParams();
  const selecedAccelerator = utils.generic.capitalize(acceleratorType?.split('-')?.filter(Boolean)?.join(' ') || '');

  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  const breadcrumbs = [
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.home.root)}>
      {consts.APP_TITLE}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      sx={{ cursor: 'pointer' }}
      onClick={(e) => handleClick(e, config.routes.accelerators.root)}
    >
      {consts.FEATURE_LIST_OBJ.accelerators}
    </Link>,
    <Typography key="3" color="text.primary" sx={{ fontSize: '14px' }}>
      {selecedAccelerator}
    </Typography>,
  ];

  const handleACClick = (acceleator) => {
    if (acceleator.enabled) navigate(`${config.routes.accelerators.type}/${acceleator.name}`);
  };

  const getAcceleratorIcon = (acceleator) => {
    const { ACCELERATORS } = consts;
    switch (acceleator) {
      case ACCELERATORS.EXPLORATORY_DATA_ANALYSIS:
        return EdaNoBackground;
      case ACCELERATORS.CLASSIFICATION:
        return ClassificationNoBackground;
      case ACCELERATORS.REGRESSION:
        return RegressionNoBackground;
      case ACCELERATORS.CLUSTERING:
        return ClusteringNoBackground;
      case ACCELERATORS.TIME_SERIES:
        return TimeSeriesNoBackground;
      case ACCELERATORS.NATURAL_LANGUAGE_PROCESSING:
        return NaturalLanguageProcessingNoBackground;
      case ACCELERATORS.ANOMALY_DETECTION:
        return AnomalyDetectionNoBackground;
      case ACCELERATORS.ARTIFICIAL_INTELLIGENCE:
        return ArtificialIntelligenceNoBackground;
      default:
        return ComingSoonIcon;
    }
  };

  const getAcceleratorForm = (acceleator) => {
    const { EDA, CLASSIFICATION, REGRESSION, TIMESERIES, CLUSTERING, EDAU,ARTIFICIAL_INTELLIGENCE, ANOMALY_DETECTION } = consts;
    switch (acceleator) {
      case EDA:
        return <AddEditEDAForm />;
      case EDAU:
        return <AddEditEDAUnsupervisedForm />;
      case CLASSIFICATION:
        return <AddEditClassificationForm />;
      case REGRESSION:
        return <AddEditRegressionForm />;
      case TIMESERIES:
        return <AddEditTimeSeriesForm />;
      case CLUSTERING:
        return <AddEditClusteringForm />;
      case ARTIFICIAL_INTELLIGENCE:
        return <AddArtificialIntelligenceForm />;
      case ANOMALY_DETECTION:
          return <AddAnomalyDetectionForm />;
      default:
        return <h2>Coming Soon !!</h2>;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AcceleratorDetailsView
      breadcrumbs={breadcrumbs}
      acceleratorType={acceleratorType}
      handleACClick={handleACClick}
      accelerators={accelerators}
      artificialIntelligence={artificialIntelligence}
      machineLearning={machineLearning}
      getAcceleratorIcon={getAcceleratorIcon}
      getAcceleratorForm={getAcceleratorForm}
      selecedAccelerator={selecedAccelerator}
    />
  );
}
