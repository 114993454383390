import React, { useState } from 'react';
import BVITTorqueCalculationView from './BVITTorqueCalculation.view';

const BVITTorqueCalculation = () => {

    return(
        <BVITTorqueCalculationView />
    )
}

export default BVITTorqueCalculation;