import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

// app
import { loginClient } from '../../common-partner-login-sdk';
import { config, consts, useMedia } from 'globalsData';
import * as utils from 'utils';
import { collapseSidebar, expandSidebar } from 'stores';
import { BootstrapTooltip, SideDrawerList } from 'components';
import { ReactComponent as SidebarHomeIcon } from 'assets/svg/newHomeIcon.svg';
import { ReactComponent as SidebarAcceleratorIcon } from 'assets/svg/SidebarAcceleratorIcon.svg';
import { ReactComponent as SidebarAnalyticsStudioIcon } from 'assets/svg/SidebarAnalyticsStudioIcon.svg';
import { ReactComponent as SidebarDCMPIcon } from 'assets/svg/SidebarDCMPIcon.svg';
import { ReactComponent as SidebarUserManagementIcon } from 'assets/svg/newUserManagement.svg';
import { ReactComponent as SidebarDLPIcon } from 'assets/svg/SidebarDLPIcon.svg';
import { ReactComponent as AcceleratorIcon } from 'assets/svg/newAcceleratorIcon.svg';
import { ReactComponent as NewSidebarDataCrawlerIcon } from 'assets/svg/newDataCrawler.svg';
import { ReactComponent as LearningIcon } from 'assets/svg/learning.svg';
import { ReactComponent as LogoutIcon } from 'assets/svg/signoutIcon.svg';
import { ReactComponent as BusinessSolutionIcon } from 'assets/svg/newBusinessSolutionIcon.svg';
import { ReactComponent as DataCrawlerIcon } from 'assets/svg/newDataCrawlerIcon.svg';
import hexagon from 'assets/png/hexagon.png';
import { ReactComponent as MarutiSuzukiArena } from 'assets/svg/MarutiSuzukiArena.svg';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// mui
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Box, SwipeableDrawer, Typography, useTheme, ListItemIcon, Menu, Divider, MenuItem, Grid } from '@mui/material';

export default function SideDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { mobile, tablet, desktop, wide } = useMedia();

  // const configVars = useSelector((state) => get(state, 'config', null));
  const configVars = useSelector((state) => get(state, 'config.vars'));
  const open = useSelector((state) => get(state, 'ui.sidebar.expanded', false));
  const userInfo = useSelector((state) => get(state, 'user.userInfo', {}));
  const userDetails = useSelector((state) => get(state, 'user.userDetails', {}));
  const userEmailId = userDetails?.email;

  const isAdmin = userInfo?.roleName?.toLowerCase() === 'admin';

  const hasDLPAccess = utils.app.hasFeatureAccess(userInfo, consts.FEATURE_LIST_OBJ.os);
  const locationParams = location.pathname.split('/');
  const locationFirstParam = locationParams.length > 0 && locationParams[1] ? locationParams[1] : null;

  const [firstLevel, setFirstLevel] = useState(locationFirstParam);
  const [anchorEl, setAnchorEl] = useState(null);

  const sidebarList = [
    {
      name: 'home',
      title: consts.FEATURE_LIST_OBJ.home,
      icon: <SidebarHomeIcon width={15} height={15} />,
      link: config.routes.home.root,
    },
    {
      name: 'accelerators',
      title: consts.FEATURE_LIST_OBJ.accelerators,
      icon: <AcceleratorIcon width={15} height={15} />,
      // link: config.routes.accelerators.root,
      link: `${config.routes.accelerators.type}/exploratory-data-analysis`,
    },
    // {
    //   name: 'analyticsStudio',
    //   title: consts.FEATURE_LIST_OBJ.as,
    //   icon: <SidebarAnalyticsStudioIcon width={20} height={20} />,
    //   link: config.routes.analyticsStudio.root,
    // },
    {
      name: 'dcmp',
      title: consts.FEATURE_LIST_OBJ.dcmp,
      icon: <DataCrawlerIcon width={15} height={15} />,
      // link: config.routes.dcmp.root,
      link: `${config.routes.dcmp.websiteManagment}/Competition-Spares-Pricing-–-Commercial/5afefc60-9ae2-11ed-811a-ebbbfa59ab8d`,
    },
    ...(hasDLPAccess
      ? [
          {
            name: 'os',
            title: consts.FEATURE_LIST_OBJ.os,
            icon: <BusinessSolutionIcon width={15} height={15} />,
            link: config.routes.otherSolutions.root,
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            name: 'userManagement',
            title: consts.FEATURE_LIST_OBJ.userManagement,
            icon: <SidebarUserManagementIcon width={15} height={15} />,
            link: config.routes.userManagement.root,
          },
        ]
      : []),
      {
        name: 'reports',
        title: consts.FEATURE_LIST_OBJ.reports,
        icon: <SidebarHomeIcon width={15} height={15} />,
        link: config.routes.reports.root,
      },
  ];

  useEffect(() => {
    const paramArr = location.pathname.split('/');
    const firstParam = paramArr.length > 0 && paramArr[1] ? paramArr[1] : null;
    setFirstLevel(firstParam);
  }, [location]);

  const sideBarListFiltered = sidebarList.map((item) => {
    const isDashboard = item.name?.toLowerCase() === consts.FEATURE_LIST_OBJ.home?.toLowerCase();
    item.isSelected = isDashboard ? firstLevel === null : item.link && item.link.includes(firstLevel);
    return item;
  });

  const handleDrawerOpen = () => {
    dispatch(expandSidebar(true));
  };

  const handleDrawerClose = () => {
    dispatch(collapseSidebar());
  };

  // code related to logout start here
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.clear();
    loginClient.federatedLogout(configVars?.clientId, configVars?.loginRedirectUrl);
  };
  // code related to logout ends here

  const handleSideDrawerIconClick = (title, link) => {
    if (title !== consts.FEATURE_LIST_OBJ.dlp) {
      navigate(link);
      dispatch(collapseSidebar());
    } else {
      window.open(link, '_blank');
    }
  };

  const getWidth = () => {
    if (mobile) return '70%';
    if (tablet) return '35%';
    if (desktop) return '25%';
    return '20%';
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 2, backgroundColor: theme.palette.primary.white }}>
        <Box sx={{ p: 1 }} className="eapLogoHolder">
          <MarutiSuzukiArena width={150} height={25} />
        </Box>
        <Typography sx={{ fontSize: '16px', color: 'black', fontWeight: '500', p: 1 }}>Menu</Typography>
        {sideBarListFiltered?.map((item, idx) => {
          return (
            <Box
              sx={{
                p: 1,
                px: 2,
                borderRadius: '12px',
                ...(item.isSelected && { background: theme.palette.primary.sidebarBackground }),
                cursor: 'pointer',
              }}
              key={idx}
              onClick={() => handleSideDrawerIconClick(item.title, item.link)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  ...(item.isSelected && { filter: 'brightness(0) invert(1)' }),
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  height: '20px',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <Typography
                sx={{
                  fontSize: '12px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  padding: '0px 8px',
                  color: '#737791',
                  ...(item.isSelected && { color: theme.palette.primary.white }),
                }}
              >
                {utils.app.getEllipsisString(item?.title, consts.SIDEBAR_MENU_TITLE_ELLIPISIS_LENGTH)}
              </Typography>
            </Box>
          );
        })}
        <Box sx={{ minHeight: 'calc(85% - 0px)', display: 'flex', flexDirection: 'column-reverse' }}>
          {/* <Typography sx={{ fontSize: '16px', color: 'black', fontWeight: '500', p: 1 }}>Account</Typography> */}
          <Box>
            {/* <Box sx={{ p: 1, px: 2, borderRadius: '12px', cursor: 'pointer' }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                <LearningIcon width={20} height={20} />
              </ListItemIcon>
              <Typography
                sx={{
                  fontSize: '12px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  padding: '0px 10px',
                  color: '#737791',
                }}
              >
                {utils.app.getEllipsisString('Learning', consts.SIDEBAR_MENU_TITLE_ELLIPISIS_LENGTH)}
              </Typography>
            </Box> */}
            <Box onClick={handleMenu}>
              <BootstrapTooltip title={userEmailId?.length > 10 ? userEmailId : ''}>
                <Box sx={{ p: 1, px: 2, borderRadius: '12px', cursor: 'pointer' }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : 'auto',
                      justifyContent: 'center',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    <LogoutIcon width={15} height={15} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      padding: '0px 10px 5px 10px',
                      color: '#737791',
                    }}
                  >
                    {utils.app.getEllipsisString('Sign Out', consts.SIDEBAR_MENU_TITLE_ELLIPISIS_LENGTH)}
                  </Typography>
                </Box>
              </BootstrapTooltip>
            </Box>
            <Menu
              id="menu-app-sidebar"
              sx={{ mt: '5px' }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {userEmailId && (
                <Box pb={1}>
                  <Box py={1} px={2} display="flex">
                    <EmailOutlinedIcon />
                    <BootstrapTooltip title={userEmailId?.length > 25 ? userEmailId : ''}>
                      <Typography pl={1}>{utils.app.getEllipsisString(userEmailId, 25)}</Typography>
                    </BootstrapTooltip>
                  </Box>
                  <Divider />
                </Box>
              )}
              <MenuItem onClick={logout} >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography pl={0.1}>Logout</Typography>
                  </Grid>
                  <Grid item>
                    <LogoutOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>

      {/* {!open ? (
        <>
          {!mobile && (
            <Drawer variant="permanent" open={false}>
              <SideDrawerList
                open={false}
                sideBarListFiltered={sideBarListFiltered}
                handleSideDrawerIconClick={handleSideDrawerIconClick}
              />
            </Drawer>
          )}
        </>
      ) : (
        <Box pr={!mobile ? `calc(140px + 1px)` : '0px'}>
          <SwipeableDrawer
            anchor="left"
            open={true}
            onOpen={handleDrawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '>div:nth-of-type(3)': {
                background: `${theme.palette.primary.white} url(${hexagon}) 34% 77% no-repeat`,
                backgroundSize: `${wide ? '42%' : 'auto'}`,
                width: getWidth(),
              },
            }}
          >
            <SideDrawerList open sideBarListFiltered={sideBarListFiltered} handleSideDrawerIconClick={handleSideDrawerIconClick} />
          </SwipeableDrawer>
        </Box>
      )} */}
    </React.Fragment>
  );
}

const openedMixin = (theme) => ({
  width: consts.SIDE_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: consts.SIDE_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
