import React, { useEffect } from 'react';
import ReportsView from './Reports.view';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

// app
import {
  getTotalActiveUser,
  getMostActiveUser,
  getAverageResponseTime,
  getAcceleratorWeeklyUsage,
  getOverallAcceleratorsUsage,
  getOverallAppResponseTime,
  // getVerticalPieChartData,
  getDepartmentPieChartData,
} from 'stores';

export default function Reports() {
  const dispatch = useDispatch();
  const totalActiveUsers = useSelector((state) => get(state, 'reports.totalActiveUsers', {}));
  const mostActiveUsers = useSelector((state) => get(state, 'reports.mostActiveUser', {}));
  const avgResponseTime = useSelector((state) => get(state, 'reports.avgResponseTime', {}));
  const acceleratorsWeeklyUsage = useSelector((state) => get(state, 'reports.acceleratorsWeeklyUsage', {}));
  const avgAppLoadTime = useSelector((state) => get(state, 'reports.avgAppLoadTime', {}));
  // const verticalPieChart = useSelector((state) => get(state, 'reports.verticalPieChartData', {}));
  const departmentPieChart = useSelector((state) => get(state, 'reports.departmentPieChartData', {}));
  const overallAcceleratorsUsage = useSelector((state) => get(state, 'reports.overallAcceleratorsUsage', []));

  // if (verticalPieChart?.data?.length > 0) {
  //   verticalPieChart.data[0].selected = true;
  //   verticalPieChart.data[0].sliced = true;
  // } 
  if(departmentPieChart?.data?.length > 0){
    departmentPieChart.data[0].selected = true;
    departmentPieChart.data[0].sliced = true;
  }

  useEffect(() => {
    dispatch(getTotalActiveUser());
    dispatch(getMostActiveUser());
    dispatch(getAverageResponseTime());
    dispatch(getAcceleratorWeeklyUsage());
    dispatch(getOverallAcceleratorsUsage());
    dispatch(getOverallAppResponseTime());
    // dispatch(getVerticalPieChartData());
    dispatch(getDepartmentPieChartData());
  }, []);

  return (
    <>
      <ReportsView
        totalActiveUsers={totalActiveUsers}
        user={mostActiveUsers}
        avgResponseTime={avgResponseTime}
        acceleratorsWeeklyData={acceleratorsWeeklyUsage}
        overallAcceleratorsUsage={overallAcceleratorsUsage}
        avgAppLoadTime={avgAppLoadTime}
        // verticalPieChart={verticalPieChart}
        departmentPieChart={departmentPieChart}
      />
    </>
  );
}
