const config = {
  routes: {
    blank: {
      root: '/blank',
    },
    home: {
      root: '/',
    },
    userManagement: {
      root: '/userManagement',
    },
    reports: {
      root: '/reports',
    },
    dcmp: {
      root: '/dcmp',
      websiteManagment: '/dcmp/websiteManagement',
    },
    analyticsStudio: {
      root: '/analyticsStudio',
    },
    accelerators: {
      root: '/accelerators',
      type: '/accelerator',
    },
    otherSolutions: {
      root: '/businessSolutions', 
    },
    logisticsServiceProvider: {
      root: '/logisticsServiceProvider',
    },
    modelCarrier: {
      root: '/modelCarrier',
    },
    bvit: {
      root: '/bvit',
    },
    emuLpp: {
      root: '/emuLpp',
    },
  },
};

export default config;
