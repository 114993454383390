import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { get } from 'lodash';
//app
import { AcceleratorChartsView } from './AcceleratorCharts.view';
import { config, consts } from 'globalsData';
import * as utils from 'utils';
import {
  downloadFile,
  getGraphData,
  getSummaryDetails,
  redirectFromEdaChart,
  showModal,
  snackbarNotification,
  getInputTextFileDetails,
} from 'stores';

//mui
import { Link, Typography } from '@mui/material';

export default function AcceleratorCharts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type: acceleratorType, trackerId } = useParams();

  const configVars = useSelector((state) => get(state, 'config', null));
  const summaryDeteails = useSelector((state) => get(state, 'accelerators.summaryDeteails', {}));
  const inputTextFileDetails = useSelector((state) => get(state, 'accelerators.inputTextFileDetails', ''));
  const signedUrl = useSelector((state) => get(state, 'accelerators.signedUrl', ''));

  const csvInputFileName = summaryDeteails?.input_data?.fileName?.split('.')[0];
  const isEdaHypothesisSelectedYes = Boolean(summaryDeteails?.input_data?.hypothesisTesting?.toLowerCase() === 'yes');
  const isAutoNClusterSelectedForClustering = Boolean(summaryDeteails?.input_data?.algorithm?.toLowerCase() === 'auto_n_cluster');
  // const selecedAccelerator = utils.generic.capitalize(acceleratorType?.split('-')?.filter(Boolean)?.join(' ') || '');
  const selecedAccelerator = utils.generic.capitalizeFirstLetterOfString(acceleratorType?.split('-')?.filter(Boolean)?.join(' ') || '');
  const getAcceType = () => {
    if (acceleratorType === consts.EDA || acceleratorType === consts.EDAU) return 'eda';
    if (acceleratorType === consts.CLASSIFICATION) return consts.CLASSIFICATION;
    if (acceleratorType === consts.REGRESSION) return consts.REGRESSION;
    if (acceleratorType === consts.TIMESERIES) return consts.TIMESERIES;
    if (acceleratorType === consts.ACCELERATORS.SPEECH_TO_TEXT) return consts.ACCELERATORS.SPEECH_TO_TEXT;
    if (acceleratorType === consts.TEXT_TO_SPEECH) return consts.TEXT_TO_SPEECH;
    if (acceleratorType === consts.CLUSTERING) return consts.CLUSTERING;
    return '';
  };
  const type = getAcceType();

  const [isConnect, setIsConnect] = useState(true);

  const getSocketUrl = useCallback(() => {
    return `${configVars?.vars?.webSocketUrl}/?trackerId=${trackerId}`;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { sendMessage, lastMessage, readyState } = useWebSocket(getSocketUrl, {}, isConnect);

  const webSocketMessage = lastMessage?.data ? JSON.parse(lastMessage?.data) : {};
  const [status, setStatus] = useState('');

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const getSummaryData = () => {
    dispatch(getSummaryDetails({ trackerId: trackerId, type: type }));
  };

  useEffect(() => {
    getSummaryData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Eda Graphs Response
  const [bivariateAnalyisCategoricalPlotsOptions, setBivariateAnalyisCategoricalPlotsOptions] = useState({});
  const [numericalVariablesLogScaleBarPlotOptions, setNumericalVariablesLogScaleBarPlotOptions] = useState({});
  const [missingValuesBarGraphOptions, setMissingValuesBarGraphOptions] = useState({});
  const [inputDataHistogramOptions, setInputDataHistogramOptions] = useState({});
  const [numericalvariablesPirorScalingBoxPlotOptions, setNumericalvariablesPirorScalingBoxPlotOptions] = useState({});
  const [numericalvariablesPostScalingBoxPlotOptions, setNumericalvariablesPostScalingBoxPlotOptions] = useState({});
  const [hypothesisTestingBarGraphOptions, setHypothesisTestingBarGraphOptions] = useState({});
  const [vifBarGraphOptions, setVifBarGraphOptions] = useState({});
  const [outliersBoxplotOptions, setOutliersBoxplotOptions] = useState({});
  const [outliersCountBoxPlotOptions, setOutliersCountBoxPlotOptions] = useState({});
  const [bivariateAnalyisNumericalPlotsOptions, setBivariateAnalyisNumericalPlotsOptions] = useState({});
  const [univariateAnalysisPlotsOptions, setUnivariateAnalysisPlotsOptions] = useState({});
  const [skewnessBarPlotOptions, setSkewnessBarPlotOptions] = useState({});
  const [numericalVariablesHeatmapOptions, setNumericalVariablesHeatmapOptions] = useState({});

  // Classification and Regression Graph Response
  const [featureImportanceBarGraphPlotsOptions, setFeatureImportanceBarGraphPlotsOptions] = useState({});
  const [modelMetricsArr, setModelMetricsArr] = useState([]);

  // Classification Graphs Response
  const [aucPlotslPlotsOptions, setAucPlotslPlotsOptions] = useState({});
  const [confusionMatrixPlotsOptions, setConfusionMatrixPlotsOptions] = useState({});
  const [classCountBarGraphPlotsOptions, setClassCountBarGraphPlotsOptions] = useState({});

  //Regression Graphs Response
  const [predictionPlotOptions, setPredictionPlotOptions] = useState({});
  const [residualsPlotOptions, setResidualsPlotOptions] = useState({});

  // Timeseries Graphs Response
  const [yearlyDistributionPlots, setYearlyDistributionPlots] = useState({});
  const [edaTrendLinegraph, setEDATrendLinegraph] = useState({});
  const [ecdfLinegraph, setECDFLinegraph] = useState({});
  const [trendLinegraph, setTrendLinegraph] = useState({});

  // clustering Graphs Response
  const [clusteringSizePie, setClusteringSizePie] = useState({});
  const [elbowCurve, setElbowCurve] = useState({});
  const [featureDisTributionBarPlot, setFeatureDisTributionBarPlot] = useState({});
  const [silhouetteScorePlots, setSilhouetteScorePlots] = useState({});
  const [tsnePlot, SetTsnePlot] = useState({});

  // speech to text
  const [audio, setAudio] = useState('');
  const [fileDetails, setFileDetails] = useState('');

  const getModelMatrixRes = () => {
    dispatch(getGraphData({ graphName: 'model_metrics.json', trackerId })).then((res) => {
      if (res?.status === 200) {
        setModelMetricsArr(res?.data?.data);
      }
    });
  };
  const getFeatureImportanceBarGraphRes = () => {
    dispatch(getGraphData({ graphName: 'featureImportanceBarGraph.json', trackerId })).then((res) => {
      if (res?.status === 200) {
        setFeatureImportanceBarGraphPlotsOptions(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    if (acceleratorType === consts.EDA || acceleratorType === consts.EDAU) {
      if (status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase()) {
        dispatch(getGraphData({ graphName: 'bivariateAnalyisCategoricalPlots.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setBivariateAnalyisCategoricalPlotsOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'bivariateAnalyisNumericalPlots.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setBivariateAnalyisNumericalPlotsOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'univariateAnalysisPlots.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setUnivariateAnalysisPlotsOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'numericalVariablesLogScaleBarPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setNumericalVariablesLogScaleBarPlotOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'missingValuesBarGraph.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setMissingValuesBarGraphOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'inputDataHistogram.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setInputDataHistogramOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'numericalvariablesPriorScalingBoxPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setNumericalvariablesPirorScalingBoxPlotOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'numericalvariablesPostScalingBoxPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setNumericalvariablesPostScalingBoxPlotOptions(res?.data?.data);
          }
        });
        if (isEdaHypothesisSelectedYes) {
          dispatch(getGraphData({ graphName: 'hypothesisTestingBarGraph.json', trackerId })).then((res) => {
            if (res?.status === 200) {
              setHypothesisTestingBarGraphOptions(res?.data?.data);
            }
          });
        }
        dispatch(getGraphData({ graphName: 'vifBarGraph.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setVifBarGraphOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'outliersBoxplot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setOutliersBoxplotOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'outliersCountBoxPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setOutliersCountBoxPlotOptions(res?.data?.data);
          }
        });

        dispatch(getGraphData({ graphName: 'skewnessBarPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setSkewnessBarPlotOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'numericalVariablesHeatmap.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setNumericalVariablesHeatmapOptions(res?.data?.data);
          }
        });
      }
    } else if (acceleratorType === consts.CLASSIFICATION) {
      if (
        status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() &&
        summaryDeteails?.input_data?.algorithm &&
        summaryDeteails?.input_data?.algorithm?.toLowerCase() !== 'all'
      ) {
        getFeatureImportanceBarGraphRes();
        dispatch(getGraphData({ graphName: 'classCountBarGraph.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setClassCountBarGraphPlotsOptions(res?.data?.data);
          }
        });
        if (summaryDeteails?.input_data?.modelMetrics?.split(', ')?.includes('auc')) {
          dispatch(getGraphData({ graphName: 'aucPlots.json', trackerId })).then((res) => {
            if (res?.status === 200) {
              setAucPlotslPlotsOptions(res?.data?.data);
            }
          });
        }
        if (summaryDeteails?.input_data?.modelMetrics?.split(', ')?.includes('confusionMatrix')) {
          dispatch(getGraphData({ graphName: 'confusionMatrix.json', trackerId })).then((res) => {
            if (res?.status === 200) {
              setConfusionMatrixPlotsOptions(res?.data?.data);
            }
          });
        }
      } else if (
        status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() &&
        summaryDeteails?.input_data?.algorithm &&
        summaryDeteails?.input_data?.algorithm?.toLowerCase() === 'all'
      ) {
        getModelMatrixRes();
      }
    } else if (acceleratorType === consts.TIMESERIES) {
      if (status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase()) {
        dispatch(getGraphData({ graphName: 'yearlyDistributionPlots.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setYearlyDistributionPlots(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'edaTrendLinegraph.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setEDATrendLinegraph(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'ecdfLinegraph.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setECDFLinegraph(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'trendLinegraph.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setTrendLinegraph(res?.data?.data);
          }
        });
      }
    } else if (acceleratorType === consts.CLUSTERING) {
      if (
        status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() &&
        summaryDeteails?.input_data?.algorithm &&
        summaryDeteails?.input_data?.algorithm?.toLowerCase() !== 'all'
      ) {
        if (isAutoNClusterSelectedForClustering) {
          dispatch(getGraphData({ graphName: 'elbowCurve.json', trackerId })).then((res) => {
            if (res?.status === 200) {
              setElbowCurve(res?.data?.data);
            }
          });
          dispatch(getGraphData({ graphName: 'silhouetteScorePlots.json', trackerId })).then((res) => {
            if (res?.status === 200) {
              setSilhouetteScorePlots(res?.data?.data);
            }
          });
        }
        dispatch(getGraphData({ graphName: 'ClusterSize_Pie.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setClusteringSizePie(res?.data?.data);
          }
        });

        dispatch(getGraphData({ graphName: 'featureDistributionBarPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setFeatureDisTributionBarPlot(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'tsnePlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            SetTsnePlot(res?.data?.data);
          }
        });
      }
    } else if (acceleratorType === consts.ACCELERATORS.SPEECH_TO_TEXT) {
      if (status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase()) {
        const params = {
          trackerId: trackerId,
          fileType: summaryDeteails?.input_data?.filetype,
          fileName: summaryDeteails?.input_data?.fileName,
          type: consts.ACCELERATORS.SPEECH_TO_TEXT,
          path: 'download-input-audio',
        };
        dispatch(
          getInputTextFileDetails({
            trackerId,
            fileName: `${summaryDeteails?.input_data?.fileName?.split('.')[0]}_translated.docx`,
            type,
            fileType: 'docx',
          })
        );
        dispatch(downloadFile(params));
      }
    } else if (acceleratorType === consts.TEXT_TO_SPEECH) {
      if (status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase()) {
        dispatch(getInputTextFileDetails({ trackerId, fileName: summaryDeteails?.input_data?.fileName, type }));
        const params = {
          trackerId: trackerId,
          fileType: 'wav',
          fileName: 'audiooutput.wav',
          type: consts?.TEXT_TO_SPEECH,
        };
        dispatch(downloadFile(params));
      }
    } else {
      if (
        status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() &&
        summaryDeteails?.input_data?.algorithm &&
        summaryDeteails?.input_data?.algorithm?.toLowerCase() !== 'all'
      ) {
        getFeatureImportanceBarGraphRes();
        dispatch(getGraphData({ graphName: 'predictionPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setPredictionPlotOptions(res?.data?.data);
          }
        });
        dispatch(getGraphData({ graphName: 'residualsPlot.json', trackerId })).then((res) => {
          if (res?.status === 200) {
            setResidualsPlotOptions(res?.data?.data);
          }
        });
      } else if (
        status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() &&
        summaryDeteails?.input_data?.algorithm &&
        summaryDeteails?.input_data?.algorithm?.toLowerCase() === 'all'
      ) {
        getModelMatrixRes();
      }
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const handler = () => {
    setIsConnect(false);
  };

  useEffect(() => {
    setFileDetails(inputTextFileDetails);
  }, [inputTextFileDetails]);

  useEffect(() => {
    if (acceleratorType === consts.TEXT_TO_SPEECH) {
      setAudio(signedUrl[0]?.audiooutput);
    } else if (acceleratorType === consts.ACCELERATORS.SPEECH_TO_TEXT) {
      setAudio(signedUrl[0]?.[`${summaryDeteails?.input_data?.fileName?.split('.')[0]}`]);
    }
  }, [signedUrl]);

  // remove if it is not working
  useEffect(() => {
    window.addEventListener('popstate', handler);
    return () => {
      window.removeEventListener('popstate', handler);
    };
  }, []);

  useEffect(() => {
    sendMessage(JSON.stringify({ action: 'sendMessage', data: trackerId }));
    if (webSocketMessage?.trackerId && webSocketMessage?.trackerId === trackerId) {
      if (webSocketMessage?.status && status?.toLowerCase() !== consts.acceleratorStatus.complete?.toLowerCase()) {
        setStatus(webSocketMessage?.status);
      }
      if (
        webSocketMessage?.status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() ||
        webSocketMessage?.status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus)
      ) {
        setIsConnect(false);
        if (webSocketMessage?.status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus)) {
          dispatch(snackbarNotification(webSocketMessage?.status, 'error'));
        }
      }
    }
  }, [webSocketMessage?.status, connectionStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLinkClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  const breadcrumbs = [
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleLinkClick(e, config.routes.home.root)}>
      {consts.APP_TITLE}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      sx={{ cursor: 'pointer' }}
      onClick={(e) => handleLinkClick(e, config.routes.accelerators.root)}
    >
      {consts.FEATURE_LIST_OBJ.accelerators}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      sx={{ cursor: 'pointer' }}
      onClick={(e) => handleLinkClick(e, `${config.routes.accelerators.type}/${acceleratorType}`)}
    >
      {selecedAccelerator}
    </Link>,
    <Typography key="3" color="text.primary" sx={{ fontSize: '14px' }}>
      Results
    </Typography>,
  ];

  const expadChart = (chart) => {
    dispatch(
      showModal({
        component: 'VIEW_ACCELERATOR_MATRIX',
        props: {
          title: chart?.title,
          // maxWidth: 'lg',
          fullScreen: true,
          componentProps: {
            chart,
          },
        },
      })
    );
  };

  const handleEdit = useCallback((accSummaryDeteails) => {
    if (acceleratorType === consts.EDA) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_EDA',
          props: {
            title: 'Update Exploratory Data Analysis',
            maxWidth: 'sm',
            componentProps: {
              data: accSummaryDeteails,
              submitHandler: () => {
                getSummaryData();
                setIsConnect(false);
                setStatus('');
                setNumericalVariablesLogScaleBarPlotOptions({});
                setMissingValuesBarGraphOptions({});
                setInputDataHistogramOptions({});
                setNumericalvariablesPirorScalingBoxPlotOptions({});
                setNumericalvariablesPostScalingBoxPlotOptions({});
                setHypothesisTestingBarGraphOptions({});
                setVifBarGraphOptions({});
                setOutliersBoxplotOptions({});
                setOutliersCountBoxPlotOptions({});
                setBivariateAnalyisCategoricalPlotsOptions({});
                setBivariateAnalyisNumericalPlotsOptions({});
                setUnivariateAnalysisPlotsOptions({});
                setSkewnessBarPlotOptions({});
                setNumericalVariablesHeatmapOptions({});
                setIsConnect(true);
              },
            },
          },
        })
      );
    } else if (acceleratorType === consts.EDAU) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_EDAU',
          props: {
            title: 'Update Exploratory Data Analysis Unsupervised',
            maxWidth: 'sm',
            componentProps: {
              data: accSummaryDeteails,
              submitHandler: () => {
                getSummaryData();
                setIsConnect(false);
                setStatus('');
                setNumericalVariablesLogScaleBarPlotOptions({});
                setMissingValuesBarGraphOptions({});
                setInputDataHistogramOptions({});
                setNumericalvariablesPirorScalingBoxPlotOptions({});
                setNumericalvariablesPostScalingBoxPlotOptions({});
                setHypothesisTestingBarGraphOptions({});
                setVifBarGraphOptions({});
                setOutliersBoxplotOptions({});
                setOutliersCountBoxPlotOptions({});
                setBivariateAnalyisCategoricalPlotsOptions({});
                setBivariateAnalyisNumericalPlotsOptions({});
                setUnivariateAnalysisPlotsOptions({});
                setSkewnessBarPlotOptions({});
                setNumericalVariablesHeatmapOptions({});
                setIsConnect(true);
              },
            },
          },
        })
      );
    } else if (acceleratorType === consts.CLASSIFICATION) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_CLASSIFICATION',
          props: {
            title: 'Update Classification',
            maxWidth: 'sm',
            componentProps: {
              data: accSummaryDeteails,
              submitHandler: () => {
                getSummaryData();
                setIsConnect(false);
                setStatus('');
                //reset chart state objects
                setFeatureImportanceBarGraphPlotsOptions({});
                setClassCountBarGraphPlotsOptions({});
                setAucPlotslPlotsOptions({});
                setConfusionMatrixPlotsOptions({});
                setModelMetricsArr([]);
                setIsConnect(true);
              },
            },
          },
        })
      );
    }
    // show modal for timeseries starts here
    else if (acceleratorType === consts.TIMESERIES) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_TIMESERIES',
          props: {
            title: 'Update Timeseries',
            maxWidth: 'sm',
            componentProps: {
              data: accSummaryDeteails,
              submitHandler: () => {
                getSummaryData();
                setIsConnect(false);
                setStatus('');
                //reset chart state objects
                setFeatureImportanceBarGraphPlotsOptions({});
                setClassCountBarGraphPlotsOptions({});
                setAucPlotslPlotsOptions({});
                setConfusionMatrixPlotsOptions({});
                setModelMetricsArr([]);
                setIsConnect(true);
              },
            },
          },
        })
      );
    }
    // show modal for clustering data  starts here
    else if (acceleratorType === consts.CLUSTERING) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_CLUSTERING',
          props: {
            title: 'Update Clustering',
            maxWidth: 'sm',
            componentProps: {
              data: accSummaryDeteails,
              submitHandler: () => {
                getSummaryData();
                setIsConnect(false);
                setStatus('');
                //reset chart state objects
                setFeatureImportanceBarGraphPlotsOptions({});
                setClassCountBarGraphPlotsOptions({});
                setAucPlotslPlotsOptions({});
                setConfusionMatrixPlotsOptions({});
                setModelMetricsArr([]);
                setIsConnect(true);
              },
            },
          },
        })
      );
    }
    // show modal for timeseries ends here
    else {
      dispatch(
        showModal({
          component: 'ADD_EDIT_REGRESSION',
          props: {
            title: 'Update Regression',
            maxWidth: 'sm',
            componentProps: {
              data: accSummaryDeteails,
              submitHandler: () => {
                getSummaryData();
                setIsConnect(false);
                setStatus('');
                //reset chart state objects
                setFeatureImportanceBarGraphPlotsOptions({});
                setPredictionPlotOptions({});
                setResidualsPlotOptions({});
                setModelMetricsArr([]);
                setIsConnect(true);
              },
            },
          },
        })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCsvFileName = () => {
    if (type === consts.CLASSIFICATION || type === consts.REGRESSION || type === consts.ACCELERATORS.CLUSTERING.toLocaleLowerCase())
      return `${csvInputFileName}_predictions.csv`;
    if (type === 'eda') return `${csvInputFileName}_train.csv,${csvInputFileName}_eap_cleaned_data.csv`;
    return '';
  };

  const getPdfFileName = () => {
    if (type === 'eda') return `${csvInputFileName}_EAP_EDA.pdf`;
    if (type === consts.CLASSIFICATION) {
      if (summaryDeteails?.input_data?.algorithm === 'all') {
        return `${csvInputFileName}_EAP_PyCaret_Classification_report.pdf`;
      }
      return `${csvInputFileName}_EAP_Classification_report.pdf`;
    }
    if (type === consts.REGRESSION) {
      if (summaryDeteails?.input_data?.algorithm === 'all') {
        return `${csvInputFileName}_EAP_PyCaret_Regression_report.pdf`;
      }
      return `${csvInputFileName}_EAP_Regression_report.pdf`;
    }
    if (type === consts.TIMESERIES) return `${csvInputFileName}_EAP_Forecasting.pdf`;
    if (type === consts.CLUSTERING) return `${csvInputFileName}_EAP_clustering_report.pdf`;

    return '';
  };

  const handleDownloadCsv = () => {
    const params = {
      trackerId: trackerId,
      fileType: `${
        type === consts.CLASSIFICATION || type === consts.REGRESSION || type === consts.ACCELERATORS.CLUSTERING.toLocaleLowerCase()
          ? 'predicted_data'
          : 'csv'
      }`,
      fileName: getCsvFileName(),
      type,
    };
    dispatch(downloadFile(params));
  };

  const handleDownloadHtml = () => {
    const params = {
      trackerId: trackerId,
      fileType: `${
        type === consts.CLASSIFICATION || type === consts.REGRESSION || type === consts.CLUSTERING || type === consts.TIMESERIES
          ? 'pkl'
          : 'html'
      }`,
      fileName: `${csvInputFileName}_${
        type === consts.CLASSIFICATION || type === consts.REGRESSION || type === consts.CLUSTERING || type === consts.TIMESERIES
          ? 'model.pkl'
          : 'pandas_profiling_EDA.html'
      }`,
      type,
    };
    dispatch(downloadFile(params));
  };

  const handleDownloadPdf = () => {
    const params = {
      trackerId: trackerId,
      fileType: 'pdf',
      fileName: getPdfFileName(),
      type,
    };
    dispatch(downloadFile(params));
  };

  const handleDownloadAudioFiles = () => {
    let params = {
      trackerId: trackerId,
      isForDownload: true,
    };
    if (acceleratorType === consts?.TEXT_TO_SPEECH) {
      params = {
        ...params,
        fileType: 'wav',
        fileName: 'audiooutput.wav',
        type: consts?.TEXT_TO_SPEECH,
      };
    }

    if (acceleratorType === consts?.ACCELERATORS.SPEECH_TO_TEXT) {
      params = {
        ...params,
        fileType: 'docx',
        fileName: `${summaryDeteails?.input_data?.fileName?.split('.')[0]}_translated.docx`,
      };
    }
    dispatch(downloadFile(params));
  };

  const handleLearningTypeClick = (e, learningType) => {
    if (!learningType) return;

    dispatch(redirectFromEdaChart(true));
    handleLinkClick(e, `${config.routes.accelerators.type}/${learningType?.toLowerCase()}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AcceleratorChartsView
      breadcrumbs={breadcrumbs}
      status={status}
      selecedAccelerator={selecedAccelerator}
      modelMetricsArr={modelMetricsArr}
      bivariateAnalyisCategoricalPlotsOptions={bivariateAnalyisCategoricalPlotsOptions}
      numericalVariablesLogScaleBarPlotOptions={numericalVariablesLogScaleBarPlotOptions}
      missingValuesBarGraphOptions={missingValuesBarGraphOptions}
      inputDataHistogramOptions={inputDataHistogramOptions}
      numericalvariablesPirorScalingBoxPlotOptions={numericalvariablesPirorScalingBoxPlotOptions}
      numericalvariablesPostScalingBoxPlotOptions={numericalvariablesPostScalingBoxPlotOptions}
      hypothesisTestingBarGraphOptions={hypothesisTestingBarGraphOptions}
      vifBarGraphOptions={vifBarGraphOptions}
      outliersBoxplotOptions={outliersBoxplotOptions}
      outliersCountBoxPlotOptions={outliersCountBoxPlotOptions}
      bivariateAnalyisNumericalPlotsOptions={bivariateAnalyisNumericalPlotsOptions}
      univariateAnalysisPlotsOptions={univariateAnalysisPlotsOptions}
      skewnessBarPlotOptions={skewnessBarPlotOptions}
      numericalVariablesHeatmapOptions={numericalVariablesHeatmapOptions}
      featureImportanceBarGraphPlotsOptions={featureImportanceBarGraphPlotsOptions}
      classCountBarGraphPlotsOptions={classCountBarGraphPlotsOptions}
      aucPlotslPlotsOptions={aucPlotslPlotsOptions}
      confusionMatrixPlotsOptions={confusionMatrixPlotsOptions}
      summaryDeteails={summaryDeteails}
      isConnect={isConnect}
      predictionPlotOptions={predictionPlotOptions}
      residualsPlotOptions={residualsPlotOptions}
      yearlyDistributionPlots={yearlyDistributionPlots}
      edaTrendLinegraph={edaTrendLinegraph}
      ecdfLinegraph={ecdfLinegraph}
      trendLinegraph={trendLinegraph}
      audio={audio}
      textDetails={fileDetails}
      handleDownloadAudioFiles={handleDownloadAudioFiles}
      clusteringSizePie={clusteringSizePie}
      elbowCurve={elbowCurve}
      featureDisTributionBarPlot={featureDisTributionBarPlot}
      silhouetteScorePlots={silhouetteScorePlots}
      tsnePlot={tsnePlot}
      handlers={{ expadChart, handleEdit, handleDownloadCsv, handleDownloadHtml, handleDownloadPdf, handleLearningTypeClick }}
    />
  );
}
