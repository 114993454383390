import React from 'react';
import BVITSummaryStatisticsView from './BVITSummaryStatistics.view';

const BVITSummaryStatistics = ({ summaryDetails, label }) => {

    return(
        <BVITSummaryStatisticsView summaryDetails={summaryDetails} label={label} />
    )
}

export default BVITSummaryStatistics;