import axios from 'axios';
import { snackbarNotification } from 'stores';
import { loginClient } from '../../common-partner-login-sdk';

const missingFetchParameters = 'Missing fetch parameters';

const utilsApi = {
  get: async ({ endpoint, path, params, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return await axios.get(getUrl(endpoint, path, params), {
      headers: headers ? getHeaders(token) : {},
    });
  },
  post: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.post(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token) : {},
      }
    );
  },
  multipartPost: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.post(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token, 'multipart') : {},
      }
    );
  },
  put: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.put(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token) : {},
      }
    );
  },
  multipartPut: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.put(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token, 'multipart') : {},
      }
    );
  },
  multipartPutForCsvAndXlFiles: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;
    const file=data.file
    const contentType = file.type;
    return axios.put(
      getUrl(endpoint, path, params),
      file,      
      {
        headers: {...(headers ? getHeaders(token, 'multipart') : {}),
        'Content-Type': contentType,
      }
      }
    );
  },
  multipartPutForAudioFiles: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;
    const file = data.file;
    const blob = new Blob([file], { type: file.type });
    const contentType = blob.type;
    return axios.put(getUrl(endpoint, path, params), blob, {
      headers: {
        ...(headers ? getHeaders(token, 'multipart') : {}),
        'Content-Type': contentType,
      },
    });
  },

  patch: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.patch(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token) : {},
      }
    );
  },
  delete: ({ endpoint, path, params, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.delete(getUrl(endpoint, path, params), {
      headers: headers ? getHeaders(token) : {},
    });
  },
  handleUnauthorized: (error, dispatch, redirectUrl) => {
    if (error?.response?.status === 401 && dispatch && redirectUrl) {
      const msilCommonLoginSdkApiKey = localStorage.getItem('msil-common-login-sdk-api-key');
      if (msilCommonLoginSdkApiKey) {
        localStorage.clear();
        alert('Session expired, please login again...');
        loginClient.federatedLogout(msilCommonLoginSdkApiKey, redirectUrl);
      } else {
        dispatch(snackbarNotification('App Key Missing', 'error'));
      }
    }
  },
};

export const accessToken = () => {
   const msilCommonLoginSdkApiKey = localStorage.getItem('msil-common-login-sdk-api-key');
   const lastLoggedInUserId = localStorage.getItem('lastAuthUserId');
   return localStorage.getItem(`CognitoIdentityServiceProvider.${msilCommonLoginSdkApiKey}.${lastLoggedInUserId}.idToken`) || '';
};

export const getUrl = (endpoint, path, params) => {
  const apiPath = path ? `/${path}` : '';
  if (!endpoint) return '';
  return `${endpoint}${apiPath}${getQueryString(params)}`;
};

export const getQueryString = (params) => {
  const isObject = params !== null && typeof params === 'object' && Array.isArray(params) === false;

  if (!isObject) return '';

  const paramsArray = Object.entries(params).map((param) => {
    return Array.isArray(param[1]) ? `${param[0]}=${JSON.stringify(param[1])}` : `${param[0]}=${param[1]}`;
  });
  return paramsArray.length > 0 ? `?${paramsArray.join('&')}` : '';
};

export const getHeaders = (token, type = 'json') => {
  return {
    ...(type === 'json' && { 'Content-Type': 'application/json' }),
    ...(type === 'multipart' && { 'Content-Type': 'multipart/form-data' }),
    ...(token && { Authorization: token }),
  };
};

export default utilsApi;
