import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const runCrawlerScript = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(runCrawlerScriptRequest(reqParams));
  dispatch(addLoader('runCrawlerScript'));

  const { websiteList } = reqParams;

  const data = {
    websiteList,
  };

  return api
    .post({
      endpoint: endpoint.crawlerScriptService,
      path: 'v1/scraping/runScript',
      data,
    })
    .then((res) => {
      dispatch(runCrawlerScriptSuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message?.join(', '), 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(runCrawlerScriptError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('runCrawlerScript'));
    });
};

export const runCrawlerScriptRequest = (params) => {
  return {
    type: 'RUN_CRAWLER_SCRIPT_REQUEST',
    payload: params,
  };
};

export const runCrawlerScriptSuccess = (data) => {
  return {
    type: 'RUN_CRAWLER_SCRIPT_REQUEST_SUCCESS',
    payload: data,
  };
};

export const runCrawlerScriptError = (error) => {
  return {
    type: 'RUN_CRAWLER_SCRIPT_REQUEST_FAILURE',
    payload: error,
  };
};
