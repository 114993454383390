import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getBvitPresignedUrl = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!reqParams?.filename) {
    return Promise.reject({ message: 'file name not found' });
  }

  dispatch(getBvitPresignedUrlRequest(reqParams));
  dispatch(addLoader('getBvitPresignedUrl'));

  return api
    .post({
      endpoint: endpoint.bvitService,
      path: 'getPreSignedUrl',
      data: reqParams,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(getBvitPresignedUrlError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getBvitPresignedUrl'));
    });
};

export const getBvitPresignedUrlRequest = (params) => {
  return {
    type: 'GET_BVIT_PRE_SIGNED_URL_REQUEST',
    payload: params,
  };
};

export const getBvitPresignedUrlSuccess = (data) => {
  return {
    type: 'GET_BVIT_PRE_SIGNED_URL_SUCCESS',
    payload: data,
  };
};

export const getBvitPresignedUrlError = (error) => {
  return {
    type: 'GET_BVIT_PRE_SIGNED_URL_FAILURE',
    payload: error,
  };
};
