import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const deleteCarModel = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
  dispatch(deleteCarModelRequest(params));
  dispatch(addLoader('deleteCarModel'));

  const { carModelId } = params;

  return api
    .post({
      endpoint: endpoint.modelCarrier,
      path: `deleteCarModel/${carModelId}`,
    })
    .then((data) => {
      dispatch(deleteCarModelSuccess(data?.data));
      dispatch(snackbarNotification(data?.data?.message || 'Deleted successfully','success'));
      return data;
    })
    .catch((error) => {
      dispatch(deleteCarModelError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('deleteCarModel'));
    });
};

export const deleteCarModelRequest = (params) => {
  return {
    type: 'DELETE_CAR_MODEL_REQUEST',
    payload: params,
  };
};

export const deleteCarModelSuccess = (data) => {
  return {
    type: 'DELETE_CAR_MODEL_SUCCESS',
    payload: data,
  };
};

export const deleteCarModelError = (error) => {
  return {
    type: 'DELETE_CAR_MODEL_FAILURE',
    payload: error,
  };
};
