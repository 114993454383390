import getAlgorithmHyperParameters from 'globalsData/getAlgorithmHyperParameters/getAlgorithmHyperParameters';
import { consts } from 'globalsData';

const getRegressionPayload = (formData) => {
  if (!formData) return;
  const { targetVariable, testSizeRatio, algorithm, justificationRemarks } = formData;
  const hyperParameters = getAlgorithmHyperParameters(consts.REGRESSION, formData);
  if (algorithm === 'linearRegression') {
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {},
    };
  } else if (algorithm === 'ridgeRegression') {
    const { alpha, solver, tol } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        alpha,
        solver,
        tol,
      },
    };
  } else if (algorithm === 'lassoRegression') {
    const { alpha, selection, tol } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        alpha,
        selection,
        tol,
      },
    };
  } else if (algorithm === 'randomForestRegressor') {
    const { rfCriterion, rfMax_features, rfN_estimators } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        criterion: rfCriterion,
        max_features: rfMax_features,
        n_estimators: rfN_estimators,
      },
    };
  } else if (algorithm === 'kNNRegressor') {
    const { kNNalgorithm, n_neighbors, p, weights } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        algorithm: kNNalgorithm,
        n_neighbors,
        p: Number(p),
        weights,
      },
    };
  } else if (algorithm === 'baggingRegressor') {
    const { brMax_features, brN_estimators } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        max_features: brMax_features,
        n_estimators: brN_estimators,
      },
    };
  } else if (algorithm === 'adaBoostRegressor') {
    const { learning_rate, adaLoss, adaN_estimators } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        learning_rate,
        loss: adaLoss,
        n_estimators: adaN_estimators,
      },
    };
  } else if (algorithm === 'gradientBoostingRegressor') {
    const { gbrCriterion, gbrLoss, gbrMaxFeatures, gbrN_estimators, tol } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        criterion: gbrCriterion,
        loss: gbrLoss,
        max_features: gbrMaxFeatures,
        n_estimators: gbrN_estimators,
        tol,
      },
    };
  } else if (algorithm === 'XGBRegressor') {
    const { booster, importance_type, learning_rate, xgbN_estimators } = hyperParameters;
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
      modelHyperParameters: {
        booster,
        importance_type,
        learning_rate,
        n_estimators: xgbN_estimators,
      },
    };
  } else {
    return {
      targetVariable,
      testSizeRatio,
      algorithm,
      justificationRemarks,
    };
  }
};
export default getRegressionPayload;
