import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getReportsData = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(addLoader('getReportsData'));

  return api
    .get({
      endpoint: endpoint.bvitService,
      path: 'getReportsData'
    })
    .then((res) => {
      dispatch(getReportsDataSuccess(res?.data));
      return res;
    })
    .catch((error) => {
      dispatch(getReportsDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getReportsData'));
    });
};

export const getReportsDataRequest = () => {
  return {
    type: 'GET_REPORTS_DATA_REQUEST'
  };
};

export const getReportsDataSuccess = (data) => {
  return {
    type: 'GET_REPORTS_DATA_SUCCESS',
    payload: data,
  };
};

export const getReportsDataError = (error) => {
  return {
    type: 'GET_REPORTS_DATA_FAILURE',
    payload: error,
  };
};
