/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';

//app
import * as utils from 'utils';
import { AddEditClusteringFormView } from './AddEditClusteringForm.view';
import { config, getClusteringPayload, consts, clusterStaticData } from 'globalsData';
import {
  addLoader,
  removeLoader,
  getPresignedUrl,
  postProcessingInput,
  snackbarNotification,
  uploadFileToS3,
  editAccSummary,
  hideModal,
} from 'stores';

AddEditClusteringForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

export default function AddEditClusteringForm({ data, submitHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const summaryDeteails = useSelector((state) => get(state, 'accelerators.summaryDeteails', {}));
  const isRedirectFromEdaCharts = useSelector((state) => get(state, 'accelerators.isRedirectFromEdaCharts', false));

  const inputDataRef = useRef();
  const predictionDataRef = useRef();

  const inputFormDataFromApi = data?.input_data || {};
  const inputDataFileNameFromApi = data?.input_data?.fileName;
  const predictionFileNameFromApi = data?.input_data?.predictionFileName;
  const trackerIdFromApi = data?.trackerId;
  const selectedIdCols = data?.input_data?.idCols;

  const [columns, setColumns] = useState([]);
  const [files, setFiles] = useState({ inputDataFile: null, predictionDataFile: null });
  const [isNoFilesUploaded, setIsNoFilesUploaded] = useState({ inputDataFile: false, predictionDataFile: false });
  const [isValidFileFormats, setIsValidFileFormats] = useState({ inputDataFile: true, predictionDataFile: true });
  const [isValidFileSizes, setIsValidFileSizes] = useState({ inputDataFile: true, predictionDataFile: true });

  const isLastProcessDataInprogress = false;
  const algorithms = clusterStaticData.algorithms;
  const KmeansInitList = clusterStaticData.KmeansInitList;
  const KmodesInitList = clusterStaticData.KmodesInitList;
  const KmeansAlgorithmList = clusterStaticData.KmeansAlgorithmList;
  const AgglomotiveMetricClusteringList = clusterStaticData.agglomotiveMetricClusteringList;
  const AgglomotiveLinkageClusteringList = clusterStaticData.agglomotiveLinkageClusteringList;

  const fileUploadFieldList = [
    { field: 'inputData', label: 'Upload Input Data ', file: files.inputDataFile, ref: inputDataRef },
    {
      field: 'predictionData',
      label: 'Upload Prediction Data (Optional)',
      file: files.predictionDataFile,
      ref: predictionDataRef,
    },
  ];

  const getDefaultModelMetrics = () => {
    let convertedArray = '';
    if (selectedIdCols && selectedIdCols.length) {
      convertedArray = selectedIdCols?.map((label) => {
        const name = label.replace(/_/, ' ').replace(/(\b\w)/g, (firstChar) => firstChar.toUpperCase());
        const key = label.toLowerCase();

        return {
          label,
          name,
          key,
        };
      });
    }
    return convertedArray;
  };

  const fields = [
    {
      name: 'justificationRemarks',
      id: 'justificationRemarks',
      size: 'small',
      required: true,
      default: inputFormDataFromApi?.justificationRemarks || '',
      validation: Yup.string().min(30, 'Min 30 characters').max(150, 'Max 150 characters').required('Justification Remarks required'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'agglomative_n_cluster',
      id: 'agglomative_n_cluster',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.n_clusters || 2,
      min: 2,
      max: 20,
      // marks: targetSizeRatioMarks,
      required: true,
      validation: Yup.number().min(2, 'Min value can be 10').max(40, 'Max value can be 40').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'kmeans_n_cluster',
      id: 'kmeans_n_cluster',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.n_clusters || 8,
      min: 8,
      max: 20,
      // marks: targetSizeRatioMarks,
      required: inputFormDataFromApi?.modelHyperParameters ? false : true,
      validation: Yup.number().when('required', {
        is: true,
        then: Yup.number().min(8, 'Min value can be 10').max(40, 'Max value can be 40').required('Value cannot be empty'),
        otherwise: Yup.number(),
      }),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'kmod_n_cluster',
      id: 'kmod_n_cluster',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.n_clusters || 8,
      min: 8,
      max: 20,
      // marks: targetSizeRatioMarks,
      required: inputFormDataFromApi?.modelHyperParameters ? false : true,
      validation: Yup.number().when('required', {
        is: true,
        then: Yup.number().min(8, 'Min value can be 10').max(40, 'Max value can be 40').required('Value cannot be empty'),
        otherwise: Yup.number(),
      }),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'algorithm',
      id: 'algorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm || 'auto_n_cluster',
      disabled: !!inputFormDataFromApi?.algorithm,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    //ridgeRegression && lassoRegression hyperparameters
    {
      name: 'tol',
      id: 'tol',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.0001, max: 1 } },
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.tol || 0.0001,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.0001, 'Min value can be 0.0001')
        .max(1, 'Max value can be 1')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },

    {
      name: 'kmeansInitListAlgorithm',
      id: 'kmeansInitListAlgorithm',
      size: 'small',
      row: true,
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.init || 'k-means++',

      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    //randomForestRegressor hyperparameters
    {
      name: 'agglomerativeMetricListAlgorithm',
      id: 'agglomerativeMetricListAlgorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.metric || 'euclidean',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'agglomerativeLinkageListAlgorithm',
      id: 'agglomerativeLinkageListAlgorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.linkage || 'ward',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'kmeans_algorithm',
      id: 'kmeans_algorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.algorithm || 'lloyd',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'kmodesInitList',
      id: 'kmodesInitList',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.init || 'Cao',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'idCols',
      id: 'idCols',
      size: 'large',
      required: true,
      options: columns || [],
      multiple: true,
      optionLabel: 'label',
      filterSelectedOptions: true,
      getOptionLabel: (option) => option.label,
      defaultValue: getDefaultModelMetrics() || [],
      validation: Yup.array().min(1, 'Select atleast one column value'),
      sx: { textAlign: 'left' },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchAlgorithm = watch('algorithm');
  const watchAgglomotiveNCluster = watch('agglomative_n_cluster');
  const watchKMeansNCluster = watch('kmeans_n_cluster');
  const watchKmodNCluster = watch('kmod_n_cluster');
  const processData = (dataString) => {
    const targetVariableList = utils.app.getFileColumnReader(dataString, consts.CLUSTERING);
    setColumns(targetVariableList);
    dispatch(removeLoader('readingFile...'));
  };

  const handleFileUpload = (files, field) => {
    const uploadedFile = files[0];
    const isValidFileFormat = consts.formAllowedFileFormats?.includes(uploadedFile?.name?.split('.')[1]);

    if (fileUploadFieldList[0].field === field) {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: uploadedFile }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));

          dispatch(addLoader('readingFile...'));
          utils.app.getFileReader(processData, uploadedFile);
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
      }
    } else {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: uploadedFile }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, predictionDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
      }
    }
  };

  const renderHyperParameters = (algorithm) => algorithm === watchAlgorithm;

  const onDelete = (field) => {
    switch (field) {
      case fileUploadFieldList[0].field:
        setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: null }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
        setColumns([]);
        break;
      case fileUploadFieldList[1].field:
        setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: null }));
        break;
      default:
        setFiles((prevFiles) => ({ ...prevFiles }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData }));
        setColumns([]);
    }
  };

  const onError = () => {
    if (!files.inputDataFile) {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
    } else {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
    }
  };

  const getFileFieldTitle = ({ field, file, label }) => {
    if (field === fileUploadFieldList[0].field) {
      return !isNoFilesUploaded.inputDataFile && !!file ? 'Input data' : label;
    } else if (field === fileUploadFieldList[1].field) {
      return !isNoFilesUploaded.predictionDataFile && !!file ? 'Prediction data' : label;
    }
  };

  const postFormData = (reqParams, trackerId) => {
    dispatch(postProcessingInput(reqParams)).then((res) => {
      if (res?.status === 200) {
        dispatch(snackbarNotification('Data submitted successully, Soon you will get prediction graphs', 'success'));
        navigate(`${config.routes.accelerators.type}/${consts.CLUSTERING}/${trackerId}`);
      }
    });
  };

  const onSubmit = (formData) => {
    const idCols = formData?.idCols?.map((item) => item.label.split('_')[1]) || [];
    const formattedFormData = {
      ...formData,
      idCols,
    };
    const payload = getClusteringPayload(formattedFormData);
    if (inputDataFileNameFromApi && trackerIdFromApi) {
      const modifiedpayload={
        ...payload,
        idCols:selectedIdCols
      }
      const editedReqParams = {
        ...modifiedpayload,
        trackerId: trackerIdFromApi,
        type: consts.CLUSTERING,
      };
      dispatch(editAccSummary(editedReqParams)).then((res) => {
        if (res?.status === 200) {
          dispatch(snackbarNotification('Clustering  Data updated successully, Soon you will get prediction graphs', 'success'));
          submitHandler();
          dispatch(hideModal('ADD_EDIT_CLUSTERING'));
        }
      });
    } else {
      if (!isRedirectFromEdaCharts && !files.inputDataFile) {
        setIsNoFilesUploaded({ inputDataFile: true });
      } else {
        dispatch(
          getPresignedUrl({
            type: consts.CLUSTERING,
            fileName: isRedirectFromEdaCharts ? summaryDeteails?.input_data?.fileName : files?.inputDataFile?.name,
            ...(files?.predictionDataFile?.name && { predictionFileName: files?.predictionDataFile?.name }),
            ...(isRedirectFromEdaCharts && summaryDeteails?.trackerId && { trackerId: summaryDeteails?.trackerId }),
          })
        ).then((res) => {
          if (res?.status === 200) {
            const inputPreSignedUrl = res?.data?.data?.inputPreSignedUrl;
            const predictionPreSignedUrl = res?.data?.data?.predictionPreSignedUrl;
            const trackerId = res?.data?.trackerId;
            const reqParams = {
              ...payload,
              trackerId: trackerId,
              type: consts.CLUSTERING,
            };

            if (!isRedirectFromEdaCharts) {
              if (inputPreSignedUrl && trackerId) {
                dispatch(uploadFileToS3({ preSignedUrl: inputPreSignedUrl, file: files?.inputDataFile,isThisForCsvOrXl: true  })).then((fS3res) => {
                  if (fS3res?.status === 200) {
                    if (predictionPreSignedUrl) {
                      dispatch(uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile,isThisForCsvOrXl: true  })).then((sS3res) => {
                        if (sS3res?.status === 200) {
                          postFormData(reqParams, trackerId);
                        }
                      });
                    } else {
                      postFormData(reqParams, trackerId);
                    }
                  }
                });
              }
            } else {
              if (predictionPreSignedUrl) {
                dispatch(uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile,isThisForCsvOrXl: true  })).then((ppres) => {
                  if (ppres?.status === 200) {
                    postFormData(reqParams, trackerId);
                  }
                });
              } else {
                postFormData(reqParams, trackerId);
              }
            }
          }
        });
      }
    }
  };
  const viewLastProcessData = (lastProcessData) => {
    if (!lastProcessData?.trackerId) return;
    navigate(`${config.routes.accelerators.type}/${consts.CLUSTERING}/${lastProcessData.trackerId}`);
  };

  return (
    <AddEditClusteringFormView
      inputDataFileNameFromApi={inputDataFileNameFromApi}
      predictionFileNameFromApi={predictionFileNameFromApi}
      fields={fields}
      columns={columns}
      algorithms={algorithms}
      KmeansInitList={KmeansInitList}
      watchAlgorithm={watchAlgorithm}
      summaryDeteails={summaryDeteails}
      agglomative_n_cluster={watchAgglomotiveNCluster}
      kmeans_n_cluster={watchKMeansNCluster}
      kmod_n_cluster={watchKmodNCluster}
      AgglomotiveMetricClusteringList={AgglomotiveMetricClusteringList}
      handleFileUpload={handleFileUpload}
      getFileFieldTitle={getFileFieldTitle}
      isNoFilesUploaded={isNoFilesUploaded}
      isValidFileFormats={isValidFileFormats}
      isValidFileSizes={isValidFileSizes}
      fileUploadFieldList={fileUploadFieldList}
      renderHyperParameters={renderHyperParameters}
      isRedirectFromEdaCharts={isRedirectFromEdaCharts}
      isLastProcessDataInprogress={isLastProcessDataInprogress}
      formProps={{ errors, register, handleSubmit, control }}
      AgglomotiveLinkageClusteringList={AgglomotiveLinkageClusteringList}
      KmeansAlgorithmList={KmeansAlgorithmList}
      KmodesInitList={KmodesInitList}
      inputFormDataFromApi={inputFormDataFromApi}
      handlers={{
        onSubmit,
        onError,
        onDelete,
        viewLastProcessData,
      }}
    />
  );
}
