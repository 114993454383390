import React from 'react';
import PropTypes from 'prop-types';

//app
import { Breadcrumbs, Layout } from 'components';
import { consts, useMedia } from 'globalsData';

//mui
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

AcceleratorDetailsView.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  acceleratorType: PropTypes.string.isRequired,
  handleACClick: PropTypes.func.isRequired,
  accelerators: PropTypes.array.isRequired,
  machineLearning: PropTypes.array.isRequired,
  artificialIntelligence: PropTypes.array.isRequired,
  getAcceleratorIcon: PropTypes.func.isRequired,
  getAcceleratorForm: PropTypes.func.isRequired,
  selecedAccelerator: PropTypes.string.isRequired,
};

export function AcceleratorDetailsView({
  breadcrumbs,
  acceleratorType,
  handleACClick,
  accelerators,
  machineLearning,
  artificialIntelligence,
  getAcceleratorIcon,
  getAcceleratorForm,
  selecedAccelerator,
}) {
  const { desktopUp } = useMedia();
  const getColor = (accelerator) => {
    if (Boolean(accelerator.enabled)) {
      if (accelerator.name === acceleratorType) {
        return (theme) => theme.palette.otherColor.solutionTextColor;
      }
      return (theme) => theme.palette.primary.textGrey2;
    }
    return (theme) => theme.palette.otherColor.silver90;
  };
  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  const [expanded, setExpanded] = React.useState(
    selecedAccelerator === consts.ACCELERATORS.ANOMALY_DETECTION || selecedAccelerator === consts.ACCELERATORS.ARTIFICIAL_INTELLIGENCE
      ? 'panel2'
      : 'panel1'
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Layout>
      {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
      <Box pt={2} mt={1}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontSize="1.5rem"
              fontWeight="600"
              color={(theme) => theme.palette.otherColor.solutionTextColor}
              pb={2}
            >
              {selecedAccelerator}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} p={1}>
            {/* <Box pb={4}>
              <Typography variant="h6" fontSize="0.875rem" fontWeight="700" color={(theme) => theme.palette.primary.titleColor} pb={2}>
                {consts.FEATURE_LIST_OBJ.accelerators}
              </Typography>
              <Typography variant="body1" color="#66696b">
                Tools for performing baseline analysis and modelling to offer users with business intelligence and visual representation of
                data to facilitate data-driven decision making
              </Typography>
            </Box> */}
            <div>
              <Accordion
                sx={{ mb: 2, backgroundColor: '#ffffff', borderRadius: '18px', border: 'none' }}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{ backgroundColor: '#ffffff', borderRadius: '18px' }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Analytics</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {machineLearning?.map((acceleratorML) => (
                    <Box
                      onClick={() => handleACClick(acceleratorML)}
                      display="flex"
                      alignContent="center"
                      key={acceleratorML.name}
                      sx={
                        acceleratorML?.enabled
                          ? {
                              cursor: 'pointer',
                            }
                          : { cursor: 'not-allowed' }
                      }
                      mb={1}
                      p={0}
                      alignItems="center"
                    >
                      {/* <Typography variant="body2" pl={2} color={getColor(acceleratorML)}> */}
                      <Typography
                        variant="body2"
                        pl={2}
                        fontSize={13}
                        sx={acceleratorML?.name === acceleratorType ? { fontWeight: 'bold' } : { fontWeight: '400' }}
                      >
                        {acceleratorML.title}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ mb: 2, backgroundColor: '#ffffff', borderRadius: '18px', border: 'none' }}
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  sx={{ backgroundColor: '#ffffff', borderRadius: '18px' }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Artificial Intelligence</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {artificialIntelligence?.map((acceleratorAI) => (
                    <Box
                      onClick={() => handleACClick(acceleratorAI)}
                      display="flex"
                      alignContent="center"
                      key={acceleratorAI.name}
                      sx={
                        acceleratorAI.enabled
                          ? {
                              cursor: 'pointer',
                              ...(acceleratorAI.name === acceleratorType && {
                                borderRadius: '4px',
                                // color: (theme) => theme.palette.primary.white,
                                // background: (theme) => theme.palette.primary.sidebarBackground,
                                fontWeight: 'bold',
                              }),
                            }
                          : { cursor: 'not-allowed' }
                      }
                      mb={1}
                      p={0}
                      alignItems="center"
                    >
                      {/* <img
                  src={getAcceleratorIcon(accelerator.title)}
                  alt={accelerator.title}
                  width={30}
                  height={30}
                  style={accelerator.name === acceleratorType ? { filter: 'invert(1) sepia(1) saturate(0)' } : {}}
                /> */}
                      <Typography
                        variant="body2"
                        pl={2}
                        color={getColor(acceleratorAI)}
                        sx={
                          (selecedAccelerator === consts.ACCELERATORS.ANOMALY_DETECTION &&
                            acceleratorAI.title.toString().split(' ').join('').toLowerCase() === consts.TEXT_TO_SPEECH) ||
                          (selecedAccelerator === consts.ACCELERATORS.ARTIFICIAL_INTELLIGENCE &&
                            acceleratorAI.title.toString().split(' ').join('').toLowerCase() === consts.ACCELERATORS.SPEECH_TO_TEXT)
                            ? { fontWeight: 'bold' }
                            : { fontWeight: '400' }
                        }
                      >
                        {acceleratorAI.title}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
            {/* {accelerators?.map((acceleratorAI) => (
              <Box
                onClick={() => handleACClick(accelerator)}
                display="flex"
                alignContent="center"
                key={accelerator.name}
                sx={
                  accelerator.enabled
                    ? {
                        cursor: 'pointer',
                        ...(accelerator.name === acceleratorType && {
                          borderRadius: '4px',
                          color: (theme) => theme.palette.primary.white,
                          background: (theme) => theme.palette.primary.sidebarBackground,
                          fontWeight: 'bold',
                        }),
                      }
                    : { cursor: 'not-allowed' }
                }
                mb={1}
                p={0}
                alignItems="center"
              >
                <img
                  src={getAcceleratorIcon(accelerator.title)}
                  alt={accelerator.title}
                  width={30}
                  height={30}
                  style={accelerator.name === acceleratorType ? { filter: 'invert(1) sepia(1) saturate(0)' } : {}}
                />
                <Typography variant="body2" pl={2} color={getColor(accelerator)}>
                  {accelerator.title}
                </Typography>
              </Box>
            ))} */}
          </Grid>
          <Grid item xs={12} md={9} p={desktopUp ? 1 : 0} textAlign="center" sx={{ ...(desktopUp && { borderLeft: '0px dashed #ccc' }) }}>
            {getAcceleratorForm(acceleratorType)}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
