import React, { useRef, useState } from 'react';
import ShareOfBusinessView from './ShareOfBusiness.view';
import { useNavigate } from 'react-router-dom';
import {
  getShareOfBusinessTable,
  getTransportersList,
  getZonesList,
  uploadSOBFileToS3,
  getSOBPresignedUrl,
  snackbarNotification,
  shareOfBusinessFileDownload,
} from 'stores';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import * as utils from 'utils';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { consts } from 'globalsData';
import * as XLSX from 'xlsx';

export default function ShareOfBusiness() {
  const [modeOptions, setModeOptions] = useState([]);
  const dispatch = useDispatch();
  const sobData = useSelector((state) => get(state, 'sob.sobTableData', []));
  const transporters = useSelector((state) => get(state, 'sob.transporters', []));
  const allZones = useSelector((state) => get(state, 'sob.zones', []));
  const downloadedFile = useSelector((state) => get(state, 'sob.downloadFileData', {}));
  const hiddenFileInput = useRef(null);
  const fields = [
    {
      name: 'mode',
      id: 'mode',
      size: 'small',
      required: true,
      validation: Yup.string().required(consts.sobValidationMessages.SELECT_ONE_MODE),
      sx: { textAlign: 'left' },
    },
    {
      name: 'transporter',
      label: 'Transporter',
      id: 'transporter',
      options: transporters ? utils.generic.getArrayOfObjects(transporters, 'transporter') : [],
      size: 'small',
      defaultValue: [],
      multiple: true,
      optionLabel: 'transporter',
      filterSelectedOptions: true,
      getOptionLabel: (option) => option.transporter,
      validation: Yup.array().min(1, consts.sobValidationMessages.SELECT_AT_LEAST_ONE_TRANSPORTER),
    },
    {
      name: 'areaOfOperation',
      label: 'Area of Operation',
      id: 'areaOfOperation',
      size: 'small',
      defaultValue: [],
      multiple: true,
      optionLabel: 'areaOfOperation',
      filterSelectedOptions: true,
      getOptionLabel: (option) => option.areaOfOperation,
      validation: Yup.array().min(1, consts.sobValidationMessages.SELECT_AT_LEAST_ONE_ZONE),
    },
    {
      name: 'fromDate',
      id: 'fromDate',
      size: 'small',
      required: true,
      validation: Yup.string().required(consts.sobValidationMessages.SELECT_FROM_DATE),
      sx: { textAlign: 'left' },
    },
    {
      name: 'toDate',
      id: 'toDate',
      size: 'small',
      required: true,
      validation: Yup.string().required(consts.sobValidationMessages.SELECT_TO_DATE),
      sx: { textAlign: 'left' },
    },
  ];
  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });
  const modeWatch = watch('mode');
  const zoneWatch = watch('areaOfOperation');
  const transporterWatch = watch('transporter');
  const fromDateWatch = watch('fromDate');
  const toDateWatch = watch('toDate');

  useEffect(() => {
    setModeOptions(utils.generic.getArrayOfObjects(allZones[modeWatch] ?? [], 'areaOfOperation'));
  }, [modeWatch, allZones]);

  useEffect(() => {
    if (downloadedFile) {
      downloadExcel(downloadedFile?.data, downloadedFile?.fileName);
    }
  }, [downloadedFile]);
  const onSubmit = (formData) => {
    formData.areaOfOperation = utils.generic.getArrayOfStrings(formData?.areaOfOperation);
    formData.transporter = utils.generic.getArrayOfStrings(formData?.transporter);
    formData.fromDate = new Date(formData.fromDate).toLocaleDateString('en-GB');
    formData.toDate = new Date(formData.toDate).toLocaleDateString('en-GB');
    dispatch(getShareOfBusinessTable(formData));
  };

  let date = new Date();
  let defaultFromDate= date.setDate(date.getDate() - 7);
  const params = {
    mode: 'air',
    areaOfOperation: ['All'],
    transporter: ['All'],
    fromDate: new Date(defaultFromDate).toLocaleDateString('en-GB'),
    toDate: new Date().toLocaleDateString('en-GB'),
  };

  useEffect(() => {
    dispatch(getShareOfBusinessTable(params));
    dispatch(getTransportersList());
    dispatch(getZonesList());
  }, []);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState('1');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const downloadExcel = (data, fileName) => {
    if (data && fileName) {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, fileName);
    }
  };
  const fileDownload = () => {
    let downloadParams = {};
    downloadParams.mode = modeWatch;
    downloadParams.areaOfOperation = utils.generic.getArrayOfStrings(zoneWatch);
    downloadParams.transporter = utils.generic.getArrayOfStrings(transporterWatch);
    downloadParams.fromDate = new Date(fromDateWatch).toLocaleDateString('en-GB');
    downloadParams.toDate = new Date(toDateWatch).toLocaleDateString('en-GB');
    downloadParams.download = true;
    dispatch(shareOfBusinessFileDownload(downloadParams));
    
  };
  const handleFileUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChangeFileUpload = (event) => {
   
    let fileUploaded = event.target.files[0];
    var blob = fileUploaded.slice(0, fileUploaded.size, 'text/csv'); 
    let file = new File([blob], 'approved.csv', {type: 'text/csv'});
   
    const validFileFormat = consts.sobAllowedFileFormats?.includes(file?.name?.split('.')[1]);
    if (validFileFormat) {
      dispatch(getSOBPresignedUrl({ fileName: file?.name })).then((res) => {
        if (res?.status === 200) {
          const preSignedUrl = res?.data;
          if (preSignedUrl) {
            dispatch(uploadSOBFileToS3({ preSignedUrl, file })).then((s3res) => {
              if (s3res?.status === 200) {
                dispatch(snackbarNotification('Submitted successully', 'success'));
              }
            });
          }
        }
      });
    } else {
      dispatch(snackbarNotification('This file type is not allowed, upload only csv and xlsx format', 'warning'));
    }
  };

  return (
    <>
      <ShareOfBusinessView
        handleFileUploadClick={handleFileUploadClick}
        handleChangeFileUpload={handleChangeFileUpload}
        hiddenFileInput={hiddenFileInput}
        tableData={sobData}
        zones={modeOptions}
        handleChange={handleChange}
        fileDownload={fileDownload}
        tabValue={tabValue}
        fields={fields}
        formProps={{ control, errors, register, handleSubmit, setValue }}
        handlers={{
          onSubmit,
        }}
      />
    </>
  );
}
