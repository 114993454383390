const initialState = {
  userList: [],
};

export const userManagmentReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_USER_LIST_REQUEST':
      return {
        ...state,
      };
    case 'GET_USER_LIST_SUCCESS':
      return {
        ...state,
        userList: [...action.payload],
      };
    case 'GET_USER_LIST_FAILURE':
      return {
        ...state,
        userList: [],
      };

    default:
      return state;
  }
};

export default userManagmentReducers;
