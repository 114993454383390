import React from 'react';
import PropTypes from 'prop-types';

// app
import { DataGridTable, Breadcrumbs, Layout } from 'components';

// mui
import { Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

UserManagmentView.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  handlers: PropTypes.shape({
    handleAddUser: PropTypes.func.isRequired,
  }),
  breadcrumbs: PropTypes.array.isRequired,
};

export function UserManagmentView({ rows, columns, userId, handlers, breadcrumbs }) {
  return (
    <Layout>
      {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
      <Box py={2} mt={1}>
        <Box>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 700, fontSize: '30px' }} color={(theme) => theme.palette.otherColor.blue70}>
                User Management
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                px={5}
                sx={{ textTransform: 'none', borderRadius: '20px' }}
                startIcon={<AddIcon />}
                onClick={handlers.handleAddUser}
              >
                New User
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <DataGridTable
            checkboxSelection={false}
            columns={columns}
            rows={rows}
            uniqRowId={userId}
            height={317}
            emptyRowsText="No user(s) found"
          />
        </Box>
      </Box>
    </Layout>
  );
}
