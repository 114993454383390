import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

//app
import * as utils from 'utils';
import { config, consts, TimeSeriesStaticData, getTimeseriesPayload } from 'globalsData';
import { AddEditTimeSeriesFormView } from './AddEditTimeSeriesForm.view';
import {
  addLoader,
  getLastProcessedData,
  getPresignedUrl,
  postProcessingInput,
  redirectFromEdaChart,
  removeLoader,
  snackbarNotification,
  uploadFileToS3,
  editAccSummary,
  hideModal,
} from 'stores';

AddEditTimeSeriesForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

export default function AddEditTimeSeriesForm({ data, submitHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const summaryDeteails = useSelector((state) => get(state, 'accelerators.summaryDeteails', {}));
  const isRedirectFromEdaCharts = useSelector((state) => get(state, 'accelerators.isRedirectFromEdaCharts', false));

  const inputDataRef = useRef();

  const isUpdateTimeseries = Boolean(data?.input_data?.forecastCol);
  const inputFormDataFromApi = isUpdateTimeseries ? data?.input_data : {};
  const inputDataFileNameFromApi = data?.input_data?.fileName;
  const predictionFileNameFromApi = data?.input_data?.predictionFileName;
  const trackerIdfromApi = data?.trackerId;
  const [columns, setColumns] = useState([]);
  const [timeStep,setTimeStep]=useState([]);
  const [columnnError, setColumnError] = useState(false);
  const [lastProcessedData, setLastProcessedData] = useState({});
  const [files, setFiles] = useState({ inputDataFile: null, predictionDataFile: null });
  const [isNoFilesUploaded, setIsNoFilesUploaded] = useState({ inputDataFile: false, predictionDataFile: false });
  const [isValidFileFormats, setIsValidFileFormats] = useState({ inputDataFile: true, predictionDataFile: true });
  const [isValidFileSizes, setIsValidFileSizes] = useState({ inputDataFile: true, predictionDataFile: true });
  const [specificValue, setSpecificValue] = useState(
    typeof inputFormDataFromApi?.modelHyperParameters?.seasonal_periods === 'number' ? inputFormDataFromApi?.modelHyperParameters?.seasonal_periods : ''
  );
  const fileUploadFieldList = [{ field: 'inputData', label: 'Upload Input Data', file: files.inputDataFile, ref: inputDataRef }];

  // static data options for classification
  const algorithms = TimeSeriesStaticData.algorithms;
  const arimaSeasonal = TimeSeriesStaticData.arimaSeasonal;
  const trend = TimeSeriesStaticData.trend;
  const dayFirst = TimeSeriesStaticData.dayFirst;
  const dampedTrend = TimeSeriesStaticData.dampedTrend;
  const exponentialSeasonal = TimeSeriesStaticData.exponentialSeasonal;
  const freq = TimeSeriesStaticData.freq;
  const seasonality = TimeSeriesStaticData.seasonality;
  const prophetDailySeasonality = TimeSeriesStaticData.prophetDailySeasonality;
  const seasonalityMode = TimeSeriesStaticData.seasonalityMode;
  const seasonalPeriod = TimeSeriesStaticData.seasonalPeriod;

  const handleSpecificValue = (event) => {
    setSpecificValue(event.target.value);
  };
  const differencingValueList = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const fields = [
    {
      name: 'justificationRemarks',
      id: 'justificationRemarks',
      size: 'small',
      required: true,
      validation: Yup.string().min(30, 'Min 30 characters').max(150, 'Max 150 characters').required('Justification Remarks required'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'forecastCol',
      id: 'forecastCol',
      defaultValue: inputFormDataFromApi?.forecastCol || '',
      size: 'small',
      required: true,
      disabled: isUpdateTimeseries,
      validation: Yup.string().required('Please select atleast one option and ensure '),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'timeStepCol',
      id: 'timeStepCol',
      defaultValue: inputFormDataFromApi?.timeStepCol || '',
      size: 'small',
      required: true,
      disabled: isUpdateTimeseries,
      validation: Yup.string().when('timeStep', {
        is: () => timeStep.length>=1,
        then: Yup.string().required("Please select at least one option"),
        otherwise: Yup.string().required(` The uploaded data doesn't have time step (datetime) kind of column. Please provide time step column in datetime format.`)
      }),
      
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'dayFirst',
      id: 'dayFirst',
      size: 'small',
      required: true,
      disabled: isUpdateTimeseries,
      defaultValue: inputFormDataFromApi?.dayFirst || 'false',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'differencingValue',
      id: 'differencingValue',
      size: 'small',
      type: 'number',
      required: true,
      disabled: isUpdateTimeseries,
      defaultValue: inputFormDataFromApi?.differencingVal || 0,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0, 'Min value can be 0')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'algorithm',
      id: 'algorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm || 'exponentialSmoothing',
      disabled: isUpdateTimeseries,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'forecastLength',
      id: 'forecastLength',
      size: 'small',
      type: 'number',
      required: true,
      disabled: isUpdateTimeseries,
      defaultValue: inputFormDataFromApi?.forecastLength || 6,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0, 'Min value can be 0')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'testSizeRatio',
      id: 'testSizeRatio',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.testSizeRatio || 20,
      disabled: isUpdateTimeseries,
      min: 10,
      max: 40,
      // marks: targetSizeRatioMarks,
      required: true,
      validation: Yup.number().min(10, 'Min value can be 10').max(40, 'Max value can be 40').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'trend',
      id: 'trend',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.trend || 'none',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'dampedTrend',
      id: 'dampedTrend',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.damped_trend || 'none',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'seasonal',
      id: 'seasonal',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.seasonal || 'none',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'seasonalPeriods',
      id: 'seasonalPeriods',
      size: 'small',
      required: true,
      defaultValue: typeof inputFormDataFromApi?.modelHyperParameters?.seasonal_periods === 'number' ? 'specificValue' : 'none',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'freq',
      id: 'freq',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.freq || 'none',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'arimaSeasonal',
      id: 'arimaSeasonal',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.seasonal || 'true',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'seasonalDifferencing',
      id: 'seasonalDifferencing',
      size: 'small',
      type: 'number',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.seasonal_differencing || 1,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(1, 'Min value can be 1')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'prophetSeasonalityMode',
      id: 'prophetSeasonalityMode',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.seasonality_mode || 'additive',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'prophetYearlySeasonality',
      id: 'prophetYearlySeasonality',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.yearly_seasonality || 'auto',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'prophetWeeklySeasonality',
      id: 'prophetWeeklySeasonality',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.weekly_seasonality || 'auto',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'prophetDailySeasonality',
      id: 'prophetDailySeasonality',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.daily_seasonality || 'auto',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'npSeasonalityMode',
      id: 'npSeasonalityMode',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.seasonality_mode || 'additive',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'npYearlySeasonality',
      id: 'npYearlySeasonality',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.yearly_seasonality || 'auto',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'npWeeklySeasonality',
      id: 'npWeeklySeasonality',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.weekly_seasonality || 'auto',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'npDailySeasonality',
      id: 'npDailySeasonality',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.modelHyperParameters?.daily_seasonality || 'auto',
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchAlgorithm = watch('algorithm');
  const watchSeasonalPeriods = watch('seasonalPeriods');
  const watchTargetSizeRatio = watch('testSizeRatio');
  const watchTimesetpCol = watch('timeStepCol');
  const watchForecastCol = watch('forecastCol');

  useEffect(() => {
    if (!!watchForecastCol && !!watchTimesetpCol && watchForecastCol === watchTimesetpCol) {
      setColumnError(true);
    } else {
      setColumnError(false);
    }
  }, [watchForecastCol, watchTimesetpCol]);

  useEffect(() => {
    if (!isUpdateTimeseries) {
      dispatch(getLastProcessedData({ type: consts.TIMESERIES })).then((res) => {
        if (res?.status === 200) {
          setLastProcessedData(res?.data);
        }
      });
    }

    // clean up
    return () => {
      dispatch(redirectFromEdaChart(false));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processData = (dataString) => {
    const targetVariableList = utils.app.getFileColumnReader(dataString, consts.TIMESERIES);
    const timeStep = utils.app.getFileDateOrTimeColumnReader(dataString, consts.TIMESERIES);
    setTimeStep(timeStep)
    setColumns(targetVariableList);
    dispatch(removeLoader('readingFile...'));
  };

  const handleFileUpload = (files, field) => {
    const file = files[0];
    const isValidFileFormat = consts.formAllowedFileFormats?.includes(file?.name?.split('.')[1]);

    if (fileUploadFieldList[0].field === field) {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: true }));

        if (file && file?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: file }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));

          dispatch(addLoader('readingFile...'));
          utils.app.getFileReader(processData, file);
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
      }
    } else {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        if (file && file?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: file }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, predictionDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
      }
    }
  };

  const onDelete = (field) => {
    switch (field) {
      case fileUploadFieldList[0].field:
        setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: null }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
        setColumns([]);
        break;
      case fileUploadFieldList[1].field:
        setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: null }));
        break;
      default:
        setFiles((prevFiles) => ({ ...prevFiles }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData }));
    }
  };

  const postFormData = (reqParams, trackerId) => {
    dispatch(postProcessingInput(reqParams)).then((res) => {
      if (res?.status === 200) {
        dispatch(snackbarNotification('Data submitted successully, Soon you will get prediction graphs', 'success'));
        navigate(`${config.routes.accelerators.type}/${consts.TIMESERIES}/${trackerId}`);
      }
    });
  };

  const onSubmit = (formData) => {
    const formattedFormData = {
      ...formData,
      ...(watchSeasonalPeriods === 'specificValue' && { seasonalPeriods: Number(specificValue) }),
    };
    const payload = getTimeseriesPayload(formattedFormData);

    if (inputDataFileNameFromApi && trackerIdfromApi) {
      const editedReqParams = {
        ...payload,
        trackerId: trackerIdfromApi,
        type: consts.TIMESERIES,
      };

      dispatch(editAccSummary(editedReqParams)).then((res) => {
        if (res?.status === 200) {
          dispatch(snackbarNotification('Timeseries Data updated successully, Soon you will get prediction graphs', 'success'));
          submitHandler();
          dispatch(hideModal('ADD_EDIT_TIMESERIES'));
        }
      });
    } else {
      if (!isRedirectFromEdaCharts && !files.inputDataFile) {
        setIsNoFilesUploaded({ inputDataFile: true });
      } else {
        dispatch(
          getPresignedUrl({
            type: consts.TIMESERIES,
            fileName: isRedirectFromEdaCharts ? summaryDeteails?.input_data?.fileName : files?.inputDataFile?.name,
            ...(files?.predictionDataFile?.name && { predictionFileName: files?.predictionDataFile?.name }),
            ...(isRedirectFromEdaCharts && summaryDeteails?.trackerId && { trackerId: summaryDeteails?.trackerId }),
          })
        ).then((res) => {
          if (res?.status === 200) {
            const inputPreSignedUrl = res?.data?.data?.inputPreSignedUrl;
            const predictionPreSignedUrl = res?.data?.data?.predictionPreSignedUrl;
            const trackerId = res?.data?.trackerId;
            const reqParams = {
              ...payload,
              trackerId: trackerId,
              type: consts.TIMESERIES,
            };

            if (!isRedirectFromEdaCharts) {
              if (inputPreSignedUrl && trackerId) {
                dispatch(uploadFileToS3({ preSignedUrl: inputPreSignedUrl, file: files?.inputDataFile, isThisForCsvOrXl: true })).then(
                  (fS3res) => {
                    if (fS3res?.status === 200) {
                      if (predictionPreSignedUrl) {
                        dispatch(
                          uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile, isThisForCsvOrXl: true })
                        ).then((sS3res) => {
                          if (sS3res?.status === 200) {
                            postFormData(reqParams, trackerId);
                          }
                        });
                      } else {
                        postFormData(reqParams, trackerId);
                      }
                    }
                  }
                );
              }
            } else {
              if (predictionPreSignedUrl) {
                dispatch(
                  uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile, isThisForCsvOrXl: true })
                ).then((pS3res) => {
                  if (pS3res?.status === 200) {
                    postFormData(reqParams, trackerId);
                  }
                });
              } else {
                postFormData(reqParams, trackerId);
              }
            }
          }
        });
      }
    }
  };

  const onError = (error) => {
    if (!files.inputDataFile) {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
    } else {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
    }
  };

  const getFileFieldTitle = ({ field, file, label }) => {
    if (field === fileUploadFieldList[0].field) {
      return !isNoFilesUploaded.inputDataFile && !!file ? 'Input data' : label;
    } else if (field === fileUploadFieldList[1].field) {
      return !isNoFilesUploaded.predictionDataFile && !!file ? 'Prediction data' : label;
    }
  };

  const renderHyperParameters = (algorithm) => algorithm === watchAlgorithm;

  const viewLastProcessData = (lastProcessData) => {
    if (!lastProcessData?.trackerId) return;
    navigate(`${config.routes.accelerators.type}/${consts.TIMESERIES}/${lastProcessData.trackerId}`);
  };

  return (
    <AddEditTimeSeriesFormView
      inputDataFileNameFromApi={inputDataFileNameFromApi}
      predictionFileNameFromApi={predictionFileNameFromApi}
      watchAlgorithm={watchAlgorithm}
      isRedirectFromEdaCharts={isRedirectFromEdaCharts}
      summaryDeteails={summaryDeteails}
      fields={fields}
      columns={columns}
      dayFirst={dayFirst}
      algorithms={algorithms}
      arimaSeasonal={arimaSeasonal}
      seasonalityMode={seasonalityMode}
      seasonalPeriod={seasonalPeriod}
      dampedTrend={dampedTrend}
      exponentialSeasonal={exponentialSeasonal}
      seasonalPeriodsVal={watchSeasonalPeriods}
      freq={freq}
      targetSizeRatio={watchTargetSizeRatio}
      specificValue={specificValue}
      trend={trend}
      seasonality={seasonality}
      prophetDailySeasonality={prophetDailySeasonality}
      handleFileUpload={handleFileUpload}
      getFileFieldTitle={getFileFieldTitle}
      isNoFilesUploaded={isNoFilesUploaded}
      lastProcessedData={lastProcessedData}
      isValidFileFormats={isValidFileFormats}
      isValidFileSizes={isValidFileSizes}
      fileUploadFieldList={fileUploadFieldList}
      renderHyperParameters={renderHyperParameters}
      columnnError={columnnError}
      formProps={{ errors, register, handleSubmit, control }}
      handlers={{
        onSubmit,
        onError,
        onDelete,
        viewLastProcessData,
        handleSpecificValue,
      }}
      files={files}
      differencingValueList={differencingValueList}
      timeStep={timeStep}
    />
  );
}
