import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const postProcessingInput = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(postProcessingInputRequest(reqParams));
  dispatch(addLoader({ key: 'postProcessingInput', message: 'Submitting Form data...' }));

  return api
    .post({
      endpoint: endpoint.acceleratorService,
      path: 'accelerator',
      data: reqParams,
    })
    .then((res) => {
      dispatch(postProcessingInputSuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message, 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(postProcessingInputError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('postProcessingInput'));
    });
};

export const postProcessingInputRequest = (params) => {
  return {
    type: 'POST_PROCESSING_INPUT_REQUEST',
    payload: params,
  };
};

export const postProcessingInputSuccess = (data) => {
  return {
    type: 'POST_PROCESSING_INPUT_SUCCESS',
    payload: data,
  };
};

export const postProcessingInputError = (error) => {
  return {
    type: 'POST_PROCESSING_INPUT_FAILURE',
    payload: error,
  };
};
