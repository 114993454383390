import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

//app
import * as utils from 'utils';
import { getFeatureAccess } from 'stores';
import { config, consts, otherSolutionsTiles, marketingTiles, qualityAssuranceTiles } from 'globalsData';
import { OtherSolutionsView } from './OtherSolutions.view';

//svg images
// import { ReactComponent as ArtOfAnalyticsForPitstop } from 'assets/svg/ArtOfAnalyticsForPitstop_adobe_express.svg';
// import { ReactComponent as ASBP } from 'assets/svg/AutoStockBalancingPlatform_adobe_express.svg';
// import { ReactComponent as ComponentPerformanceScreen } from 'assets/svg/ComponentPerformanceScreen.svg';
// import { ReactComponent as DLP } from 'assets/svg/DataLakePlatform.svg';
// import { ReactComponent as DMSClickLink } from 'assets/svg/DMSClickLink.svg';
// import { ReactComponent as EnginePerformanceScreen } from 'assets/svg/EnginePerformanceScreen_adobe_express.svg';
// import { ReactComponent as FailureProbabilityScreen } from 'assets/svg/FailureProbabilityScreen.svg';
// import { ReactComponent as InboundLogisticsScreen } from 'assets/svg/InboundLogisticsScreen_adobe_express.svg';
// import { ReactComponent as MMMLoginScreen } from 'assets/svg/MMMLoginScreen.svg';
// import { ReactComponent as QualityIntelligenceScreen } from 'assets/svg/QualityIntelligenceScreen.svg';
// import { ReactComponent as RuralCoveragenPotentialPlatFormScreen } from 'assets/svg/RuralCoverageAndPotentialPlatform.svg';
// import { ReactComponent as SalesClusterAnalysisForSuperCarry } from 'assets/svg/SalesClusterAnalysisForSuperCarry_adobe_express.svg';
// import { ReactComponent as SocialMediaScreen } from 'assets/svg/SocialMediaScreen.svg';
// import { ReactComponent as SparesPricingSystem } from 'assets/svg/SparesPricingSystem_adobe_express.svg';
// import { ReactComponent as VendorPerformanceScreen } from 'assets/svg/VendorPerformanceScreen.svg';

//mui
import { Link, Typography } from '@mui/material';

export default function OtherSolutions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => get(state, 'user.userInfo', {}));

  // const hasASBPAccess = utils.app.hasBusinessSolutionAccess(userInfo, 'Auto Stock Balancing Platform');
  // console.log('hasASBPAccess ', hasASBPAccess);
  //   const bannerProps = {
  //     title: 'Analytics Studio',
  //     paragraph1:
  //       'A GUI based platform for Exploratory Data Analysis and Experimentations with various Analytics and AI-ML algorithms to get business insights and derive value from data',
  //     icon: AnalyticsStudioMainLogo,
  //   };
  // const otherSolutionsTiles = [
  //   {
  //     name: 'Vendor Performance Analytics',
  //     title: 'Vendor Performance Analytics',
  //     // icon: ASIconDataPreparation,
  //     description: 'Vendor Performance Analytics',
  //     // highlights: highlights.DataPreparation,
  //     tileLink: 'https://app.powerbi.com/groups/me/reports/f3310b8d-7449-45dc-9657-a90c0facf419?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
  //     vertical: 'Quality Assurance',
  //     // btnText: 'Explore',
  //     icon: VendorPerformanceScreen,
  //   },
  //   {
  //     name: 'Failure Probability Analytics',
  //     title: 'Failure Probability Analytics',
  //     description: 'Failure Probability Analytics',
  //     tileLink: 'https://app.powerbi.com/groups/me/reports/9b0e8880-bef8-49f8-88f1-7f1aef585c57?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
  //     vertical: 'Quality Assurance',
  //     // btnText: 'Explore',
  //     icon: FailureProbabilityScreen,
  //   },
  //   {
  //     name: 'Component Performance Analytics',
  //     title: 'Component Performance Analytics',
  //     description: 'Component Performance Analytics',
  //     tileLink:
  //       'https://app.powerbi.com/groups/me/reports/2e9c4fc3-0f8f-4365-ac9a-582165df82c5/ReportSectionc655a6ff9eddedcb7181?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
  //     vertical: 'Quality Assurance',
  //     // btnText: 'Explore',
  //     icon: ComponentPerformanceScreen,
  //   },
  //   {
  //     name: 'Social Media Analytics',
  //     title: 'Social Media Analytics',
  //     description: 'Social Media Analytics',
  //     tileLink: 'https://app.powerbi.com/groups/me/reports/77da7128-43dc-4879-adf4-d008bcc2efaf?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
  //     vertical: 'Quality Assurance',
  //     // btnText: 'Explore',
  //     icon: SocialMediaScreen,
  //   },
  //   {
  //     name: 'Quality Intelligence Analytics',
  //     title: 'Quality Intelligence Analytics',
  //     description: 'Quality Intelligence Analytics',
  //     tileLink:
  //       'https://app.powerbi.com/groups/me/reports/5730f1cb-376b-4016-a707-ad16dcc40c33/ReportSectiona4edefef60de73b097bc?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5&bookmarkGuid=Bookmark8f832dcde82886cca460',
  //     vertical: 'Quality Assurance',
  //     // btnText: 'Explore',
  //     icon: QualityIntelligenceScreen,
  //   },
  //   {
  //     name: 'Rural Coverage And Potential Platform',
  //     title: 'Rural Coverage And Potential Platform',
  //     description: 'Rural Coverage And Potential Platform',
  //     tileLink: 'https://dev.msilruralmaps.com/',
  //     vertical: 'MNS',
  //     // btnText: 'Explore',
  //     icon: RuralCoveragenPotentialPlatFormScreen,
  //   },
  //   {
  //     name: 'Marketing Media Mix',
  //     title: 'Marketing Media Mix',
  //     description: 'Marketing Media Mix',
  //     tileLink: 'www.marutisuzukimmmodel.com',
  //     vertical: 'MNS',
  //     // btnText: 'Explore',
  //     icon: MMMLoginScreen,
  //   },
  //   {
  //     name: 'Engine Performance Testing Analytics',
  //     title: 'Engine Performance Testing Analytics',
  //     description: 'Engine Performance Testing Analytics',
  //     tileLink:
  //       'https://app.powerbi.com/groups/me/reports/971144ab-10d6-4463-a2c3-cd8d8694ce0b/ReportSection?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5',
  //     vertical: 'Production',
  //     // btnText: 'Explore',
  //     icon: EnginePerformanceScreen,
  //   },

  //   // {
  //   //   name: 'DMS Click Link',
  //   //   title: 'DMS Click Link',
  //   //   description: 'DMS Click Link',
  //   //   tileLink: 'http://msilbi.maruti.com/',
  //   //   vertical: 'DMS',
  //   //   btnText: 'Explore',
  //   //   icon: DMSClickLink,
  //   // },

  //   {
  //     name: 'Spares Pricing System',
  //     title: 'Spares Pricing System',
  //     description: 'Spares Pricing System',
  //     tileLink: 'https://marutisuzukipaccpricing.com/',
  //     vertical: 'PACCL',
  //     // btnText: 'Explore',
  //     icon: SparesPricingSystem,
  //   },
  //   {
  //     name: 'Auto Stock Balancing Platform',
  //     title: 'Auto Stock Balancing Platform',
  //     description: 'Auto Stock Balancing Platform',
  //     tileLink: 'https://msgp-asbportal.com',
  //     vertical: 'PACCL',
  //     // btnText: hasASBPAccess ? 'Explore' : 'Request Access',
  //     // hasAccess: hasASBPAccess,
  //     icon: ASBP,
  //   },
  //   {
  //     name: '	Inbound Logistics Platform',
  //     title: 'Inbound Logistics Platform',
  //     description: 'Inbound Logistics Platform',
  //     tileLink: 'https://msilvendortrucknav.com/',
  //     vertical: 'SC',
  //     // btnText: 'Explore',
  //     icon: InboundLogisticsScreen,
  //   },
  //   // {
  //   //   name: 'Sales Cluster Analysis For Super Carry',
  //   //   title: 'Sales Cluster Analysis For Super Carry',
  //   //   description: 'Sales Cluster Analysis For Super Carry',
  //   //   tileLink: '',
  //   //   vertical: 'COMS',
  //   //   btnText: 'Explore',
  //   //   icon: SalesClusterAnalysisForSuperCarry,
  //   // },
  //   {
  //     name: 'Data Lake Platform',
  //     title: 'Data Lake Platform',
  //     description: 'Data Lake Platform',
  //     tileLink: 'https://qa.marutisuzukidatafabric.com/',
  //     vertical: 'Digital Enterprise',
  //     // btnText: 'Explore',
  //     icon: DLP,
  //   },
  //   // {
  //   //   name: 'Art of Analytics For Pitstop',
  //   //   title: 'Art of Analytics For Pitstop',
  //   //   description: 'Art of Analytics For Pitstop',
  //   //   tileLink: 'http://newpitstop/news/-/asset_publisher/cj8j2Y2I9s4H/content/learn-with-the-art-of-analytics-comic-series',
  //   //   vertical: 'Digital Enterprise',
  //   //   btnText: 'Explore',
  //   //   icon: ArtOfAnalyticsForPitstop,
  //   // },
  // ];

  const newSolutionList = utils.app.getBusinessSolutionAccessList(userInfo, otherSolutionsTiles);
  const newMarketingList = utils.app.getBusinessSolutionAccessList(userInfo, marketingTiles)
  const newQualityAssuranceList = utils.app.getBusinessSolutionAccessList(userInfo, qualityAssuranceTiles)
  // console.log('newSolutionList ', newSolutionList);
  const getBtnLink = (tile) => {
    // if (tile.title === consts.FEATURE_LIST_OBJ.os) {
    //   return window.open(tile?.link);
    // }
    if (tile?.name === 'Logistics Service Provider') {
      navigate('/logisticsServiceProvider');
    } else if (tile?.name === 'Model Carrier') {
      navigate('/modelCarrier');
    } else if (tile?.name === 'BVIT') {
      navigate('/bvit');
    } else if (tile?.name === 'EMU LPP') {
      navigate('/emuLpp');
    } else {
      window.open(tile?.tileLink);
    }
  };

  const handleClick = (tile) => {
    if (tile?.hasAccess) {
      getBtnLink(tile);
    } else {
      dispatch(getFeatureAccess());
    }
  };
  const handleBreadcrumbClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  //   const handleTileClick = (cardInfo) => {
  //     if (cardInfo?.tileLink) window.open(cardInfo.tileLink, '_blank');
  //   };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      sx={{ cursor: 'pointer' }}
      onClick={(e) => handleBreadcrumbClick(e, config.routes.home.root)}
    >
      {consts.APP_TITLE}
    </Link>,
    <Typography key="3" color="text.primary" sx={{fontSize:'14px'}}>
      {consts.FEATURE_LIST_OBJ.os}
    </Typography>,
  ];

  return <OtherSolutionsView breadcrumbs={breadcrumbs} otherSolutionsTiles={newSolutionList} marketingTiles={newMarketingList} qualityAssuranceTiles={newQualityAssuranceList} handleClick={handleClick} />;
}
