import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';

// mui
import { Autocomplete, Box, Button, Chip, Divider, Grid, TextField, Typography } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

AddEditWebsiteView.propTypes = {
  isPageEdited: PropTypes.bool.isRequired,
  isNoFileUploaded: PropTypes.bool.isRequired,
  allowedFileFormats: PropTypes.array.isRequired,
  fileNameWhileEdit: PropTypes.string,
  fields: PropTypes.array.isRequired,
  errors: PropTypes.object,
  isUpdateWeb: PropTypes.bool.isRequired,
  formProps: PropTypes.shape({
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    getUploadedFile: PropTypes.func.isRequired,
  }).isRequired,
};

export function AddEditWebsiteView({
  isPageEdited,
  isNoFileUploaded,
  allowedFileFormats,
  fileNameWhileEdit,
  fields,
  errors,
  isUpdateWeb,
  formProps,
  handlers,
}) {
  const { control, register, handleSubmit, setValue } = formProps;
  const fileInput = React.useRef();
  const selectedFile = fileInput?.current?.files[0];
  const manageCategoriesField = utils.form.getFieldProp(fields, 'manageCategories');
  const isAllowedFileFormats = allowedFileFormats?.includes(selectedFile?.name?.split('.')[selectedFile?.name?.split('.')?.length - 1])
    ? selectedFile?.name
    : '';

  return (
    <Box>
      <Box py={2} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              sx={{ width: '100%' }}
              {...register('websiteName')}
              {...utils.form.getFieldProp(fields, 'websiteName')}
              error={!!errors?.websiteName?.message}
              helperText={!!errors?.websiteName?.message ? errors?.websiteName?.message : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              sx={{ width: '100%' }}
              {...register('websiteDescription')}
              {...utils.form.getFieldProp(fields, 'websiteDescription')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              sx={{ width: '100%' }}
              {...register('websiteUrl')}
              {...utils.form.getFieldProp(fields, 'websiteUrl')}
              error={!!errors?.websiteUrl?.message}
              helperText={!!errors?.websiteUrl?.message ? errors?.websiteUrl?.message : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={manageCategoriesField?.name}
              control={control}
              defaultValue={[]}
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    {...field}
                    value={field.value}
                    disablePortal={false}
                    filterSelectedOptions
                    multiple
                    size="medium"
                    getOptionDisabled={(option) => option.disabled}
                    getOptionLabel={manageCategoriesField?.getOptionLabel}
                    isOptionEqualToValue={(option, value) => option.categoryId === value.categoryId}
                    onChange={(event, values) => {
                      field.onChange(values);
                      setValue('manageCategories', values);
                    }}
                    options={manageCategoriesField?.options}
                    renderTags={(value, getTagProps) => {
                      return value?.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          variant="outlined"
                          size="small"
                          label={option[manageCategoriesField?.optionLabel]}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={!!error}
                        helperText={error?.message}
                        id={manageCategoriesField?.name}
                        label={manageCategoriesField?.label}
                        name={manageCategoriesField?.name}
                        type="search"
                        inputRef={ref}
                        {...params}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Box py={3}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '14px' }}>
            Attach Crawler Script {!isUpdateWeb && '*'}
          </Typography>
          <Button
            variant="outlined"
            sx={{ textTransform: 'none', width: '32%' }}
            size="small"
            endIcon={<FileUploadOutlinedIcon />}
            color={!!errors?.uploadButton?.message ? 'error' : 'primary'}
            onClick={() => fileInput.current.click()}
          >
            Choose File
          </Button>
          <input ref={fileInput} type="file" style={{ display: 'none' }} accept=".py, .ipynb" onChange={handlers.getUploadedFile} />
          <Box component="div" my={0.5}>
            <Typography fontSize={14}>{selectedFile?.name ? isAllowedFileFormats : fileNameWhileEdit || ''}</Typography>
          </Box>
          {isNoFileUploaded && (
            <Box component="div" py={1}>
              <Typography style={{ color: '#d32f2f', fontSize: '0.75rem' }}>Please Attach (.py or .ipynb) Script File</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Divider />
      <Box px={3} pb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
        <Button
          type="submit"
          sx={{ textTransform: 'none' }}
          variant="contained"
          size="small"
          autoFocus
          disabled={Boolean(isUpdateWeb && !isPageEdited)}
          onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
        >
          {!isUpdateWeb ? 'Create' : 'Update'}
        </Button>
      </Box>
    </Box>
  );
}
