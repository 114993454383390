import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';
import { AddEditWebsiteView } from './AddEditWebsite.view';
import { addWebsite, hideModal, updateWebsite } from 'stores';

AddEditWebsite.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

export default function AddEditWebsite({ data = {}, catId, submitHandler }) {
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => get(state, 'categories.categoryList', []));
  const isUpdateWeb = Boolean(data?.websiteName);
  const fileNameWhileEdit = data?.scrapingScriptFilename || '';

  const [file, setFile] = useState(null);
  const [isNoFileUploaded, setIsNoFileUploaded] = useState(false);

  const allowedFileFormats = ['py', 'ipynb'];

  const fields = [
    {
      name: 'websiteName',
      label: 'Website Name',
      defaultValue: data?.websiteName || '',
      size: 'small',
      required: true,
      type: 'textfield',
      validation: Yup.string().trim().max(20, 'Website Name must be at most 20 characters').required('Website Name is required'),
    },
    {
      name: 'websiteDescription',
      label: 'Website Description',
      defaultValue: data?.description || '',
      size: 'small',
      required: false,
      validation: Yup.string().trim().max(2000),
    },
    {
      name: 'websiteUrl',
      label: 'Website URL',
      defaultValue: data?.websiteUrl || '',
      size: 'small',
      required: true,
      type: 'textfield',
      validation: Yup.string()
        .trim()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .matches(utils.app.regExps.url, 'Enter valid URL!')
        .required('Website URL is required'),
    },
    {
      name: 'manageCategories',
      label: 'Map Categories',
      id: 'manage-categories',
      options: categoryList || [],
      size: 'medium',
      defaultValue: categoryList?.filter((cat) => data?.categoryList?.includes(cat.categoryId)) || [],
      multiple: true,
      optionLabel: 'categoryName',
      filterSelectedOptions: true,
      getOptionLabel: (option) => option.categoryName,
      validation: Yup.array().min(1, 'Select atleast one category'),
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const selectedCatListFromApi = categoryList?.filter((cat) => data?.categoryList?.includes(cat.categoryId)) || [];

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const formValues = watch();
  const isPageEdited = Boolean(file?.name) || !isEqual(defaultValues, formValues);

  useEffect(() => {
    if (selectedCatListFromApi?.length > 0) setValue('manageCategories', selectedCatListFromApi);
  }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (formData) => {
    const selectedCatList = formData?.manageCategories?.map((seletedCat) => seletedCat.categoryId) || [];
    const { websiteName, websiteDescription, websiteUrl } = formData;

    if (file || fileNameWhileEdit) {
      if (!isUpdateWeb) {
        dispatch(
          addWebsite({
            websiteName,
            websiteDescription,
            websiteUrl,
            categoryList: selectedCatList,
            crawlerScript: file,
          })
        ).then((res) => {
          if (res?.status === 200) {
            dispatch(hideModal('ADD_EDIT_WEBSITE'));
            submitHandler();
          }
        });
      } else {
        dispatch(
          updateWebsite({
            catId,
            websiteName,
            websiteDescription,
            websiteUrl,
            categoryList: selectedCatList,
            crawlerScript: file,
            websiteId: data?.websiteId,
          })
        ).then((res) => {
          if (res?.status === 200) {
            dispatch(hideModal('ADD_EDIT_WEBSITE'));
          }
        });
      }
    } else {
      setIsNoFileUploaded(true);
    }
  };

  const onError = (erros) => {
    if (!file) {
      if (isUpdateWeb) {
        if (!fileNameWhileEdit) {
          setIsNoFileUploaded(true);
        }
      } else {
        setIsNoFileUploaded(true);
      }
    }
  };

  const getUploadedFile = (uploadedFile) => {
    const selectedFile = uploadedFile.target.files[0];
    if (allowedFileFormats?.includes(selectedFile?.name?.split('.')[selectedFile?.name?.split('.')?.length - 1])) {
      setFile(selectedFile);
      setIsNoFileUploaded(false);
    } else {
      setFile(null);
      setIsNoFileUploaded(true);
    }
  };

  return (
    <AddEditWebsiteView
      isPageEdited={isPageEdited}
      isNoFileUploaded={isNoFileUploaded}
      allowedFileFormats={allowedFileFormats}
      fileNameWhileEdit={fileNameWhileEdit}
      fields={fields}
      errors={errors}
      isUpdateWeb={isUpdateWeb}
      formProps={{ control, register, handleSubmit, setValue }}
      handlers={{ onSubmit, onError, getUploadedFile }}
    />
  );
}
