import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useNavigate, useLocation } from 'react-router';

// app
import { HeaderView } from './Header.view';
import { expandSidebar } from 'stores';
import { config, consts, useMedia } from 'globalsData';
import { loginClient } from '../../common-partner-login-sdk';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import * as utils from 'utils';
export default function Header() {
  const { mobile, tablet } = useMedia();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const selecedAccelerator = utils.generic.capitalize(acceleratorType?.split('-')?.filter(Boolean)?.join(' ') || '');
  const configVars = useSelector((state) => get(state, 'config.vars'));
  const userDetails = useSelector((state) => get(state, 'user.userDetails', {}));
  const userEmailId = userDetails?.email;
  const drawerOpen = useSelector((state) => get(state, 'ui.sidebar.expanded', false));
  const path = useSelector((state) => get(state, 'path.routePath', []));
  const [anchorEl, setAnchorEl] = useState(null);
  // const [breadcrumbs, setBreadcrumb] = useState([]);

  const titleEllipsisLength = tablet ? consts.TABLET_APP_TITLE_ELLIPSIS_LENGTH : consts.APP_TITLE.length;
  const ellipsisLength = mobile ? consts.MOBILE_APP_TITLE_ELLIPSIS_LENGTH : titleEllipsisLength;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    dispatch(expandSidebar(!drawerOpen));
  };

  const appLogoClick = () => {
    navigate(config.routes.home.root);
  };

  const logout = () => {
    localStorage.clear();
    loginClient.federatedLogout(configVars?.clientId, configVars?.loginRedirectUrl);
  };

  // const handleLinkClick = (event, link) => {
  //   event.preventDefault();
  //   navigate(link);
  // };
  
  // const getBreadcrumb = (path) => {
  //   if(path.length === 0){
  //     return <Link
  //     underline="hover"
  //     key="2"
  //     color="inherit"
  //     sx={{ cursor: 'pointer' }}
  //     onClick={(e) => handleLinkClick(e, config.routes.home.root)}
  //   >
  //     Home
  //   </Link>
  //   }
  //   let breadcrumbArr = path.split('/');
  //   let tempArr = [];
  //   tempArr = [
  //     ...tempArr,
  //     <Link
  //       underline="hover"
  //       key="2"
  //       color="inherit"
  //       sx={{ cursor: 'pointer' }}
  //       onClick={(e) => handleLinkClick(e, config.routes.home.root)}
  //     >
  //       Home
  //     </Link>,
  //   ];
  //   for (let i = 1; i < breadcrumbArr.length; i++) {
  //     if (i === breadcrumbArr.length) {
  //       tempArr = [...tempArr, <Typography>{breadcrumbArr[i]}</Typography>];
  //     } else {
  //       tempArr = [
  //         ...tempArr,
  //         <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleLinkClick(e, breadcrumbArr[i])}>
  //           {breadcrumbArr[i]}
  //         </Link>,
  //       ];
  //     }
  //     setBreadcrumb(tempArr);
  //   }
  // };

  // useEffect(() => {
  //     getBreadcrumb(path);
  // }, [path]);

  // const breadcrumbs = [
  //   <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.home.root)}>
  //     {consts.APP_TITLE}
  //   </Link>,
  //   <Typography key="3" color="text.primary">
  //     {/* {selecedAccelerator} */}
  //   </Typography>,
  // ];

  return (
    <HeaderView
      anchorEl={anchorEl}
      ellipsisLength={ellipsisLength}
      userEmail={userEmailId || 'test@gmail.com'}
      mobile={mobile}
      handlers={{ handleMenu, handleClose, handleDrawerOpen, appLogoClick, logout }}
      // breadcrumbs={breadcrumbs}
    />
  );
}
