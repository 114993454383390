import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const addAppLoadTime= (data) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(addAppLoadTimeRequest(data));
  // dispatch(addLoader('addAppLoadTime'));

  return api
    .post({
      endpoint: endpoint.acceleratorService,
      path: 'storeApplicationLoadTime',
      data,
    })
    .then((res) => {
      dispatch(addAppLoadTimeSuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message || 'App Loaded Successfully', 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(addError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('add'));
    });
};

export const addAppLoadTimeRequest = (params) => {
  return {
    type: 'ADD_APP_LOAD_TIME_REQUEST',
    payload: params,
  };
};

export const addAppLoadTimeSuccess = (data) => {
  return {
    type: 'ADD_APP_LOAD_TIME_SUCCESS',
    payload: data,
  };
};

export const addError = (error) => {
  return {
    type: 'ADD_APP_LOAD_TIME_FAILURE',
    payload: error,
  };
};
