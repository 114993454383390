import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const uploadBvitFileToS3 = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { loginRedirectUrl },
    },
  } = getState();

  const { preSignedUrl, file } = reqParams;

  if (!preSignedUrl) {
    return Promise.reject({ message: 'Pre Signed Url not found' });
  }

  dispatch(uploadBvitFileToS3Request(reqParams));
  dispatch(addLoader({ key: 'uploadBvitFileToS3', message: 'Uploading BVIT file to S3...' }));

  return api.multipartPutForCsvAndXlFiles({
    endpoint: preSignedUrl,
    data: { file },
    isTokenRequired: false,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(uploadBvitFileToS3Error(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('uploadBvitFileToS3'));
    });
};

export const uploadBvitFileToS3Request = (params) => {
  return {
    type: 'UPLOAD_BVIT_FILE_TO_S3_REQUEST',
    payload: params,
  };
};

export const uploadBvitFileToS3Success = (data) => {
  return {
    type: 'UPLOAD_BVIT_FILE_TO_S3_SUCCESS',
    payload: data,
  };
};

export const uploadBvitFileToS3Error = (error) => {
  return {
    type: 'UPLOAD_BVIT_FILE_TO_S3_FAILURE',
    payload: error,
  };
};
