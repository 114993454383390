import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';

// app
import { consts } from 'globalsData';
import { userManagementFeatureList } from 'globalsData';
import * as utils from 'utils';
import { AddEditUserView } from './AddEditUser.view';
import { addUser, updateUser, getCategoryList, hideModal, getUserRoleList, getOtherSolutionsList, getDepartmentList } from 'stores';

AddEditUser.propTypes = {
  data: PropTypes.object,
  userId: PropTypes.string,
  submitHandler: PropTypes.func,
};

export default function AddEditUser({ data = {}, userId, submitHandler }) {
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => get(state, 'categories.categoryList', []));
  const otherSolutionsList = useSelector((state) => get(state, 'categories.otherSolutionsList', []));
  // const verticalListt = useSelector((state) => get(state, 'user.verticalList', []));
  const departmentList = useSelector((state) => get(state, 'user.departmentList', []));
  const isUpdateUser = Boolean(data?.email);
  const [userRoleList, setUserRoleList] = useState([]);
  const [isAdminSelected, setIsAdminSelected] = useState(false);
  const [isBusinessUserSelected, setIsBusinessUserSelected] = useState(false);
  const [isDcmpSelected, setIsDcmpSelected] = useState(false);
  const [isOtherSolutionsSelected, setIsOtherSolutionsSelected] = useState(false);
  const [department, setDepartment] = useState('');
  const [isDepartmentError, setIsDepartmentError] = useState(false);

  useEffect(() => {
    setDepartment(data?.departmentId)
    setIsDepartmentError(false);
  },[data?.departmentId])

  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getOtherSolutionsList());
    dispatch(getUserRoleList()).then((res) => {
      setUserRoleList(res.data);
    });
    // dispatch(getVerticalList());
    dispatch(getDepartmentList());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fields = [
    {
      name: 'emailId',
      label: 'Email Id',
      defaultValue: data?.email || '',
      size: 'small',
      required: true,
      type: 'textfield',
      validation: Yup.string().trim().email('Invalid Email Id').required('Email Id is required'),
    },
    {
      name: 'userRole',
      label: 'User Role',
      defaultValue: (data?.roleName === 'BusinessUser' ? 'Business User' : data?.roleName) || '',
      size: 'small',
      required: true,
      validation: Yup.string().required('Role is required'),
    },
    // {
    //   name: 'vertical',
    //   label: 'Vertical',
    //   defaultValue: data?.verticalId || '',
    //   size: 'small',
    //   required: true,
    //   validation: Yup.string().required('Vertical is required'),
    // },
    // {
    //   name: 'department',
    //   label: 'Department',
    //   //defaultValue: data?.departmentId || '',
    //   size: 'small',
    //   required: true,
    //   validation: Yup.string().required('Department is required').nullable()
    // },
    ...(isBusinessUserSelected
      ? [
          {
            name: 'manageModules',
            label: 'Map Modules',
            id: 'manage-modules',
            options: userManagementFeatureList || [],
            size: 'medium',
            defaultValue: userManagementFeatureList?.filter((feature) => data?.featureList?.includes(feature.featureName)) || [],
            multiple: true,
            optionLabel: 'featureName',
            filterSelectedOptions: true,
            getOptionLabel: (option) => option.featureName,
            validation: Yup.array().min(1, 'Select atleast one module'),
          },
        ]
      : []),
    ...(!isAdminSelected && isBusinessUserSelected && !!isDcmpSelected
      ? [
          {
            name: 'manageCategories',
            label: 'Map Categories',
            id: 'manage-categories',
            options: categoryList || [],
            size: 'medium',
            defaultValue: categoryList?.filter((cat) => data?.categoryList?.includes(cat.categoryName)) || [],
            multiple: true,
            optionLabel: 'categoryName',
            filterSelectedOptions: true,
            getOptionLabel: (option) => option.categoryName,
            validation: Yup.array().min(1, 'Select atleast one category'),
          },
        ]
      : []),
    ...(!isAdminSelected && isBusinessUserSelected && !!isOtherSolutionsSelected
      ? [
          {
            name: 'manageOtherSolutions',
            label: 'Map Business Solutions',
            id: 'manage-otherSolutions',
            options: otherSolutionsList || [],
            size: 'medium',
            defaultValue: otherSolutionsList?.filter((solution) => data?.otherSolutionsList?.includes(solution.solutionName)) || [],
            multiple: true,
            optionLabel: 'solutionName',
            filterSelectedOptions: true,
            getOptionLabel: (option) => option.solutionName,
            validation: Yup.array().min(1, 'Select atleast one solution'),
          },
        ]
      : []),
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);
  const selectedCatListFromApi = categoryList?.filter((cat) => data?.categoryList?.includes(cat.categoryName)) || [];
  const selectedSolutionListFromApi = otherSolutionsList?.filter((sol) => data?.otherSolutionsList?.includes(sol.solutionName)) || [];
  const selectedFeaturesList = userManagementFeatureList?.filter((feature) => data?.featureList?.includes(feature.featureName)) || [];
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    setError
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const formValues = watch();
  const roleWatch = watch('userRole');
  const selectedModuleListWatch = watch('manageModules');
  // const verticalWatch = watch('vertical');

  const isDCMPSelected = selectedModuleListWatch?.some((feature) => feature.featureName === consts.FEATURE_LIST_OBJ.dcmp);
  const isOTHERSOLSelected = selectedModuleListWatch?.some((feature) => feature.featureName === consts.FEATURE_LIST_OBJ.os);

  useEffect(() => {
    setIsAdminSelected(Boolean(roleWatch === 'Admin'));
    setIsBusinessUserSelected(Boolean(roleWatch === 'Business User'));
  }, [roleWatch]);
  // useEffect(()=>{
  //   dispatch(getDepartmentList(verticalWatch))
  // },[verticalWatch])
  useEffect(() => {
    setIsDcmpSelected(Boolean(isDCMPSelected));
  }, [isDCMPSelected]);
  useEffect(() => {
    setIsOtherSolutionsSelected(Boolean(isOTHERSOLSelected));
  }, [isOTHERSOLSelected]);
  const isPageEdited = !isEqual(defaultValues, formValues);

  useEffect(() => {
    if (selectedCatListFromApi?.length > 0) setValue('manageCategories', selectedCatListFromApi);
    if (selectedSolutionListFromApi?.length > 0) setValue('manageOtherSolutions', selectedSolutionListFromApi);
    if (selectedFeaturesList?.length > 0) setValue('manageModules', selectedFeaturesList);
  }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onError = (error) => {
    if(error.department){
      setIsDepartmentError(true);
    }
  }

  const onSubmit = (formData) => {
    if(!formData?.department){
      setIsDepartmentError(true);
      return;
    }
    const selectedCatList = formData?.manageCategories?.map((seletedCat) => seletedCat.categoryId) || [];
    const selectedSolutionList = formData?.manageOtherSolutions?.map((seletedSol) => seletedSol.solutionId) || [];
    const selectedModuleList = formData?.manageModules?.map((seletedFeature) => seletedFeature.featureName) || [];
    const { emailId, userRole, department } = formData;

    const callSubmitForAddEditUser = (res) => {
      if (res?.status === 200) {
        dispatch(hideModal('ADD_EDIT_USER'));
        submitHandler();
      }
    };

    if (!isUpdateUser) {
      dispatch(
        addUser({
          email: emailId,
          roleName: userRole === 'Business User' ? 'BusinessUser' : userRole,
          departmentId:department,
          // verticalId:vertical,
          ...(!isAdminSelected && isBusinessUserSelected && { featureList: selectedModuleList }),
          ...(!isAdminSelected && isBusinessUserSelected && isDcmpSelected && { categoryList: selectedCatList }),
          ...(!isAdminSelected && isBusinessUserSelected && isOtherSolutionsSelected && { otherSolutionsList: selectedSolutionList }),
        })
      ).then((res) => {
        callSubmitForAddEditUser(res);
      });
    } else {
      dispatch(
        updateUser({
          userId,
          email: emailId,
          roleName: userRole === 'Business User' ? 'BusinessUser' : userRole,
          departmentId:department,
          // verticalId:vertical,
          ...(!isAdminSelected && isBusinessUserSelected && { featureList: selectedModuleList }),
          ...(!isAdminSelected && isBusinessUserSelected && isDcmpSelected && { categoryList: selectedCatList }),
          ...(!isAdminSelected && isBusinessUserSelected && isOtherSolutionsSelected && { otherSolutionsList: selectedSolutionList }),
        })
      ).then((res) => {
        callSubmitForAddEditUser(res);
      });
    }
  };

  return (
    <AddEditUserView
      isAdminSelected={isAdminSelected}
      isBusinessUserSelected={isBusinessUserSelected}
      isDcmpSelected={isDcmpSelected}
      isOtherSolutionSelected={isOtherSolutionsSelected}
      fields={fields}
      isPageEdited={isPageEdited}
      roleWatch={roleWatch}
      errors={errors}
      isUpdateUser={isUpdateUser}
      userRoleList={userRoleList}
      // verticalList={verticalListt}
      departmentList={departmentList}
      featureList={userManagementFeatureList}
      categoryList={categoryList}
      otherSolutionsList={otherSolutionsList}
      formProps={{ control, register, handleSubmit, setValue ,setError}}
      handlers={{ onSubmit, onError }}
      department={data?.departmentId}
      isDepartmentError={isDepartmentError}
      setIsDepartmentError={setIsDepartmentError}
    />
  );
}
