import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import { DataGridTable } from 'components';

const ModelCarrierCarModelsView = ({columns,rows,handleAddNewCarModel,carModels}) => {
    return(
        <Box>
          <Box>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" sx={{ fontWeight: 700, fontSize: '18px' }} color={(theme) => theme.palette.otherColor.blue70}>
                  Car Models
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  px={5}
                  sx={{ textTransform: 'none', borderRadius: '20px' }}
                  startIcon={<AddIcon />}
                  onClick={handleAddNewCarModel}
                >
                  New Car Model
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <DataGridTable
              checkboxSelection={false}
              columns={columns}
              rows={rows}
              uniqRowId="_id"
              height={320}
              emptyRowsText="No user(s) found"
            />
          </Box>
        </Box>
    );
}

export default ModelCarrierCarModelsView;