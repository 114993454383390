import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const addVehicleData = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!reqParams?.uploadId) {
    return Promise.reject({ message: 'Upload Id not found' });
  }

  dispatch(addVehicleDataRequest(reqParams));
  dispatch(addLoader({ key: 'addVehicleData', message: 'Adding vehicle data...' }));

  return api
    .post({
      endpoint: endpoint.bvitService,
      path: 'addVehicleData',
      data: reqParams,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(addVehicleDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('addVehicleData'));
    });
};

export const addVehicleDataRequest = (params) => {
  return {
    type: 'ADD_VEHICLE_DATA_REQUEST',
    payload: params,
  };
};

export const addVehicleDataSuccess = (data) => {
  return {
    type: 'ADD_VEHICLE_DATA_SUCCESS',
    payload: data,
  };
};

export const addVehicleDataError = (error) => {
  return {
    type: 'ADD_VEHICLE_DATA_FAILURE',
    payload: error,
  };
};
