import React from 'react';
import './BVIT.css';
import { TabComponent } from 'components';

const BVITView = ({ tabs }) => {
  return (
    <div className='bvit-container'>
      <p className='bvit-header'>Bench & Vehicle Impact Torque Analysis</p>
        <TabComponent tabs={tabs} />
    </div>
  );
};

export default BVITView;
