import React from 'react';
import PropTypes from 'prop-types';

//mui
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, styled, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';

VideoModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  videoInfo: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default function VideoModal({ modalOpen, onCloseModal, videoInfo }) {
  //abort
  if (!videoInfo?.link) return null;
  return (
    <BootstrapDialog onClose={onCloseModal} aria-labelledby="video-dialog-title" open={modalOpen}>
      <DialogContent sx={{ backgroundColor: (theme) => theme.palette.otherColor.grey90 }}>
        <BootstrapDialogTitle id="video-dialog-title" onClose={onCloseModal}>
          {videoInfo?.title}
        </BootstrapDialogTitle>
        <Box pt={1}>
          <video height="100%" width="100%" controls={true} autoPlay>
            <source src={videoInfo.link} type="video/mp4" />
          </video>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    background: 'rgba(0,0,0,.275)',
  },
  '& #video-dialog-title': {
    padding: '0',
    fontSize: '1rem',
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        <Typography variant="caption" fontSize="1rem" sx={{ color: (theme) => theme.palette.primary.white }}>
          {children}
        </Typography>
      </DialogTitle>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            paddingTop: '0',
            color: (theme) => theme.palette.background.paper,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </>
  );
}
