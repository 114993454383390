import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useLocation } from 'react-router';

//app
import { loginClient } from '../../common-partner-login-sdk';
import WelcomeScreen from 'Pages/WelcomeScreen/WelcomeScreen';
import { ReactComponent as MarutiSuzukiArena } from 'assets/svg/MarutiSuzukiArena.svg';
import { snackbarNotification } from 'stores';

// mui
import { Grid } from '@mui/material';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const configVars = useSelector((state) => get(state, 'config.vars'));

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthInProgress, setIsAuthInprogress] = useState(false);

  const codeFromUrl = new URLSearchParams(location.search).get('code');
  const token = localStorage.getItem('token'); //check token on local storage on initial load

  useEffect(() => {
    // if token available on initial load then skip calling getToken method
    if (!token) {
      if (codeFromUrl) {
        setIsAuthInprogress(true);
        loginClient.init(configVars?.clientId, true).then((res) => {
          loginClient.getToken().then((res) => {
            try {
              setIsAuthInprogress(false);
              setIsAuthenticated(true);

              const lastLoggedInUserId = localStorage.getItem(`CognitoIdentityServiceProvider.${configVars?.clientId}.LastAuthUser`);
              const tokenFromLocalStorage = localStorage.getItem(
                `CognitoIdentityServiceProvider.${configVars?.clientId}.${lastLoggedInUserId}.idToken`
              );

              localStorage.setItem('token', tokenFromLocalStorage);
              localStorage.setItem('lastAuthUserId', lastLoggedInUserId);
            } catch (error) {
              dispatch(snackbarNotification('Something Went Wrong', 'error'));
            }
          });
        });
      }
    } else {
      setIsAuthenticated(true);
    }
  }, [codeFromUrl, token]); // eslint-disable-line react-hooks/exhaustive-deps

  const loginHandler = () => {
    loginClient.init(configVars?.clientId, true).then((res) => {
      loginClient.signInWithFederation(configVars?.loginRedirectUrl);
    });
  };

  if (isAuthenticated) return children;
  else if (isAuthInProgress) {
    return (
      <Grid
        container
        justify="center" // centers horizontally
        alignItems="center" // centers vertically
        style={{ minHeight: '100vh' }} // makes sure the grid takes up the full height of the screen
      >
        <Grid item sx={{ margin: 'auto' }}>
          <MarutiSuzukiArena width={250} />
        </Grid>
      </Grid>
    );
  } 
  else {
     return <WelcomeScreen loginHandler={loginHandler} />;
  }
};

export default Auth;
