import React from 'react';
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

Charts.propTypes = {
  chartOptions: PropTypes.object.isRequired,
};

export default function Charts({ chartOptions, chartRefs, data }) {
  const colors =  [
    '#5051F9',
    '#C893FD',
    '#81f78c',
    '#fca699',
    '#fcf255',
    '#849efa',
    '#fad83e',

  ];
  const style = {
    borderRadius:'30px'
  }
  return (
    <div className="custom-chart">
     <HighchartsReact highcharts={Highcharts} options={{...chartOptions,colors,style}} ref={(chart) => {
      if(chartRefs){
        chartRefs.current[data] = chart;
      }
    }} />
    </div>
  ) 
}
