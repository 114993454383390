export const setIdToken = (token) => {
  return {
    type: 'USER_DETAILS_SET_ID_TOKEN',
    payload: token,
  };
};

export const setUserLoggedIn = (payload) => {
  return {
    type: 'USER_LOGGED_IN',
    payload: payload,
  };
};

export const clearUserData = () => {
  return {
    type: 'CLEAR_USER_DATA',
  };
};

export const getUserDetails = (userDetails) => {
  return {
    type: 'GET_USER_AD_DETAILS',
    payload: userDetails,
  };
};
