import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const deleteCarrier = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();
  dispatch(deleteCarrierRequest(params));
  dispatch(addLoader('deleteCarrier'));

  const { carrierId } = params;

  return api
    .post({
      endpoint: endpoint.modelCarrier,
      path: `deleteCarrier/${carrierId}`,
    })
    .then((data) => {
      dispatch(deleteCarrierSuccess(data?.data));
      dispatch(snackbarNotification(data?.data?.message || 'Deleted successfully','success'));
      return data;
    })
    .catch((error) => {
      dispatch(deleteCarrierError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('deleteCarrier'));
    });
};

export const deleteCarrierRequest = (params) => {
  return {
    type: 'DELETE_CARRIER_REQUEST',
    payload: params,
  };
};

export const deleteCarrierSuccess = (data) => {
  return {
    type: 'DELETE_CARRIER_SUCCESS',
    payload: data,
  };
};

export const deleteCarrierError = (error) => {
  return {
    type: 'DELETE_CARRIER_FAILURE',
    payload: error,
  };
};
