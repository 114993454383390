import React from 'react';

//app
import { consts } from 'globalsData';
import * as utils from 'utils';
import { BootstrapTooltip } from 'components';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Grid, Typography, Button } from '@mui/material';

import Waveform from 'components/Waveform/Waveform';

export default function SpeechAndTextSummary({ summaryDeteails, status, audio, textDetails, handleDownloadAudioFiles }) {
  const theme = useTheme();
  const isCompleted = status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase();
  const type = summaryDeteails?.type;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '837px',
          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            gap: '20px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 'normal',
              width: '81%',
              marginLeft: '0px',
            }}
          >
            <Box
              sx={{
                color: '#768396',
                marginTop: '19px',
                font: '500 12px Inter, sans-serif ',
              }}
            >
              Input Data File:
            </Box>
            <Box
              sx={{
                color: '#0177FB',
                marginTop: '10px',
                font: '600 14px Inter, sans-serif ',
              }}
            >
              {summaryDeteails?.input_data?.fileName}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 'normal',
              width: '81%',
              marginLeft: '0px',
            }}
          >
            <Box
              sx={{
                color: '#768396',
                marginTop: '19px',
                font: '500 12px Inter, sans-serif ',
              }}
            >
              Source Language:
            </Box>
            <Box
              sx={{
                color: '#0177FB',
                marginTop: '10px',
                font: '600 14px Inter, sans-serif ',
              }}
            >
              {summaryDeteails?.input_data?.sourceLanguage}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 'normal',
              width: '81%',
              marginLeft: '0px',
            }}
          >
            <Box
              sx={{
                color: '#768396',
                marginTop: '19px',
                font: '500 12px Inter, sans-serif ',
              }}
            >
              Target Language:
            </Box>
            <Box
              sx={{
                color: '#0177FB',
                marginTop: '10px',
                font: '600 14px Inter, sans-serif ',
              }}
            >
              {summaryDeteails?.input_data?.targetLanguage}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" py={0.5}>
          <Typography
            sx={{
              color: '#768396',
              marginTop: '19px',
              font: '500 12px Inter, sans-serif ',
            }}
          >
            Status:
          </Typography>
          {status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus) && (
            <Typography fontSize={16} fontWeight={400} color={theme.palette.otherColor.textGrey2}>
              Error
            </Typography>
          )}
          {!status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus) && (
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '1rem',
              }}
            >
              <Grid item>
                <BootstrapTooltip title={status.length > 40 ? status : ''}>
                  <Typography
                    sx={{
                      color: '#768396',
                      marginTop: '14px',
                      font: '500 12px Inter, sans-serif ',
                    }}
                  >
                    {Boolean(status) ? utils.app.getEllipsisString(status, 40) : utils.app.getEllipsisString(summaryDeteails?.status, 40)}
                  </Typography>
                </BootstrapTooltip>
              </Grid>
              {!(isCompleted || status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus)) && (
                <Grid item>
                  <CircularProgress size={16} />
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Box>
      {isCompleted && audio && textDetails && (
        <Box
          sx={{
            marginLeft: '18px',
            marginRight: '18px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '30px',
            }}
          >
            <Box
              sx={{
                color: '#000',
                alignSelf: 'end',
                width: '100%',
                font: '500 14px Inter, sans-serif ',
              }}
            >
              Speech
            </Box>
            {type === consts.TEXT_TO_SPEECH?.toLowerCase() && (
              <Button
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
                  borderRadius: '5px',
                  backgroundColor: '#0177FB',
                  justifyContent: 'center',
                  padding: '10px 20px',
                  font: '400 13px/154% Inter, sans-serif ',
                  height: '35px',
                }}
                onClick={handleDownloadAudioFiles}
              >
                Download
              </Button>
            )}
          </Box>
          <Box
            sx={{
              borderRadius: '9px',
              border: '1px solid #E5E5ED',
              backgroundColor: '#FFF',
              alignSelf: 'stretch',
              display: 'flex',
              marginTop: '12px',
              justifyContent: 'space-between',
              gap: '20px',
              padding: '14px 16px',
              position: 'relative',
            }}
          >
            <Waveform audiourl={audio} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '49px',
            }}
          >
            <Box
              sx={{
                color: '#000',
                alignSelf: 'end',
                width: '100%',
                font: '500 14px Inter, sans-serif ',
              }}
            >
              Text
            </Box>
            {type === consts.ACCELERATORS.SPEECH_TO_TEXT && (
              <Button
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
                  borderRadius: '5px',
                  backgroundColor: '#0177FB',
                  justifyContent: 'center',
                  padding: '10px 20px',
                  font: '400 13px/154% Inter, sans-serif ',
                  height: '35px',
                }}
                onClick={handleDownloadAudioFiles}
              >
                Download
              </Button>
            )}
          </Box>
          <Box
            sx={{
              borderRadius: '9px',
              border: '1px solid #E5E5ED',
              backgroundColor: '#FFF',
              alignSelf: 'stretch',
              display: 'flex',
              marginTop: '12px',
              justifyContent: 'space-between',
              gap: '20px',
              padding: '14px 16px',
              position: 'relative',
              height: '135px',
              overflowY: 'scroll',
            }}
          >
            <div>{textDetails}</div>
          </Box>
        </Box>
      )}
    </>
  );
}
