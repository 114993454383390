import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

//mui
import {
  Box,
  Button,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
  FormControlLabel,
  Autocomplete,
  Select,
  Slider,
  Chip,
  FormHelperText,
  TextareaAutosize,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

//app
import * as utils from 'utils';
import { consts } from 'globalsData';
import { BootstrapTooltip, FileDropZone } from 'components';
import '../../Pages/Home/Home.css';

AddEditClassificationFormView.propTypes = {
  inputDataFileNameFromApi: PropTypes.string,
  predictionFileNameFromApi: PropTypes.string,
  watchAlgorithm: PropTypes.string.isRequired,
  isRedirectFromEdaCharts: PropTypes.bool.isRequired,
  summaryDeteails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  knnPList: PropTypes.array.isRequired,
  lossList: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    viewLastProcessData: PropTypes.func.isRequired,
  }),
  algorithms: PropTypes.array.isRequired,
  kernelList: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  penaltyList: PropTypes.array.isRequired,
  boosterList: PropTypes.array.isRequired,
  lrSolverList: PropTypes.array.isRequired,
  ldaSolverList: PropTypes.array.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  rfCriterionList: PropTypes.array.isRequired,
  gbcCriterionList: PropTypes.array.isRequired,
  getFileFieldTitle: PropTypes.func.isRequired,
  modelMetricsList: PropTypes.array.isRequired,
  knnAlgorithmList: PropTypes.array.isRequired,
  adcAlgorithmList: PropTypes.array.isRequired,
  rfMaxFeaturesList: PropTypes.array.isRequired,
  gbcMaxFeaturesList: PropTypes.array.isRequired,
  lastProcessedData: PropTypes.object.isRequired,
  isNoFilesUploaded: PropTypes.object.isRequired,
  importanceTypeList: PropTypes.array.isRequired,
  isValidFileFormats: PropTypes.object.isRequired,
  isValidFileSizes: PropTypes.object.isRequired,
  fileUploadFieldList: PropTypes.array.isRequired,
  renderHyperParameters: PropTypes.func.isRequired,
  isLastProcessDataInprogress: PropTypes.bool.isRequired,
  numberOfEstimators: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  targetSizeRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  nNeighbors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bcMaxFeatures: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bcNumberOfEstimatorsVal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  adcNumberOfEstimatorsVal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export function AddEditClassificationFormView({
  inputDataFileNameFromApi,
  predictionFileNameFromApi,
  watchAlgorithm,
  isRedirectFromEdaCharts,
  summaryDeteails,
  columns,
  fileUploadFieldList,
  bcNumberOfEstimatorsVal,
  adcNumberOfEstimatorsVal,
  handleFileUpload,
  formProps,
  fields,
  targetSizeRatio,
  algorithms,
  handlers,
  numberOfEstimators,
  nNeighbors,
  gbcMaxFeaturesList,
  importanceTypeList,
  bcMaxFeatures,
  renderHyperParameters,
  isLastProcessDataInprogress,
  lastProcessedData,
  isValidFileFormats,
  isValidFileSizes,
  lrSolverList,
  penaltyList,
  gammaList,
  knnPList,
  lossList,
  knnAlgorithmList,
  adcAlgorithmList,
  kernelList,
  rfCriterionList,
  ldaSolverList,
  rfMaxFeaturesList,
  gbcCriterionList,
  modelMetricsList,
  isNoFilesUploaded,
  getFileFieldTitle,
  boosterList,
}) {
  const { register, handleSubmit, errors, control } = formProps;
  const modelMetricsFields = utils.form.getFieldProp(fields, 'modelMetrics');
  const editFormOverFlow = inputDataFileNameFromApi ? { overflowX: 'hidden', overflowY: 'auto' } : {};
  const HelperText = ({ helperTextProp }) => {
    return (
      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
        {helperTextProp}
      </FormHelperText>
    );
  };
  return (
    <>
    {!inputDataFileNameFromApi &&
      <Box sx={{ backgroundColor: '#D6E7FB', borderRadius: '18px', marginBottom: '10px', padding: '15px', textAlign: 'left', fontSize: '14px' }}>
       <Typography fontSize={14} textAlign="left" pb={0.5} >
         Data Guidelines
       </Typography>
       <ul style={{ margin: '0', paddingLeft: '30px' }}>
         <li style={{ fontSize: '14px' }}>Exclude Primary Key or ID variables and designate a categorical variable as the target.</li>
         <li style={{ fontSize: '14px' }}>Remove irrelevant variables before uploading.</li>
       </ul>
     </Box>}
    <Box sx={{ borderRadius: '18px', backgroundColor: '#fff', ...editFormOverFlow }}>
      <Box px={4} py={2}>
        {!inputDataFileNameFromApi && (
          <Box id="classificationFormHeader">
            {/* {Boolean(lastProcessedData?.isLastProDataAvail) && (
              <Box textAlign="right">
                <Link component="button" onClick={() => handlers.viewLastProcessData(lastProcessedData)}>
                  <Typography fontSize={14} fontWeight={400}>
                    View Last Process Data
                  </Typography>
                </Link>
              </Box>
            )} */}
            {/* <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Classification width={65} height={65} />
              </Grid>
              <Grid item>
                <Typography variant="h2" fontSize="1.8rem" fontWeight="600" color={(theme) => theme.palette.primary.titleColor} pl={2}>
                  Classification
                </Typography>
              </Grid>
            </Grid> */}
            {/* <Box my={1}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                  <Typography fontSize={14} textAlign="left">
                    All fields are mandatory to generate relevant data*
                  </Typography>
                </Grid>
              </Grid>
            </Box> */}
          </Box>
        )}
        <Box
          id="classification-form"
          mt={2}
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(handlers.onSubmit)}
          sx={
            !inputDataFileNameFromApi
              ? { overflowY: 'auto', height: `${watchAlgorithm?.toLowerCase() !== 'all' ? '100%' : '100%'}`, overflow: 'auto' }
              : {}
          }
        >
          <Box>
            {inputDataFileNameFromApi && watchAlgorithm?.toLowerCase() === 'all' && <HelperText helperTextProp="You are not allowed to edit the model hyperparameters as you have selected 'All' for algorithm." />}
          </Box>
          <Grid container spacing={2}>
            {(inputDataFileNameFromApi || predictionFileNameFromApi) && (
              <>
                {inputDataFileNameFromApi && (
                  <Grid item xs={12}>
                    <Typography textAlign="left" pb={0.5} fontSize={14}>
                      Input Data
                    </Typography>
                    <Box border="2px dashed #767676" px={1} py={0.5} borderRadius="6px">
                      {inputDataFileNameFromApi}
                    </Box>
                  </Grid>
                )}
                {predictionFileNameFromApi && (
                  <Grid item xs={12}>
                    <Typography textAlign="left" pb={0.5} fontSize={14}>
                      Prediciton Data
                    </Typography>
                    <Box border="2px dashed #767676" px={1} py={0.5} borderRadius="6px">
                      {predictionFileNameFromApi}
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {!(inputDataFileNameFromApi || predictionFileNameFromApi) && (
              <>
                {fileUploadFieldList.map((item, i) => (
                  <Grid item xs={12} key={item.field}>
                    <Box id="uploadInputFile" sx={{ width: '350px' }}>
                      <Typography textAlign="left" pb={0.5} fontSize={14}>
                        {isRedirectFromEdaCharts && i === 0 ? 'Input Data' : getFileFieldTitle(item)}
                      </Typography>
                      <Box border="1px dashed #cfcaca" px={1} py={0.5} borderRadius="6px">
                        {isRedirectFromEdaCharts && i === 0 && (
                          <>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                              <Grid item>
                                <Typography fontSize={14}>{summaryDeteails?.input_data?.fileName}</Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {!(isRedirectFromEdaCharts && i === 0) && (
                          <>
                            {!item?.file?.name ? (
                              <>
                                <FileDropZone field={item.field} handleFileUpload={handleFileUpload} />
                              </>
                            ) : (
                              <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                <Grid item>
                                  <Typography fontSize={14}>{item?.file?.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    color="inherit"
                                    aria-label="delete file"
                                    onClick={() => handlers.onDelete(item.field)}
                                    edge="start"
                                    sx={{
                                      marginLeft: 1,
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </Box>
                      <Box className="classificationErrMsgHolder">
                        {isValidFileFormats.inputDataFile &&
                          isValidFileSizes.inputDataFile &&
                          item.field === fileUploadFieldList[0].field &&
                          isNoFilesUploaded.inputDataFile &&
                          !item.file && <HelperText helperTextProp="Please Attach (.csv or .xlsx) Input Data File" />}
                        {!isValidFileFormats.inputDataFile && item.field === fileUploadFieldList[0].field && (
                          <HelperText helperTextProp="Input data file format should be (.csv or .xlsx) and should not contains multiple (.) dots in file name" />
                        )}
                        {!isValidFileFormats.predictionDataFile && item.field === fileUploadFieldList[1].field && (
                          <HelperText helperTextProp="Prediction data file format should be (.csv or .xlsx) and should not contains multiple (.) dots in file name" />
                        )}

                        {!isValidFileSizes.inputDataFile && item.field === fileUploadFieldList[0].field && (
                          <HelperText
                            helperTextProp={`Input data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                          />
                        )}
                        {!isValidFileSizes.predictionDataFile && item.field === fileUploadFieldList[1].field && (
                          <HelperText
                            helperTextProp={`Prediction data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={6}>
              <Box id="targetVariable">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Target Variable
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.DATA_CLEANING_PARAMETER_REPLACE} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>

                {inputDataFileNameFromApi ? (
                  <TextField {...register('targetVariable')} {...utils.form.getFieldProp(fields, 'targetVariable')} />
                ) : (
                  <>
                    {!isRedirectFromEdaCharts ? (
                      <FormControl sx={{ width: '100%' }} error={!!errors?.targetVarialble?.message}>
                        <Select
                          {...register('targetVariable')}
                          {...utils.form.getFieldProp(fields, 'targetVariable')}
                          sx={{ fontSize: '14px', textAlign: 'left' }}
                        >
                          {columns?.map((header) => (
                            <MenuItem value={header.name} key={header.key} sx={{ fontSize: '14px' }}>
                              {header.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors?.targetVariable?.message && (
                          <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                            {errors.targetVariable.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <TextField {...register('targetVariable')} {...utils.form.getFieldProp(fields, 'targetVariable')} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="testSizeRatio">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Test Size Ratio
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.DATA_CLEANING_PARAMETER_REPLACE} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                  <Grid item xs={11}>
                    <Slider {...register('testSizeRatio')} {...utils.form.getFieldProp(fields, 'testSizeRatio')} />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle2" pt={0.5}>
                      {targetSizeRatio}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="algorithm">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Algorithm
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.DATA_CLEANING_PARAMETER_REPLACE} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.algorithm?.message}>
                  <Select
                    {...register('algorithm')}
                    {...utils.form.getFieldProp(fields, 'algorithm')}
                    sx={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {algorithms.map((method) => (
                      <MenuItem value={method.value} key={method.value} sx={{ fontSize: '14px' }}>
                        {method.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.algorithm?.message && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.algorithm?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {watchAlgorithm?.toLowerCase() !== 'all' && (
            <>
              <Box my={2} id="hyperParametersAccordion">
                <Typography fontSize={14} fontWeight={600} textAlign="left" color="#5a5a5a" py={2}>
                  Model Hyper Parameters
                </Typography>

                {renderHyperParameters(algorithms[0].value) && (
                  <Box id="logisticRegression">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="regulationParameter">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              C (RegulationParameter)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.MODEL_HYPERPARAMETERS_C_REGULATION} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <TextField
                            {...register('regulationParameter')}
                            {...utils.form.getFieldProp(fields, 'regulationParameter')}
                            error={!!errors?.regulationParameter?.message}
                          />
                          {!!errors?.regulationParameter?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.regulationParameter?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="lrsolver">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Solver
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.SOLVER} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.lrsolver?.message}>
                            <Select {...register('lrsolver')} {...utils.form.getFieldProp(fields, 'lrsolver')}>
                              {lrSolverList.map((solver) => (
                                <MenuItem value={solver.value} key={solver.value}>
                                  {solver.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.lrsolver?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.lrsolver?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="penalty">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Penalty
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.PENALTY} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.penalty?.message}>
                            <Select {...register('penalty')} {...utils.form.getFieldProp(fields, 'penalty')}>
                              {penaltyList.map((penalty) => (
                                <MenuItem value={penalty.value} key={penalty.value}>
                                  {penalty.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.penalty?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.penalty?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Tolerance
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.TOLERANCE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <TextField
                            {...register('tolerance')}
                            {...utils.form.getFieldProp(fields, 'tolerance')}
                            error={!!errors?.tolerance?.message}
                          />
                          {!!errors?.tolerance?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tolerance?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[1].value) && (
                  <Box id="linearDiscriminantAnalysis">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="ldaSolver">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Solver
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.SOLVER} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>

                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('ldaSolver')} {...utils.form.getFieldProp(fields, 'ldaSolver')}>
                              {ldaSolverList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('ldaSolver')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Tolerance
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.TOLERANCE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>

                          <TextField
                            {...register('tolerance')}
                            {...utils.form.getFieldProp(fields, 'tolerance')}
                            error={!!errors?.tolerance?.message}
                          />
                          {!!errors?.tolerance?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tolerance?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[2].value) && (
                  <Box id="supportVectorMachine">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="regulationParameter">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              C (RegulationParameter)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.MODEL_HYPERPARAMETERS_C_REGULATION} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <TextField
                            {...register('regulationParameter')}
                            {...utils.form.getFieldProp(fields, 'regulationParameter')}
                            error={!!errors?.regulationParameter?.message}
                          />
                          {!!errors?.regulationParameter?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.regulationParameter?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="gamma">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              gamma
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={'Gamma'} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}></Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('gamma')} {...utils.form.getFieldProp(fields, 'gamma')}>
                              {gammaList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('gamma')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="kernel">
                          <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                            Kernel
                          </Typography>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.kernel?.message}>
                            <Select {...register('kernel')} {...utils.form.getFieldProp(fields, 'kernel')}>
                              {kernelList.map((kernel) => (
                                <MenuItem value={kernel.value} key={kernel.value}>
                                  {kernel.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.kernel?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.kernel?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Tolerance
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.TOLERANCE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <TextField
                            {...register('tolerance')}
                            {...utils.form.getFieldProp(fields, 'tolerance')}
                            error={!!errors?.tolerance?.message}
                          />
                          {!!errors?.tolerance?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tolerance?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[3].value) && (
                  <Box id="randomForest">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="numberOfEstimators">
                          <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                            Number of estimators(n_estimators testing)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={10}>
                              <Slider {...register('numberOfEstimators')} {...utils.form.getFieldProp(fields, 'numberOfEstimators')} />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {numberOfEstimators}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="rfCriterion">
                          <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                            Criterion
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('rfCriterion')} {...utils.form.getFieldProp(fields, 'rfCriterion')}>
                              {rfCriterionList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('rfCriterion')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="rfMaxFeatures">
                          <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                            Maximum Feature(max_features)
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('rfMaxFeatures')} {...utils.form.getFieldProp(fields, 'rfMaxFeatures')}>
                              {rfMaxFeaturesList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('rfMaxFeatures')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[4].value) && (
                  <Box id="kNearestNeighborsClassifier">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="numberOfNearestNeighbors">
                          <Typography textAlign="left" pb={0.5} sx={{ fontSize: '14px' }}>
                            Number of nearest neighbors(n_neighbors)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider
                                {...register('numberOfNearestNeighbors')}
                                {...utils.form.getFieldProp(fields, 'numberOfNearestNeighbors')}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {nNeighbors}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="p">
                          <Typography textAlign="left" pb={0.5}>
                            p
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('p')} {...utils.form.getFieldProp(fields, 'p')}>
                              {knnPList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('p')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="kNNalgorithm">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Algorithm
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.ALGORITHM} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.kNNalgorithm?.message}>
                            <Select {...register('kNNalgorithm')} {...utils.form.getFieldProp(fields, 'kNNalgorithm')}>
                              {knnAlgorithmList.map((kNN) => (
                                <MenuItem value={kNN.value} key={kNN.value}>
                                  {kNN.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.kNNalgorithm?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.kNNalgorithm?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[5].value) && (
                  <Box id="baggingClassifier">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="bcNumberOfEstimators">
                          <Typography textAlign="left" pb={0.5}>
                            Number of estimators(n_estimators)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider {...register('bcNumberOfEstimators')} {...utils.form.getFieldProp(fields, 'bcNumberOfEstimators')} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {bcNumberOfEstimatorsVal}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="maximumFeatures">
                          <Typography textAlign="left" pb={0.5}>
                            Maximum Feature(max_features)
                          </Typography>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider {...register('bcMaxFeatures')} {...utils.form.getFieldProp(fields, 'bcMaxFeatures')} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {bcMaxFeatures}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[6].value) && (
                  <Box id="adaBoostClassifier">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="adcAlgorithm">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              algorithm
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.ALGORITHM} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('adcAlgorithm')} {...utils.form.getFieldProp(fields, 'adcAlgorithm')}>
                              {adcAlgorithmList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('adcAlgorithm')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="adcNumberOfEstimators">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Number of estimators(n_estimators)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATIONZ_ADA_BOOST_N_ESTIMATOR} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider
                                {...register('adcNumberOfEstimators')}
                                {...utils.form.getFieldProp(fields, 'adcNumberOfEstimators')}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {adcNumberOfEstimatorsVal}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="learningRate">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Learning Rate
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_ADA_BOOST_LEARNING_RATE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>

                          <TextField
                            {...register('learningRate')}
                            {...utils.form.getFieldProp(fields, 'learningRate')}
                            error={!!errors?.learningRate?.message}
                          />
                          {!!errors?.learningRate?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.learningRate?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[7].value) && (
                  <Box id="gradientBoostingClassifier">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="gbcCriterion">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Criterion
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_CRITERION} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.gbcCriterion?.message}>
                            <Select {...register('gbcCriterion')} {...utils.form.getFieldProp(fields, 'gbcCriterion')}>
                              {gbcCriterionList.map((criterion) => (
                                <MenuItem value={criterion.value} key={criterion.value}>
                                  {criterion.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.gbcCriterion?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.gbcCriterion?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="learningRateGbc">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Learning Rate
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_CLASSIFIER_LEARNING_RATE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <TextField
                            {...register('learningRateGbc')}
                            {...utils.form.getFieldProp(fields, 'learningRateGbc')}
                            error={!!errors?.learningRateGbc?.message}
                          />
                          {!!errors?.learningRateGbc?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.learningRateGbc?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="loss">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              loss
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_CLASSIFIER_LOSS} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup {...register('loss')} {...utils.form.getFieldProp(fields, 'loss')}>
                              {lossList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('loss')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="gbcMaxFeatures">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Maximum Features(max_features)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_CLASSIFIER_MAX_FEATURES} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.gbcMaxFeatures?.message}>
                            <Select {...register('gbcMaxFeatures')} {...utils.form.getFieldProp(fields, 'gbcMaxFeatures')}>
                              {gbcMaxFeaturesList.map((gbcMax) => (
                                <MenuItem value={gbcMax.value} key={gbcMax.value}>
                                  {gbcMax.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.gbcMaxFeatures?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.gbcMaxFeatures?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="numberOfEstimators">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Number of estimators(n_estimators)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_CLASSIFIER_N_ESTIMATOR} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider {...register('numberOfEstimators')} {...utils.form.getFieldProp(fields, 'numberOfEstimators')} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {numberOfEstimators}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Tolerance
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.TOLERANCE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <TextField
                            {...register('tolerance')}
                            {...utils.form.getFieldProp(fields, 'tolerance')}
                            error={!!errors?.tolerance?.message}
                          />
                          {!!errors?.tolerance?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tolerance?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[8].value) && (
                  <Box id="XGBClassifier">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="booster">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              booster
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_BOOSTER} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.booster?.message}>
                            <Select {...register('booster')} {...utils.form.getFieldProp(fields, 'booster')}>
                              {boosterList.map((booster) => (
                                <MenuItem value={booster.value} key={booster.value}>
                                  {booster.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.booster?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.booster?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="importanceType">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Importance_type
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_IMPORTANCE_TYPE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.importanceType?.message}>
                            <Select {...register('importanceType')} {...utils.form.getFieldProp(fields, 'importanceType')}>
                              {importanceTypeList.map((importanceType) => (
                                <MenuItem value={importanceType.value} key={importanceType.value}>
                                  {importanceType.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.importanceType?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.importanceType?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="learningRateXgb">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Learning Rate
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_LEARNING_RATE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>

                          <TextField
                            {...register('learningRateXgb')}
                            {...utils.form.getFieldProp(fields, 'learningRateXgb')}
                            error={!!errors?.learningRateXgb?.message}
                          />
                          {!!errors?.learningRateXgb?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.learningRateXgb?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="numberOfEstimators">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Number of estimators(n_estimators)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLASSIFICATION_N_ESTIMATOR} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={12}>
                              <Slider {...register('numberOfEstimators')} {...utils.form.getFieldProp(fields, 'numberOfEstimators')} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Box id="modelMetrics" mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box sx={{ textAlign: 'left' }}>
                        <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                          Model Metrics (4 Selected)
                        </Typography>
                        <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                          <BootstrapTooltip title={consts.TOOLTIP.MODEL_METRICS} placement={'bottom'}>
                            <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                          </BootstrapTooltip>
                        </Box>
                      </Box>
                      <Controller
                        name={modelMetricsFields?.name}
                        control={control}
                        defaultValue={modelMetricsFields?.defaultValue}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => {
                          return (
                            <Autocomplete
                              {...field}
                              value={field.value}
                              disablePortal={false}
                              disabled={isLastProcessDataInprogress}
                              filterSelectedOptions
                              multiple
                              size="medium"
                              getOptionLabel={modelMetricsFields?.getOptionLabel}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              onChange={(event, values) => {
                                field.onChange(values);
                              }}
                              options={modelMetricsList}
                              renderTags={(value, getTagProps) => {
                                return value?.map((option, index) => (
                                  <Chip
                                    {...getTagProps({ index })}
                                    variant="outlined"
                                    size="small"
                                    label={option[modelMetricsFields?.optionLabel]}
                                  />
                                ));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  error={!!error}
                                  helperText={error?.message}
                                  id={modelMetricsFields?.id}
                                  name={modelMetricsFields?.name}
                                  {...params}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
          <Box id="justificationRemarks" mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box id="justificationRemarks">
                  <Typography textAlign="left" pb={0.5} fontSize={14}>
                    Justification Remarks
                  </Typography>
                  <FormControl sx={{ width: '100%' }} error={!!errors?.justificationRemarks?.message}>
                    <TextareaAutosize
                      {...register('justificationRemarks')}
                      {...utils.form.getFieldProp(fields, 'justificationRemarks')}
                      className="textAreaStyles"
                    />

                    {!!errors?.justificationRemarks?.message && (
                      <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors.justificationRemarks.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box id="actionsButtons" sx={{ background: '#d2d2d2' }} px={4} py={2}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                borderRadius: '99px',
                p: '0.4rem 1.5rem',
              }}
              type="submit"
              onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
              disabled={isLastProcessDataInprogress}
            >
              {`${inputDataFileNameFromApi ? 'Update Graphs' : 'Analyze'}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
   </>
  );
}
