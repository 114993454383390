import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { get } from 'lodash';
import jwt_decode from 'jwt-decode';

// app
import AppView from './App.view';
import { getUser, getUserDetails, setIdToken, snackbarNotification, addAppLoadTime } from 'stores';

// mui
// import { Box, Typography } from '@mui/material';

const interceptor = () => {
  axios.interceptors.request.use(
    (req) => {
      return req;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
};

interceptor();

export default function App() {
  const dispatch = useDispatch();

  const userStore = useSelector((state) => get(state, 'user', {}));
  const userInfo = useSelector((state) => get(state, 'user.userInfo', {}));

  const userDetails = userStore?.userDetails;
  const userEmailId = userDetails?.email;

  const tokenFromLocalStorage = localStorage.getItem('token');
  
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);

  useEffect(() => {
    // check if token available in localStorage while App component rendering
    if (tokenFromLocalStorage) {
      try {
        const decoded = jwt_decode(tokenFromLocalStorage);
        dispatch(getUserDetails(decoded));
        dispatch(setIdToken(tokenFromLocalStorage));
      } catch (error) {
        dispatch(snackbarNotification(error?.n?.message, 'error'));
      }
    } else {
      dispatch(setIdToken(''));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userEmailId) {
      setIsUserInfoLoading(true);
      dispatch(getUser({ email: userEmailId })).then((res) => {
        if (res?.status === 200) {
          setIsUserInfoLoading(false);
        }
      });
    }
  }, [userEmailId]); // eslint-disable-line react-hooks/exhaustive-deps
  const storeAppLoadTime = () => {
    const loadTime = window.performance.getEntries()[0].duration;
    const payload = {
      page: 'HOME',
      loadTime: loadTime,
    };
    dispatch(addAppLoadTime(payload));
  };
  useEffect(() => {
    storeAppLoadTime();
  }, []);

  return <AppView userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} />;
}
