import React, { useEffect } from 'react';
import './BarsAnimation.css'; 
const BarsAnimation = () => {
  useEffect(() => {
    const barsContainer = document.getElementById('bars');

    for (let i = 0; i < 45; i++) {
      const left = (i * 2) + 1;
      const anim = Math.floor(Math.random() * 75 + 400);
      const height = Math.floor(Math.random() * 25 + 3);

      const barElement = document.createElement('div');
      barElement.className = 'bar';
      barElement.style.left = `${left}px`;
      barElement.style.animationDuration = `${anim}ms`;
      barElement.style.height = `${height}px`;

      barsContainer.appendChild(barElement);
    }
  }, []); 

  return (
    <div style={{
      display:'flex'
    }}>
      <div id="bars" className="bars-container"></div>
    </div>
  );
};

export default BarsAnimation;
