import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getViewRecordsList = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getViewRecordsListRequest());
  dispatch(addLoader('getViewRecordsList'));

  return api
    .get({
      endpoint: endpoint.crawlerScriptService,
      path: `v1/scraping/viewRecords/${params?.websiteId}`,
    })
    .then((res) => {
      dispatch(getViewRecordsListSuccess(res?.data || []));
      return res;
    })
    .catch((error) => {
      dispatch(getViewRecordsListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getViewRecordsList'));
    });
};

export const getViewRecordsListRequest = () => {
  return {
    type: 'GET_VIEW_RECORDS_LIST_REQUEST',
  };
};

export const getViewRecordsListSuccess = (data) => {
  return {
    type: 'GET_VIEW_RECORDS_LIST_SUCCESS',
    payload: data,
  };
};

export const getViewRecordsListError = (error) => {
  return {
    type: 'GET_VIEW_RECORDS_LIST_FAILURE',
    payload: error,
  };
};
