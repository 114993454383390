import React from 'react';
import PropTypes from 'prop-types';

//app
import * as utils from 'utils';
import { config, useMedia } from 'globalsData';
import { ReactComponent as ViewMoreIcon } from 'assets/svg/ViewMoreIcon.svg';
import { BootstrapTooltip, TileTooltip } from 'components';

//mui
import { Avatar, Box, Card as MuiCard, CardContent, Typography, useTheme, Link, Menu, MenuItem, IconButton } from '@mui/material';

Card.propTypes = {
  title: PropTypes.string,
  paragraph1: PropTypes.string,
  paragraph2: PropTypes.string,
  icon: PropTypes.any,
  isCardEnabled: PropTypes.bool.isRequired,
  cardInfo: PropTypes.object.isRequired,
  cardMenuAnchor: PropTypes.object,
  cardMenuInfo: PropTypes.object,
  currentTileTooltip: PropTypes.string.isRequired,
  menuList: PropTypes.array,
  callbacks: PropTypes.object,
};

export default function Card({ isCardEnabled, cardInfo, cardMenuAnchor, cardMenuInfo, currentTileTooltip, menuList, callbacks }) {
  const { tablet, desktop, desktopUp } = useMedia();
  const theme = useTheme();

  const getDescEllipsisStringLength = () => {
    if (tablet) return 100;
    if (desktop) return 28;
    if (desktopUp) return 40;
    return 45;
  };

  const getHeight = () => {
    if (desktop) return '280px';
    if (desktopUp) return '260px';
    return '288px';
  };

  const titleEllipsisLength = desktop ? 15 : 25;
  const descriptionEllipsisLength = getDescEllipsisStringLength();
  const Icon = cardInfo?.icon;

  return (
    <MuiCard
      sx={{
        height: getHeight(),
        cursor: `${isCardEnabled ? 'pointer' : 'not-allowed'}`,
        boxShadow: '0px 2px 16px 2px rgb(133 133 133 / 25%)',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '8px',
        ...(isCardEnabled && {
          '&:hover': {
            boxShadow: '0px 2px 18px 6px rgb(0 0 0 / 20%)',
          },
        }),
      }}
      onClick={() => callbacks.handleCardClick(cardInfo)}
    >
      <CardContent sx={{ width: '100%' }}>
        <Box
          textAlign="right"
          sx={{
            boxShadow: 'none',
            cursor: `${isCardEnabled ? 'pointer' : 'not-allowed'}`,
          }}
        >
          <IconButton
            size="small"
            aria-label="tile view more menu icon"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(event) => callbacks.openCardMenu(event, cardInfo)}
            sx={{ cursor: 'pointer' }}
          >
            <ViewMoreIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            sx={{ mt: '30px', '>div:nth-of-type(3)': { boxShadow: '0px 2px 8px 0px rgb(133 133 133 / 25%)' } }}
            anchorEl={cardMenuAnchor}
            data-menu="tile-menu"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(cardMenuAnchor)}
            onClose={(event) => callbacks.closeCardMenu(event)}
          >
            {menuList?.map((item) => {
              const Icon1 = item.icon;
              return (
                <MenuItem key={item?.name} onClick={(event) => item.onMenuItemClick(event, cardMenuInfo)} disabled={item?.disabled}>
                  <Icon1 width={20} height={20} sx={{ color: theme.palette.otherColor.textGrey2 }} />
                  <Typography variant="body1" pl={1} sx={{ color: theme.palette.otherColor.textGrey2 }}>
                    {item?.label}
                  </Typography>
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
        <Box pb={2}>
          <Avatar sx={{ width: 76, height: 76, margin: 'auto', bgcolor: theme.palette.otherColor.blue10 }}>
            <Icon width="40px" height="40px" />
          </Avatar>
        </Box>
        <Box>
          <BootstrapTooltip title={cardInfo?.title?.length > titleEllipsisLength ? cardInfo?.title : ''}>
            <Typography
              textAlign="center"
              fontWeight={600}
              fontSize={18}
              color={`${cardInfo.link !== config.routes.blank.root ? theme.palette.primary.textColor : '#9ea1a7'}`}
            >
              {utils.app.getEllipsisString(cardInfo?.title, titleEllipsisLength)}
            </Typography>
          </BootstrapTooltip>
        </Box>
        <Box textAlign="center" pt={2}>
          <Typography variant="div" color={`${cardInfo.link !== config.routes.blank.root ? theme.palette.primary.textColor : '#9ea1a7'}`}>
            {utils.app.getEllipsisString(cardInfo?.description, descriptionEllipsisLength)}
            {isCardEnabled && (
              <TileTooltip
                openToolTip={cardInfo?.title === currentTileTooltip}
                onCloseToolTip={callbacks.closeToolTip}
                title={cardInfo?.title}
                description={cardInfo?.description}
                highlights={cardInfo?.highlights}
              >
                <Link
                  component="button"
                  data-tile={cardInfo?.title}
                  onMouseEnter={(event) => callbacks.openToolTip(event)}
                  onMouseLeave={callbacks.closeToolTip}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  Know more
                </Link>
              </TileTooltip>
            )}
          </Typography>
        </Box>
      </CardContent>
    </MuiCard>
  );
}
