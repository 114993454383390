import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getCategoryManagementList = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getCategoryManagementListRequest());
  dispatch(addLoader('getCategoryManagementList'));

  return api
    .get({
      endpoint: endpoint.websiteService,
      path: `v1/websiteMgmt/${params?.catId}`,
    })
    .then((res) => {
      dispatch(getCategoryManagementListSuccess({ id: params?.catId, data: res?.data || [] }));
    })
    .catch((error) => {
      dispatch(getCategoryManagementListError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getCategoryManagementList'));
    });
};

export const getCategoryManagementListRequest = () => {
  return {
    type: 'GET_CATEGORY_MANAGEMENT_LIST_REQUEST',
  };
};

export const getCategoryManagementListSuccess = (data) => {
  return {
    type: 'GET_CATEGORY_MANAGEMENT_LIST_SUCCESS',
    payload: data,
  };
};

export const getCategoryManagementListError = (error) => {
  return {
    type: 'GET_CATEGORY_MANAGEMENT_LIST_FAILURE',
    payload: error,
  };
};
