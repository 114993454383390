import React from 'react';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';

// mui
import { Box, Button, Divider, FormControl, FormHelperText, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';

AddEditCategoryView.propTypes = {
  isPageEdited: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  errors: PropTypes.object,
  isUpdateCat: PropTypes.bool.isRequired,
  formProps: PropTypes.shape({
    register: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }),
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }),
};

export function AddEditCategoryView({ isPageEdited, fields, errors, isUpdateCat, formProps, handlers }) {
  const { register, handleSubmit } = formProps;

  return (
    <Box>
      <Box pl={2} py={3} sx={{ display: 'flex', overflowY: 'auto' }}>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1 },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(handlers.onSubmit)}
        >
          <TextField
            {...register('categoryName')}
            {...utils.form.getFieldProp(fields, 'categoryName')}
            error={!!errors?.categoryName?.message}
            helperText={!!errors?.categoryName?.message ? errors?.categoryName?.message : ''}
          />
          <Box id="status">
            
            <FormControl sx={{ width: '100%', padding:'8px' }} error={!!errors?.status?.message}>
            <Typography  sx={{fontSize:'12px',color:'grey', margin:'-8px 16px'}}>
              Status*
            </Typography>
              <Select {...register('status')} {...utils.form.getFieldProp(fields, 'status')} sx={{padding:'0px'}} size='small'>
                <MenuItem value="ENABLED">
                  <ListItemText primary="Active" />
                </MenuItem>
                <MenuItem value="DISABLED">
                  <ListItemText primary="Inactive" />
                </MenuItem>
              </Select>
              {!!errors?.status?.message && (
                <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                  {errors?.status?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box px={3} pb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }} pt={2}>
        <Button
          type="submit"
          variant="contained"
          size="small"
          sx={{ textTransform: 'none' }}
          autoFocus
          disabled={Boolean(isUpdateCat && !isPageEdited)}
          onClick={handleSubmit(handlers.onSubmit)}
        >
          {!isUpdateCat ? 'Create' : 'Update'}
        </Button>
      </Box>
    </Box>
  );
}
