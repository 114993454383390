import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getDepartmentPieChartData = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

 
  dispatch(getDepartmentPieChartDataRequest());
 
  return api
    .get({
      endpoint: endpoint.userManagementService,
      path: 'v1/um/departmentUserCount'
    })
    .then((res) => {
      dispatch(getDepartmentPieChartDataSuccess(res?.data || {}));
    })
    .catch((error) => {
      dispatch(getDepartmentPieChartDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getDepartmentPieChartData'));
    });
};

export const getDepartmentPieChartDataRequest = () => {
  return {
    type: 'GET_DEPARTMENT_PIE_CHART_DATA_REQUEST',
  };
};

export const getDepartmentPieChartDataSuccess = (data) => {
  return {
    type: 'GET_DEPARTMENT_PIE_CHART_DATA_SUCCESS',
    payload: data,
  };
};

export const getDepartmentPieChartDataError = (error) => {
  return {
    type: 'GET_DEPARTMENT_PIE_CHART_DATA_FAILURE',
    payload: error,
  };
};
