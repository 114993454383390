import { combineReducers } from 'redux';

import userReducer from './user/user.reducers';
import uiReducer from './ui/ui.reducers';
import categoriesReducer from './categories/categories.reducers';
import configReducer from './config/config.reducers';
import categoryManagementReducer from './categoryManagement/categoryManagement.reducers';
import acceleratorsReducer from './accelerators/accelerators.reducers';
import userManagementReducer from './userManagement/userManagement.reducers';
import analyticsStudioReducer from './analyticsStudio/analyticsStudio.reducers';
import shareOfBusiness from './shareOfBusiness/shareOfBusiness.reducer';
import routePath from './routing/routing.reducer';
import reports from './reports/reports.reducer';
import modelCarrier from './modelCarrier/modelCarrier.reducer';
import bvitReducers from './bvit/bvit.reducers';

export * from './user/user.actions';
export * from './user/user.actions.getUser';
export * from './user/user.actions.getFeatureAccess';
export * from './user/reports.action.getVerticalList';
export * from './user/reports.action.getDepartmentList';

export * from './ui/ui.actions';
export * from './config/config.actions';

export * from './categories/categories.actions';
export * from './categories/categories.actions.getCategoryList';
export * from './categories/categories.actions.addCategory';
export * from './categories/categories.actions.updateCategory';
export * from './categories/categories.actions.getOtherSolutionsList';

export * from './categoryManagement/categoryManagement.actions';
export * from './categoryManagement/categoryManagement.actions.getCategoryManagementList';
export * from './categoryManagement/categoryManagement.actions.addWebsite';
export * from './categoryManagement/categoryManagement.actions.updateWebsite';
export * from './categoryManagement/categoryManagement.actions.deleteWebsite';
export * from './categoryManagement/categoryManagement.actions.runCrawlerScript';
export * from './categoryManagement/categoryManagement.actions.downloadIncrementalData';
export * from './categoryManagement/categoryManagement.actions.downloadAllData';
export * from './categoryManagement/categoryManagement.actions.downloadScript';
export * from './categoryManagement/categoryManagement.actions.getViewRecordsList';

export * from './accelerators/accelerators.actions';
export * from './accelerators/accelerators.actions.postProcessingInput';
export * from './accelerators/accelerators.actions.getPresignedUrl';
export * from './accelerators/accelerators.actions.getLastProcessedData';
export * from './accelerators/accelerators.actions.uploadFileToS3';
export * from './accelerators/accelerators.actions.downloadFile';
export * from './accelerators/accelerators.actions.getSummaryDetails';
export * from './accelerators/accelerators.actions.editAccSummary';
export * from './accelerators/accelerators.actions.getGraphData';
export * from './accelerators/accelerators.actions.sourceAndTargetLanguage';
export * from './accelerators/accelerators.actions.getInputTextFileDetails';

export * from './routing/routing.actions.getRoutePath';

export * from './userManagement/userManagement.actions.addUser';
export * from './userManagement/userManagement.actions.deleteUser';
export * from './userManagement/userManagement.actions.getUserList';
export * from './userManagement/userManagement.actions.getUserRoleList';
export * from './userManagement/userManagement.actions.updateUser';

export * from './analyticsStudio/analyticsStudio.actions.accessRequest';

export * from './shareOfBusiness/shareOfBusiness.action.getShareOfBusinessTable';
export * from './shareOfBusiness/shareOfBusiness.action.getTransportersList';
export * from './shareOfBusiness/shareOfBusiness.action.getZonesList';
export * from './shareOfBusiness/shareOfBusiness.action.shareOfBusinessFileDownload';
export * from './shareOfBusiness/shareOfBusiness.action.getSOBPresignedUrl';
export * from './shareOfBusiness/shareOfBusiness.action.uploadSOBFileTos3';

export * from './modelCarrier/modelCarrier.action.getCarModel';
export * from './modelCarrier/modelCarrier.action.getCarrier';
export * from './modelCarrier/modelCarrier.action.getModelCarrierTableData';
export * from './modelCarrier/modelCarrier.action.getCarModelList';
export * from './modelCarrier/modelCarrier.action.getCarrierList';
export * from './modelCarrier/modelCarrier.action.addCarModel';
export * from './modelCarrier/modelCarrier.action.updateCarModel';
export * from './modelCarrier/modelCarrier.action.deleteCarModel';
export * from './modelCarrier/modelCarrier.action.uploadCarModel';
export * from './modelCarrier/modelCarrier.action.uploadCarrier';
export * from './modelCarrier/modelCarrier.action.deleteCarrier';

export * from './reports/reports.action.getTotalActiveUsers';
export * from './reports/reports.action.getMostActiveUser';
export * from './reports/reports.action.getAverageResponseTime';
export * from './reports/reports.action.getAcceleratorWeeklyUsage';
export * from './reports/reports.action.getOverallAcceleratorsUsage';
export * from './reports/reports.action.getOverallAppResponseTime';
export * from './reports/reports.action.getVerticalPieChartData';
export * from './reports/reports.action.getDepartmentPieChartData';
export * from './reports/reports.action.addAppLoadTime';

export * from './bvit/bvit.actions.getFilterData';
export * from './bvit/bvit.actions.getBvitPresignedUrl';
export * from './bvit/bvit.actions.uploadBvitFileToS3';
export * from './bvit/bvit.actions.addVehicleData';
export * from './bvit/bvit.actions.getBvitGraphData';
export * from './bvit/bvit.actions.downloadOldReport';

export default combineReducers({
  accelerators: acceleratorsReducer,
  categories: categoriesReducer,
  categoryManagement: categoryManagementReducer,
  config: configReducer,
  ui: uiReducer,
  user: userReducer,
  userManagement: userManagementReducer,
  analyticsStudio: analyticsStudioReducer,
  sob: shareOfBusiness,
  path: routePath, 
  reports: reports,
  modelCarrier: modelCarrier,
  bvit: bvitReducers
});
