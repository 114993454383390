import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

// app
import { UserManagmentView } from './UserManagment.view';
import { deleteUser, hideModal, showModal, getUserList } from 'stores';
import * as utils from 'utils';
import { DataGridCellExpand, BootstrapTooltip } from 'components';
import { config, consts } from 'globalsData';

// mui
import { IconButton, Typography, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function UserManagment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rows = useSelector((state) => get(state, 'userManagement.userList', []));

  const rowsModified = rows?.map((row) => {
    const convertCategoryListToString = utils.generic.isValidArray(row?.categoryList, true) ? row?.categoryList?.join(', ') : '';
    const convertFeatureListToString = utils.generic.isValidArray(row?.featureList, true) ? row?.featureList?.join(', ') : '';
    const convertOtherSolutionsListToString = utils.generic.isValidArray(row?.otherSolutionsList, true)
      ? row?.otherSolutionsList?.join(', ')
      : '';
    const isAdmin = row?.roleName?.toLowerCase() === 'admin';
    return {
      ...row,
      categoryList: isAdmin ? 'All' : convertCategoryListToString,
      featureList: isAdmin ? 'All' : convertFeatureListToString,
      otherSolutionsList: isAdmin ? 'All' : convertOtherSolutionsListToString,
    };
  });

  const uId = 'uId'; // unique Id in rows list

  const callUserList = () => {
    dispatch(getUserList());
  };

  useEffect(() => {
    callUserList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDataDeleteHandler = (row) => {
    dispatch(
      showModal({
        component: 'CONFIRM',
        props: {
          title: 'Delete User',
          maxWidth: 'xs',
          componentProps: {
            confirmText: (
              <Typography>
                Are you sure you want to delete <strong>{row?.email}</strong> user?
              </Typography>
            ),
            confirmButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('CONFIRM'));
            },
            submitHandler: () => {
              dispatch(deleteUser({ userId: row?.uId })).then((res) => {
                if (res.status === 200) {
                  dispatch(hideModal('CONFIRM'));
                  callUserList();
                }
              });
            },
          },
        },
      })
    );
  };
  const onDataEditHandler = (e, data) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'ADD_EDIT_USER',
        props: {
          title: 'Update User',
          maxWidth: 'sm',
          componentProps: {
            data,
            userId: data?.uId,
            submitHandler: () => {
              callUserList();
            },
          },
        },
      })
    );
  };
  const handleAddUser = () => {
    dispatch(
      showModal({
        component: 'ADD_EDIT_USER',
        props: {
          title: 'Create New User',
          maxWidth: 'sm',
          componentProps: {
            submitHandler: () => {
              callUserList();
            },
          },
        },
      })
    );
  };

  const columns = [
    {
      field: 'email',
      headerName: 'Email ID',
      minWidth: 150,
      flex: 0.18,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.email ?? ''} />,
    },
    {
      field: 'roleName',
      headerName: 'Role',
      minWidth: 110,
      flex: 0.1,
    },
    {
      field: 'featureList',
      headerName: 'Mapped Modules',
      minWidth: 150,
      flex: 0.2,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.featureList ?? ''} />,
    },
    {
      field: 'categoryList',
      headerName: 'Mapped Crawler Management Categories',
      minWidth: 150,
      flex: 0.2,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.categoryList ?? ''} />,
    },
    {
      field: 'otherSolutionsList',
      headerName: 'Mapped Business Solutions',
      minWidth: 150,
      flex: 0.2,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.otherSolutionsList ?? ''} />,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      minWidth: 100,
      flex: 0.15,
      type: 'string',
      editable: false,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.createdBy ?? ''} />,
    },
    {
      field: 'createdDate',
      headerName: 'Created On',
      type: 'string',
      minWidth: 50,
      flex: 0.15,
      editable: false,
      renderCell: (params) => <DataGridCellExpand value={utils.generic.getLocalTimeFromTimeStamp(params?.row?.createdDate)} />,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 100,
      flex: 0.07,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
            <BootstrapTooltip title={'Edit'} placement={'bottom'}>
              <IconButton sx={{ cursor: 'pointer' }} aria-label="Edit" onClick={(e) => onDataEditHandler(e, params?.row)}>
                <EditIcon />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title={'Delete'} placement={'bottom'}>
              <IconButton aria-label="Delete" onClick={() => onDataDeleteHandler(params?.row)}>
                <DeleteIcon />
              </IconButton>
            </BootstrapTooltip>
          </div>
        );
      },
    },
  ];
  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };
  const breadcrumbs = [
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.home.root)}>
      {consts.APP_TITLE}
    </Link>,
    <Typography key="3" color="text.primary" sx={{fontSize:'14px'}}>
      {consts.FEATURE_LIST_OBJ.userManagement}
    </Typography>,
  ];

  return (
    <UserManagmentView
      handlers={{ handleAddUser }}
      columns={columns}
      rows={rowsModified}
      userId={uId}
      uniqRowId={uId}
      breadcrumbs={breadcrumbs}
    />
  );
}
