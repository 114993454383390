import React from 'react';
import './TabComponent.css';

export default function TabComponentView({ tabs, activeTab, handleTabClick }) {
  return (
   <div className='tabs-container'>
      <div className='tabs-component'>
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            className={`main-tab ${activeTab === index ? 'tab-active' : ''}`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className='tabs-content'>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};