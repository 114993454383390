import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

//app
import * as utils from 'utils';
import { config, getClassificationPayload, consts, classificationStaticData } from 'globalsData';
import { AddEditClassificationFormView } from './AddEditClassificationForm.view';
import {
  addLoader,
  getLastProcessedData,
  getPresignedUrl,
  postProcessingInput,
  redirectFromEdaChart,
  removeLoader,
  snackbarNotification,
  uploadFileToS3,
  editAccSummary,
  hideModal,
} from 'stores';

AddEditClassificationForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

export default function AddEditClassificationForm({ data, submitHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const summaryDeteails = useSelector((state) => get(state, 'accelerators.summaryDeteails', {}));
  const isRedirectFromEdaCharts = useSelector((state) => get(state, 'accelerators.isRedirectFromEdaCharts', false));

  const inputDataRef = useRef();
  const predictionDataRef = useRef();

  const isUpdateClassification = Boolean(data?.input_data?.targetVariable);
  const inputFormDataFromApi = isUpdateClassification ? data?.input_data : {};
  const inputDataFileNameFromApi = data?.input_data?.fileName;
  const predictionFileNameFromApi = data?.input_data?.predictionFileName;
  const selectedModelMetricsFromApi = data?.input_data?.modelMetrics?.split(', ');
  const trackerIdfromApi = data?.trackerId;

  const [columns, setColumns] = useState([]);
  const [lastProcessedData, setLastProcessedData] = useState({});
  const [files, setFiles] = useState({ inputDataFile: null, predictionDataFile: null });
  const [isNoFilesUploaded, setIsNoFilesUploaded] = useState({ inputDataFile: false, predictionDataFile: false });
  const [isValidFileFormats, setIsValidFileFormats] = useState({ inputDataFile: true, predictionDataFile: true });
  const [isValidFileSizes, setIsValidFileSizes] = useState({ inputDataFile: true, predictionDataFile: true });
  const isLastProcessDataInprogress = false;
  // Remove false and uncomment below lines if client wants to disable when other job is running...
  // Boolean(lastProcessedData?.isLastProDataAvail) &&
  // lastProcessedData?.status !== consts.acceleratorStatus.complete &&
  // !lastProcessedData?.status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus);

  const fileUploadFieldList = [
    { field: 'inputData', label: 'Upload Input Data', file: files.inputDataFile, ref: inputDataRef },
    {
      field: 'predictionData',
      label: 'Upload Prediction Data (Optional)',
      file: files.predictionDataFile,
      ref: predictionDataRef,
    },
  ];

  // static data options for classification
  const algorithms = classificationStaticData.algorithms;
  const targetSizeRatioMarks = classificationStaticData.targetSizeRatioMarks;
  const modelMetricsList = classificationStaticData.modelMetricsList;
  const lrSolverList = classificationStaticData.lrSolverList;
  const penaltyList = classificationStaticData.penaltyList;
  const ldaSolverList = classificationStaticData.ldaSolverList;
  const gammaList = classificationStaticData.gammaList;
  const kernelList = classificationStaticData.kernelList;
  const rfCriterionList = classificationStaticData.rfCriterionList;
  const gbcCriterionList = classificationStaticData.gbcCriterionList;
  const rfMaxFeaturesList = classificationStaticData.rfMaxFeaturesList;
  const gbcMaxFeaturesList = classificationStaticData.gbcMaxFeaturesList;
  const knnPList = classificationStaticData.knnPList;
  const knnAlgorithmList = classificationStaticData.knnAlgorithmList;
  const adcAlgorithmList = classificationStaticData.adcAlgorithmList;
  const lossList = classificationStaticData.lossList;
  const boosterList = classificationStaticData.boosterList;
  const importanceTypeList = classificationStaticData.importanceTypeList;
  const nEstimatorsMarks = classificationStaticData.nEstimatorsMarks;
  const nNeighborsMarks = classificationStaticData.nNeighborsMarks;
  const bcMaxFeaturesMarks = classificationStaticData.bcMaxFeaturesMarks;

  const getDefaultModelMetrics = () => {
    if (selectedModelMetricsFromApi && selectedModelMetricsFromApi.length) {
      return modelMetricsList?.filter((model) => selectedModelMetricsFromApi?.includes(model?.value));
    } else {
      return modelMetricsList.filter((item, index) => index < 4);
    }
  };

  const numberOfEstimatorsDefault = {
    size: 'small',
    getAriaValueText: (value) => value,
    valueLabelDisplay: 'auto',
    disabled: isLastProcessDataInprogress,
    min: 10,
    max: 1000,
    // marks: nEstimatorsMarks,
    required: true,
    validation: Yup.number().min(10, 'Min value can be 10').max(1000, 'Max value can be 1000').required('Value cannot be empty'),
    sx: { width: 'calc(100% - 18px)' },
  };

  const getTargetVariableDefaultValue = () => {
    if (isRedirectFromEdaCharts) {
      return summaryDeteails?.input_data?.targetVariable;
    }
    if (isUpdateClassification) {
      return inputFormDataFromApi?.targetVariable;
    }
    return '';
  };

  const fields = [
    {
      name: 'justificationRemarks',
      id: 'justificationRemarks',
      size: 'small',
      required: true,
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().min(30, 'Min 30 characters').max(150, 'Max 150 characters').required('Justification Remarks required'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'targetVariable',
      id: 'targetVariable',
      defaultValue: getTargetVariableDefaultValue(),
      disabled: isLastProcessDataInprogress || isRedirectFromEdaCharts || isUpdateClassification,
      size: 'small',
      required: !isRedirectFromEdaCharts,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'testSizeRatio',
      id: 'testSizeRatio',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.testSizeRatio || 20,
      disabled: isLastProcessDataInprogress || isUpdateClassification,
      min: 10,
      max: 40,
      // marks: targetSizeRatioMarks,
      required: true,
      validation: Yup.number().min(10, 'Min value can be 10').max(40, 'Max value can be 40').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'algorithm',
      id: 'algorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm || 'logisticRegression',
      disabled: isLastProcessDataInprogress || isUpdateClassification,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'modelMetrics',
      id: 'modelMetrics',
      size: 'small',
      required: true,
      options: modelMetricsList,
      multiple: true,
      optionLabel: 'label',
      filterSelectedOptions: true,
      getOptionLabel: (option) => option.label,
      defaultValue: getDefaultModelMetrics(),
      disabled: isLastProcessDataInprogress,
      validation: Yup.array().min(1, 'Select atleast one model metrics'),
      sx: { textAlign: 'left' },
    },
    //logisticRegression, supportVectorMachine HP's
    {
      name: 'regulationParameter',
      id: 'regulationParameter',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.001, max: 10, step: 0.01 } },
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'logisticRegression' || inputFormDataFromApi?.algorithm === 'supportVectorMachine'
          ? inputFormDataFromApi?.modelHyperParameters?.C
          : 1.0,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.001, 'Min value can be 0.001')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    //logisticRegression HP's
    {
      name: 'lrsolver',
      id: 'lrsolver',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'logisticRegression' ? inputFormDataFromApi?.modelHyperParameters?.solver : 'lbfgs',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'penalty',
      id: 'penalty',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'logisticRegression' ? inputFormDataFromApi?.modelHyperParameters?.penalty : 'l2',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    //linearDiscriminantAnalysis, logisticRegression, supportVectorMachine, gradientBoostingClassifier HP's
    {
      name: 'tolerance',
      id: 'tolerance',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.0001, max: 10, step: 0.01 } },
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'logisticRegression' ||
        inputFormDataFromApi?.algorithm === 'linearDiscriminantAnalysis' ||
        inputFormDataFromApi?.algorithm === 'supportVectorMachine' ||
        inputFormDataFromApi?.algorithm === 'gradientBoostingClassifier'
          ? inputFormDataFromApi?.modelHyperParameters?.tol
          : 0.01,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.0001, 'Min value can be 0.0001')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    //linearDiscriminantAnalysis HP's
    {
      name: 'ldaSolver',
      id: 'ldaSolver',
      size: 'small',
      row: true,
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'linearDiscriminantAnalysis' ? inputFormDataFromApi?.modelHyperParameters?.solver : 'svd',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    //supportVectorMachine HP's
    {
      name: 'gamma',
      id: 'gamma',
      size: 'small',
      row: true,
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'supportVectorMachine' ? inputFormDataFromApi?.modelHyperParameters?.gamma : 'scale',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'kernel',
      id: 'kernel',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'supportVectorMachine' ? inputFormDataFromApi?.modelHyperParameters?.kernel : 'rbf',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    // randomForest, gradientBoostingClassifier, XGBClassifier HP's
    {
      ...numberOfEstimatorsDefault,
      name: 'numberOfEstimators',
      id: 'numberOfEstimators',
      defaultValue:
        inputFormDataFromApi?.algorithm === 'randomForest' ||
        inputFormDataFromApi?.algorithm === 'gradientBoostingClassifier' ||
        inputFormDataFromApi?.algorithm === 'XGBClassifier'
          ? inputFormDataFromApi?.modelHyperParameters?.n_estimators
          : 100,
    },
    // randomForest HP's
    {
      name: 'rfCriterion',
      id: 'rfCriterion',
      size: 'small',
      row: true,
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'randomForest' ? inputFormDataFromApi?.modelHyperParameters?.criterion : 'gini',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'rfMaxFeatures',
      id: 'rfMaxFeatures',
      size: 'small',
      row: true,
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'randomForest' ? inputFormDataFromApi?.modelHyperParameters?.max_features : 'auto',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    //kNearestNeighborsClassifier HP's
    {
      name: 'kNNalgorithm',
      id: 'kNNalgorithm',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'kNearestNeighborsClassifier' ? inputFormDataFromApi?.modelHyperParameters?.algorithm : 'auto',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    //kNearestNeighborsClassifier HP's
    {
      name: 'numberOfNearestNeighbors',
      id: 'numberOfNearestNeighbors',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue:
        inputFormDataFromApi?.algorithm === 'kNearestNeighborsClassifier' ? inputFormDataFromApi?.modelHyperParameters?.n_neighbors : 5,
      disabled: isLastProcessDataInprogress,
      min: 1,
      max: 50,
      // marks: nNeighborsMarks,
      required: true,
      validation: Yup.number().min(1, 'Min value can be 1').max(50, 'Max value can be 50').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'p',
      id: 'p',
      size: 'small',
      row: true,
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'kNearestNeighborsClassifier' ? inputFormDataFromApi?.modelHyperParameters?.p : 2,
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    //baggingClassifier HP's
    {
      name: 'bcMaxFeatures',
      id: 'bcMaxFeatures',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.algorithm === 'baggingClassifier' ? inputFormDataFromApi?.modelHyperParameters?.max_features : 1,
      disabled: isLastProcessDataInprogress,
      min: 1,
      max: 10,
      // marks: bcMaxFeaturesMarks,
      required: true,
      validation: Yup.number().min(1, 'Min value can be 1').max(10, 'Max value can be 10').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      ...numberOfEstimatorsDefault,
      name: 'bcNumberOfEstimators',
      id: 'bcNumberOfEstimators',
      defaultValue: inputFormDataFromApi?.algorithm === 'baggingClassifier' ? inputFormDataFromApi?.modelHyperParameters?.n_estimators : 10,
    },
    //adaBoostClassifier HP's
    {
      ...numberOfEstimatorsDefault,
      name: 'adcNumberOfEstimators',
      id: 'adcNumberOfEstimators',
      defaultValue:
        inputFormDataFromApi?.algorithm === 'adaBoostClassifier' ? inputFormDataFromApi?.modelHyperParameters?.n_estimators : 50,
    },
    {
      name: 'adcAlgorithm',
      id: 'adcAlgorithm',
      size: 'small',
      row: true,
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'adaBoostClassifier' ? inputFormDataFromApi?.modelHyperParameters?.algorithm : 'SAMME.R',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'learningRate',
      id: 'learningRate',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.0001, max: 10, step: 0.01 } },
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'adaBoostClassifier' ? inputFormDataFromApi?.modelHyperParameters?.learning_rate : 1.0,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.0001, 'Min value can be 0.0001')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    //gradientBoostingClassifier HP's
    {
      name: 'gbcCriterion',
      id: 'gbcCriterion',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingClassifier'
          ? inputFormDataFromApi?.modelHyperParameters?.criterion
          : 'friedman_mse',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'loss',
      id: 'loss',
      size: 'small',
      row: true,
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingClassifier' ? inputFormDataFromApi?.modelHyperParameters?.loss : 'deviance',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'learningRateGbc',
      id: 'learningRateGbc',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.001, max: 1, step: 0.01 } },
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingClassifier' ? inputFormDataFromApi?.modelHyperParameters?.learning_rate : 0.1,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.001, 'Min value can be 0.001')
        .max(1, 'Max value can be 1')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'gbcMaxFeatures',
      id: 'gbcMaxFeatures',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingClassifier'
          ? inputFormDataFromApi?.modelHyperParameters?.max_features
          : 'none',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    // XGBClassifier HP's
    {
      name: 'booster',
      id: 'booster',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'XGBClassifier' ? inputFormDataFromApi?.modelHyperParameters?.booster : 'none',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'importanceType',
      id: 'importanceType',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'XGBClassifier' ? inputFormDataFromApi?.modelHyperParameters?.importance_type : 'gain',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'learningRateXgb',
      id: 'learningRateXgb',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.001, max: 1, step: 0.01 } },
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'XGBClassifier' ? inputFormDataFromApi?.modelHyperParameters?.learning_rate : 0.1,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.001, 'Min value can be 0.001')
        .max(1, 'Max value can be 1')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchAlgorithm = watch('algorithm');
  const watchBcMaxFeatures = watch('bcMaxFeatures');
  const watchTargetSizeRatio = watch('testSizeRatio');
  const watchNEstimators = watch('numberOfEstimators');
  const watchNNeighbors = watch('numberOfNearestNeighbors');
  const watchBcNumberOfEstimators = watch('bcNumberOfEstimators');
  const watchAdcNumberOfEstimators = watch('adcNumberOfEstimators');

  useEffect(() => {
    if (!isUpdateClassification) {
      dispatch(getLastProcessedData({ type: consts.CLASSIFICATION })).then((res) => {
        if (res?.status === 200) {
          setLastProcessedData(res?.data);
        }
      });
    }

    // clean up
    return () => {
      dispatch(redirectFromEdaChart(false));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processData = (dataString) => {
    const targetVariableList = utils.app.getFileColumnReader(dataString, consts.CLASSIFICATION);
    setColumns(targetVariableList);
    dispatch(removeLoader('readingFile...'));
  };

  const handleFileUpload = (files, field) => {
    const file = files[0];
    const isValidFileFormat = consts.formAllowedFileFormats?.includes(file?.name?.split('.')[1]);

    if (fileUploadFieldList[0].field === field) {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: true }));

        if (file && file?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: file }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));

          dispatch(addLoader('readingFile...'));
          utils.app.getFileReader(processData, file);
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
      }
    } else {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        if (file && file?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: file }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, predictionDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
      }
    }
  };

  const onDelete = (field) => {
    switch (field) {
      case fileUploadFieldList[0].field:
        setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: null }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
        setColumns([]);
        break;
      case fileUploadFieldList[1].field:
        setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: null }));
        break;
      default:
        setFiles((prevFiles) => ({ ...prevFiles }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData }));
    }
  };

  const postFormData = (reqParams, trackerId) => {
    dispatch(postProcessingInput(reqParams)).then((res) => {
      if (res?.status === 200) {
        dispatch(snackbarNotification('Data submitted successully, Soon you will get prediction graphs', 'success'));
        navigate(`${config.routes.accelerators.type}/${consts.CLASSIFICATION}/${trackerId}`);
      }
    });
  };

  const onSubmit = (formData) => {
    const formattedFormData = {
      ...formData,
      modelMetrics: formData?.modelMetrics?.length ? formData?.modelMetrics?.map((item) => item.value)?.join(', ') : '',
    };
    const payload = getClassificationPayload(formattedFormData);

    if (inputDataFileNameFromApi && selectedModelMetricsFromApi && trackerIdfromApi) {
      const editedReqParams = {
        ...payload,
        trackerId: trackerIdfromApi,
        type: consts.CLASSIFICATION,
      };

      dispatch(editAccSummary(editedReqParams)).then((res) => {
        if (res?.status === 200) {
          dispatch(snackbarNotification('Classification Data updated successully, Soon you will get prediction graphs', 'success'));
          submitHandler();
          dispatch(hideModal('ADD_EDIT_CLASSIFICATION'));
        }
      });
    } else {
      if (!isRedirectFromEdaCharts && !files.inputDataFile) {
        setIsNoFilesUploaded({ inputDataFile: true });
      } else {
        dispatch(
          getPresignedUrl({
            type: consts.CLASSIFICATION,
            fileName: isRedirectFromEdaCharts ? summaryDeteails?.input_data?.fileName : files?.inputDataFile?.name,
            ...(files?.predictionDataFile?.name && { predictionFileName: files?.predictionDataFile?.name }),
            ...(isRedirectFromEdaCharts && summaryDeteails?.trackerId && { trackerId: summaryDeteails?.trackerId }),
          })
        ).then((res) => {
          if (res?.status === 200) {
            const inputPreSignedUrl = res?.data?.data?.inputPreSignedUrl;
            const predictionPreSignedUrl = res?.data?.data?.predictionPreSignedUrl;
            const trackerId = res?.data?.trackerId;
            const reqParams = {
              ...payload,
              trackerId: trackerId,
              type: consts.CLASSIFICATION,
            };

            if (!isRedirectFromEdaCharts) {
              if (inputPreSignedUrl && trackerId) {
                dispatch(uploadFileToS3({ preSignedUrl: inputPreSignedUrl, file: files?.inputDataFile,isThisForCsvOrXl:true })).then((fS3res) => {
                  if (fS3res?.status === 200) {
                    if (predictionPreSignedUrl) {
                      dispatch(uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile,isThisForCsvOrXl:true  })).then((sS3res) => {
                        if (sS3res?.status === 200) {
                          postFormData(reqParams, trackerId);
                        }
                      });
                    } else {
                      postFormData(reqParams, trackerId);
                    }
                  }
                });
              }
            } else {
              if (predictionPreSignedUrl) {
                dispatch(uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile,isThisForCsvOrXl:true  })).then((pS3res) => {
                  if (pS3res?.status === 200) {
                    postFormData(reqParams, trackerId);
                  }
                });
              } else {
                postFormData(reqParams, trackerId);
              }
            }
          }
        });
      }
    }
  };

  const onError = () => {
    if (!files.inputDataFile) {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
    } else {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
    }
  };

  const getFileFieldTitle = ({ field, file, label }) => {
    if (field === fileUploadFieldList[0].field) {
      return !isNoFilesUploaded.inputDataFile && !!file ? 'Input data' : label;
    } else if (field === fileUploadFieldList[1].field) {
      return !isNoFilesUploaded.predictionDataFile && !!file ? 'Prediction data' : label;
    }
  };

  const renderHyperParameters = (algorithm) => algorithm === watchAlgorithm;

  const viewLastProcessData = (lastProcessData) => {
    if (!lastProcessData?.trackerId) return;
    navigate(`${config.routes.accelerators.type}/${consts.CLASSIFICATION}/${lastProcessData.trackerId}`);
  };

  return (
    <AddEditClassificationFormView
      inputDataFileNameFromApi={inputDataFileNameFromApi}
      predictionFileNameFromApi={predictionFileNameFromApi}
      watchAlgorithm={watchAlgorithm}
      isRedirectFromEdaCharts={isRedirectFromEdaCharts}
      summaryDeteails={summaryDeteails}
      fields={fields}
      columns={columns}
      lossList={lossList}
      knnPList={knnPList}
      gammaList={gammaList}
      algorithms={algorithms}
      kernelList={kernelList}
      penaltyList={penaltyList}
      boosterList={boosterList}
      lrSolverList={lrSolverList}
      nNeighbors={watchNNeighbors}
      ldaSolverList={ldaSolverList}
      rfCriterionList={rfCriterionList}
      bcMaxFeatures={watchBcMaxFeatures}
      modelMetricsList={modelMetricsList}
      gbcCriterionList={gbcCriterionList}
      adcAlgorithmList={adcAlgorithmList}
      knnAlgorithmList={knnAlgorithmList}
      handleFileUpload={handleFileUpload}
      numberOfEstimators={watchNEstimators}
      getFileFieldTitle={getFileFieldTitle}
      rfMaxFeaturesList={rfMaxFeaturesList}
      isNoFilesUploaded={isNoFilesUploaded}
      lastProcessedData={lastProcessedData}
      targetSizeRatio={watchTargetSizeRatio}
      isValidFileFormats={isValidFileFormats}
      isValidFileSizes={isValidFileSizes}
      gbcMaxFeaturesList={gbcMaxFeaturesList}
      importanceTypeList={importanceTypeList}
      fileUploadFieldList={fileUploadFieldList}
      renderHyperParameters={renderHyperParameters}
      bcNumberOfEstimatorsVal={watchBcNumberOfEstimators}
      adcNumberOfEstimatorsVal={watchAdcNumberOfEstimators}
      isLastProcessDataInprogress={isLastProcessDataInprogress}
      formProps={{ errors, register, handleSubmit, control }}
      handlers={{
        onSubmit,
        onError,
        onDelete,
        viewLastProcessData,
      }}
      files={files}
    />
  );
}
