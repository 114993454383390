import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { config, consts } from 'globalsData';
import lineWaves from 'assets/png/lineWaves.png';

// App
import { AccessControl, Header, SideDrawer, Modal, Loader, SnackbarNotification, ErrorBoundary } from 'components';
import Categories from '../../Pages/Categories/Categories';
import CategoryManagment from '../../Pages/CategoryManagment/CategoryManagment';
import UserManagment from '../../Pages/UserManagment/UserManagment';
import Blank from '../../Pages/Blank/Blank';
import Home from '../../Pages/Home/Home';
import Accelerators from '../../Pages/Accelerators/Accelerators';
import AnalyticsStudio from '../../Pages/AnalyticsStudio/AnalyticsStudio';
import AcceleratorCharts from '../../Pages/AcceleratorCharts/AcceleratorCharts';
import AcceleratorDetails from '../../Pages/AcceleratorDetails/AcceleratorDetails';
import OtherSolutions from '../../Pages/OtherSolutions/OtherSolutions';
import ModelCarrier from 'Pages/ModelCarrier/ModelCarrier';
import ShareOfBusiness from 'Pages/ShareOfBusiness/ShareOfBusiness';
import BVIT from 'Pages/BVIT/BVIT';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import Reports from 'Pages/Reports/Reports';
import EmuLpp from 'Pages/EmuLpp/EmuLpp';

AppView.propTypes = {
  userInfo: PropTypes.object.isRequired,
  isUserInfoLoading: PropTypes.bool.isRequired,
};
const drawerWidth = 220;

export default function AppView({ userInfo, isUserInfoLoading = false }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const waveBackground = `url(${lineWaves}) no-repeat bottom`;

  const getBg = () => {
    return `${pathname.split('/').length <= 3 ? waveBackground : 'none'}`;
  };
  return (
    <Box>
      <Box sx={{ background: theme.palette.primary.backgroundColor }}>
        <Stack direction="row" sx={{ minHeight: '100vh' }}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, boxShadow:'none', borderBottom:'unset' }}>
              <Toolbar>
                <Header />
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <SideDrawer />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, bgcolor: '#efefef !important', p: 2, pt: 7, height: '100%', width: 'calc(80% - 0px)', background: getBg(), backgroundSize: 'cover' }}>
              <Suspense fallback={<Loader visible />}>
                <ErrorBoundary>
                  <Routes>
                    <Route exact path={config.routes.blank.root} element={<Blank />} />

                    {/*Home */}

                    <Route exact path={config.routes.home.root} element={<Home />} />

                    {/*Accelerators */}
                    <Route
                      exact
                      path={config.routes.accelerators.root}
                      element={
                        <AccessControl
                          userInfo={userInfo}
                          isUserInfoLoading={isUserInfoLoading}
                          featureName={consts.FEATURE_LIST_OBJ.accelerators}
                        >
                          <Accelerators />
                        </AccessControl>
                      }
                    />
                    <Route
                      exact
                      path={`${config.routes.accelerators.type}/:type`}
                      element={
                        <AccessControl
                          userInfo={userInfo}
                          isUserInfoLoading={isUserInfoLoading}
                          featureName={consts.FEATURE_LIST_OBJ.accelerators}
                        >
                          <AcceleratorDetails />
                        </AccessControl>
                      }
                    />
                    <Route
                      exact
                      path={`${config.routes.accelerators.type}/:type/:trackerId`}
                      element={
                        <AccessControl
                          userInfo={userInfo}
                          isUserInfoLoading={isUserInfoLoading}
                          featureName={consts.FEATURE_LIST_OBJ.accelerators}
                        >
                          <AcceleratorCharts />
                        </AccessControl>
                      }
                    />

                    {/*Analytics Studio */}
                    <Route
                      exact
                      path={config.routes.analyticsStudio.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.as}>
                          <AnalyticsStudio />
                        </AccessControl>
                      }
                    />

                    {/*DCMP */}
                    <Route
                      exact
                      path={config.routes.dcmp.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.dcmp}>
                          <Categories />
                        </AccessControl>
                      }
                    />
                    <Route
                      exact
                      path={`${config.routes.dcmp.websiteManagment}/:name/:id`}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.dcmp}>
                          <CategoryManagment />
                        </AccessControl>
                      }
                    />

                    {/*User Management */}
                    <Route exact path={config.routes.userManagement.root} element={<UserManagment />} />

                    {/* OtherSolutions */}
                    <Route
                      exact
                      path={config.routes.otherSolutions.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.os}>
                          <OtherSolutions />
                        </AccessControl>
                      }
                    />
                     {/* reports */}
                     <Route
                      exact
                      path={config.routes.reports.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.os}>
                          <Reports />
                        </AccessControl>
                      }
                    />
                    {/* Model Carrier */}
                    <Route
                      exact
                      path={config.routes.modelCarrier.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.os}>
                          <ModelCarrier />
                        </AccessControl>
                      }
                    />
                    {/*Share of Business*/}
                    <Route
                      exact
                      path={config.routes.logisticsServiceProvider.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.os}>
                          <ShareOfBusiness />
                        </AccessControl>
                      }
                    />
                    {/* BVIT */}
                    <Route
                      exact
                      path={config.routes.bvit.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.os}>
                          <BVIT />
                        </AccessControl>
                      }
                    />
                    {/* EMU LPP */}
                    <Route
                      exact
                      path={config.routes.emuLpp.root}
                      element={
                        <AccessControl userInfo={userInfo} isUserInfoLoading={isUserInfoLoading} featureName={consts.FEATURE_LIST_OBJ.os}>
                          <EmuLpp />
                        </AccessControl>
                      }
                    />
                  </Routes>
                </ErrorBoundary>
              </Suspense>
            </Box>
          </Box>
        </Stack>
        <ErrorBoundary>
          <Modal />
        </ErrorBoundary>

        <Loader label="Loading..." />
        <SnackbarNotification />
      </Box>
    </Box>
  );
}
