export const clearCategoryManagementList = () => {
  return {
    type: 'CLEAR_CATEGORY_MANAGEMENT_LIST',
  };
};

export const clearViewRecordsList = () => {
  return {
    type: 'CLEAR_VIEW_RECORDS_LIST',
  };
};
