import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const updateCategory = (rerParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(updateCategoryRequest());
  dispatch(addLoader('updateCategory'));

  const { categoryName, powerBiUrl, status, categoryId } = rerParams;

  const data = {
    categoryName,
    powerBIURL: powerBiUrl,
    status,
  };

  return api
    .post({
      endpoint: endpoint.categoryService,
      path: `v1/category/${categoryId}`,
      data,
    })
    .then((res) => {
      dispatch(updateCategorySuccess(res?.data?.data));
      dispatch(snackbarNotification(res?.data?.message, 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(updateCategoryError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('updateCategory'));
    });
};

export const updateCategoryRequest = () => {
  return {
    type: 'UPDATE_CATEGORY_REQUEST',
  };
};

export const updateCategorySuccess = (data) => {
  return {
    type: 'UPDATE_CATEGORY_SUCCESS',
    payload: data,
  };
};

export const updateCategoryError = (error) => {
  return {
    type: 'UPDATE_CATEGORY_FAILURE',
    payload: error,
  };
};
