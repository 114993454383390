import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';

// mui
import { Autocomplete, Box, Button, Chip, Divider, FormHelperText, Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

AddEditUserView.propTypes = {
  isAdminSelected: PropTypes.bool.isRequired,
  isDcmpSelected: PropTypes.bool.isRequired,
  isOtherSolutionSelected: PropTypes.bool.isRequired,
  isBusinessUserSelected: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  errors: PropTypes.object,
  isUpdateUser: PropTypes.bool.isRequired,
  userRoleList: PropTypes.array.isRequired,
  // verticalList: PropTypes.array.isRequired,
  departmentList: PropTypes.array.isRequired,
  isPageEdited: PropTypes.bool.isRequired,
  formProps: PropTypes.shape({
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
  }).isRequired,
  department: PropTypes.string
};

export function AddEditUserView({
  isAdminSelected,
  isDcmpSelected,
  isOtherSolutionSelected,
  isBusinessUserSelected,
  fields,
  errors,
  isUpdateUser,
  userRoleList,
  // verticalList,
  departmentList,
  isPageEdited,
  formProps,
  handlers,
  department,
  isDepartmentError,
  setIsDepartmentError
}) {
  const { control, register, handleSubmit, setValue,setError } = formProps;
  const manageCategoriesField = utils.form.getFieldProp(fields, 'manageCategories');
  const manageOtherSolutionsField = utils.form.getFieldProp(fields, 'manageOtherSolutions');
  const manageModulesField = utils.form.getFieldProp(fields, 'manageModules');
  departmentList = departmentList.map((department) => department.departmentName).sort();
  return (
    <Box>
      <Box py={2} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ width: '100%' }}
              {...register('emailId')}
              {...utils.form.getFieldProp(fields, 'emailId')}
              error={!!errors?.emailId?.message}
              helperText={!!errors?.emailId?.message ? errors?.emailId?.message : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ minWidth: 260 }} size="small" error={!!errors?.userRole?.message}>
              <InputLabel id="user-role">Role *</InputLabel>
              <Select
                {...register('userRole')}
                {...utils.form.getFieldProp(fields, 'userRole')}
                labelId="user-role"
                id="demo-simple-select-error"
                label="role"
                size="small"
                renderValue={(value) => `${value}`}
              >
                {userRoleList.map((option, index) => (
                  <MenuItem key={option.roleId} value={option.roleName}>
                    {option.roleName}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.userRole?.message && <FormHelperText>{errors?.userRole?.message}</FormHelperText>}
            </FormControl>
          </Grid>
          {/* Vertical select dropdown starts */}
          {/* <Grid item xs={12} sm={6}>
            <FormControl sx={{ minWidth: 260 }} size="small" error={!!errors?.userRole?.message}>
              <InputLabel id="vertical">Vertical *</InputLabel>
              <Select
                {...register('vertical')}
                {...utils.form.getFieldProp(fields, 'vertical')}
                labelId="vertical"
                id="demo-simple-select-error"
                label="Vertical"
                size="small"
                // renderValue={(name) => `${name}`}
              >
                {verticalList.map((option, index) => (
                  <MenuItem key={option.verticalId} value={option.verticalId} name={option.verticalName}>
                    {option.verticalName}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.userRole?.message && <FormHelperText>{errors?.userRole?.message}</FormHelperText>}
            </FormControl>
          </Grid> */}
          {/* Vertical select dropdown ends */}
          {/* Department select dropdown starts */}
          <Grid item xs={12}>
              <Controller
                name="department"
                control={control}
                defaultValue={department}
                render={({ field: props }) => (
                  <Autocomplete
                    {...props}
                    id="department"
                    options={departmentList}
                    getOptionLabel={(option) => option || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    size='small'
                    onChange={(event, values) => {
                      setError("department", null);
                      setIsDepartmentError(false);
                      props.onChange(values)}
                     }
                    value={props.value}
                    renderInput={(params) => <TextField error={isDepartmentError} {...params} label="Department *" />}
                  />
              )}
              />
              {isDepartmentError && <FormHelperText sx={{ color: '#d32f2f', marginLeft: '14px' }}>Department is required</FormHelperText>}
          </Grid>
          {/* Department select dropdown starts */}
          {isBusinessUserSelected && (
            <Grid item xs={12}>
              <Controller
                name={manageModulesField?.name}
                control={control}
                
                defaultValue={[]}
                render={({ field: { ref, ...field }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      {...field}
                      value={field.value}
                      disablePortal={false}
                      filterSelectedOptions
                      multiple
                      size="medium"
                      getOptionDisabled={(option) => option.disabled}
                      getOptionLabel={manageModulesField?.getOptionLabel}
                      isOptionEqualToValue={(option, value) => option.featureName === value.featureName}
                      onChange={(event, values) => {
                        field.onChange(values);
                        setValue('manageModules', values);
                      }}
                      options={manageModulesField?.options}
                      renderTags={(value, getTagProps) => {
                        return value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            variant="outlined"
                            size="small"
                            label={option[manageModulesField?.optionLabel]}
                          />
                        ));
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          error={!!error}
                          helperText={error?.message}
                          id={manageModulesField?.name}
                          label={manageModulesField?.label}
                          name={manageModulesField?.name}
                          type="search"
                          inputRef={ref}
                          {...params}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
          )}

          {!isAdminSelected && isBusinessUserSelected && isDcmpSelected && (
            <Grid item xs={12}>
              <Controller
                name={manageCategoriesField?.name}
                control={control}
                defaultValue={[]}
                render={({ field: { ref, ...field }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      {...field}
                      value={field.value}
                      disablePortal={false}
                      filterSelectedOptions
                      multiple
                      size="medium"
                      getOptionDisabled={(option) => option.disabled}
                      getOptionLabel={manageCategoriesField?.getOptionLabel}
                      isOptionEqualToValue={(option, value) => option.categoryId === value.categoryId}
                      onChange={(event, values) => {
                        field.onChange(values);
                        setValue('manageCategories', values);
                      }}
                      options={manageCategoriesField?.options}
                      renderTags={(value, getTagProps) => {
                        return value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            variant="outlined"
                            size="small"
                            label={option[manageCategoriesField?.optionLabel]}
                          />
                        ));
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          error={!!error}
                          helperText={error?.message}
                          id={manageCategoriesField?.name}
                          label={manageCategoriesField?.label}
                          name={manageCategoriesField?.name}
                          type="search"
                          inputRef={ref}
                          {...params}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
          )}

          {!isAdminSelected && isBusinessUserSelected && isOtherSolutionSelected && (
            <Grid item xs={12}>
              <Controller
                name={manageOtherSolutionsField?.name}
                control={control}
                defaultValue={[]}
                render={({ field: { ref, ...field }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      {...field}
                      value={field.value}
                      disablePortal={false}
                      filterSelectedOptions
                      multiple
                      size="medium"
                      getOptionDisabled={(option) => option.disabled}
                      getOptionLabel={manageOtherSolutionsField?.getOptionLabel}
                      isOptionEqualToValue={(option, value) => option.solutionId === value.solutionId}
                      onChange={(event, values) => {
                        field.onChange(values);
                        setValue('manageOtherSolutions', values);
                      }}
                      options={manageOtherSolutionsField?.options}
                      renderTags={(value, getTagProps) => {
                        return value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            variant="outlined"
                            size="small"
                            label={option[manageOtherSolutionsField?.optionLabel]}
                          />
                        ));
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          error={!!error}
                          helperText={error?.message}
                          id={manageOtherSolutionsField?.name}
                          label={manageOtherSolutionsField?.label}
                          name={manageOtherSolutionsField?.name}
                          type="search"
                          inputRef={ref}
                          {...params}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <Divider />
      <Box px={3} pb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
        <Button
          type="submit"
          sx={{ textTransform: 'none', borderRadius: '20px' }}
          variant="contained"
          size="medium"
          autoFocus
          disabled={Boolean(isUpdateUser && !isPageEdited)}
          onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
        >
          {!isUpdateUser ? 'Create' : 'Update'}
        </Button>
      </Box>
    </Box>
  );
}
