import React, { useRef, useCallback, useState, useEffect } from 'react';
import { WaveSurfer, WaveForm } from 'wavesurfer-react';
import './Waveform.css';
import pauseButton from '../../assets/svg/pausebutton.svg';
import playButton from '../../assets/svg/playbutton.svg';

const Waveform = ({audiourl}) => {
  const wavesurferRef = useRef();
  const [audio, setAudio] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleWSMount = useCallback((waveSurfer) => {
    wavesurferRef.current = waveSurfer;
    if (wavesurferRef.current) {
      wavesurferRef.current.load(audiourl||'');
    }
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    setAudio(new Audio(audiourl||''));
    const handleTimeUpdate = () => {
      setCurrentTime(wavesurferRef.current.getCurrentTime());
    };

    const handleLoadedMetadata = () => {
      setTotalDuration(wavesurferRef.current.getDuration());
    };

    const handleFinish = () => {
      wavesurferRef.current.stop();
      setIsPlaying(false);
    };

    if (wavesurferRef.current) {
      wavesurferRef.current.on('audioprocess', handleTimeUpdate);
      wavesurferRef.current.on('ready', handleLoadedMetadata);
      wavesurferRef.current.on('finish', handleFinish);
    }

    return () => {
      wavesurferRef.current.un('audioprocess', handleTimeUpdate);
      wavesurferRef.current.un('ready', handleLoadedMetadata);
      wavesurferRef.current.un('finish', handleFinish);
      wavesurferRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      wavesurferRef.current.play();
    } else {
      wavesurferRef.current.pause();
    }
  }, [isPlaying]);

  const play = useCallback(() => {
    setIsPlaying((prev) => !prev);
    wavesurferRef.current.playPause();
  }, []);

  const options = {
    waveColor: '#E5E5ED',
    progressColor: '#0177FB',
    cursorColor: 'transparent',
    barWidth: 3,
    barGap: 3,
    barRadius: 3,
    responsive: true,
    height: 40,
    normalize: true,
    partialRender: true,
    hideScrollbar: true,
  };

  return (
    <>
      <div
        className="waveformContainer"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <WaveSurfer onMount={handleWSMount} audioContext={audio}>
          <WaveForm id="waveform" {...options}></WaveForm>
        </WaveSurfer>

        <div>{isPlaying ? <img alt="pause" src={pauseButton} onClick={play} /> : <img alt="play" src={playButton} onClick={play} />}</div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '-2rem',
          right: '1%',
        }}
      >
        <span
          style={{
            color: 'rgba(0, 0, 0, 0.40)',
            textAlign: 'right',
            fontFamily: 'Inter',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}
        >
          {formatTime(currentTime)}/{formatTime(totalDuration)}
        </span>
      </div>
    </>
  );
};

export default Waveform;  
