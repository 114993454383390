import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//app
import { AcceleratorsView } from './Accelerators.view';
import { config, accelerators, consts } from 'globalsData';
import AcceleratorMainLogo from 'assets/svg/AcceleratorMainLogo.svg';

//mui
import { Link, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function Accelerators() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [videoInfo, setVideoInfo] = useState({ title: '', link: '' });
  const [tileMenu, setTileMenu] = useState(null);
  const [tileMenuInfo, setTileMenuInfo] = useState({});
  const [currentTileTooltip, setCurrentTileTooltip] = useState('');

  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  const handleAcceleratorClick = (acceleator) => {
    if (acceleator.enabled) navigate(acceleator.link);
  };

  const openToolTip = (event) => {
    event.stopPropagation();
    setCurrentTileTooltip(event.target.getAttribute('data-tile'));
  };

  const closeToolTip = (event) => {
    event.stopPropagation();
    setCurrentTileTooltip('');
  };

  const openTileMenu = (event, tile) => {
    event.stopPropagation();
    setTileMenuInfo(tile);
    setTileMenu(event.currentTarget);
  };

  const closeTileMenu = (event) => {
    event.stopPropagation();
    setTileMenuInfo({});
    setTileMenu(null);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const videoHandler = (event, { title, videoLink }) => {
    event.stopPropagation();
    openModal();
    setVideoInfo({ title, link: videoLink });
  };

  const bannerProps = {
    title: consts.FEATURE_LIST_OBJ.accelerators,
    paragraph1:
      'Tools for performing baseline analysis and modelling to offer users with business intelligence and visual representation of data to facilitate data-driven decision making',
    icon: AcceleratorMainLogo,
  };

  const breadcrumbs = [
    <Link underline="hover" key="2" color="inherit" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, config.routes.home.root)}>
      {consts.APP_TITLE}
    </Link>,
    <Typography key="3" color="text.primary" sx={{fontSize:'14px'}}>
      {consts.FEATURE_LIST_OBJ.accelerators}
    </Typography>,
  ];

  const menuList = [
    {
      name: 'playVideo',
      label: 'Play Video',
      icon: PlayCircleOutlineIcon,
      onMenuItemClick: videoHandler,
    },
    {
      name: 'developeGuide',
      label: 'Visit Developer Guide',
      icon: VisibilityIcon,
      disabled: true,
      onMenuItemClick: handleAcceleratorClick,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AcceleratorsView
      config={config}
      tileMenu={tileMenu}
      videoInfo={videoInfo}
      tileMenuInfo={tileMenuInfo}
      accelerators={accelerators}
      breadcrumbs={breadcrumbs}
      bannerProps={bannerProps}
      menuList={menuList}
      modalActions={{ modalOpen, closeModal }}
      handleAcceleratorClick={handleAcceleratorClick}
      currentTileTooltip={currentTileTooltip}
      handlers={{ handleAcceleratorClick, openToolTip, closeToolTip, videoHandler, openTileMenu, closeTileMenu }}
    />
  );
}
