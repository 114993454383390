import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getShareOfBusinessTable = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  if (!params) {
    return Promise.reject({ message: 'Request params are missing' });
  }
  dispatch(getShareOfBusinessTableRequest(params));

  return api
    .get({
      endpoint: endpoint.shareOfBusinessService,
      path: 'getSobReport',
      params
    })
    .then((res) => {
      dispatch(getShareOfBusinessTableSuccess(res?.data));
      dispatch(snackbarNotification('Execution Sccessful','success'))
      return res;
    })
    .catch((error) => {
      dispatch(getShareOfBusinessError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getShareOfBusinessTable'));
    });
};

export const getShareOfBusinessTableRequest = (params) => {
  return {
    type: 'GET_SHARE_OF_BUSINESS_TABLE_REQUEST',
    payload: params,
  };
};

export const getShareOfBusinessTableSuccess = (data) => {
  return {
    type: 'GET_SHARE_OF_BUSINESS_TABLE_SUCCESS',
    payload: data,
  };
};

export const getShareOfBusinessError = (error) => {
  return {
    type: 'GET_SHARE_OF_BUSINESS_TABLE_FAILURE',
    payload: error,
  };
};
