import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getOverallAppResponseTime = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

 
  dispatch(getOverallAppResponseTimeRequest());
 
  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'averageApplicationLoadTime'
    })
    .then((res) => {
      dispatch(getOverallAppResponseTimeSuccess(res?.data || {}));
    })
    .catch((error) => {
      dispatch(getOverallAppResponseTimeError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getOverallAppResponseTime'));
    });
};

export const getOverallAppResponseTimeRequest = () => {
  return {
    type: 'GET_OVERALL_APP_RESPONSE_TIME_REQUEST',
  };
};

export const getOverallAppResponseTimeSuccess = (data) => {
  return {
    type: 'GET_OVERALL_APP_RESPONSE_TIME_SUCCESS',
    payload: data,
  };
};

export const getOverallAppResponseTimeError = (error) => {
  return {
    type: 'GET_OVERALL_APP_RESPONSE_TIME_FAILURE',
    payload: error,
  };
};
