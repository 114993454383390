import React from 'react';
import BVITView from './BVIT.view';
import { BVITDataVisualization, BVITTorqueCalculation } from 'modules';

const BVIT = () => {
    const tabsData = [
        { label: 'Vehicle Shock Load Data Visualization', content: <BVITDataVisualization /> },
        { label: 'Impact Torque Estimation', content: <BVITTorqueCalculation /> },
        { label: 'Comparison', content: '' },
        { label: 'Bench Shock Load Data Visualization', content: '' },
        { label: 'Bench & Vehicle Impact Testing DB', content: '' }
      ];

    return(
        <BVITView tabs={tabsData} />
    )
}

export default BVIT;