import { consts } from 'globalsData';

const userManagementFeatureList = [
  {
    featureName: consts.FEATURE_LIST_OBJ.accelerators,
  },
  {
    featureName: consts.FEATURE_LIST_OBJ.as,
  },
  {
    featureName: consts.FEATURE_LIST_OBJ.dcmp,
  },
  {
    featureName: consts.FEATURE_LIST_OBJ.os,
  },
];

export default userManagementFeatureList;
