import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getOverallAcceleratorsUsage = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getOverallAcceleratorsUsageRequest());
  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'overallAcceleratorsUsage',
    })
    .then((res) => {
      dispatch(getOverallAcceleratorsUsageSuccess( res?.data || {} ));
    })
    .catch((error) => {
      dispatch(getOverallAcceleratorsUsageError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('getOverallAcceleratorsUsage'));
    });
};

export const getOverallAcceleratorsUsageRequest = () => {
  return {
    type: 'GET_OVERALL_ACCELERATORS_USAGE_REQUEST',
  };
};

export const getOverallAcceleratorsUsageSuccess = (data) => {
  return {
    type: 'GET_OVERALL_ACCELERATORS_USAGE_SUCCESS',
    payload: data,
  };
};

export const getOverallAcceleratorsUsageError = (error) => {
  return {
    type: 'GET_OVERALL_ACCELERATORS_USAGE_FAILURE',
    payload: error,
  };
};
