import React, { useRef, useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';

//app
import * as utils from 'utils';
import { AddEditRegressionFormView } from './AddEditRegressionForm.view';
import { config, getRegressionPayload, consts, regressionStaticData } from 'globalsData';
import {
  addLoader,
  removeLoader,
  getLastProcessedData,
  getPresignedUrl,
  postProcessingInput,
  redirectFromEdaChart,
  snackbarNotification,
  uploadFileToS3,
  editAccSummary,
  hideModal,
} from 'stores';

AddEditRegressionForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

export default function AddEditRegressionForm({ data, submitHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const summaryDeteails = useSelector((state) => get(state, 'accelerators.summaryDeteails', {}));
  const isRedirectFromEdaCharts = useSelector((state) => get(state, 'accelerators.isRedirectFromEdaCharts', false));

  const inputDataRef = useRef();
  const predictionDataRef = useRef();

  const isUpdateRegression = Boolean(data?.input_data?.targetVariable);
  const inputFormDataFromApi = isUpdateRegression ? data?.input_data : {};
  const inputDataFileNameFromApi = data?.input_data?.fileName;
  const predictionFileNameFromApi = data?.input_data?.predictionFileName;
  const trackerIdFromApi = data?.trackerId;

  const [columns, setColumns] = useState([]);
  const [lastProcessedData, setLastProcessedData] = useState({});
  const [files, setFiles] = useState({ inputDataFile: null, predictionDataFile: null });
  const [isNoFilesUploaded, setIsNoFilesUploaded] = useState({ inputDataFile: false, predictionDataFile: false });
  const [isValidFileFormats, setIsValidFileFormats] = useState({ inputDataFile: true, predictionDataFile: true });
  const [isValidFileSizes, setIsValidFileSizes] = useState({ inputDataFile: true, predictionDataFile: true });

  const isLastProcessDataInprogress = false;
  // Remove false and uncomment below lines if client wants to disable when other job is running...
  // Boolean(lastProcessedData?.isLastProDataAvail) &&
  // lastProcessedData?.status !== consts.acceleratorStatus.complete &&
  // !lastProcessedData?.status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus);

  // static data options for Regression
  const algorithms = regressionStaticData.algorithms;
  const targetSizeRatioMarks = regressionStaticData.targetSizeRatioMarks;
  const nEstimatorsMarks = regressionStaticData.nEstimatorsMarks;
  const nNeighborsMarks = regressionStaticData.nNeighborsMarks;
  const rrSolverList = regressionStaticData.rrSolverList;
  const selectionList = regressionStaticData.selectionList;
  const kNNalgorithmList = regressionStaticData.kNNalgorithmList;
  const pList = regressionStaticData.pList;
  const weightsList = regressionStaticData.weightsList;
  const rfMaxFeaturesList = regressionStaticData.rfMaxFeaturesList;
  const rfCriterionList = regressionStaticData.rfCriterionList;
  const brMaxFeaturesMarks = regressionStaticData.brMaxFeaturesMarks;
  const adaLossList = regressionStaticData.adaLossList;
  const gbrCriterionList = regressionStaticData.gbrCriterionList;
  const gbrLossList = regressionStaticData.gbrLossList;
  const gbrMaxFeaturesList = regressionStaticData.gbrMaxFeaturesList;
  const boosterList = regressionStaticData.boosterList;
  const importanceTypeList = regressionStaticData.importanceTypeList;

  const fileUploadFieldList = [
    { field: 'inputData', label: 'Upload Input Data ', file: files.inputDataFile, ref: inputDataRef },
    {
      field: 'predictionData',
      label: 'Upload Prediction Data (Optional)',
      file: files.predictionDataFile,
      ref: predictionDataRef,
    },
  ];

  const defaultNestimators = {
    size: 'small',
    getAriaValueText: (value) => value,
    valueLabelDisplay: 'auto',
    min: 10,
    max: 1000,
    // marks: nEstimatorsMarks,
    disabled: isLastProcessDataInprogress,
    required: true,
    validation: Yup.number().min(10, 'Min value can be 10').max(1000, 'Max value can be 1000').required('Value cannot be empty'),
    sx: { width: 'calc(100% - 18px)' },
  };

  const getTargetVariableDefaultValue = () => {
    if (isRedirectFromEdaCharts) {
      return summaryDeteails?.input_data?.targetVariable;
    }
    if (isUpdateRegression) {
      return inputFormDataFromApi?.targetVariable;
    }
    return '';
  };

  const fields = [
    {
      name: 'justificationRemarks',
      id: 'justificationRemarks',
      size: 'small',
      required: true,
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().min(30, 'Min 30 characters').max(150, 'Max 150 characters').required('Justification Remarks required'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'targetVariable',
      id: 'targetVariable',
      defaultValue: getTargetVariableDefaultValue(),
      disabled: isLastProcessDataInprogress || isRedirectFromEdaCharts || isUpdateRegression,
      size: 'small',
      required: !isRedirectFromEdaCharts,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'testSizeRatio',
      id: 'testSizeRatio',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.testSizeRatio || 20,
      disabled: isLastProcessDataInprogress || isUpdateRegression,
      min: 10,
      max: 40,
      // marks: targetSizeRatioMarks,
      required: true,
      validation: Yup.number().min(10, 'Min value can be 10').max(40, 'Max value can be 40').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'algorithm',
      id: 'algorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm || 'linearRegression',
      disabled: isLastProcessDataInprogress || isUpdateRegression,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    //ridgeRegression && lassoRegression hyperparameters
    {
      name: 'alpha',
      id: 'alpha',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.01, max: 10, step: 0.01 } },
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'ridgeRegression' || inputFormDataFromApi?.algorithm === 'lassoRegression'
          ? inputFormDataFromApi?.modelHyperParameters?.alpha
          : 1.0,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.01, 'Min value can be 0.01')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'tol',
      id: 'tol',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.0001, max: 10, step: 0.01 } },
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'ridgeRegression' || inputFormDataFromApi?.algorithm === 'lassoRegression'
          ? inputFormDataFromApi?.modelHyperParameters?.tol
          : 0.01,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.0001, 'Min value can be 0.0001')
        .max(10, 'Max value can be 10')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    //ridgeRegression Hyperparameters
    {
      name: 'solver',
      id: 'solver',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'ridgeRegression' ? inputFormDataFromApi?.modelHyperParameters?.solver : 'auto',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    //lassoRegression hyperperameters
    {
      name: 'selection',
      id: 'selection',
      size: 'small',
      row: true,
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'lassoRegression' ? inputFormDataFromApi?.modelHyperParameters?.selection : 'cyclic',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    //randomForestRegressor hyperparameters
    {
      name: 'rfCriterion',
      id: 'rfCriterion',
      size: 'small',
      row: true,
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'randomForestRegressor'
          ? inputFormDataFromApi?.modelHyperParameters?.criterion
          : 'squared_error',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'rfMax_features',
      id: 'rfMax_features',
      size: 'small',
      row: true,
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'randomForestRegressor' ? inputFormDataFromApi?.modelHyperParameters?.max_features : 'auto',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'rfN_estimators',
      id: 'rfN_estimators',
      defaultValue:
        inputFormDataFromApi?.algorithm === 'randomForestRegressor' ? inputFormDataFromApi?.modelHyperParameters?.n_estimators : 100,
      ...defaultNestimators,
    },
    //kNNRegressor hyperparameters
    {
      name: 'kNNalgorithm',
      id: 'kNNalgorithm',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'kNNRegressor' ? inputFormDataFromApi?.modelHyperParameters?.algorithm : 'auto',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'n_neighbors',
      id: 'n_neighbors',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.algorithm === 'kNNRegressor' ? inputFormDataFromApi?.modelHyperParameters?.n_neighbors : 5,
      disabled: isLastProcessDataInprogress,
      min: 1,
      max: 50,
      // marks: nNeighborsMarks,
      required: true,
      validation: Yup.number().min(1, 'Min value can be 10').max(50, 'Max value can be 50').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'p',
      id: 'p',
      size: 'small',
      row: true,
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'kNNRegressor' ? inputFormDataFromApi?.modelHyperParameters?.p : 2,
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'weights',
      id: 'weights',
      size: 'small',
      row: true,
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'kNNRegressor' ? inputFormDataFromApi?.modelHyperParameters?.weights : 'uniform',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    //baggingRegressor Hyperparameters
    {
      name: 'brMax_features',
      id: 'brMax_features',
      size: 'small',
      getAriaValueText: (value) => value,
      valueLabelDisplay: 'auto',
      defaultValue: inputFormDataFromApi?.algorithm === 'baggingRegressor' ? inputFormDataFromApi?.modelHyperParameters?.max_features : 1.0,
      disabled: isLastProcessDataInprogress,
      min: 1,
      max: 10,
      // marks: brMaxFeaturesMarks,
      required: true,
      validation: Yup.number().min(1, 'Min value can be 1').max(10, 'Max value can be 10').required('Value cannot be empty'),
      sx: { width: 'calc(100% - 18px)' },
    },
    {
      name: 'brN_estimators',
      id: 'brN_estimators',
      defaultValue: inputFormDataFromApi?.algorithm === 'baggingRegressor' ? inputFormDataFromApi?.modelHyperParameters?.n_estimators : 10,
      ...defaultNestimators,
    },
    //adaBoostRegressor Hyperparameters
    {
      name: 'learning_rate',
      id: 'learning_rate',
      size: 'small',
      type: 'number',
      slotProps: { input: { min: 0.001, max: 1, step: 0.01 } },
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'adaBoostRegressor' ? inputFormDataFromApi?.modelHyperParameters?.learning_rate : 1.0,
      disabled: isLastProcessDataInprogress,
      validation: Yup.number()
        .typeError('Value must be number type and cannot be empty')
        .min(0.001, 'Min value can be 0.001')
        .max(1, 'Max value can be 1')
        .required('Value cannot be empty'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'adaLoss',
      id: 'adaLoss',
      size: 'small',
      row: true,
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'adaBoostRegressor' ? inputFormDataFromApi?.modelHyperParameters?.loss : 'linear',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    {
      name: 'adaN_estimators',
      id: 'adaN_estimators',
      defaultValue: inputFormDataFromApi?.algorithm === 'adaBoostRegressor' ? inputFormDataFromApi?.modelHyperParameters?.n_estimators : 50,
      ...defaultNestimators,
    },
    //gradientBoostingRegressor Hyperparameters
    {
      name: 'gbrCriterion',
      id: 'gbrCriterion',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingRegressor'
          ? inputFormDataFromApi?.modelHyperParameters?.criterion
          : 'friedman_mse',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'gbrLoss',
      id: 'gbrLoss',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingRegressor'
          ? inputFormDataFromApi?.modelHyperParameters?.loss
          : 'squared_error',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'gbrMaxFeatures',
      id: 'gbrMaxFeatures',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingRegressor' ? inputFormDataFromApi?.modelHyperParameters?.max_features : 'none',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'gbrN_estimators',
      id: 'gbrN_estimators',
      defaultValue:
        inputFormDataFromApi?.algorithm === 'gradientBoostingRegressor' ? inputFormDataFromApi?.modelHyperParameters?.n_estimators : 100,
      ...defaultNestimators,
    },
    //XGBRegressor Hyperparameters
    {
      name: 'booster',
      id: 'booster',
      size: 'small',
      required: true,
      defaultValue: inputFormDataFromApi?.algorithm === 'XGBRegressor' ? inputFormDataFromApi?.modelHyperParameters?.booster : 'none',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'importance_type',
      id: 'importance_type',
      size: 'small',
      required: true,
      defaultValue:
        inputFormDataFromApi?.algorithm === 'XGBRegressor' ? inputFormDataFromApi?.modelHyperParameters?.importance_type : 'gain',
      disabled: isLastProcessDataInprogress,
      validation: Yup.string().required('Please select atleast one option'),
      sx: { textAlign: 'left' },
    },
    {
      name: 'xgbN_estimators',
      id: 'xgbN_estimators',
      defaultValue: inputFormDataFromApi?.algorithm === 'XGBRegressor' ? inputFormDataFromApi?.modelHyperParameters?.learning_rate : 100,
      ...defaultNestimators,
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchAlgorithm = watch('algorithm');
  const watchNneighbors = watch('n_neighbors');
  const watchRfNestimators = watch('rfN_estimators');
  const watchBrMaxFeatures = watch('brMax_features');
  const watchBrNestimators = watch('brN_estimators');
  const watchTestSizeRatio = watch('testSizeRatio');
  const watchAdaNestimators = watch('adaN_estimators');
  const watchGbrNestimators = watch('gbrN_estimators');
  const watchXgbNestimators = watch('xgbN_estimators');

  useEffect(() => {
    if (!isUpdateRegression) {
      dispatch(getLastProcessedData({ type: consts.REGRESSION })).then((res) => {
        if (res?.status === 200) {
          setLastProcessedData(res?.data);
        }
      });
    }

    // clean up
    return () => {
      dispatch(redirectFromEdaChart(false));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processData = (dataString) => {
    const targetVariableList = utils.app.getFileColumnReader(dataString, consts.REGRESSION);
    setColumns(targetVariableList);
    dispatch(removeLoader('readingFile...'));
  };

  const handleFileUpload = (files, field) => {
    const uploadedFile = files[0];
    const isValidFileFormat = consts.formAllowedFileFormats?.includes(uploadedFile?.name?.split('.')[1]);

    if (fileUploadFieldList[0].field === field) {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: uploadedFile }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));

          dispatch(addLoader('readingFile...'));
          utils.app.getFileReader(processData, uploadedFile);
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, inputDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, inputDataFile: true }));
      }
    } else {
      if (isValidFileFormat) {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        if (uploadedFile && uploadedFile?.size <= consts.ACCELERATORS_MAX_FILE_SIZE) {
          setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: uploadedFile }));
          setIsNoFilesUploaded((prevData) => ({ ...prevData, predictionDataFile: false }));
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
        } else {
          setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        }
      } else {
        setIsValidFileFormats((prevFiles) => ({ ...prevFiles, predictionDataFile: false }));
        setIsValidFileSizes((prevFiles) => ({ ...prevFiles, predictionDataFile: true }));
      }
    }
  };

  const renderHyperParameters = (algorithm) => algorithm === watchAlgorithm;

  const onDelete = (field) => {
    switch (field) {
      case fileUploadFieldList[0].field:
        setFiles((prevFiles) => ({ ...prevFiles, inputDataFile: null }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
        setColumns([]);
        break;
      case fileUploadFieldList[1].field:
        setFiles((prevFiles) => ({ ...prevFiles, predictionDataFile: null }));
        break;
      default:
        setFiles((prevFiles) => ({ ...prevFiles }));
        setIsNoFilesUploaded((prevData) => ({ ...prevData }));
    }
  };

  const onError = () => {
    if (!files.inputDataFile) {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: true }));
    } else {
      setIsNoFilesUploaded((prevData) => ({ ...prevData, inputDataFile: false }));
    }
  };

  const getFileFieldTitle = ({ field, file, label }) => {
    if (field === fileUploadFieldList[0].field) {
      return !isNoFilesUploaded.inputDataFile && !!file ? 'Input data' : label;
    } else if (field === fileUploadFieldList[1].field) {
      return !isNoFilesUploaded.predictionDataFile && !!file ? 'Prediction data' : label;
    }
  };

  const postFormData = (reqParams, trackerId) => {
    dispatch(postProcessingInput(reqParams)).then((res) => {
      if (res?.status === 200) {
        dispatch(snackbarNotification('Data submitted successully, Soon you will get prediction graphs', 'success'));
        navigate(`${config.routes.accelerators.type}/${consts.REGRESSION}/${trackerId}`);
      }
    });
  };

  const onSubmit = (formData) => {
    const formattedFormData = {
      ...formData,
      modelMetrics: formData?.modelMetrics?.length ? formData?.modelMetrics?.map((item) => item.value)?.join(', ') : '',
    };
    const payload = getRegressionPayload(formattedFormData);

    if (inputDataFileNameFromApi && trackerIdFromApi) {
      const editedReqParams = {
        ...payload,
        trackerId: trackerIdFromApi,
        type: consts.REGRESSION,
      };

      dispatch(editAccSummary(editedReqParams)).then((res) => {
        if (res?.status === 200) {
          dispatch(snackbarNotification('Regression Data updated successully, Soon you will get prediction graphs', 'success'));
          submitHandler();
          dispatch(hideModal('ADD_EDIT_REGRESSION'));
        }
      });
    } else {
      if (!isRedirectFromEdaCharts && !files.inputDataFile) {
        setIsNoFilesUploaded({ inputDataFile: true });
      } else {
        dispatch(
          getPresignedUrl({
            type: consts.REGRESSION,
            fileName: isRedirectFromEdaCharts ? summaryDeteails?.input_data?.fileName : files?.inputDataFile?.name,
            ...(files?.predictionDataFile?.name && { predictionFileName: files?.predictionDataFile?.name }),
            ...(isRedirectFromEdaCharts && summaryDeteails?.trackerId && { trackerId: summaryDeteails?.trackerId }),
          })
        ).then((res) => {
          if (res?.status === 200) {
            const inputPreSignedUrl = res?.data?.data?.inputPreSignedUrl;
            const predictionPreSignedUrl = res?.data?.data?.predictionPreSignedUrl;
            const trackerId = res?.data?.trackerId;
            const reqParams = {
              ...payload,
              trackerId: trackerId,
              type: consts.REGRESSION,
            };

            if (!isRedirectFromEdaCharts) {
              if (inputPreSignedUrl && trackerId) {
                dispatch(uploadFileToS3({ preSignedUrl: inputPreSignedUrl, file: files?.inputDataFile,isThisForCsvOrXl:true  })).then((fS3res) => {
                  if (fS3res?.status === 200) {
                    if (predictionPreSignedUrl) {
                      dispatch(uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile,isThisForCsvOrXl:true  })).then((sS3res) => {
                        if (sS3res?.status === 200) {
                          postFormData(reqParams, trackerId);
                        }
                      });
                    } else {
                      postFormData(reqParams, trackerId);
                    }
                  }
                });
              }
            } else {
              if (predictionPreSignedUrl) {
                dispatch(uploadFileToS3({ preSignedUrl: predictionPreSignedUrl, file: files.predictionDataFile,isThisForCsvOrXl:true  })).then((ppres) => {
                  if (ppres?.status === 200) {
                    postFormData(reqParams, trackerId);
                  }
                });
              } else {
                postFormData(reqParams, trackerId);
              }
            }
          }
        });
      }
    }
  };
  const viewLastProcessData = (lastProcessData) => {
    if (!lastProcessData?.trackerId) return;
    navigate(`${config.routes.accelerators.type}/${consts.REGRESSION}/${lastProcessData.trackerId}`);
  };

  return (
    <AddEditRegressionFormView
      inputDataFileNameFromApi={inputDataFileNameFromApi}
      predictionFileNameFromApi={predictionFileNameFromApi}
      pList={pList}
      fields={fields}
      columns={columns}
      algorithms={algorithms}
      adaLossList={adaLossList}
      weightsList={weightsList}
      boosterList={boosterList}
      gbrLossList={gbrLossList}
      solverList={rrSolverList}
      nNeighbors={watchNneighbors}
      selectionList={selectionList}
      watchAlgorithm={watchAlgorithm}
      summaryDeteails={summaryDeteails}
      rfCriterionList={rfCriterionList}
      testSizeRatio={watchTestSizeRatio}
      rfNestimators={watchRfNestimators}
      brMaxFeatures={watchBrMaxFeatures}
      brNestimators={watchBrNestimators}
      kNNalgorithmList={kNNalgorithmList}
      handleFileUpload={handleFileUpload}
      gbrCriterionList={gbrCriterionList}
      gbrNestimators={watchGbrNestimators}
      xgbNestimators={watchXgbNestimators}
      adaNestimators={watchAdaNestimators}
      lastProcessedData={lastProcessedData}
      rfMaxFeaturesList={rfMaxFeaturesList}
      getFileFieldTitle={getFileFieldTitle}
      isNoFilesUploaded={isNoFilesUploaded}
      isValidFileFormats={isValidFileFormats}
      isValidFileSizes={isValidFileSizes}
      bcMaxFeaturesMarks={brMaxFeaturesMarks}
      gbrMaxFeaturesList={gbrMaxFeaturesList}
      importanceTypeList={importanceTypeList}
      fileUploadFieldList={fileUploadFieldList}
      renderHyperParameters={renderHyperParameters}
      isRedirectFromEdaCharts={isRedirectFromEdaCharts}
      isLastProcessDataInprogress={isLastProcessDataInprogress}
      formProps={{ errors, register, handleSubmit, control }}
      handlers={{
        onSubmit,
        onError,
        onDelete,
        viewLastProcessData,
      }}
    />
  );
}
