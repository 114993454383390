import React from 'react';
import PropTypes from 'prop-types';

//app
import { Breadcrumbs, Banner, BootstrapTooltip, VideoModal, Card, Layout } from 'components';

//mui
import { Box, Typography, Grid, Link } from '@mui/material';

AnalyticsStudioView.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  bannerProps: PropTypes.object.isRequired,
  currentTileTooltip: PropTypes.string.isRequired,
  analyticsStudioTiles: PropTypes.array.isRequired,
  tileMenuInfo: PropTypes.object.isRequired,
  modalActions: PropTypes.object.isRequired,
  videoInfo: PropTypes.object.isRequired,
  tileMenu: PropTypes.object,
  menuList: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    videoHandler: PropTypes.func.isRequired,
    handleTileClick: PropTypes.func.isRequired,
    handleAccessRequest: PropTypes.func.isRequired,
    openToolTip: PropTypes.func.isRequired,
    closeToolTip: PropTypes.func.isRequired,
    openTileMenu: PropTypes.func.isRequired,
    closeTileMenu: PropTypes.func.isRequired,
  }).isRequired,
};

export function AnalyticsStudioView({
  bannerProps,
  tileMenu,
  breadcrumbs,
  tileMenuInfo,
  analyticsStudioTiles,
  currentTileTooltip,
  modalActions,
  menuList,
  videoInfo,
  handlers,
}) {
  const { modalOpen, closeModal } = modalActions;

  return (
    <Layout>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={10}>
          {/* <Breadcrumbs breadcrumbs={breadcrumbs} paddingLeftRight={0} /> */}
        </Grid>
        <Grid item xs={12} sm={2} textAlign="right">
          <BootstrapTooltip
            title={<Typography variant="body2">Please download the attached template and submit the service request.</Typography>}
            arrow
          >
            <Link component="button" variant="body2" fontWeight="bold" onClick={handlers.handleAccessRequest}>
              Access Request
            </Link>
          </BootstrapTooltip>
        </Grid>
      </Grid>
      <Box py={2} mt={1}>
        <Banner {...bannerProps} />
        <Box>
          <Grid container spacing={2}>
            {analyticsStudioTiles?.length ? (
              analyticsStudioTiles?.map((tile) => {
                return (
                  <Grid item key={tile?.name} xs={12} md={3}>
                    <Card
                      cardInfo={tile}
                      cardMenuAnchor={tileMenu}
                      cardMenuInfo={tileMenuInfo}
                      currentTileTooltip={currentTileTooltip}
                      isCardEnabled
                      menuList={menuList}
                      callbacks={{
                        openCardMenu: handlers.openTileMenu,
                        closeCardMenu: handlers.closeTileMenu,
                        videoHandler: handlers.videoHandler,
                        handleCardClick: handlers.handleTileClick,
                        closeToolTip: handlers.closeToolTip,
                        openToolTip: handlers.openToolTip,
                      }}
                    />
                  </Grid>
                );
              })
            ) : (
              <Box px={2}>
                <Typography variant="body1">Nothing found !!</Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
      {modalOpen && <VideoModal modalOpen={modalOpen} onCloseModal={closeModal} videoInfo={videoInfo} />}
    </Layout>
  );
}
