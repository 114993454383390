import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const downloadAllData = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(downloadAllDataRequest());
  dispatch(addLoader('downloadAllData'));

  return api
    .get({
      endpoint: endpoint.crawlerScriptService,
      path: `v1/scraping/downloadAllData/${params?.websiteId}`,
    })
    .then((res) => {
      if (res?.data?.message) {
        dispatch(downloadAllDataSuccess(res?.data));
        dispatch(snackbarNotification(res?.data?.message, 'success', { delay: 6000 }));
      } else {
        const url = res?.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${'All Data'}.${'csv'}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    })
    .catch((error) => {
      dispatch(downloadAllDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);
    })
    .finally(() => {
      dispatch(removeLoader('downloadAllData'));
    });
};

export const downloadAllDataRequest = () => {
  return {
    type: 'DOWNLOAD_ALL_DATA_REQUEST',
  };
};

export const downloadAllDataSuccess = (data) => {
  return {
    type: 'DOWNLOAD_ALL_DATA_SUCCESS',
    payload: data,
  };
};

export const downloadAllDataError = (error) => {
  return {
    type: 'DOWNLOAD_ALL_DATA_FAILURE',
    payload: error,
  };
};
