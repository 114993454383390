const classificationStaticData = {
  algorithms: [
    { value: 'logisticRegression', label: 'Logistic Regression' },
    { value: 'linearDiscriminantAnalysis', label: 'Linear Discriminant Analysis' },
    { value: 'supportVectorMachine', label: 'Support Vector Machine' },
    { value: 'randomForest', label: 'Random Forest' },
    { value: 'kNearestNeighborsClassifier', label: 'KNearest Neighbors Classifier' },
    { value: 'baggingClassifier', label: 'Bagging Classifier' },
    { value: 'adaBoostClassifier', label: 'AdaBoost Classifier' },
    { value: 'gradientBoostingClassifier', label: 'Gradient Boosting Classifier' },
    { value: 'XGBClassifier', label: 'XGB Classifier' },
    { value: 'all', label: 'All' },
  ],
  targetSizeRatioMarks: [
    {
      value: 10,
      label: '10',
    },
    {
      value: 40,
      label: '40',
    },
  ],
  modelMetricsList: [
    { value: 'precision', label: 'Precision' },
    { value: 'recall', label: 'Recall' },
    { value: 'f1Score', label: 'F1 Score' },
    { value: 'accuracy', label: 'Accuracy' },
    { value: 'auc', label: 'AUC' },
    { value: 'confusionMatrix', label: 'Confusion Matrix' },
  ],
  lrSolverList: [
    { value: 'lbfgs', label: 'lbfgs' },
    { value: 'liblinear', label: 'liblinear' },
    { value: 'newton-cg', label: 'newton-cg' },
    { value: 'sag', label: 'sag' },
    { value: 'saga', label: 'saga' },
  ],
  penaltyList: [
    { value: 'l1', label: 'l1' },
    { value: 'l2', label: 'l2' },
    { value: 'elasticnet', label: 'elasticnet' },
    { value: 'none', label: 'none' },
  ],
  ldaSolverList: [
    { value: 'svd', label: 'svd' },
    { value: 'lsqr', label: 'lsqr' },
    { value: 'eigen', label: 'eigen' },
  ],
  gammaList: [
    { value: 'scale', label: 'scale' },
    { value: 'auto', label: 'auto' },
    { value: 'float', label: 'float' },
  ],
  kernelList: [
    { value: 'linear', label: 'linear' },
    { value: 'poly', label: 'poly' },
    { value: 'rbf', label: 'rbf' },
    { value: 'sigmoid', label: 'sigmoid' },
    { value: 'precomputed', label: 'precomputed' },
  ],
  rfCriterionList: [
    { value: 'gini', label: 'gini' },
    { value: 'entropy', label: 'entropy' },
  ],
  gbcCriterionList: [
    { value: 'friedman_mse', label: 'friedman_mse' },
    { value: 'squared_error', label: 'squared_error' },
    { value: 'mse', label: 'mse' },
    { value: 'mae', label: 'mae' },
  ],
  rfMaxFeaturesList: [
    { value: 'auto', label: 'auto' },
    { value: 'sqrt', label: 'sqrt' },
    { value: 'log2', label: 'log2' },
  ],
  gbcMaxFeaturesList: [
    { value: 'none', label: 'None' },
    { value: 'auto', label: 'auto' },
    { value: 'sqrt', label: 'sqrt' },
    { value: 'log2', label: 'log2' },
  ],
  knnPList: [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
  ],
  knnAlgorithmList: [
    { value: 'auto', label: 'auto' },
    { value: 'ball_tree', label: 'ball_tree' },
    { value: 'kd_tree', label: 'kd_tree' },
    { value: 'brute', label: 'brute' },
  ],
  adcAlgorithmList: [
    { value: 'SAMME', label: 'SAMME' },
    { value: 'SAMME.R', label: 'SAMME.R' },
  ],
  lossList: [
    { value: 'deviance', label: 'deviance' },
    { value: 'exponential', label: 'exponential' },
  ],
  boosterList: [
    { value: 'none', label: 'None' },
    { value: 'gbtree', label: 'gbtree' },
    { value: 'gblinear', label: 'gblinear' },
    { value: 'dart', label: 'dart' },
  ],
  importanceTypeList: [
    { value: 'gain', label: 'gain' },
    { value: 'weight', label: 'weight' },
    { value: 'cover', label: 'cover' },
    { value: 'total_gain', label: 'total_gain' },
    { value: 'total_cover', label: 'total_cover' },
  ],
  nEstimatorsMarks: [
    {
      value: 10,
      label: '10',
    },
    {
      value: 1000,
      label: '1000',
    },
  ],
  nNeighborsMarks: [
    {
      value: 1,
      label: '10',
    },
    {
      value: 50,
      label: '50',
    },
  ],
  bcMaxFeaturesMarks: [
    {
      value: 1,
      label: '1',
    },
    {
      value: 10,
      label: '10',
    },
  ],
};

export default classificationStaticData;
