const initialState = {
  vars: {},
};

const configReducers = (state = initialState, action = {}) => {
  if (action.type === 'CONFIG_VARS_SET') {
    return {
      ...state,
      vars: action.payload,
    };
  }
  return state;
};

export default configReducers;
