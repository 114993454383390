import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getFeatureAccess = () => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getFeatureAccessRequest());
  dispatch(addLoader('getFeatureAccess'));

  return api
    .get({
      endpoint: endpoint.requestAccessService,
      path: `requestAccess`,
    })
    .then((res) => {
      if (res?.data) {
        const url = res?.data;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', `requestAccess.xlxs`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        dispatch(snackbarNotification('Url not found', 'error'));
      }
    })
    .catch((error) => {
      dispatch(getFeatureAccessError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getFeatureAccess'));
    });
};

export const getFeatureAccessRequest = () => {
  return {
    type: 'GET_FEATURE_ACCESS_REQUEST',
  };
};

export const getFeatureAccessSuccess = (data) => {
  return {
    type: 'GET_FEATURE_ACCESS_SUCCESS',
    payload: data,
  };
};

export const getFeatureAccessError = (error) => {
  return {
    type: 'GET_FEATURE_ACCESS_FAILURE',
    payload: error,
  };
};
