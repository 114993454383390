import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// mui
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';

DataGridTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  uniqRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checkboxSelection: PropTypes.bool,
  disableColumnSelector: PropTypes.bool,
  disableColumnFilter: PropTypes.bool,
  disableDensitySelector: PropTypes.bool,
  isSearchable: PropTypes.bool,
  debounceMs: PropTypes.number,
  emptyRowsText: PropTypes.string,
  pageSize: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  height: PropTypes.number,
  handleRowSelectable: PropTypes.func,
  handlers: PropTypes.shape({
    selectedRowIds: PropTypes.func,
  }),
};

DataGridTable.defaultProps = {
  checkboxSelection: true,
  disableColumnSelector: true,
  disableColumnFilter: true,
  disableDensitySelector: true,
  debounceMs: 500,
  emptyRowsText: 'No rows found',
  isSearchable: true,
  pageSize: 5,
  rowsPerPageOptions: [5],
  height: 400,
  handleRowSelectable: () => {},
};

export default function DataGridTable({
  rows: gridRows,
  columns,
  uniqRowId,
  checkboxSelection,
  disableColumnSelector,
  disableColumnFilter,
  disableDensitySelector,
  debounceMs,
  emptyRowsText,
  isSearchable,
  pageSize,
  rowsPerPageOptions,
  height,
  handleRowSelectable,
  handlers,
}) {
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(gridRows);
  }, [gridRows]); // eslint-disable-line react-hooks/exhaustive-deps

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = gridRows?.filter((row) => {
      return Object.keys(row)?.some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  if (!uniqRowId || !Array.isArray(rows) || !Array.isArray(columns)) return null;

  const boderStyle = '1px solid rgba(224, 224, 224, 1)';

  return (
    <Box sx={{ backgroundColor: '#FFF', borderRadius: '8px', boxShadow: '0px 2px 16px 2px rgb(133 133 133 / 5%)' }}>
      {isSearchable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: boderStyle,
            borderLeft: boderStyle,
            borderRight: boderStyle,
            borderRadius: '4px 4px 0 0',
          }}
          py={1}
          pr={1}
        >
          <TextField
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              requestSearch(e.target.value);
            }}
            placeholder="Search here"
            inputProps={{
              style: {
                padding: '4.5px 14px',
              },
            }}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" color="action" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: searchText ? 'visible' : 'hidden',
                    borderRadius: '57%',
                    paddingRight: '1px',
                    margin: '0',
                    fontSize: '1.25rem',
                  }}
                  onClick={(e) => {
                    setSearchText('');
                    setRows(gridRows);
                  }}
                >
                  <ClearIcon fontSize="small" color="action" />
                </IconButton>
              ),
            }}
            sx={{
              width: { xs: 1, sm: 'auto' },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              '& .MuiSvgIcon-root': {
                mr: 0.5,
              },
              '& .css-7sxom3-MuiDataGrid-root': {
                borderRadius: '0 0 4px 4px',
              },
            }}
          />
        </Box>
      )}
      <Box sx={{ height: height, width: '100%', backgroundColor: '#FFF' }}>
        <DataGrid
          rows={rows}
          getRowHeight={() => 'auto'}
          columns={columns}
          getRowId={(row) => row[uniqRowId]}
          pageSize={pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          checkboxSelection={checkboxSelection}
          isRowSelectable={handleRowSelectable}
          disableColumnSelector={disableColumnSelector}
          disableColumnFilter={disableColumnFilter}
          disableDensitySelector={disableDensitySelector}
          experimentalFeatures={{ newEditingApi: true }}
          showColumnRightBorder
          showCellRightBorder
          density="standard"
          sx={{
            '& .MuiDataGrid-columnSeparator--sideRight': {
              visibility: 'hidden',
            },
            '&.MuiDataGrid-root': {
              border: '1px solid rgba(224, 224, 224, 1)',
              borderRadius: `${isSearchable ? '0 0' : '4px 4px'} 4px 4px`,
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F8F8F8',
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {emptyRowsText}
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Local filter returns no result
              </Stack>
            ),
          }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: debounceMs },
            },
          }}
          onSelectionModelChange={(ids) => {
            handlers.selectedRowIds(ids);
          }}
        />
      </Box>
    </Box>
  );
}
