import { Box } from '@mui/system';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { ModelCarrierDashboard, ModelCarrierCarModels, Carriers } from 'modules';
const ModelCarrierView = ({ isActiveTab, setIsActiveTab, handleChange, value, tableData}) => {
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Model Carrier" value="1" />
              <Tab label="Car Models" value="2" />
              <Tab label="Carriers" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ModelCarrierDashboard />
          </TabPanel>
          <TabPanel value="2">
            <ModelCarrierCarModels />
          </TabPanel>
          <TabPanel value="3">
            <Carriers />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default ModelCarrierView;
