
import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const getModelCarrierTableData = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getModelCarrierTableDataRequest(reqParams));
  dispatch(addLoader('getModelCarrierTableData'));

  const { carrier_list,model_list } = reqParams;

  const data = {
    carrier_list,
    model_list,
  };

  return api
    .post({
      endpoint: endpoint.modelCarrierSecond,
      path: 'modelCarrier/placement',
      data,
    })
    .then((res) => {
      dispatch(getModelCarrierTableDataSuccess(res?.data));
      dispatch(snackbarNotification(res?.data?.message || 'Model carrier table data fetched successfully', 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(getModelCarrierTableDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getModelCarrierTableData'));
    });
};

export const getModelCarrierTableDataRequest = (params) => {
  return {
    type: 'GET_MODEL_CARRIER_TABLE_DATA_REQUEST',
    payload: params,
  };
};

export const getModelCarrierTableDataSuccess = (data) => {
  return {
    type: 'GET_MODEL_CARRIER_TABLE_DATA_SUCCESS',
    payload: data,
  };
};

export const getModelCarrierTableDataError = (error) => {
  return {
    type: 'GET_MODEL_CARRIER_TABLE_DATA_FAILURE',
    payload: error,
  };
};
