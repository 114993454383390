import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
//mui
import {
  Box,
  Button,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
  FormControlLabel,
  TextField,
  Select,
  Slider,
  FormHelperText,
  TextareaAutosize,
  Autocomplete,
  Chip,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//app
import * as utils from 'utils';
import { consts } from 'globalsData';
import { BootstrapTooltip, FileDropZone } from 'components';
import '../../Pages/Home/Home.css';

AddEditClusteringFormView.propTypes = {
  inputDataFileNameFromApi: PropTypes.string,
  predictionFileNameFromApi: PropTypes.string,
  fields: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  algorithms: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  initList: PropTypes.array.isRequired,
  watchAlgorithm: PropTypes.string.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  summaryDeteails: PropTypes.object.isRequired,
  isNoFilesUploaded: PropTypes.object.isRequired,
  importanceTypeList: PropTypes.array.isRequired,
  isValidFileFormats: PropTypes.object.isRequired,
  isValidFileSizes: PropTypes.object.isRequired,
  fileUploadFieldList: PropTypes.array.isRequired,
  renderHyperParameters: PropTypes.func.isRequired,
  isRedirectFromEdaCharts: PropTypes.bool.isRequired,
  isLastProcessDataInprogress: PropTypes.bool.isRequired,
  agglomative_n_cluster: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handlers: PropTypes.shape({
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    viewLastProcessData: PropTypes.func.isRequired,
  }),
};

export function AddEditClusteringFormView({
  inputDataFileNameFromApi,
  predictionFileNameFromApi,
  fields,
  columns,
  handlers,
  formProps,
  algorithms,
  agglomative_n_cluster,
  watchAlgorithm,
  summaryDeteails,
  AgglomotiveMetricClusteringList,
  handleFileUpload,
  getFileFieldTitle,
  isNoFilesUploaded,
  isValidFileFormats,
  isValidFileSizes,
  fileUploadFieldList,
  renderHyperParameters,
  isRedirectFromEdaCharts,
  isLastProcessDataInprogress,
  AgglomotiveLinkageClusteringList,
  kmeans_n_cluster,
  KmeansInitList,
  KmeansAlgorithmList,
  KmodesInitList,
  kmod_n_cluster,
  inputFormDataFromApi,
}) {
  const { register, handleSubmit, errors, control } = formProps;
  const modelMetricsFields = utils.form.getFieldProp(fields, 'idCols');
  const editFormOverFlow = inputDataFileNameFromApi ? { overflowX: 'hidden', overflowY: 'auto' } : {};
  const HelperText = ({ helperTextProp }) => {
    return (
      <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
        {helperTextProp}
      </FormHelperText>
    );
  };

  return (
    <>
    {!inputDataFileNameFromApi && (
      <Box sx={{ backgroundColor: '#D6E7FB', borderRadius: '18px', marginBottom: '10px', padding: '15px', textAlign: 'left', fontSize: '14px' }}>
        <Typography fontSize={14} textAlign="left" pb={0.5}>
          Data Guidelines
        </Typography>
        <ul style={{ margin: '0', paddingLeft: '30px' }}>
        <li style={{ fontSize: '14px' }}>Select the appropriate clustering algorithm and model hyperparameters according to your data type and use case.</li>
        <li style={{ fontSize: '14px' }}>Remove irrelevant variables before uploading.</li>
      </ul>
      </Box>
    )}
    <Box sx={{ backgroundColor: '#fff', borderRadius: '18px', boxShadow: '0px 2px 5px 2px rgb(133 133 133 / 5%)', ...editFormOverFlow }}>
      <Box px={4} py={2}>
        <Box
          id="clustering-form"
          mt={2}
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(handlers.onSubmit)}
          sx={
            inputDataFileNameFromApi
              ? {}
              : {
                  overflowY: 'auto',
                  height: `${
                    watchAlgorithm?.toLowerCase() !== 'auto_n_cluster' && watchAlgorithm !== 'linearRegression' ? '100%' : 'auto'
                  }`,
                  overflow: 'auto',
                }
          }
        >
          <Box>
            {inputDataFileNameFromApi && watchAlgorithm?.toLowerCase() === 'auto_n_cluster' && (
              <HelperText helperTextProp="You are not allowed to edit the model hyperparameters as you have selected 'Auto N Clusters' for algorithm." />
            )}
          </Box>
          <Grid container spacing={2} display="flex" alignItems="center">
            {inputDataFileNameFromApi || predictionFileNameFromApi ? (
              <>
                {inputDataFileNameFromApi && (
                  <Grid item xs={12}>
                    <Typography textAlign="left" pb={0.5} fontSize={14}>
                      Input Data
                    </Typography>
                    <Box border="2px dashed #767676" px={1} py={0.5} borderRadius="6px">
                      {inputDataFileNameFromApi}
                    </Box>
                  </Grid>
                )}
                {predictionFileNameFromApi && (
                  <Grid item xs={12}>
                    <Typography textAlign="left" pb={0.5} fontSize={14}>
                      Prediciton Data
                    </Typography>
                    <Box border="2px dashed #767676" px={1} py={0.5} borderRadius="6px">
                      {predictionFileNameFromApi}
                    </Box>
                  </Grid>
                )}
              </>
            ) : (
              <>
                {fileUploadFieldList.map((item, i) => (
                  <Grid item xs={12} key={item.field}>
                    <Box id="uploadInputFile" sx={{ width: '350px' }}>
                      <Typography textAlign="left" pb={0.5} fontSize={14}>
                        {isRedirectFromEdaCharts && i === 0 ? 'Input Data' : getFileFieldTitle(item)}
                      </Typography>
                      <Box border="1px dashed #cfcaca" px={1} py={0.5} borderRadius="6px">
                        {isRedirectFromEdaCharts && i === 0 ? (
                          <>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                              <Grid item>
                                <Typography fontSize={14}>{summaryDeteails?.input_data?.fileName}</Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            {!item.file?.name ? (
                              <FileDropZone field={item.field} handleFileUpload={handleFileUpload} />
                            ) : (
                              <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                <Grid item>
                                  <Typography fontSize={14}>{item.file?.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    color="inherit"
                                    aria-label="delete file"
                                    onClick={() => handlers.onDelete(item.field)}
                                    edge="start"
                                    sx={{
                                      marginLeft: 1,
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </Box>
                      {isValidFileFormats.inputDataFile &&
                        isValidFileSizes.inputDataFile &&
                        item.field === fileUploadFieldList[0].field &&
                        isNoFilesUploaded.inputDataFile &&
                        !item.file && <HelperText helperTextProp="Please Attach (.csv or .xlsx) Input Data File" />}
                      {!isValidFileFormats.inputDataFile && item.field === fileUploadFieldList[0].field && (
                        <HelperText helperTextProp="Input data file format should be (.csv or .xlsx) and should not contains multiple (.) dots in file name" />
                      )}
                      {!isValidFileFormats.predictionDataFile && item.field === fileUploadFieldList[1].field && (
                        <HelperText helperTextProp="Prediction data file format should be (.csv or .xlsx) and should not contains multiple (.) dots in file name" />
                      )}
                      {!isValidFileSizes.inputDataFile && item.field === fileUploadFieldList[0].field && (
                        <HelperText
                          helperTextProp={`Input data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                        />
                      )}
                      {!isValidFileSizes.predictionDataFile && item.field === fileUploadFieldList[1].field && (
                        <HelperText
                          helperTextProp={`Prediction data file size must be less than ${consts.ACCELERATORS_MAX_FILE_SIZE / 1000000}MB`}
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={12}>
              <Box id="algorithm">
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Algorithm
                  </Typography>
                  <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                    <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_SELECTALGORITHM} placement={'bottom'}>
                      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                    </BootstrapTooltip>
                  </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} error={!!errors?.algorithm?.message}>
                  <Select
                    {...register('algorithm')}
                    {...utils.form.getFieldProp(fields, 'algorithm')}
                    sx={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {algorithms.map((method) => (
                      <MenuItem value={method.value} key={method.value} sx={{ fontSize: '14px' }}>
                        {method.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors?.algorithm?.message && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                      {errors?.algorithm?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box id="idCols" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: 'left' }}>
                      <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        Id Column
                      </Typography>
                      <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                        <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_IDCOLUMNS} placement={'bottom'}>
                          <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                        </BootstrapTooltip>
                      </Box>
                    </Box>
                    <Controller
                      name={modelMetricsFields?.name}
                      control={control}
                      defaultValue={modelMetricsFields?.defaultValue}
                      // disabled={true}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => {
                        return (
                          <Autocomplete
                            {...field}
                            value={field.value}
                            disablePortal={false}
                            disabled={!!inputFormDataFromApi?.idCols}
                            filterSelectedOptions
                            multiple
                            size="medium"
                            getOptionLabel={(option) => option[modelMetricsFields?.optionLabel]}
                            isOptionEqualToValue={(option, value) =>
                              option[modelMetricsFields?.optionLabel] === value[modelMetricsFields?.optionLabel]
                            }
                            onChange={(event, values) => {
                              field.onChange(values);
                            }}
                            options={columns || ''}
                            renderTags={(value, getTagProps) => {
                              return value?.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  variant="outlined"
                                  size="small"
                                  label={option[modelMetricsFields?.optionLabel]}
                                />
                              ));
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                error={!!error}
                                helperText={error?.message}
                                id={modelMetricsFields?.id}
                                name={modelMetricsFields?.name}
                                {...params}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {watchAlgorithm?.toLowerCase() !== 'auto_n_cluster' && (
            <>
              <Box my={2} id="hyperParametersAccordion">
                <Typography fontSize={14} fontWeight={600} textAlign="left" color="#5a5a5a" sx={{ py: 2 }}>
                  Model Hyper Parameters
                </Typography>

                {renderHyperParameters(algorithms[0].value) && (
                  <Box id="agglomotiveClustering">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="agglomative_n_cluster">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Number of Clusters (n_clusters)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_AGGLOMERATIVE_NCLUSTERS} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider
                                {...register('agglomative_n_cluster')}
                                {...utils.form.getFieldProp(fields, 'agglomative_n_cluster')}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {agglomative_n_cluster}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="agglomerative">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Metric
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_AGGLOMERATIVE_METRIC} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.agglomerativeMetricListAlgorithm?.message}>
                            <Select
                              {...register('agglomerativeMetricListAlgorithm')}
                              {...utils.form.getFieldProp(fields, 'agglomerativeMetricListAlgorithm')}
                            >
                              {AgglomotiveMetricClusteringList.map((algorithm) => (
                                <MenuItem value={algorithm.value} key={algorithm.value}>
                                  {algorithm.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.agglomerativeMetricListAlgorithm?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.agglomerativeMetricListAlgorithm?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="agglomerative">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Linkage
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_AGGLOMERATIVE_LINKAGE} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.agglomerativeLinkageListAlgorithm?.message}>
                            <Select
                              {...register('agglomerativeLinkageListAlgorithm')}
                              {...utils.form.getFieldProp(fields, 'agglomerativeLinkageListAlgorithm')}
                            >
                              {AgglomotiveLinkageClusteringList.map((algorithm) => (
                                <MenuItem value={algorithm.value} key={algorithm.value}>
                                  {algorithm.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.agglomerativeLinkageListAlgorithm?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.agglomerativeLinkageListAlgorithm?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {renderHyperParameters(algorithms[1].value) && (
                  <Box id="kmeans">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="kmeans_n_cluster">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Number of Clusters (n_clusters)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_KMEANS_NCLUSTERS} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider {...register('kmeans_n_cluster')} {...utils.form.getFieldProp(fields, 'kmeans_n_cluster')} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {kmeans_n_cluster}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="kmeans-Initialize-list-algorithm">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Initialize
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_KMEANS_INIT} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Typography>
                          <FormControl sx={{ width: '100%' }}>
                            <RadioGroup
                              {...register('kmeansInitListAlgorithm')}
                              {...utils.form.getFieldProp(fields, 'kmeansInitListAlgorithm')}
                            >
                              {KmeansInitList.map((item) => (
                                <FormControlLabel key={item.value} control={<Radio />} {...item} {...register('kmeansInitListAlgorithm')} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="tolerance">
                          <Typography textAlign="left" pb={0.5} fontSize={14}>
                            Tolerance (tol)
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_KMEANS__TOL} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Typography>

                          <TextField
                            {...register('tol')}
                            {...utils.form.getFieldProp(fields, 'tol')}
                            error={!!errors?.tol?.message}
                            inputProps={{ step: '0.01', min: '0.00001', max: '1' }}
                          />
                          {!!errors?.tol?.message && (
                            <FormHelperText
                              sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}
                            >
                              {errors?.tol?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="kmeans_algorithm">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Algorithm
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_KMEANS_ALGORITHM} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.agglomerative?.message}>
                            <Select {...register('kmeans_algorithm')} {...utils.form.getFieldProp(fields, 'kmeans_algorithm')}>
                              {KmeansAlgorithmList.map((algorithm) => (
                                <MenuItem value={algorithm.value} key={algorithm.value}>
                                  {algorithm.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.kmeans_algorithm?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.kmeans_algorithm?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {renderHyperParameters(algorithms[2].value) && (
                  <Box id="Kmod">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box id="kmod">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Number of Clusters (n_clusters)
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_KMODE_NCLUSTERS} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <Grid container sx={{ border: '1px solid #e5e5ed', padding: '5px', borderRadius: '5px' }}>
                            <Grid item xs={11}>
                              <Slider {...register('kmod_n_cluster')} {...utils.form.getFieldProp(fields, 'kmod_n_cluster')} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="subtitle2" pt={0.5}>
                                {kmod_n_cluster}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box id="kmod">
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography textAlign="left" pb={0.5} fontSize={14} sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                              Initialize
                            </Typography>
                            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', px: 1, cursor: 'pointer' }}>
                              <BootstrapTooltip title={consts.TOOLTIP.CLUSTERING_KMODE_INIT} placement={'bottom'}>
                                <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
                              </BootstrapTooltip>
                            </Box>
                          </Box>
                          <FormControl sx={{ width: '100%' }} error={!!errors?.kmodesInitList?.message}>
                            <Select {...register('kmodesInitList')} {...utils.form.getFieldProp(fields, 'kmodesInitList')}>
                              {KmodesInitList.map((algorithm) => (
                                <MenuItem value={algorithm.value} key={algorithm.value}>
                                  {algorithm.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors?.kmodesInitList?.message && (
                              <FormHelperText sx={{ color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                                {errors?.kmodesInitList?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box id="justificationRemarks" mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box id="justificationRemarks">
                  <Typography textAlign="left" pb={0.5} fontSize={14}>
                    Justification Remarks
                  </Typography>
                  <FormControl sx={{ width: '100%' }} error={!!errors?.justificationRemarks?.message}>
                    <TextareaAutosize
                      {...register('justificationRemarks')}
                      {...utils.form.getFieldProp(fields, 'justificationRemarks')}
                      className="textAreaStyles"
                    />

                    {!!errors?.justificationRemarks?.message && (
                      <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors.justificationRemarks.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box id="actionsButtons" sx={{ background: '#d2d2d2' }} px={4} py={2}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                borderRadius: '99px',
                p: '0.4rem 1.5rem',
              }}
              type="submit"
              onClick={handleSubmit(handlers.onSubmit, handlers.onError)}
              disabled={isLastProcessDataInprogress}
            >
              {`${inputDataFileNameFromApi ? 'Update Analysis' : 'Analyze'}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
   </>
  );
}
