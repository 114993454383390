import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

//app
import { getFeatureAccess } from 'stores';

//mui
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

AccessControl.propTypes = {
  userInfo: PropTypes.object,
  featureName: PropTypes.string.isRequired,
  isUserInfoLoading: PropTypes.bool.isRequired,
};

export default function AccessControl({ userInfo, featureName, isUserInfoLoading, children }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const featureList = userInfo?.featureList;
  const isAdmin = userInfo?.roleName?.toLowerCase() === 'admin';
  const isActive = userInfo?.status?.toLowerCase() !== 'deleted';

  const handleClick = () => {
    dispatch(getFeatureAccess());
  };

  // abort;
  if (!featureName || isUserInfoLoading) return null;

  if (!userInfo || !isActive || (!isAdmin && !featureList?.includes(featureName))) {
    return (
      <Box textAlign="center" pr="65PX" mt="5%">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize="50px"
              variant="body1"
              color={(theme) => theme.palette.otherColor.grey90}
            >
              Access Denied
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontFamily="Roboto" variant="h3" fontSize="16px" color={(theme) => theme.palette.primary.textColor}>
              You don't have permission to view {featureName}.
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: (theme1) => theme1.palette.primary.white,
                color: (theme1) => theme1.palette.primary.main,
                boxShadow: 'none',
                textTransform: 'capitalize',
                fontWeight: '700',
                fontSize: '1rem',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '99px',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.primary.white,
                },
              }}
              onClick={handleClick}
            >
              Request Access
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return children;
}
