import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import Exporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';

//app
import { Breadcrumbs, Charts, DataGridCellExpand, DataGridTable, Layout } from 'components';
import Summary from './Summary';
import * as utils from 'utils';
import { consts, useMedia } from 'globalsData';

//mui
import { Box, Button, Grid, Typography, Card, CardContent, IconButton } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import Skeleton from '@mui/material/Skeleton';

AcceleratorChartsView.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  selecedAccelerator: PropTypes.string.isRequired,
  modelMetricsArr: PropTypes.array.isRequired,
  classCountBarGraphPlotsOptions: PropTypes.object.isRequired,
  summaryDeteails: PropTypes.object.isRequired,
  isConnect: PropTypes.bool.isRequired,
  handlers: PropTypes.shape({
    expadChart: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDownloadCsv: PropTypes.func.isRequired,
    handleDownloadHtml: PropTypes.func.isRequired,
    handleDownloadPdf: PropTypes.func.isRequired,
  }),
};

HighchartsHeatmap(Highcharts);
HighchartsMore(Highcharts);
Exporting(Highcharts);

export function AcceleratorChartsView({
  breadcrumbs,
  status,
  selecedAccelerator,
  modelMetricsArr,
  bivariateAnalyisCategoricalPlotsOptions,
  numericalVariablesLogScaleBarPlotOptions,
  missingValuesBarGraphOptions,
  inputDataHistogramOptions,
  numericalvariablesPirorScalingBoxPlotOptions,
  numericalvariablesPostScalingBoxPlotOptions,
  hypothesisTestingBarGraphOptions,
  vifBarGraphOptions,
  outliersBoxplotOptions,
  outliersCountBoxPlotOptions,
  bivariateAnalyisNumericalPlotsOptions,
  univariateAnalysisPlotsOptions,
  skewnessBarPlotOptions,
  numericalVariablesHeatmapOptions,
  featureImportanceBarGraphPlotsOptions,
  classCountBarGraphPlotsOptions,
  aucPlotslPlotsOptions,
  confusionMatrixPlotsOptions,
  summaryDeteails,
  predictionPlotOptions,
  residualsPlotOptions,
  handlers,
  yearlyDistributionPlots,
  edaTrendLinegraph,
  ecdfLinegraph,
  trendLinegraph,
  audio,
  textDetails,
  handleDownloadAudioFiles,
  clusteringSizePie,
  elbowCurve,
  featureDisTributionBarPlot,
  silhouetteScorePlots,
  tsnePlot,
}) {
  const { mobile, tablet } = useMedia();
  // const colors = Highcharts.getOptions().colors.map((color) => Highcharts.color(color).setOpacity(0.9).get());
  const acceleratorType = summaryDeteails?.type;

  const ChartContent = ({ chartOptions }) => {
    return (
      <Grid item key={chartOptions?.name} xs={12} sm={6}>
        <Card sx={{ boxShadow: '0px 2px 16px 2px rgb(133 133 133 / 25%)' }}>
          <CardContent px={1}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={11}>
                <Typography fontSize={16} fontWeight={600} variant="subtitle1">
                  {chartOptions?.title}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton sx={{ cursor: 'pointer' }} aria-label="expand-chart" onClick={() => handlers.expadChart(chartOptions)}>
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
            </Grid>
            {chartOptions?.EDACharts?.length && <Charts chartOptions={chartOptions?.EDACharts[0]} />}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const capitalizeFirstLetter = (str) => {
    // converting first letter to uppercase
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const columns = utils.generic.isValidArray(modelMetricsArr, true)
    ? Object.keys(modelMetricsArr[0])?.map((c, i, arr) => ({
        field: c,
        headerName: c
          ?.split(/([A-Z][a-z]+)/)
          ?.map((x) => capitalizeFirstLetter(x))
          ?.filter(Boolean)
          ?.join(' '),

        flex: arr?.length / 100,
        renderCell: (params) => <DataGridCellExpand value={params.value || ''} whiteSpace="break-spaces" />,
        ...(mobile || tablet ? { minWidth: c === 'algorithm' ? 200 : 80 } : { minWidth: c === 'algorithm' ? 200 : 100 }),
      }))
    : [];

  const rows = utils.generic.isValidArray(modelMetricsArr, true) ? modelMetricsArr : [];

  // const getAccType = () => {
  //   if (acceleratorType === 'eda') return `${consts.ACCELERATORS.EXPLORATORY_DATA_ANALYSIS} Charts`;
  //   if (acceleratorType === consts.CLASSIFICATION) return `${consts.ACCELERATORS.CLASSIFICATION} Charts`;
  //   if (acceleratorType === consts.REGRESSION) return `${consts.ACCELERATORS.REGRESSION} Charts`;
  //   return '';
  // };

  return (
    <Layout>
      {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
      <Box py={2}>
        <Summary
          acceleratorType={selecedAccelerator}
          summaryDeteails={summaryDeteails}
          status={status}
          onEdit={handlers.handleEdit}
          onDownloadCsv={handlers.handleDownloadCsv}
          onDownloadHtml={handlers.handleDownloadHtml}
          onLearningTypeClick={handlers.handleLearningTypeClick}
          audio={audio}
          textDetails={textDetails}
          handleDownloadAudioFiles={handleDownloadAudioFiles}
        />
        {selecedAccelerator?.toLowerCase() !== consts.TEXT_TO_SPEECH && selecedAccelerator !== 'Speechtotext' && (
          <Box mt={4}>
            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
              {summaryDeteails?.input_data?.algorithm !== 'all' && (
                <Grid item>
                  <Typography fontSize={24} fontWeight={600} color={(theme) => theme.palette.otherColor.textGrey2}>
                    Data Visualization
                    {/* {getAccType()} */}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Button
                  disabled={status?.toLowerCase() !== consts.acceleratorStatus.complete?.toLowerCase()}
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.white,
                  }}
                  startIcon={
                    <PictureAsPdfOutlinedIcon
                      sx={{
                        color: (theme) =>
                          status?.toLowerCase() !== consts.acceleratorStatus.complete?.toLowerCase()
                            ? theme.palette.otherColor.textGrey3
                            : theme.palette.primary.main,
                      }}
                    />
                  }
                  onClick={handlers.handleDownloadPdf}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    Download PDF
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {!status?.toLowerCase()?.includes(consts.acceleratorStatus.errorStatus) ? (
              <Box mt={2}>
                {status?.toLowerCase() === consts.acceleratorStatus.complete?.toLowerCase() ? (
                  <>
                    {selecedAccelerator?.toLowerCase() === consts.ACCELERATORS.EXPLORATORY_DATA_ANALYSIS?.toLowerCase() && (
                      <Grid container spacing={2}>
                        {bivariateAnalyisCategoricalPlotsOptions?.name && (
                          <ChartContent chartOptions={bivariateAnalyisCategoricalPlotsOptions} />
                        )}
                        {bivariateAnalyisNumericalPlotsOptions?.name && (
                          <ChartContent chartOptions={bivariateAnalyisNumericalPlotsOptions} />
                        )}
                        {univariateAnalysisPlotsOptions?.name && <ChartContent chartOptions={univariateAnalysisPlotsOptions} />}
                        {numericalVariablesLogScaleBarPlotOptions?.name && (
                          <ChartContent chartOptions={numericalVariablesLogScaleBarPlotOptions} />
                        )}
                        {missingValuesBarGraphOptions?.name && <ChartContent chartOptions={missingValuesBarGraphOptions} />}
                        {inputDataHistogramOptions?.name && <ChartContent chartOptions={inputDataHistogramOptions} />}
                        {numericalvariablesPirorScalingBoxPlotOptions?.name && (
                          <ChartContent chartOptions={numericalvariablesPirorScalingBoxPlotOptions} />
                        )}
                        {numericalvariablesPostScalingBoxPlotOptions?.name && (
                          <ChartContent chartOptions={numericalvariablesPostScalingBoxPlotOptions} />
                        )}

                        {hypothesisTestingBarGraphOptions?.name && <ChartContent chartOptions={hypothesisTestingBarGraphOptions} />}
                        {vifBarGraphOptions?.name && <ChartContent chartOptions={vifBarGraphOptions} />}
                        {outliersBoxplotOptions?.name && <ChartContent chartOptions={outliersBoxplotOptions} />}
                        {outliersCountBoxPlotOptions?.name && <ChartContent chartOptions={outliersCountBoxPlotOptions} />}
                        {skewnessBarPlotOptions?.name && <ChartContent chartOptions={skewnessBarPlotOptions} />}
                        {numericalVariablesHeatmapOptions?.name && <ChartContent chartOptions={numericalVariablesHeatmapOptions} />}
                      </Grid>
                    )}
                    {/* eda unsupervised graph code starts */}
                    {selecedAccelerator?.toLowerCase() === consts.ACCELERATORS.EXPLORATORY_DATA_ANALYSIS_UNSUPERVISED?.toLowerCase() && (
                      <Grid container spacing={2}>
                        {bivariateAnalyisCategoricalPlotsOptions?.name && (
                          <ChartContent chartOptions={bivariateAnalyisCategoricalPlotsOptions} />
                        )}
                        {bivariateAnalyisNumericalPlotsOptions?.name && (
                          <ChartContent chartOptions={bivariateAnalyisNumericalPlotsOptions} />
                        )}
                        {univariateAnalysisPlotsOptions?.name && <ChartContent chartOptions={univariateAnalysisPlotsOptions} />}
                        {numericalVariablesLogScaleBarPlotOptions?.name && (
                          <ChartContent chartOptions={numericalVariablesLogScaleBarPlotOptions} />
                        )}
                        {missingValuesBarGraphOptions?.name && <ChartContent chartOptions={missingValuesBarGraphOptions} />}
                        {inputDataHistogramOptions?.name && <ChartContent chartOptions={inputDataHistogramOptions} />}
                        {numericalvariablesPirorScalingBoxPlotOptions?.name && (
                          <ChartContent chartOptions={numericalvariablesPirorScalingBoxPlotOptions} />
                        )}
                        {numericalvariablesPostScalingBoxPlotOptions?.name && (
                          <ChartContent chartOptions={numericalvariablesPostScalingBoxPlotOptions} />
                        )}

                        {hypothesisTestingBarGraphOptions?.name && <ChartContent chartOptions={hypothesisTestingBarGraphOptions} />}
                        {vifBarGraphOptions?.name && <ChartContent chartOptions={vifBarGraphOptions} />}
                        {outliersBoxplotOptions?.name && <ChartContent chartOptions={outliersBoxplotOptions} />}
                        {outliersCountBoxPlotOptions?.name && <ChartContent chartOptions={outliersCountBoxPlotOptions} />}
                        {skewnessBarPlotOptions?.name && <ChartContent chartOptions={skewnessBarPlotOptions} />}
                        {numericalVariablesHeatmapOptions?.name && <ChartContent chartOptions={numericalVariablesHeatmapOptions} />}
                      </Grid>
                    )}
                    {/* eda unsupervised graph code ends */}
                    {selecedAccelerator?.toLowerCase() === consts.ACCELERATORS.CLASSIFICATION?.toLowerCase() && (
                      <Grid container spacing={2}>
                        {featureImportanceBarGraphPlotsOptions?.name && (
                          <ChartContent chartOptions={featureImportanceBarGraphPlotsOptions} />
                        )}
                        {classCountBarGraphPlotsOptions?.name && <ChartContent chartOptions={classCountBarGraphPlotsOptions} />}
                        {aucPlotslPlotsOptions?.name && <ChartContent chartOptions={aucPlotslPlotsOptions} />}
                        {confusionMatrixPlotsOptions?.name && <ChartContent chartOptions={confusionMatrixPlotsOptions} />}
                        {modelMetricsArr?.length > 0 && (
                          <Grid item xs={12}>
                            <Box py={2} style={{ height: 800 }}>
                              <DataGridTable
                                checkboxSelection={false}
                                columns={columns}
                                rows={rows}
                                uniqRowId={'algorithm'}
                                isSearchable={false}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                height={450}
                                emptyRowsText="No record(s) found"
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {selecedAccelerator?.toLowerCase() === consts.ACCELERATORS.REGRESSION?.toLowerCase() && (
                      <Grid container spacing={2}>
                        {featureImportanceBarGraphPlotsOptions?.name && (
                          <ChartContent chartOptions={featureImportanceBarGraphPlotsOptions} />
                        )}
                        {predictionPlotOptions?.name && <ChartContent chartOptions={predictionPlotOptions} />}
                        {residualsPlotOptions?.name && <ChartContent chartOptions={residualsPlotOptions} />}
                        {modelMetricsArr?.length > 0 && (
                          <Grid item xs={12}>
                            <Box py={2} style={{ height: 800 }}>
                              <DataGridTable
                                checkboxSelection={false}
                                columns={columns}
                                rows={rows}
                                uniqRowId={'algorithm'}
                                isSearchable={false}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                height={450}
                                emptyRowsText="No record(s) found"
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {/* timeseries starts here */}
                    {selecedAccelerator?.toLowerCase() === consts.ACCELERATORS.TIME_SERIES?.toLowerCase() && (
                      <Grid container spacing={2}>
                        {yearlyDistributionPlots?.name && <ChartContent chartOptions={yearlyDistributionPlots} />}
                        {edaTrendLinegraph?.name && <ChartContent chartOptions={edaTrendLinegraph} />}
                        {ecdfLinegraph?.name && <ChartContent chartOptions={ecdfLinegraph} />}
                        {trendLinegraph?.name && <ChartContent chartOptions={trendLinegraph} />}
                      </Grid>
                    )}
                    {/* timeseries ends here */}
                    {selecedAccelerator?.toLowerCase() === consts.ACCELERATORS.CLUSTERING?.toLowerCase() && (
                      <Grid container spacing={2}>
                        {clusteringSizePie?.name && <ChartContent chartOptions={clusteringSizePie} />}
                        {elbowCurve?.name && <ChartContent chartOptions={elbowCurve} />}
                        {featureDisTributionBarPlot?.name && <ChartContent chartOptions={featureDisTributionBarPlot} />}
                        {silhouetteScorePlots?.name && <ChartContent chartOptions={silhouetteScorePlots} />}
                        {tsnePlot?.name && <ChartContent chartOptions={tsnePlot} />}
                      </Grid>
                    )}
                  </>
                ) : (
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Skeleton variant="rectangular" width={'100%'} height={200} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Skeleton variant="rectangular" width={'100%'} height={200} />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ) : (
              <Box mt={8} textAlign="center">
                <Typography variant="h6" fontWeight={600} sx={{ color: (theme) => theme.palette.otherColor.errorText }}>
                  {status}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Layout>
  );
}
