import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const fetchSourceAndTargetLanguages = (reqParams) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(fetchSourceAndTargetLanguagesRequest(reqParams));


  return api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'language',
      params: reqParams,
    })
    .then((res) => {
      dispatch(fetchSourceAndTargetLanguagesSuccess(res?.data));
   //   dispatch(snackbarNotification(res?.data?.message, 'success'));
      return res;
    })
    .catch((error) => {
      dispatch(fetchSourceAndTargetLanguagesError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('fetchSourceAndTargetLanguages'));
    });
};

export const fetchSourceAndTargetLanguagesRequest = (params) => {
  return {
    type: 'FETCH_SOURCE_TARGET_LANGUAGES_REQUEST',
    payload: params,
  };
};

export const fetchSourceAndTargetLanguagesSuccess = (data) => {
  return {
    type: 'FETCH_SOURCE_TARGET_LANGUAGES_SUCCESS',
    payload: data,
  };
};

export const fetchSourceAndTargetLanguagesError = (error) => {
  return {
    type: 'FETCH_SOURCE_TARGET_LANGUAGES_FAILURE',
    payload: error,
  };
};
