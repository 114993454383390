import { addLoader, removeLoader } from 'stores';
import * as utils from 'utils';

export const getGraphData = (params) => (dispatch, getState) => {
  const {
    config: {
      vars: { endpoint, loginRedirectUrl },
    },
  } = getState();

  dispatch(getGraphDataRequest());
  dispatch(addLoader({ key: 'getGraphData', message: 'Loading Graphs...' }));

  return utils.api
    .get({
      endpoint: endpoint.acceleratorService,
      path: 'getGraphData',
      params: { graphName: params?.graphName, trackerId: params?.trackerId },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(getGraphDataError(error));
      utils.api.handleUnauthorized(error, dispatch, loginRedirectUrl);

      return error;
    })
    .finally(() => {
      dispatch(removeLoader('getGraphData'));
    });
};

export const getGraphDataRequest = () => {
  return {
    type: 'GET_GRAPH_DATA_REQUEST',
  };
};

export const getGraphDataSuccess = (data) => {
  return {
    type: 'GET_GRAPH_DATA_SUCCESS',
    payload: data,
  };
};

export const getGraphDataError = (error) => {
  return {
    type: 'GET_GRAPH_DATA_FAILURE',
    payload: error,
  };
};
