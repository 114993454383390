import { ReactComponent as ASIconDataPreparation } from 'assets/svg/ASIconDataPreparation.svg';
import { ReactComponent as ASIconTimeSeriesForecasting } from 'assets/svg/ASIconTimeSeriesForecasting.svg';
import { ReactComponent as ASIconAutoML } from 'assets/svg/ASIconAutoML.svg';
import { ReactComponent as ASIconPredictiveModeling } from 'assets/svg/ASIconPredictiveModeling.svg';
import { ReactComponent as ASIconTextToSpeechConversion } from 'assets/svg/ASIconTextToSpeechConversion.svg';
import { ReactComponent as ASIconSpeechToTextConversion } from 'assets/svg/ASIconSpeechToTextConversion.svg';
import { ReactComponent as ASIconTranslate } from 'assets/svg/ASIconTranslate.svg';
import { ReactComponent as ASIconTextDetection } from 'assets/svg/ASIconTextDetection.svg';

const highlights = {
  DataPreparation: [
    'Understand data patterns and detect anomalies',
    'Visualize, clean, and normalize your data with an interactive, point-and-click visual interface.',
    'Visually map the lineage of your data to understand the various data sources and transformation steps that the data has been through.',
  ],
  TimeSeriesForecasting: [
    'Uses statistical and ML algorithms to deliver highly accurate time-series forecasts',
    'Includes deep learning models in its processing capabilities',
    'Helps to build inventory planning solution…etc.',
  ],
  AutoML: [
    'Accelerate your machine learning models faster',
    'Boost efficiency',
    'Chooses right algorithm and does feature selection',
    'Gives the best-fit model pipeline',
  ],
  PredictiveModeling: [
    'Browse, import, and join data',
    'Built-in data cleansing and data adjustments',
    'Model preview',
    'Automatic prediction creation',
  ],
  TextToSpeechConversion: [
    'Allows users to build voice-enabled products, apps, and services',
    'Neural Text-to-Speech (NTTS) functionality',
    'Flexible set-up and customization',
    'Robust API',
  ],
  SpeechToTextConversion: [
    'Capable of produce easy to read transcripts.',
    'Processes live and recorded audio or video input.',
    'Offers APIs transcribe customer calls and conversations.',
    'Works to make the output ready for downstream activities.',
  ],
  Translate: [
    'It allows to take greater control over the output of your machine translation.',
    'Perform batch translation when you have large quantities of pre-existing text',
    'Real-time translation when you want to deliver on-demand translations of content as a feature of your applications.',
  ],
  Textdetection: [
    'Makes it easy to add document text detection and analysis to your applications.',
    'Integration of document text detection into our apps.',
    'It enables you to analyze and extract data quickly from millions of documents, which can accelerate decision making.',
  ],
};

const analyticsStudioTiles = [
  {
    name: 'DataPreparation',
    title: 'Data Preparation',
    icon: ASIconDataPreparation,
    description: 'Automated Data Cleaning, Transformations, Normalizations etc.',
    highlights: highlights.DataPreparation,
    tileLink: 'https://console.aws.amazon.com/databrew/home',
    infoLink: 'https://docs.aws.amazon.com/databrew/latest/dg/getting-started.html',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/DataBrew.mp4',
  },
  {
    name: 'TimeSeriesForecasting',
    title: 'Time Series Forecasting',
    icon: ASIconTimeSeriesForecasting,
    description: 'ML based Time Series Forecasting for Business Metrics Analysis',
    highlights: highlights.TimeSeriesForecasting,
    tileLink: 'https://console.aws.amazon.com/forecast/home',
    infoLink: 'https://docs.aws.amazon.com/forecast/latest/dg/howitworks-forecast.html',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/Forecast.mp4',
  },
  {
    name: 'AutoML',
    title: 'Auto ML',
    icon: ASIconAutoML,
    description: 'AutoML is viewed as about algorithm selection, hyperparameter',
    highlights: highlights.AutoML,
    tileLink: 'https://console.aws.amazon.com/sagemaker/home',
    infoLink: 'https://aws.amazon.com/getting-started/hands-on/machine-learning-tutorial-automatically-create-models/',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/AutoML.mp4',
  },
  {
    name: 'PredictiveModeling',
    title: 'Predictive Modeling',
    icon: ASIconPredictiveModeling,
    description: 'ML based Predictive Modeling using visual point-and-click interface',
    highlights: highlights.PredictiveModeling,
    tileLink: 'https://console.aws.amazon.com/sagemaker/home',
    infoLink: 'https://aws.amazon.com/getting-started/hands-on/machine-learning-tutorial-generate-predictions-without-writing-code/',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/Sagemaker+Canvas.mp4',
  },
  {
    name: 'TextToSpeechConversion',
    title: 'Text to Speech Conversion',
    icon: ASIconTextToSpeechConversion,
    description:
      'Text to speech is a natural language modeling process that requires changing units of text into units of speech for audio presentation.',
    highlights: highlights.TextToSpeechConversion,
    tileLink: 'https://aws.amazon.com/polly/',
    infoLink: 'https://docs.aws.amazon.com/polly/latest/dg/getting-started.html',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/Polly.mp4',
  },
  {
    name: 'SpeechToTextConversion',
    title: 'Speech to Text Conversion',
    icon: ASIconSpeechToTextConversion,
    description: 'Speech to text is used to recognize and translate spoken language into text by making use of computational linguistics.',
    highlights: highlights.SpeechToTextConversion,
    tileLink: 'https://console.aws.amazon.com/transcribe',
    infoLink: 'https://aws.amazon.com/getting-started/hands-on/create-audio-transcript-transcribe/',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/transcribe.mp4',
  },
  {
    name: 'Translate',
    title: 'Translate',
    icon: ASIconTranslate,
    description:
      'Neural machine translation it is a form of language translation automation that uses deep learning models to deliver more accurate and more natural sounding translation than traditional statistical and rule-based translation algorithms, we use amazon translate for translation service.',
    highlights: highlights.Translate,
    tileLink: 'https://console.aws.amazon.com/translate/home',
    infoLink: 'https://aws.amazon.com/getting-started/hands-on/translate-text-between-languages-cloud/',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/translate.mp4',
  },
  {
    name: 'Textdetection',
    title: 'Text Detection',
    icon: ASIconTextDetection,
    description:
      'Text detection is the technique of detecting text in a picture and then enclosing it with a rectangular bounding box. Text can be detected using image-based or frequency-based algorithms. Image-based approaches are used to segment images into several segments.',
    highlights: highlights.Textdetection,
    tileLink: 'https://console.aws.amazon.com/textract/home',
    infoLink: 'https://aws.amazon.com/getting-started/hands-on/extract-text-with-amazon-textract/',
    videoLink: 'https://analyticsstudio-data.s3.ap-south-1.amazonaws.com/textract.mp4',
  },
];

export default analyticsStudioTiles;
