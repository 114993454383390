import React from 'react';
import BVITOthersView from './BVITOthers.view';

const BVITOthers = ({ filterDetails, handleFiltersChange, others, setOthers }) => {

    return(
        <BVITOthersView filterDetails={filterDetails} handleFiltersChange={handleFiltersChange}
         others={others} setOthers={setOthers} />
    )
}

export default BVITOthers;