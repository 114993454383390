import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import * as utils from 'utils';

const form = {
  getFieldProp: (fields, name, control, errors) => {
    if (!fields || !Array.isArray(fields) || isEmpty(fields) || !name) return {};

    const field = fields.find((ffield) => ffield.name === name);

    return {
      ...field,
      ...(utils.generic.isValidObject(control) && { control }),
      ...(utils.generic.isValidObject(errors) && { error: errors[field.name] }),
    };
  },
  getInitialValues: (fields, key = 'name') => {
    if (!fields || !Array.isArray(fields) || isEmpty(fields)) return {};
    let values = {};

    const getValues = (field) => {
      if (!field[key]) return;

      values[field[key]] = field.defaultValue;
    };

    if (fields) {
      fields.forEach((field) => {
        getValues(field);
      });
    }

    return values;
  },
  getValidationSchema: (fields) => {
    const validation = {};

    const getValidationObj = (field) => {
      validation[field.name] = field.validation;
    };

    if (fields) {
      fields.forEach((field) => {
        if (Array.isArray(field)) {
          field.forEach((item) => getValidationObj(item));
        } else {
          getValidationObj(field);
        }
      });
    }

    return Yup.object().shape(validation);
  },
};

export default form;
