import React, { useState } from 'react';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';

// mui
import { Box, Button, Divider, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadFileIcon from '@mui/icons-material/UploadFile';

AddEditCarModelsFormView.propTypes = {
  isPageEdited: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  errors: PropTypes.object,
  isUpdateCarModel: PropTypes.bool.isRequired,
  formProps: PropTypes.shape({
    register: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }),
  handleFileUploadClick: PropTypes.func.isRequired,
  handleChangeFileUpload: PropTypes.func.isRequired,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }),
};

export function AddEditCarModelsFormView({
  isPageEdited,
  fields,
  errors,
  isUpdateCarModel,
  formProps,
  handlers,
  expanded,
  handleChange,
  hiddenFileInput,
  handleFileUploadClick,
  handleChangeFileUpload,
}) {
  const { register, handleSubmit } = formProps;

  return (
    <Box>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Upload</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Click the upload Button to upload file.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Box px={3} pb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }} pt={2}>
              <Button
                variant="contained"
                sx={{ textTransform: 'capitalize', fontSize: '13px' }}
                endIcon={<UploadFileIcon />}
                onClick={handleFileUploadClick}
              >
                <input type="file" onChange={handleChangeFileUpload} ref={hiddenFileInput} style={{ display: 'none' }} />
                Upload
              </Button>
            </Box>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Form</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Please fill the form to add new Car Model.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Box pl={2} py={1} sx={{ display: 'flex', overflowY: 'auto' }}>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handlers.onSubmit)}
              >
                <TextField
                  {...register('category')}
                  {...utils.form.getFieldProp(fields, 'category')}
                  error={!!errors?.category?.message}
                  helperText={!!errors?.category?.message ? errors?.category?.message : ''}
                />
                <TextField
                  {...register('Model')}
                  {...utils.form.getFieldProp(fields, 'Model')}
                  error={!!errors?.Model?.message}
                  helperText={!!errors?.Model?.message ? errors?.Model?.message : ''}
                />
                <TextField
                  {...register('load_factor')}
                  {...utils.form.getFieldProp(fields, 'load_factor')}
                  error={!!errors?.load_factor?.message}
                  helperText={!!errors?.load_factor?.message ? errors?.load_factor?.message : ''}
                />
                <TextField
                  {...register('length')}
                  {...utils.form.getFieldProp(fields, 'length')}
                  error={!!errors?.length?.message}
                  helperText={!!errors?.length?.message ? errors?.length?.message : ''}
                />
                <TextField
                  {...register('width')}
                  {...utils.form.getFieldProp(fields, 'width')}
                  error={!!errors?.width?.message}
                  helperText={!!errors?.width?.message ? errors?.width?.message : ''}
                />
                <TextField
                  {...register('height')}
                  {...utils.form.getFieldProp(fields, 'height')}
                  error={!!errors?.height?.message}
                  helperText={!!errors?.height?.message ? errors?.height?.message : ''}
                />
                <TextField
                  {...register('bonnet_height')}
                  {...utils.form.getFieldProp(fields, 'bonnet_height')}
                  error={!!errors?.bonnet_height?.message}
                  helperText={!!errors?.bonnet_height?.message ? errors?.bonnet_height?.message : ''}
                />
                <TextField
                  {...register('bonnet_length')}
                  {...utils.form.getFieldProp(fields, 'bonnet_length')}
                  error={!!errors?.bonnet_length?.message}
                  helperText={!!errors?.bonnet_length?.message ? errors?.bonnet_length?.message : ''}
                />
                <TextField
                  {...register('middle_height')}
                  {...utils.form.getFieldProp(fields, 'middle_height')}
                  error={!!errors?.middle_height?.message}
                  helperText={!!errors?.middle_height?.message ? errors?.middle_height?.message : ''}
                />
                <TextField
                  {...register('middle_length')}
                  {...utils.form.getFieldProp(fields, 'middle_length')}
                  error={!!errors?.middle_length?.message}
                  helperText={!!errors?.middle_length?.message ? errors?.middle_length?.message : ''}
                />
                <TextField
                  {...register('back_height')}
                  {...utils.form.getFieldProp(fields, 'back_height')}
                  error={!!errors?.back_height?.message}
                  helperText={!!errors?.back_height?.message ? errors?.back_height?.message : ''}
                />
                <TextField
                  {...register('back_length')}
                  {...utils.form.getFieldProp(fields, 'back_length')}
                  error={!!errors?.back_length?.message}
                  helperText={!!errors?.back_length?.message ? errors?.back_length?.message : ''}
                />
              </Box>
            </Box>
            <Divider />
            <Box px={3} pb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }} pt={2}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{ textTransform: 'none' }}
                autoFocus
                disabled={Boolean(isUpdateCarModel && !isPageEdited)}
                onClick={handleSubmit(handlers.onSubmit)}
              >
                {!isUpdateCarModel ? 'Create' : 'Update'}
              </Button>
            </Box>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
