import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import { Layout } from 'components';
import React from 'react';
import PropTypes from 'prop-types';

// app
import { Charts } from 'components';
import * as utils from 'utils';

ReportsView.propTypes = {
  totalActiveUsers: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  avgResponseTime: PropTypes.object.isRequired,
  avgAppLoadTime: PropTypes.object.isRequired,
  acceleratorsWeeklyData: PropTypes.object.isRequired,
  // verticalPieChart: PropTypes.object.isRequired,
  departmentPieChart: PropTypes.object.isRequired,
  overallAcceleratorsUsage: PropTypes.array.isRequired,
};
export default function ReportsView({
  totalActiveUsers,
  user,
  avgResponseTime,
  acceleratorsWeeklyData,
  overallAcceleratorsUsage,
  avgAppLoadTime,
  // verticalPieChart,
  departmentPieChart,
}) {
  // const verticalChart = {
  //   chart: {
  //     plotBackgroundColor: null,
  //     plotBorderWidth: null,
  //     plotShadow: false,
  //     type: 'pie',
  //   },
  //   title: {
  //     text: verticalPieChart?.title,
  //     align: 'center',
  //   },
  //   tooltip: {
  //     pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  //   },
  //   accessibility: {
  //     point: {
  //       valueSuffix: '%',
  //     },
  //   },
  //   plotOptions: {
  //     pie: {
  //       allowPointSelect: true,
  //       cursor: 'pointer',
  //       dataLabels: {
  //         enabled: true,
  //         format: '<b>{point.name}</b>: {point.percentage:.1f} %',
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: 'Vertical',
  //       colorByPoint: true,
  //       data: verticalPieChart?.data,
  //     },
  //   ],
  // };
  const departmentChart = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      width: 979
    },
    title: {
      text: departmentPieChart?.title,
      align: 'center',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
      },
    },
    series: [
      {
        name: 'Department',
        colorByPoint: true,
        data: departmentPieChart?.data,
      },
    ],
  };
  const stackedBarChart = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Weekly Accelerators Usage',
      align: 'center',
    },
    xAxis: {
      categories: acceleratorsWeeklyData?.yData,
      // ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Accelerators usage',
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      align: 'left',
      x: 70,
      verticalAlign: 'top',
      y: 70,
      floating: true,
      backgroundColor: 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: acceleratorsWeeklyData?.xData,
  };
  const horizontalStackedBarChart = {
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Overall Accelerator Usage',
      align:'center',
      padding:'14px'
    },
    xAxis: {
      categories: ['Usage'],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Accelerators',
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: overallAcceleratorsUsage || [],
  };
  const theme = useTheme();
  return (
    <Layout>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontSize="1.5rem"
              fontWeight="600"
              color={(theme) => theme.palette.otherColor.solutionTextColor}
              pb={2}
            >
              Reports
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ boxShadow: 'unset', border: 'none', backgroundColor: 'paper', borderRadius: '8px' }}>
              <Box sx={{ p: 2, borderBottom: '1px solid #edf2f6', textAlign: 'center' }}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '32px', color: (theme) => theme.palette.primary.main }}
                  >
                    {totalActiveUsers?.activeUserCount}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '14px', color: 'black', cursor: 'pointer', whiteSpace: 'wrap' }}
                  >
                    Total Active users
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ boxShadow: 'unset', border: 'none', backgroundColor: 'paper', borderRadius: '8px' }}>
              <Box sx={{ p: 2, borderBottom: '1px solid #edf2f6', textAlign: 'center' }}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '32px', color: (theme) => theme.palette.primary.main }}
                  >
                    {avgResponseTime?.eda}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '14px', color: 'black', cursor: 'pointer', whiteSpace: 'wrap' }}
                  >
                    EDA Response Time
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ boxShadow: 'unset', border: 'none', backgroundColor: 'paper', borderRadius: '8px' }}>
              <Box sx={{ p: 2, borderBottom: '1px solid #edf2f6', textAlign: 'center' }}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '32px', color: (theme) => theme.palette.primary.main }}
                  >
                    {avgResponseTime?.classification}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '14px', color: 'black', cursor: 'pointer', whiteSpace: 'wrap' }}
                  >
                    Classification Response Time
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ boxShadow: 'unset', border: 'none', backgroundColor: 'paper', borderRadius: '8px' }}>
              <Box sx={{ p: 2, borderBottom: '1px solid #edf2f6', textAlign: 'center' }}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '32px', color: (theme) => theme.palette.primary.main }}
                  >
                    {avgResponseTime?.regression}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '14px', color: 'black', cursor: 'pointer', whiteSpace: 'wrap' }}
                  >
                    Regression Response Time
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ boxShadow: 'unset', border: 'none', backgroundColor: 'paper', borderRadius: '8px' }}>
              <Box sx={{ p: 2, borderBottom: '1px solid #edf2f6', textAlign: 'center' }}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '32px', color: (theme) => theme.palette.primary.main }}
                  >
                    {avgAppLoadTime?.avgAppLoadTime}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '14px', color: 'black', cursor: 'pointer', whiteSpace: 'wrap' }}
                  >
                    Avg App Load Time
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ boxShadow: 'unset', border: 'none', backgroundColor: 'paper', borderRadius: '8px', minHeight: '104px' }}>
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Box>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '14px', color: 'black', cursor: 'pointer', whiteSpace: 'wrap' }}
                  >
                    Most Active User
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{ fontWeight: '600', fontSize: '14px', pt: 1, color: (theme) => theme.palette.primary.main }}
                  >
                    {utils.generic.getName(user?.mostActiveUser)}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Charts chartOptions={departmentChart} />
          </Grid>
          {/* <Grid item xs={12} sm={4} md={6} lg={6} p={1}>
            <Charts chartOptions={verticalChart} />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Charts chartOptions={stackedBarChart} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Charts chartOptions={horizontalStackedBarChart} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
